
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_list_contenu_newsletters_actions, get_liste_newsletters_action } from '../../reduxComponents/Actions/ParametrePagesActions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const ListeNewsLettersSend = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const DataLIsteStore = useSelector(state=>state?.get_list_contenu_newsletters_store)
    const [DataListNews, setDataListNews] = useState(null)
    const [loading, setLoading] = useState(false);
  

    const [NewslettersSelect, setNewslettersSelect] = useState(null)
    const [ISsELECTOne, setISsELECTOne] = useState(false)





    useEffect(()=>{
        setLoading(true)
        dispatch(get_list_contenu_newsletters_actions())
    },[])

    useEffect(()=>{

        if (DataLIsteStore?.Data_list_Con_Newsl) {
           
            setDataListNews(DataLIsteStore?.Data_list_Con_Newsl)
            setLoading(false)
        }

    },[DataLIsteStore?.Data_list_Con_Newsl])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

   
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redidectCreateCont = ()=>{
        navigate('create-contenu')
    }


    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un contenu" />
                    </span>
                </div>
                <div className="flex justify-content-end">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <Button label="Créer un nouveau contenu" className='rounded-lg btnBacground01' onClick={()=>redidectCreateCont()} />
                    </span>
                </div>
            
            </div>
        );
    };








    const header = renderHeader();



    const bodyDate= (rowData)=>{
        return(
            <div>
                {
                    formatDateBon(rowData?.date_envoi)
                }
            </div>
        )
    }

    const viewDataNesletters = (rowData)=>{
        setNewslettersSelect(rowData)
        setISsELECTOne(true)
        console.log(rowData)
    }

    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>viewDataNesletters(rowData)} ><i class="fi fi-sr-eye"></i></button>
            </div>
        )
    }



    return (
        <div className="card m-4">
            <DataTable value={DataListNews} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['sujet', 'date_envoi']} header={header} emptyMessage="Aucun contenu trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataListNews, props) => props?.rowIndex + 1} />

                <Column field="sujet" header="Titre"  style={{ minWidth: '16rem' }} />
                <Column header="envoyé le : " field='date_envoi' body={bodyDate} style={{ minWidth: '16rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />

                
                


            </DataTable>

            <Dialog header={NewslettersSelect?.sujet} visible={ISsELECTOne} style={{ width: '75vw' }} onHide={() => {if (!ISsELECTOne) return; setISsELECTOne(false); }}>
                <div className='p-4'>
                    
                    <div>
                    <div>
                            <div dangerouslySetInnerHTML={
                                { __html: NewslettersSelect?.contenu }
                            } />
                        </div>
                        
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default ListeNewsLettersSend


