import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './ExampleCarouselImage'; // Assuming this component renders the image

import imageSLide from '../../../../images/ImagesCommission/Image défilante 0F7A0926.jpeg';
import imageSLide02 from '../../../../images/ImagesCommission/Image défilante 1527362638DSC-6767.jpg';
import imageSLide03 from '../../../../images/ImagesCommission/Image défilante DSC_6721K.jpg';
import imageSLide04 from '../../../../images/ImagesCommission/Image défilante _A0A9245.jpg';
import imageSLide05 from '../../../../images/ImagesCommission/Image défilante.jpeg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Get_Last_List_Caroussels_Actions } from '../../../../reduxComponents/Actions/PublicationsActions';
import { BackendEntete } from '../../../../VariablesConfig';



function CarouselSLide() {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const DataListCarrouselStore = useSelector(state => state.get_carrouselsImage)

  const [DataListCarrousel, setDataListCarrousel] = useState()

  const redirectArticle = (idparams)=>{
    const id = btoa(idparams)
    navigate(`/actualites/publication/${id}`)
  }

  useEffect(() => {
    dispatch(Get_Last_List_Caroussels_Actions())
  }, [])

  useEffect(() => {
    if (DataListCarrouselStore?.DataListCarrousel) {
      setDataListCarrousel(DataListCarrouselStore?.DataListCarrousel)
    }

  }, [DataListCarrouselStore?.DataListCarrousel])
  return (
    <Carousel className="rounded-md overflow-hidden sectionAnime"> {/* Responsive handling */}
      {
        DataListCarrousel && (
          DataListCarrousel.map((CarrouselItems, idx) => (
            <Carousel.Item interval={3000} onClick={()=>redirectArticle(CarrouselItems?.id)} className='cursor-pointer'>
              <ExampleCarouselImage
                src={BackendEntete + CarrouselItems.image_cover}
                alt={CarrouselItems?.titre}
                text={CarrouselItems?.titre}
                className="d-block w-100 h-auto rounded-md" // Tailwind for responsiveness
              />
              <Carousel.Caption className="bg-teal-900 text-white rounded-md px-4">
                <p className='text-carrousel'>
                  {CarrouselItems?.titre}
                </p>
              </Carousel.Caption>
            </Carousel.Item>
          ))
        )
      }


    </Carousel>
  );
}

export default CarouselSLide;
