import React from 'react'
import facebookImage from '../../../../images/Facebook_icon.svg'
import instagram from '../../../../images/Instagram_logo_2016.svg.webp'
import youtubeicon from '../../../../images/youtube.svg'
import twitter from '../../../../images/logo-twitter.jpg'


const Reseau_Sociaux = () => {
  return (
    <div class="social-iconsSociaux flex-col">
        <a href="https://web.facebook.com/Page.Officielle.CC/" target='_bank' className='elemntsociaux sectionAnime '>
            <img src={facebookImage} alt="Facebook" width={40} />
        </a>

        <a href="https://www.instagram.com/invites/contact/?i=nvwikg0z23on&amp;utm_content=nf32hlv" target='_bank' className='elemntsociaux sectionAnime02'>
            <img src={instagram} alt="Instagram" width={40} />
        </a>
        <a href="https://twitter.com/de_concurrence?t=4ch-Z6rilKV9MWcZ7LL6gQ&amp;s=09" target='_bank' className='elemntsociaux sectionAnime03'>
            <img src={twitter} alt="Twitter" width={40} />
        </a>
        <a href="lien_vers_facebook" target='_bank' className='elemntsociaux sectionAnime04'>
            <img src={youtubeicon} alt="Facebook" width={40} />
        </a>
        
    </div>
  )
}

export default Reseau_Sociaux
