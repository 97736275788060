import React from 'react';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const Bonjours = () => {
    const botMessage = createChatBotMessage('Bonjour à vous ? Nous sommes heureux de vous revoir ! En quoi nous pouvons vous aider ?');
    const Question01 = createChatBotMessage('Question 01');

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage, Question01],
    }));
  };

  const QuestionCommissionWelcome = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "La concurrence déloyale se réfère à un ensemble de pratiques commerciales contraires à l'éthique et à la réglementation en vigueur, qui visent à obtenir un avantage concurrentiel injuste aux dépens d'autres entreprises. Elle vise à fausser le jeu concurrentiel en perturbant la confiance des consommateurs, en les induisant en erreur ou en nuisant à la réputation des concurrents.",
      { widget: "Question02Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question02Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "La concurrence déloyale couvre essentiellement les quatre pratiques suivantes :vLe dénigrement, l’imitation fautive, la désorganisation et le parasitisme",
      { widget: "Question02Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question03Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Le dénigrement est le plus souvent défini comme des agissements qui tendent à jeter un discrédit sur une entreprise ou sur ses produits.",
      { widget: "Question03Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question04Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Il s'agit d'une manœuvre visant à exploiter la réputation d'un concurrent en créant une confusion dans l'esprit des clients, dans le but de s'attirer une clientèle.",
      { widget: "Question04Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }


  const Question05Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Se réfère généralement à une pratique anticoncurrentielle dans laquelle une entreprise perturbe délibérément le fonctionnement normal du marché ou de la concurrence en introduisant des éléments de perturbation.",
      { widget: "Question05Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question06Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "On peut définir la concurrence parasitaire comme une série de comportements où un acteur économique profite, sans investir de ressources significatives, des efforts, du savoir-faire et de la notoriété d'un autre, en suivant de près ses actions pour en tirer un avantage économique.",
      { widget: "Question06Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question07Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Les pratiques restrictives de la concurrence sont des comportements d'acteurs économiques présumés restreindre la concurrence et, pour cette raison, sont interdits indépendamment de leur impact réel sur le marché.",
      { widget: "Question07Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question08Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Les infractions relatives aux prix, les infractions relatives aux conditions de vente, les infractions relatives aux opérations d'importations et d'exportations.",
      { widget: "Question08Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }


  const Question09Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Outres les peines privatives de liberté, les sanctions pécuniaires sont comprises entre 500 milles et 50 millions. ",
      { widget: "Question09Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }

  const Question10Response = () => {
    const ResponseCommissionWelcome = createChatBotMessage(
      "Selon l’étendu des enquêtes à mener, deux à trois mois.",
      { widget: "Question10Response" }
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, ResponseCommissionWelcome],
    }));
  }
  const QuestionPresidentCommission = () => {
    const messagesContent = createChatBotMessage(
      'Le president de la Commission de la Concirrence se nomme ......, Retrouvez-ci dessous une image du president',
      { widget: "PresidentLaCommission" }
    );

    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, messagesContent],
    }));
  }


  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {

            QuestionCommissionWelcome,
            QuestionPresidentCommission,
            Question02Response,
            Question03Response,
            Question04Response,
            Question05Response,
            Question06Response,
            Question07Response,
            Question08Response,
            Question09Response,
            Question10Response
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;