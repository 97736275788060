
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_GaleriePhoto_liste } from '../../../reduxComponents/Actions/GaleriePhotoActions';
import { BackendEntete } from '../../../VariablesConfig';

const DataLIsteGaleriePhoto = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const DataListeGaleriePhotoStore = useSelector(state=>state.getListeGaleriephoto)
    const [DataListeGaleriePhotoAdmin, setDataListeGaleriePhotoAdmin] = useState()
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre: `Utilisateur ${i}`,
               
                Date_ajoute: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    useEffect(()=>{
        dispatch(get_GaleriePhoto_liste())
    },[])

    useEffect(()=>{
        if (DataListeGaleriePhotoStore?.DataListeGaleriePhoto) {
            setDataListeGaleriePhotoAdmin(DataListeGaleriePhotoStore?.DataListeGaleriePhoto)
        }
    }, [DataListeGaleriePhotoStore?.DataListeGaleriePhoto])

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le communiqué" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/actualites/galerie-photo/creer')
    }

    const renderHeader = () => {
        return (
            <>
             <div className='py-3'>
                <h3 > <span className='font-bold text-teal-600'>Galerie Photo</span> </h3>
             </div>
            <div className='flex justify-between'>
               
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher une galérie photo" />
                </span>
                </div>
            <div className="flex justify-content-end">
            <Button label="Ajouter une nouvelle galerie" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
            </>
            
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={ BackendEntete + rowData.image_cover} width="136"/>
               
            </div>
        )
    }
    const RedirectTo = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/galerie-photo/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/galerie-photo/modifier/${idBtoa}`, {replace : false})
    }

    const DialogueArchive = ()=>{
        setVisibleDialogue(true)
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData.id)} ><i class="fi fi-sr-eye"></i></button>
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive(rowData.id)} text className='text-red-500'><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }



    return (
        <div className="card">
            <DataTable value={DataListeGaleriePhotoAdmin} paginator  showGridlines rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['titre', 'id',  'status']} header={header} emptyMessage="Aucune galérie photo enregistrée.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                <Column field="image_cover" header="Image de couverture" body={photoBody} style={{ minWidth: '9rem' }} />
                <Column header="Titre de la galerie" field='titre' style={{ minWidth: '9rem' }} />
                <Column header="Date de creation" field='date_ajoute' style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />

             
            </DataTable>

            <div className="card flex justify-content-center">
           
            {/* <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog> */}
        </div>
        </div>
    )
}

export default DataLIsteGaleriePhoto


