import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import Config from './config/Config';
import MessageParser from './messagepaser/MessageParser';
import ActionProvider from './actionProvider/ActionsProvider';
import './chatbot.css'
import { useState } from 'react';


const ChatbotComposant = () => {
    const [showBot, toggleBot] = useState(false);
    const headers = (
        <div className="chatboxheader">
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4325 21.0999C27.2012 21.9945 24.7928 22.5463 22.2738 22.6893C21.8393 22.7139 21.4016 22.7264 20.9609 22.7264C20.5203 22.7264 20.0825 22.7139 19.6481 22.6893C17.1285 22.5463 14.7195 21.9943 12.4878 21.0993C7.80445 19.2211 3.90197 15.8323 1.39648 11.5432C5.30172 4.85786 12.6009 0.359985 20.9609 0.359985C29.321 0.359985 36.6201 4.85786 40.5254 11.5432C38.0196 15.8328 34.1165 19.2218 29.4325 21.0999Z" fill="white" />
                    <path d="M12.4876 22.8403C11.9481 22.6239 11.4189 22.3875 10.901 22.132C9.21799 23.306 7.79521 24.7683 6.72388 26.4366C8.54487 29.2722 11.3812 31.5126 14.7851 32.7544C16.407 33.3461 18.1579 33.711 19.9892 33.8056H21.8975C23.7283 33.7111 26.9463 35.5072 22.2735 40.04C25.6779 38.7983 33.3416 29.2726 35.1628 26.4366C34.0945 24.773 32.6768 23.3144 31 22.142C30.4882 22.3939 29.9653 22.6272 29.4323 22.8409C27.201 23.7355 24.7926 24.2873 22.2735 24.4303C21.8391 24.4549 21.4014 24.4674 20.9607 24.4674C20.52 24.4674 20.0823 24.4549 19.6479 24.4303C17.1283 24.2873 14.7193 23.7353 12.4876 22.8403Z" fill="white" />
                    <rect x="11.527" y="8.6825" width="18.9093" height="6.68764" rx="3.34382" fill="#162550" />
                    <ellipse cx="26.196" cy="11.9891" rx="1.23811" ry="1.22607" fill="#04FED1" />
                    <ellipse cx="20.9432" cy="28.4852" rx="1.23811" ry="1.22607" fill="#162550" />
                    <ellipse cx="15.9916" cy="11.9891" rx="1.23811" ry="1.22607" fill="#04FED1" />
                    <ellipse cx="15.9916" cy="28.4852" rx="1.23811" ry="1.22607" fill="#162550" />
                    <ellipse cx="25.8963" cy="28.4852" rx="1.23811" ry="1.22607" fill="#162550" />
                </svg>
            </div>
            <div>
                <div>
                    <span className="chatbotTitle">Chatbot de la CC</span>
                </div>
                <div className='onlineText'>
                    En ligne
                </div>

            </div>

        </div>
    )

    return (
        <div>
            {showBot && (
                <Chatbot
                    config={Config}
                    headerText={headers}
                    messageParser={MessageParser}
                    actionProvider={ActionProvider}
                />
            )
            }
            <div>
                <button
                    className="app-chatbot-button"
                    onClick={() => toggleBot((prev) => !prev)}
                >

                    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="58" viewBox="0 0 58 58" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.6515 30.3154C38.4012 31.6231 34.8928 32.4296 31.2232 32.6386C30.5904 32.6746 29.9527 32.6929 29.3108 32.6929C28.6689 32.6929 28.0312 32.6746 27.3984 32.6386C23.728 32.4295 20.2187 31.6227 16.9678 30.3145C10.1454 27.5691 4.46059 22.6158 0.810791 16.3464C6.49964 6.57451 17.1325 0 29.3108 0C41.4891 0 52.1219 6.57451 57.8108 16.3464C54.1606 22.6165 48.4749 27.5702 41.6515 30.3154Z" fill="white" />
                        <path d="M16.9672 32.8593C16.1812 32.543 15.4104 32.1974 14.656 31.8239C12.2043 33.54 10.1317 35.6774 8.57104 38.1159C11.2237 42.2608 15.3555 45.5356 20.314 47.3506C22.6768 48.2155 25.2273 48.749 27.8949 48.8872H30.6748C33.3419 48.749 38.0295 51.3745 31.2226 57.9999C36.1818 56.185 47.3457 42.2613 49.9987 38.1159C48.4425 35.6844 46.3773 33.5522 43.9347 31.8386C43.1891 32.2068 42.4273 32.5478 41.6509 32.8602C38.4006 34.1679 34.8922 34.9744 31.2226 35.1834C30.5898 35.2194 29.9521 35.2377 29.3102 35.2377C28.6682 35.2377 28.0306 35.2194 27.3978 35.1834C23.7274 34.9743 20.2181 34.1675 16.9672 32.8593Z" fill="white" />
                        <rect x="15.5679" y="12.1649" width="27.5456" height="9.77528" rx="4.88764" fill="#162550" />
                        <ellipse cx="36.9369" cy="16.9983" rx="1.80358" ry="1.79213" fill="#04FED1" />
                        <ellipse cx="29.2848" cy="41.1105" rx="1.80358" ry="1.79213" fill="#162550" />
                        <ellipse cx="22.0716" cy="16.9983" rx="1.80358" ry="1.79213" fill="#04FED1" />
                        <ellipse cx="22.0716" cy="41.1105" rx="1.80358" ry="1.79213" fill="#162550" />
                        <ellipse cx="36.5001" cy="41.1105" rx="1.80358" ry="1.79213" fill="#162550" />
                    </svg>
                </button>
            </div>
        </div>
    );
};




export default ChatbotComposant