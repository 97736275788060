import React from 'react'
import { useNavigate } from 'react-router-dom'

const SaisineBtnActions = () => {
    const navigate = useNavigate()
    return (
        <>
            <div className="col-span-1 md:col-span-1 rounded-md border border-teal-600 text-center pb-4">
                <div>
                    <p className='text-xl font-bold  bg-teal-100 border-b-4 border-teal-600 py-2 text-teal-600'>DEPOT DE PLAINTE</p>
                </div>
                <div className='  sectionAnime'>
                    <div className='text-center'>
                        <p className=' px-4'>Commission de la Concurrence, Régulateur de l’activité économique.</p>
                    </div>

                    <div className='flex flex-col gap-2'>
                        <div><button type="button" className='btn-action-OrangeSaisine' onClick={()=>navigate('/nous-saisir/qui-peut-saisir-lacommission')}>QUI PEUT SAISIR LA COMMISSION ? </button></div>
                        <div><button type="button" className=' btn-action-BlackSaisine' onClick={()=>navigate('/nous-saisir/comment-saisir-la-commission')}>COMMENT SAISIR LA COMMISSION ?</button></div>
                        <div><button type="button" className='btn-action-greenSaisine' onClick={()=>navigate('/nous-saisir/faire-une-saisine')}>FAIRE UN DEPOT DE PLAINTE</button></div>
                    
                    </div>

                </div>
            </div>
        </>
    )
}

export default SaisineBtnActions
