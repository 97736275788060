import {Gestion_Des_Types_Activites} from '../Types/GestionDesActivites'

const initialCreateActivite = {
    isLoading: false,
    DataCreateActivite: [],
    error: "",
};


const initialListeActivite = {
    isLoading: false,
    DataListeActivite: [],
    error: "",
};



const initialDetailActivite = {
    isLoading: false,
    DataDetailActivite: [],
    error: "",
};


const initialUpdateActivite = {
    isLoading: false,
    DataUpdateActivite: [],
    error: "",
};

const initialListByCatActivite = {
    isLoading: false,
    DataListByCat: [],
    error: "",
};

const initialDeleteActivite = {
    isLoading: false,
    DataDeleteActivite: [],
    error: "",
};

const initialArchiveActivite = {
    isLoading: false,
    DataArchiveActivite: [],
    error: "",
};


export const put_Archive_Activite_reducers = (state=initialArchiveActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataArchiveActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataArchiveActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataArchiveActivite : null
            };
        default:
            return state;
    }
}


export const Get_Liste_By_Cat_Reducer = (state=initialListByCatActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataListByCat : null,
                error : null
            };
        case Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListByCat: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListByCat : null
            };
        default:
            return state;
    }
}



export const Put_Update_Activites = (state=initialUpdateActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.PUT_UPDATE_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataUpdateActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.PUT_UPDATE_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdateActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.PUT_UPDATE_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdateActivite : null
            };
        default:
            return state;
    }
}

export const Delete_Activite_Reducers = (state=initialDeleteActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.DELETE_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataDeleteActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.DELETE_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataDeleteActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.DELETE_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDeleteActivite : null
            };
        default:
            return state;
    }
}

export const Get_Detail_Activite_Reducers = (state=initialDetailActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataDetailActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataDetailActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailActivite : null
            };
        default:
            return state;
    }
}

export const Post_Create_Activite_Reducers = (state=initialCreateActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataCreateActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateActivite : null
            };
        default:
            return state;
    }
}

export const Get_Liste_Activite = (state=initialListeActivite, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_START:
            return {
                ...state,
                isLoading: true,
                DataListeActivite : null,
                error : null
            };
        case Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListeActivite: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListeActivite : null
            };
        default:
            return state;
    }
}





const initialCreateFlash = {
    isLoading: false,
    DataCreateFlashInfo: [],
    error: "",
};

const initialListAdminFLash = {
    isLoading: false,
    DataFlashInfoAdmin: [],
    error: "",
};

const initialListVisiteurFlash = {
    isLoading: false,
    DataListFlashVisiteur: [],
    error: "",
};

const initialUpdateFlashInfo = {
    isLoading: false,
    DalaListUpdateFlash: [],
    error: "",
};

const initialArchiveFlashInfo = {
    isLoading: false,
    DataLIsteArchiveFlash: [],
    error: "",
};

export const Put_Archive_FlashInfo = (state=initialArchiveFlashInfo, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_START:
            return {
                ...state,
                isLoading: true,
                DataLIsteArchiveFlash : null,
                error : null
            };
        case Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataLIsteArchiveFlash: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataLIsteArchiveFlash : null
            };
        default:
            return state;
    }
}




export const Put_UPdate_FlashInfo = (state=initialUpdateFlashInfo, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_START:
            return {
                ...state,
                isLoading: true,
                DalaListUpdateFlash : null,
                error : null
            };
        case Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DalaListUpdateFlash: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DalaListUpdateFlash : null
            };
        default:
            return state;
    }
}

export const Get_list_Visiteur_Reducers = (state=initialListVisiteurFlash, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_START:
            return {
                ...state,
                isLoading: true,
                DataListFlashVisiteur : null,
                error : null
            };
        case Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListFlashVisiteur: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListFlashVisiteur : null
            };
        default:
            return state;
    }
}

export const Get_list_Admin_FlashReducers = (state=initialListAdminFLash, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_START:
            return {
                ...state,
                isLoading: true,
                DataFlashInfoAdmin : null,
                error : null
            };
        case Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataFlashInfoAdmin: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataFlashInfoAdmin : null
            };
        default:
            return state;
    }
}

export const Post_Create_Flash_Info = (state=initialCreateFlash, action) => {

    switch (action.type) {
        case Gestion_Des_Types_Activites.CREATE_FLAH_INFO_START:
            return {
                ...state,
                isLoading: true,
                DataCreateFlashInfo : null,
                error : null
            };
        case Gestion_Des_Types_Activites.CREATE_FLAH_INFO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateFlashInfo: action.payload,
                error : null
            };


        case Gestion_Des_Types_Activites.CREATE_FLAH_INFO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateFlashInfo : null
            };
        default:
            return state;
    }
}


