
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { BackendEntete } from '../../../../VariablesConfig';
import { get_liste_Partenaires_Actions, post_create_partanires_actions, put_archive_partenaires_actions, put_update_partenaires_actions } from '../../../../reduxComponents/Actions/ParametrePagesActions';

const DataListPartenaires = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const DataListStore = useSelector(state => state.List_partenaires_Store)
    const DataCreatePartenaire = useSelector(state=>state.Create_Partenaire_Store)
    const DataUpdateStore = useSelector(state=>state.Update_Partenaire_Store)
    const DataArchive = useSelector(state=>state.put_Archive_partenaire_store)
    const [imageUrl, setImageUrl] = useState('');
    const [params_id, setParams_id]=useState(null)
    const [IsUpdate, setIsUpdate]=useState(false)
    const [IsArchive, setIsArchive] = useState(false)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id
    


    useEffect(()=>{
        dispatch(get_liste_Partenaires_Actions())
    },[])
    

    const id = 12
    const [DataListe, setDataListe] = useState()
    const [errors, setErrors] = useState({})

    const [Site_Internet, setSite_Internet] = useState()
    const [Lien_logo, setLien_logo] = useState(null)

    useEffect(() => {

        if (DataListStore?.DataListPartenaire) {
            setDataListe(DataListStore?.DataListPartenaire)
        }
    }, [DataListStore?.DataListPartenaire])
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');


    useEffect(()=>{
        if (DataCreatePartenaire?.DataCreatePartenaire?.id  && loading) {
            dispatch(get_liste_Partenaires_Actions())
            setLien_logo(null)
            setSite_Internet(null)
            setIsCreatePartenaire(false)
            setLoading(false)
            
        }
    },[DataCreatePartenaire?.DataCreatePartenaire])

    useEffect(()=>{
        if (DataUpdateStore?.DataUpdatePartenaire?.id && loading) {
            dispatch(get_liste_Partenaires_Actions())
            setImageUrl(null)
            setSite_Internet(null)
            setParams_id(null)
            setIsUpdate(false)
            setLoading(false)
        }
    },[DataUpdateStore?.DataUpdatePartenaire])

    useEffect(()=>{
        if (DataArchive?.DataArchive?.success && loading) {
            dispatch(get_liste_Partenaires_Actions())
            setIsArchive(false)
            setLoading(false)
        }
    },[DataArchive?.DataArchive])



    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = () => {
        navigate('/admin/parametre/administrateur/create')
    }

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un partenaire" />
                    </span>
                </div>
                <div className="flex justify-content-end">
                    <Button label="Ajouter un partenaire" className='border-none btnBacground01' onClick={() => setIsCreatePartenaire(true)} />

                </div>
            </div>
        );
    };

    const [IsCreatePartenaire, setIsCreatePartenaire] = useState(false)








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt="commission de la concurrence" src={BackendEntete + rowData.lien_logo} width="96" />

            </div>
        )
    }
  

    const RedirectToUpdate = (rowData) => {
        const idBtoa = btoa(id)
        setImageUrl(BackendEntete + rowData?.lien_logo)
        setSite_Internet(rowData?.site_internet)
        setParams_id(rowData?.id)
        setIsUpdate(true)
    
    }
    

    const DialogueArchive = (rowData) => {
        setParams_id(rowData?.id)
        setIsArchive(true)
    }


    const ActionsBody = (rowData) => {
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={() => RedirectToUpdate(rowData)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={() => DialogueArchive(rowData)} text className='text-red-500'><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }


    useEffect(()=>{},[])




    const CreateNewPartenaires = (event) => {
        event.preventDefault()
        

        const newErrors = {};

        if (!imageUrl) newErrors.imageUrl = true;
        if (!Site_Internet) newErrors.Site_Internet = true;


        let body



        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            body = {
                lien_logo: imageUrl,
                site_internet: Site_Internet,
                administrateur : admin_id
            }

            

            dispatch(post_create_partanires_actions(body))

        }

    }



    const ModifierPartenaires = (event) => {
        event.preventDefault()
        

        const newErrors = {};

        if (!imageUrl) newErrors.imageUrl = true;
        if (!Site_Internet) newErrors.Site_Internet = true;


        let body



        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            const ELevementConcerne = DataListStore?.DataListPartenaire.filter(item=>item.id===params_id)
            console.log(ELevementConcerne)
            const ancienLien = BackendEntete + ELevementConcerne[0].lien_logo
            const verificationlien_logo = imageUrl===ancienLien ? true : false
            if (verificationlien_logo) {
                body = {
                    
                    site_internet: Site_Internet,
                    administrateur : admin_id
                }
            }else{
                body = {
                    lien_logo: imageUrl,
                    site_internet: Site_Internet,
                    administrateur : admin_id
                }
            }
           

            

            dispatch(put_update_partenaires_actions(params_id,body))

        }

    }

    const ArchiveELement = ()=>{
        setLoading(true)
        dispatch(put_archive_partenaires_actions(params_id))
    }


    const headerElementArchive = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    

    const footerContentArchive = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setIsArchive(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver" icon="pi pi-check" onClick={() => ArchiveELement()} autoFocus />
        </div>
    );


    const StatutFLash = (rowData) => {
        return (
            <div>
                {
                    rowData?.is_archive ? (
                        <div className='py-2 bg-red-500 text-white text-center rounded-lg'>
                            Archivé
                        </div>
                    ) : (
                        <div className='py-2 bg-teal-500 text-white text-center rounded-lg' >
                            Non archivé
                        </div>
                    )
                }
            </div>
        )
    }



    return (
        <div className="card">
            <DataTable value={DataListe} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['name', 'username', 'representative.name', 'status']} header={header} emptyMessage="Aucun partenaires trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                <Column field="lien_logo" header="logo" body={photoBody} style={{ minWidth: '9rem' }} />
                <Column header="Lien site" field='site_internet' style={{ minWidth: '9rem' }} />
                <Column header="Statut" field='is_archive' body={StatutFLash} style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">

                <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                    <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Dialog>
            </div>

            <Dialog header="Ajouter un partenaire" visible={IsCreatePartenaire} style={{ width: '50vw' }} onHide={() => {
                setIsCreatePartenaire(false)
            }}>

                <div className="inputForm w-full">
                    <div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire px-3 w-full ">
                                <div> <label htmlFor="Nom">Logo du partenaire</label></div>
                                <div>
                                    <div className="photoROw pt-2 " >

                                        <label htmlFor="profile-photo" style={{ cursor: 'pointer' }}>
                                            <img
                                                src={imageUrl} // URL de l'image sélectionnée ou de l'avatar par défaut
                                                alt="Profile"
                                                className='imageAddUser border-3'
                                                style={{
                                                    
                                                    maxHeight: '300px',
                                                    borderRadius: '12px',
                                                    
                                                }}
                                            />
                                        </label>
                                        <input
                                            id="profile-photo"
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png " // Accepte uniquement les fichiers image
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange} // Gestionnaire d'événement pour le changement de fichier

                                        />

                                    </div>
                                    <div>
                                        <p className='py-3 font-bold text-red-500'>CLiquer sur la section pour selectionner le logo</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire p-3 w-full">
                                <div> <label htmlFor="Username">site internet</label></div>
                                <div>
                                    <div><InputText value={Site_Internet} placeholder='www.exemple-site.ci' className={errors.Site_Internet ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => setSite_Internet(e.target.value)} aria-describedby="Nom-help" /></div>

                                </div>

                            </div>




                        </div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">

                                <button className='btnDegrad' onClick={(event) => CreateNewPartenaires(event)} type="button">Ajouter le partenaire</button>

                            </div>

                        </div>
                    </div>
                </div>
            </Dialog>



            <Dialog header="MOdifier un partenaire" visible={IsUpdate} style={{ width: '50vw' }} onHide={() => {
                setIsUpdate(false)
            }}>

                <div className="inputForm w-full">
                    <div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire px-3 w-full ">
                                <div> <label htmlFor="Nom">Logo du partenaire</label></div>
                                <div>
                                    <div className="photoROw pt-2 " >

                                        <label htmlFor="profile-photo" style={{ cursor: 'pointer' }}>
                                            <img
                                                src={imageUrl} // URL de l'image sélectionnée ou de l'avatar par défaut
                                                alt="Profile"
                                                className='imageAddUser border-3'
                                                style={{
                                                    
                                                    maxHeight: '300px',
                                                    borderRadius: '12px',
                                                    
                                                }}
                                            />
                                        </label>
                                        <input
                                            id="profile-photo"
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png " // Accepte uniquement les fichiers image
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange} // Gestionnaire d'événement pour le changement de fichier

                                        />

                                    </div>
                                    <div>
                                        <p className='py-3 font-bold text-red-500'>CLiquer sur la section pour selectionner le logo</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire p-3 w-full">
                                <div> <label htmlFor="Username">site internet</label></div>
                                <div>
                                    <div><InputText value={Site_Internet} placeholder='www.exemple-site.ci' className={errors.Site_Internet ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => setSite_Internet(e.target.value)} aria-describedby="Nom-help" /></div>

                                </div>

                            </div>




                        </div>
                        <div className=" flex justify-content-center  w-full ">
                            <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">

                                <button className='btnDegrad' onClick={(event) => ModifierPartenaires(event)} type="button">Modifier le partenaire</button>

                            </div>

                        </div>
                    </div>
                </div>
            </Dialog>


            <div className="card flex justify-content-center">
           
            <Dialog visible={IsArchive} modal header={headerElementArchive} footer={footerContentArchive} style={{ width: '30rem' }} onHide={() => setIsArchive(false)}>
                <div className='text-center'>
                    <p className="m-0">
                    Vous souhaité archivé   <b> <i>un partenaire</i></b>
                    </p>
                    <p className='font-bold text-red-500'>
                        <i>
                            Cette action est irreversible
                        </i>
                    </p>
                </div>
            </Dialog>
        </div>



        </div>
    )
}

export default DataListPartenaires



