export const Gestion_DesVideosTypes = {
    CREATE_GALVIDEO_START : "CREATE_GALVIDEO_START",
    CREATE_GALVIDEO_SUCCESS : "CREATE_GALVIDEO_SUCCESS",
    CREATE_GALVIDEO_FAILED : "CREATE_GALVIDEO_FAILED",

    PUT_UPDATE_GALVIDEOS_START : " PUT_UPDATE_GALVIDEOS_START",
    PUT_UPDATE_GALVIDEOS_SUCCESS : "PUT_UPDATE_GALVIDEOS_SUCCESS",
    PUT_UPDATE_GALVIDEOS_FAILED : "PUT_UPDATE_GALVIDEOS_FAILED",


    GET_GALVIDEOS_DETAILS_START : " GET_GALVIDEOS_DETAILS_START",
    GET_GALVIDEOS_DETAILS_SUCCESS : "GET_GALVIDEOS_DETAILS_SUCCESS",
    GET_GALVIDEOS_DETAILS_FAILED : "GET_GALVIDEOS_DETAILS_FAILED",

    GET_GALVIDEOS_LISTE_START : " GET_GALVIDEOS_LISTE_START",
    GET_GALVIDEOS_LISTE_SUCCESS : "GET_GALVIDEOS_LISTE_SUCCESS",
    GET_GALVIDEOS_LISTE_FAILED : "GET_GALVIDEOS_LISTE_FAILED",

    
}