import { Gestions_Admin_Types } from "../Types/GestionAdmin_types";
import axios from '../axios/axios'
 

export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const EEROR_SERVER_SURVENIR = 'EEROR_SERVER_SURVENIR';


export const configHeadersToken = (tok) => {
  const token = localStorage.getItem('access_token_cc')
    return {
        headers:{
        "Content-Type": "application/json",
        "Authorization": 'Bearer ' + token 
    }
    }
}



export const post_Create_Admin = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Admin_Types.POST_CREATE_ADMIN_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/creer_administrateur/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Admin_Types.POST_CREATE_ADMIN_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Admin_Types.POST_CREATE_ADMIN_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Admin_Types.POST_CREATE_ADMIN_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };



  export const Post_Login_Admin = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Admin_Types.POST_LOGIN_ADMIN_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/authentification/admin/", body)
        .then((res) => {
          dispatch({
            type: Gestions_Admin_Types.POST_LOGIN_ADMIN_SUCCESS,
            payload: res.data
          });
          // Stocker les jetons dans le localStorage
          localStorage.setItem("access_token_cc", res.data.access);
          localStorage.setItem("refresh_token_cc", res.data.refresh);
          dispatch(errorServerActions(''));
          dispatch(setUserData(res.data));

        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Admin_Types.POST_LOGIN_ADMIN_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Admin_Types.POST_LOGIN_ADMIN_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };

  export const patch_desactiver_user_actions = (id,body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Admin_Types.PATHC_DESACTIVER_USER_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .patch(`app_commission/deactivate_user/${id}/`, body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Admin_Types.PATHC_DESACTIVER_USER_SUCCESS,
            payload: res.data
          });
          // Stocker les jetons dans le localStorage
         

        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Admin_Types.PATHC_DESACTIVER_USER_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Admin_Types.PATHC_DESACTIVER_USER_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  export const logoutUser = () => {
    return (dispatch) => {
        dispatch(clearUserData());
    };
};


export const errorServerActions = (error) => {
  return (dispatch) => {
      dispatch(ErrorSErver(error));
  };
};



  export const get_liste_admins_actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestions_Admin_Types.GET_LISTE_ADMIN_START,
      });
      axios
        .get(`app_commission/administrateur/listes/`, configHeadersToken())
        .then((response) => {
          dispatch({
            type: Gestions_Admin_Types.GET_LISTE_ADMIN_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestions_Admin_Types.GET_LISTE_ADMIN_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const get_info_user_connecter_actions = (params_id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestions_Admin_Types.GET_INFO_USER_CONNECTE_START,
      });
      axios
        .get(`app_commission/administrateur/voir-lui-meme/${params_id}/`, configHeadersToken())
        .then((response) => {
          dispatch({
            type: Gestions_Admin_Types.GET_INFO_USER_CONNECTE_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestions_Admin_Types.GET_INFO_USER_CONNECTE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const put_info_user_connecter_actions = (id, body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_START });
      const token = localStorage.getItem("token_access");
  
     
     console.log('actions', body)
      axios
        .put(`app_commission/administrateur/update-lui-meme/${id}/`, body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_FAILED,
              payload: err?.response?.data,
            });
            console.log(err)
          }
          
        });
        
  
  
    };
  };




  export const put_update_admins_actions = (id, body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Admin_Types.PUT_UPDATE_ADMIN_START });
      const token = localStorage.getItem("token_access");
  
     
     console.log('actions', body)
      axios
        .put(`app_commission/administrateur/update/${id}/`, body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Admin_Types.PUT_UPDATE_ADMIN_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Admin_Types.PUT_UPDATE_ADMIN_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Admin_Types.PUT_UPDATE_ADMIN_FAILED,
              payload: err?.response?.data,
            });
            console.log(err)
          }
          
        });
        
  
  
    };
  };




  export const setUserData = (userData) => {
    // Enregistrer les données de l'utilisateur dans localStorage
    localStorage.setItem('user', JSON.stringify(userData));
    return {
        type: SET_USER_DATA,
        payload: userData,
    };
};


export const clearUserData = () => {
    // Supprimer les données de l'utilisateur de localStorage
    
    localStorage.removeItem('user');
    return {
        type: CLEAR_USER_DATA,
    };
};






export const ErrorSErver = (errors) => {
 
  console.log('nouss sommes dansnnnnnnnnnnnnnnnnnnnnnnnn : ', errors)
  return {
      type: EEROR_SERVER_SURVENIR,
      payload: errors,
  };
};