import React, { useEffect, useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import { useDispatch, useSelector } from 'react-redux'
import { get_liste_By_Cat_activite_Actions } from '../../../reduxComponents/Actions/Activites_Actions'
import ListeActivites from './ListeActivites'
import { Divider } from 'primereact/divider'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const CampagneSensibilisation = () => {

  const dispatch = useDispatch()
  const DataListStore = useSelector(state => state.ListeActiviteByCat_Store)

  const [DataList, setDataList] = useState()

  useEffect(() => {
    dispatch(get_liste_By_Cat_activite_Actions("Campagne de sensibilisation"))
  }, [])


  useEffect(() => {
    if (DataListStore?.DataListByCat) {
      const activeAndNotArchivedItems = DataListStore?.DataListByCat.filter(item => !item.is_archive);
      setDataList(activeAndNotArchivedItems)
    }

  }, [DataListStore?.DataListByCat])


  return (
    <>
      <div>
        <Headers PositionParent="Activités" PositionEnfant="Campagnes de sensibilisations" PositionPetitEnfant="" />



      </div>


      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 '>
        <div className='py-8'>
            <span className='font-bold text-3xl text-teal-600 '>Campagne de sensibilisation</span>
          </div>
         

          {
            DataList && (
              <ListeActivites DataList={DataList} />
            )
          }


          <div>

          </div>
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>
      <Reseau_Sociaux />

      <div>

      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default CampagneSensibilisation
