import React from 'react'
import coverImage from '../../images/ImagesCommission/Image défilante 1527362638DSC-6767.jpg'
import { BackendEntete } from '../../VariablesConfig'

const ArticlesActivites = ({DataDetailPublications}) => {
    console.log("fdsfdsfdsffsfdsf : ", DataDetailPublications)

    const ContenuHTML = ({ contenu }) => {
        return <div dangerouslySetInnerHTML={{ __html: contenu }} />;
      }

  return (
    <div>
      <div className='px-4 md:px-36'>
                       
                        <div className="flex-1 pt-6 text-center">
                        <div className='flex items-center justify-center'>
                                <span className='font-bold text-3xl text-white bg-teal-600 p-3 activeNavLInk'>{DataDetailPublications?.titre}</span>

                            </div>
                            <div className='pt-3'>
                                <span>Publier le : <b className='text-teal-600'>{DataDetailPublications?.date_pub}</b></span> | Total lecture : <b>{DataDetailPublications?.vues}</b>

                            </div>
                            <div className='relative pt-4 h-96'>
                                <div className="flex items-center justify-center h-full">
                                    <img src={BackendEntete + DataDetailPublications?.image_cover} className='max-h-full max-w-full object-cover object-center' alt="Commission de la concurrence" />
                                </div>
                            </div>
                            <div>
                                <p className='text-justify pt-4'>
                              
                                <ContenuHTML contenu={DataDetailPublications?.contenu} />
                                </p>
                            </div>
                        </div>


                    </div>
    </div>
  )
}

export default ArticlesActivites
