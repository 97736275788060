import React, { useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import TextesItems from './TextesItems'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { get_doc_Liste_Document, get_doc_Liste_Document_Communautaire } from '../../../reduxComponents/Actions/Gestion_des_DocsActions'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const Visiteur_DIrectives = () => {
  const dispatch = useDispatch()
  const DataListeArreteDoc = useSelector(state=>state.get_listCommunautaireSTore)

  const [DataListeDoc, setDataListeDoc] = useState()
      
      const [IsPageTitle, setIsPageTitle] = useState()
      useEffect(()=>{
        const pathname = window.location.pathname;
        const IsCEDEAO = pathname.includes('cedeao');
        const ISUEMOA = pathname.includes('uemoa');
        if (IsCEDEAO) {
          setIsPageTitle('CEDEAO')
          dispatch(get_doc_Liste_Document_Communautaire("TEXTES COMMUNAUTAIRES", "CEDEAO", "Directives"))
        }
        if (ISUEMOA) {
          setIsPageTitle('UEMOA')
          dispatch(get_doc_Liste_Document_Communautaire("TEXTES COMMUNAUTAIRES", "UEMOA", "Directives"))
        }
        //
      }, [window.location.pathname])

  useEffect(()=>{
    if (DataListeArreteDoc?.DataListCommunautaire) {
      console.log("DataListeArreteDoc?.DataListCommunautaireDataListeArreteDoc?.DataListCommunautaire : ", DataListeArreteDoc?.DataListCommunautaire)
      setDataListeDoc(DataListeArreteDoc?.DataListCommunautaire)
    }
  }, [DataListeArreteDoc?.DataListCommunautaire])




  return (
    <>
      <div>
        <Headers PositionParent="Textes réglémentaires" PositionEnfant="Directives" PositionPetitEnfant="" />



      </div>

      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
          <div>
            <div className='pt-4'>
              <span className='font-bold text-3xl text-teal-500'>
                
                {
                  IsPageTitle === 'UEMOA' && (
                    <div>Directives : UEMOA</div>
                  )
                }

{
                  IsPageTitle === 'CEDEAO' && (
                    <div>Directives : CEDEAO</div>
                  )
                }
                
                
                </span>
            </div>
          </div>
          {
            DataListeDoc ?  (
                <div>
                <TextesItems DataListeDoc={DataListeDoc} isNonRegement={true} />
              </div>
             
            ) : (
                <div>
                    <p>Aucun document trouvé</p>
                </div>
            )
          }
         

        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>


      <div>
     
      </div>
      <Reseau_Sociaux />
      <div>
        <Footers />
      </div>
    </>
  )
}

export default Visiteur_DIrectives



