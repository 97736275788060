
import { Gestions_Des_Docs } from '../Types/Gestion_Des_DOC_Types';
import axios from '../axios/axios'
import { configHeadersToken } from "./Gestion_Admin_Actions";




export const get_doc_Liste_Document_Communautaire = (cats_document, sous_cat_document, type_document) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    dispatch({
      type: Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_START,
    });
    axios
      .get(`app_commission/texte_reglementaire/${cats_document}/${sous_cat_document}/${type_document}/`)
      .then((response) => {
        dispatch({
          type: Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};



export const get_doc_Liste_Document = (type_document) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    dispatch({
      type: Gestions_Des_Docs.GET_LIST_DOC_START,
    });
    axios
      .get(`app_commission/texte_reglementaire/${type_document}`)
      .then((response) => {
        dispatch({
          type: Gestions_Des_Docs.GET_LIST_DOC_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestions_Des_Docs.GET_LIST_DOC_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};

export const Get_detail_DocAction = (id) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    dispatch({
      type: Gestions_Des_Docs.GET_DETAILS_START,
    });
    axios
      .get(`app_commission/texte_reglementaire/details/${id}`)
      .then((response) => {
        dispatch({
          type: Gestions_Des_Docs.GET_DETAILS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestions_Des_Docs.GET_DETAILS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};

export const get_Liste_LastDOc = (body) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    console.log("bodyaction : ",body)
    dispatch({
      type: Gestions_Des_Docs.GET_LAST_DOC_START,
    });
    axios
      .post(`app_commission/texte_reglementaire/lastdoc/`,body)
      .then((response) => {
        dispatch({
          type: Gestions_Des_Docs.GET_LAST_DOC_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestions_Des_Docs.GET_LAST_DOC_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};

export const post_Create_AdminActions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestions_Des_Docs.CREATE_DOC_REGLEMENT_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/texte_reglementaire/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Des_Docs.CREATE_DOC_REGLEMENT_SUCCESS,
            payload: res.data
          });
         
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Des_Docs.CREATE_DOC_REGLEMENT_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestions_Des_Docs.CREATE_DOC_REGLEMENT_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };

  export const put_Update_DocumentActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestions_Des_Docs.PUT_UPDATE_DOC_START });
      // console.log(body);
      axios
        .put(`app_commission/texte_reglementaire/update/${id}/`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestions_Des_Docs.PUT_UPDATE_DOC_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestions_Des_Docs.PUT_UPDATE_DOC_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestions_Des_Docs.PUT_UPDATE_DOC_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };