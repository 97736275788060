import React from 'react'
import { BackendEntete } from '../../../VariablesConfig';

const CommuniqueContenu = ({DataCommunique}) => {
    console.log("fdsfdsfdsffsfdsf : ", DataCommunique)

    const ContenuHTML = ({ contenu }) => {
        return <div dangerouslySetInnerHTML={{ __html: contenu }} />;
      }

  return (
    <div>
      <div className='px-4 md:px-36'>
                       
                        <div className="flex-1 pt-6 text-center">
                        <div className='flex items-center justify-center'>
                                <span className='font-bold text-3xl text-white bg-teal-600 p-3 activeNavLInk'>{DataCommunique?.titre}</span>

                            </div>
                            <div className='pt-3'>

                            </div>
                            <div className='pt-3'>
                                <span> <span className='font-bold bg-orange-500 text-white  py-2 px-4 rounded-lg'>{DataCommunique?.Cat_communique}</span> | Publier le : <b className='text-teal-600'>{DataCommunique?.date_pub}</b></span> | Total lecture : <b>{DataCommunique?.vues}</b>

                            </div>
                            <div className='relative pt-4 h-96'>
                                <div className="flex items-center justify-center h-full">
                                    <img src={BackendEntete + DataCommunique?.image_cover} className='max-h-full max-w-full object-cover object-center' alt="Commission de la concurrence" />
                                </div>
                            </div>
                            <div>
                                <p className='text-justify pt-4'>
                              
                                <ContenuHTML contenu={DataCommunique?.contenu} />
                                </p>
                            </div>
                        </div>


                    </div>
    </div>
  )
}

export default CommuniqueContenu



