import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import { InputText } from 'primereact/inputtext'
import { Editor } from "primereact/editor";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';

import imageENregistrer from '../../images/ImagesCommission/Image défilante 0F7A0926.jpeg'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Get_detail_PublicationsActions, put_update_PublicationsActions } from '../../reduxComponents/Actions/PublicationsActions';
import { BackendEntete } from '../../VariablesConfig';
import { Dialog } from 'primereact/dialog';
import LoaderComponent from '../../LoaderComponent';
import TextEditor from '../Parametre/TextEditor';

const Update_Pub = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()
    const params_id = atob(params?.id)

    const DataDetailPublication = useSelector(state=>state.get_detail_publications)
    const DataUpdateSubmitData = useSelector(state=>state.update_pub)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id

    const [DataDetailPublications, setDataDetailPublications] = useState()
    const [loading, setLoading] = useState(false);

    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)


    useEffect(()=>{
        dispatch(Get_detail_PublicationsActions(params_id))
    },[])

    
    const [text, setText] = useState('');
    const [Title_pub, setTitle_pub] = useState(null)

    const [coverImage, setCoverImage] = useState(imageENregistrer);
    const options = ['Oui', 'Non'];
    const [is_active_pub, setis_active_pub] = useState(options[0]);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(()=>{

        setText(DataDetailPublication?.DataDetailPublications?.contenu)
        setTitle_pub(DataDetailPublication?.DataDetailPublications?.titre)
        const image_Cover = BackendEntete + DataDetailPublication?.DataDetailPublications?.image_cover
        setCoverImage(image_Cover)
        const is_active = DataDetailPublication?.DataDetailPublications?.is_active === true ? 'Oui' : 'Non'
       
        setis_active_pub(is_active)
        

    }, [DataDetailPublication?.DataDetailPublications?.id])
    
    useEffect(()=>{
        if (DataUpdateSubmitData?.DataUpdatePub?.id && loading) {
            setLoading(false)
            setCreateSucces(true)
        }
    }, [DataUpdateSubmitData?.DataUpdatePub?.id    ])


   

    const SubmitPublications = (event)=>{

        event.preventDefault();
        setLoading(true)
        
        const is_active = is_active_pub === 'Oui' ? true : false
        const lienImgdeBack = BackendEntete + DataDetailPublication?.DataDetailPublications?.image_cover
        const SameImageCover = lienImgdeBack === coverImage ? true : false

        let body
        if (SameImageCover) {
            body = {
                titre : Title_pub,
                contenu : text,
                is_active : is_active,
                administrateur : admin_id
            }
        }else{
            body = {
                titre : Title_pub,
                contenu : text,
                image_cover : coverImage,
                is_active : is_active,
                administrateur : admin_id
            }
        }

       


        
        dispatch(put_update_PublicationsActions(params_id, body))
    }

    const AllerListeDoc = () => {

        setCreateSucces(false)
        dispatch(Get_detail_PublicationsActions(params_id))
        
       
    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );

    return (
        <div>
            <LoaderComponent loading={loading} />
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>
                       
                    </div>
                    <div>
                        
                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div class="p-6 bg-white border-b border-gray-200">

                                        <div className="mb-4">
                                            <label htmlFor="coverImage" className="block font-medium text-gray-700">Photo de couverture <span className='font-bold text-red-500'>*</span></label>
                                            <input
                                                type="file"
                                                id="coverImage"
                                                accept="image/jpeg, image/jpg, image/png"

                                                onChange={handleImageChange}
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            />
                                        </div>
                                        {coverImage && (
                                            <div className="mb-4">
                                                <label className="block font-medium text-gray-700 font-bold">Prévisualisation de la photo de couverture</label>
                                                <img
                                                    src={coverImage}
                                                    alt="Cover Preview"
                                                    className="mt-1 w-full h-96 object-cover border border-gray-300 rounded-md"
                                                />
                                            </div>
                                        )}
                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Titre de la publications</span> <span className='font-bold text-red-500'>*</span> </label>
                                                <InputText id="username" value={Title_pub} aria-describedby="username-help"  onChange={(e) => setTitle_pub(e.target.value)} />

                                            </div>
                                        </div>


                                        {
                                            text && (
                                                <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Contenu de la publications</span></label>
                                                {/* <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} /> */}
                                                <TextEditor  content={text} setContent={setText} />

                                            </div>
                                        </div>
                                            )
                                        }
                                        
                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Activer la publications</span> </label>
                                                <SelectButton  value={is_active_pub} onChange={(e) => setis_active_pub(e.value)} options={options} />

                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' onClick={(event)=>SubmitPublications(event)} label='Modifier la publications' type='submit' />
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Publication crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>



                </div>

            </div>
        </div>
    )
}

export default Update_Pub


