import React from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import DataLIsteGaleriePhoto from './GaleriePhotoGestion/DataListeGaleriePhoto'

const Galerie_photoAdmin = () => {

  return (
    <div>
      <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                  
                    
                    <div>
                        <DataLIsteGaleriePhoto />
                    </div>






                </div>

            </div>
    </div>
  )
}

export default Galerie_photoAdmin
