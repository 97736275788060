import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"

const AvatarMenue = () => {

    
    const navigate = useNavigate()
    const [state, setState] = useState(false)
    const profileRef = useRef()

    const navigation = [
        { title: "Dashboard", path: "/" },
        { title: "Profile", path: "/userprofile" },
        { title: "Configuration", path: "/configuration" },
    ]

    const DeconnexionBtn = ()=>{
        navigate('/connexion')
    }

/*
    useEffect(() => {
        const handleDropDown = (e) => {
            if (!profileRef.current.contains(e.target)) setState(false)
        }
        document.addEventListener('click', handleDropDown)
    }, []) */

    return (
        <div className="relative border-t lg:border-none">
            <div className="">
                <button ref={profileRef} className="hidden w-10 h-10 outline-none rounded-full ring-offset-2 ring-gray-200 lg:focus:ring-2 lg:block"
                    onClick={() => setState(!state)}
                >
                    image
                </button>
            </div>
            <ul className={`bg-white top-14 right-0 mt-6 space-y-6 lg:absolute lg:border lg:rounded-md lg:w-52 lg:shadow-md lg:space-y-0 lg:mt-0 ${state ? '' : 'lg:hidden'}`}>
                {
                    navigation.map((item, idx) => (
                        <li key={idx}>
                            <a className="block text-gray-600 hover:text-gray-900 lg:hover:bg-gray-50 lg:p-3" href={item.path}>
                                {item.title}
                            </a>
                        </li>
                    ))
                }
                <button className="block w-full text-justify text-gray-600 hover:text-gray-900 border-t py-3 lg:hover:bg-gray-50 lg:p-3" onClick={()=>DeconnexionBtn()}>
                    Se deconnecter
                </button>
            </ul>
        </div>
    )
}
 const NavbarHeaders = () => {

    const [state, setState] = useState(false)

    // Replace javascript:void(0) paths with your paths
    const navigation = [
        { title: "Date", path: "#" },
        { title: "Notification", path: "#" },
        
    ]

    const navigate = useNavigate()


    

    const retirectProfil = ()=>{
        navigate('/admin/parametre/administrateur/profil/')
    }
   


    return (
        <header className="text-base lg:text-sm">
        <div className={` items-center gap-x-14  max-w-screen-xl mx-auto lg:flex lg:px-8 lg:static ${state ? "h-full fixed inset-x-0" : ""}`}>
            <div className="flex items-center justify-between py-3 lg:py-5 lg:block">
                
                
            </div>
            <div className={`nav-menu flex-1 pb-28 mt-8 overflow-y-auto max-h-screen lg:block lg:overflow-visible lg:pb-0 lg:mt-0 ${state ? "" : "hidden"}`}>
                <ul className="items-center space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                    <form onSubmit={(e) => e.preventDefault()} className='flex-1 items-center justify-start pb-4 lg:flex lg:pb-0'>
                        
                    </form>
                  
                    <div className="font-bold cursor-pointer bg-orange-500 text-white p-3" onClick={()=>retirectProfil()}>
                        Modifier mes informations
                    </div>
                </ul>
            </div>
        </div>
        
    </header>
    )
}

export default NavbarHeaders
