import React, { useState } from 'react';
import Quill from 'quill';

const TextEditor = ({ content, setContent }) => {
  //   const [text, setText] = useState(MotDuPresidentPresident);

  // Initialisez Quill avec l'élément DOM
  const editorRef = React.useRef(null);
  React.useEffect(() => {
    const editor = new Quill(editorRef.current, {
      theme: 'snow', // Choisissez un thème Quill
      modules: {
        toolbar: [
          
          [{ 'font': [] }, { 'size': [] }],
          [ 'bold', 'italic', 'underline', 'strike' ],
          [{ 'color': [] }, { 'background': [] }],
          [{ 'script': 'super' }, { 'script': 'sub' }],
          [{ 'header': '1' }, { 'header': '2' }, 'blockquote', 'code-block' ],
          [{ 'list': 'ordered' }, { 'list': 'bullet'}, { 'indent': '-1' }, { 'indent': '+1' }],
          [ 'direction', { 'align': [] }],
          [ 'link', 'image', 'video', 'formula' ],
          [ 'clean' ]
        ],
      },
    });

    // Définissez le texte initial
    editor.root.innerHTML = content;

    // Écoutez les modifications du texte
    editor.on('text-change', () => {
      setContent(editor.root.innerHTML);
    });
  }, []);

  return (
    <div>

      <div ref={editorRef} className="quill-editor" />

    </div>
  );
};

export default TextEditor;
