import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders'
import ArticlesActivites from '../Articles'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { get_GalerieVideosDetailAction } from '../../../reduxComponents/Actions/Gestion_VideosActions'
import LoaderComponent from '../../../LoaderComponent'
import { BackendEntete } from '../../../VariablesConfig'

const VoirVideo = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const params_id = atob(params?.id)

    const [loading, setLoading] = useState(true)
    const DataDetailStore = useSelector(state => state.get_Details_GalerieVideo)
    const [DataDetail, setDataDetail] = useState(null)
    const redirectModif = () => {
        const id = btoa(params_id)
        navigate(`/admin/actualites/galerie-videos/modifier/${id}`)
    }

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    useEffect(() => {
        if (DataDetailStore?.DataDetailVideo?.id) {
            setDataDetail(DataDetailStore?.DataDetailVideo)
            setLoading(false)
        }

    }, [DataDetailStore?.DataDetailVideo?.id])

    useEffect(() => {
        dispatch(get_GalerieVideosDetailAction(params_id))
    }, [params_id])

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    useEffect(()=>{

        console.log("DataDetailDataDetailDataDetail : ", BackendEntete + DataDetail?.lien_video)
    },[DataDetail])


    return (
        <div>
            <LoaderComponent loading={loading} />
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content '>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div className='grid gap-3 mb-8 md:grid-cols-2 xl:grid-cols-4 p-4 md:24 border-2 mx-4'>
                        <div>

                        </div>
                        <div>

                        </div>

                        <div>
                            <Button className='w-full bg-gray-500 border-none hover:bg-gray-600 btnBacground03' onClick={() => redirectModif()} label='Modifier le communiqué' />
                        </div>
                        <div>
                            <Button className='bg-orange-500 border-none w-full hover:bg-orange-700 btnBacground01' onClick={() => setVisibleDialogue(true)} label='Archiver le communiqué' />
                        </div>
                    </div>
                    <div className='displayLIgneColForce flex '>
                        <div className='py-4'>
                            <span className='font-bold text-3xl bg-orange-400 text-white p-3 items-center'> <span className='text-orange-300'></span> {DataDetail?.titre}</span>
                        </div>
                        <div>
                            <h3>
                               
                            </h3>
                        </div>

                        <div className='px-4'>

                            {
                                DataDetail && (
                                    <video controls autoplay name="media"><source src={BackendEntete + DataDetail?.lien_video} type="video/webm" /></video>

                                )
                            }
                        </div>
                    </div>







                </div>

            </div>

            <div className="card flex justify-content-center">

                <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                    <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Dialog>
            </div>
        </div>
    )
}

export default VoirVideo


