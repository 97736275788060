import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import imageQuiSOmme from '../../../../images/ImagesCommission/Commission de la Concurrence.jpg'

const QuiSommeNous = () => {
    const [state, setState] = useState(false)

    const navigate = useNavigate()

    // Replace javascript:void(0) path with your path
    const navigation = [
        { title: "Partners", path: "javascript:void(0)" },
        { title: "Customers", path: "javascript:void(0)" },
        { title: "Team", path: "javascript:void(0)" },

    ]

    return (
        <>

            <section className="py-28 marginContinaierWeb sectionAnime">
                <div className=" mx-auto text-gray-600  items-center justify-between overflow-hidden md:flex ">
                    <div className="flex-none space-y-5 p sm:max-w-lg md:px-0 lg:max-w-xl ">

                        <h2 className="text-4xl text-gray-800  font-extrabold md:text-5xl">
                            <span className='bg-teal-600 text-white font-bold p-1'>LES OBJECTIFS</span>
                        </h2>
                        <h2 className="text-4xl text-gray-800 font-extrabold md:text-4xl">
                            <span className='bg-orange-400	 text-white font-bold p-1'>DE LA COMMISSION</span>
                        </h2>
                        <div className='pt-6'>
                            <p className='lg:py-2 text-justify'>

                                <p className='text-gray-700 text-justify gap-3 flex'>
                                    <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_669_24188)">
                                            <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                            <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_669_24188">
                                                <rect width="25" height="24.375" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></span>
                                    Consolider le marché ivoirien et soutenir les politiques sectorielles ;
                                </p>

                                <p className='text-gray-700 text-justify gap-3 flex'>
                                    <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_669_24188)">
                                            <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                            <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_669_24188">
                                                <rect width="25" height="24.375" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></span>
                                    Promouvoir l’innovation et la qualité des biens et services ;                    </p>

                                <p className='text-gray-700 text-justify gap-3 flex'>
                                    <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <g clip-path="url(#clip0_669_24188)">
                                            <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                            <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_669_24188">
                                                <rect width="25" height="24.375" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg></span>
                                    Offrir plus de choix et des prix bas aux consommateurs ;                    </p>

                            </p>
                        </div>
                        <div className="items-center gap-x-3 space-y-3 sm:flex sm:space-y-0" >
                            <div  onClick={()=>navigate('presentation/missions')}>
                                 <Link to="#"  className="flex items-center justify-center gap-x-2 py-2 px-4 text-white hover:text-white font-medium duration-150 bg-gradient-to-r from-orange-500 to-orange-400 border rounded-lg md:inline-flex no-underline">
                                Voir plus
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                    <path fillRule="evenodd" d="M2 10a.75.75 0 01.75-.75h12.59l-2.1-1.95a.75.75 0 111.02-1.1l3.5 3.25a.75.75 0 010 1.1l-3.5 3.25a.75.75 0 11-1.02-1.1l2.1-1.95H2.75A.75.75 0 012 10z" clipRule="evenodd" />
                                </svg>
                            </Link>

                            </div>

                            <div  onClick={()=>navigate('fonctionnement/faire-une-saisine')}>
                                 <Link to="#" className="block py-2 px-4 text-center text-white font-medium duration-150 bg-gradient-to-r from-teal-700 to-teal-400 hover:bg-teal-600 rounded-lg shadow-lg hover:shadow-none no-underline">Faire un depot de plainte</Link>

                            </div>
                           

                        </div>
                    </div>
                    <div className="flex-none mt-14 md:mt-0 md:max-w-xl">
                        <img
                            src={imageQuiSOmme}
                            className="hidden md:block  md:rounded-tl-[150px] md:rounded-br-[150px]"
                            alt="Commmission de la concurrence"
                        />
                    </div>
                </div>

            </section>
        </>
    )
}


export default QuiSommeNous
