import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import GaleroiePhotoItems from './GaleroPhotoItems'
import imgaePub from '../../../images/imagecover.png'
import { useDispatch, useSelector } from 'react-redux'
import { get_GaleriePhoto_liste } from '../../../reduxComponents/Actions/GaleriePhotoActions'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'



const GaleriePhoto = () => {
  
  const dispatch = useDispatch()

  const DataListeStore = useSelector(state=>state.getListeGaleriephoto)

  const [DataListe, setDataListe] = useState(null)

  useEffect(()=>{
    
    console.log("DataListeStore?.DataListeGaleriePhoto : ", DataListeStore?.DataListeGaleriePhoto)
    setDataListe(DataListeStore?.DataListeGaleriePhoto)
  }, [DataListeStore?.DataListeGaleriePhoto])

  useEffect(()=>{
    dispatch(get_GaleriePhoto_liste())
  },[])


  return (
    <>
    <div>
    <Headers PositionParent="Actualités" PositionEnfant="Galerie Photo" PositionPetitEnfant="" />



    </div>
    <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
      <div>
            <div className='pt-4'>
              <span className='font-bold text-3xl text-teal-500'>Galerie Photo</span>
            </div>
          </div>
          <div>
            {
              DataListe && (
                <>
                  <GaleroiePhotoItems ListeImageGalerie={DataListe} />
                </>
              )
            }
          

          </div>
      <div>
      
    </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>
     <Reseau_Sociaux />

    <div>
    </div>
    <div>
    <Footers />
    </div>
</>
  )
}

export default GaleriePhoto
