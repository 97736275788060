import React from 'react'
import NavbarAdmin from '../NavBar/NavbarAdmin'
import NavbarHeaders from '../NavBar/NavbarHeaders'
import Dashboard_Contennt from '../Dashboard_Contennt'
import ChartDashboard from './ChartDashboard'

const DashboardAdmin = () => {
    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div className='px-12'>
                        <Dashboard_Contennt />
                        <ChartDashboard />
                    </div>
                    <div>
                       
                    </div>





                </div>

            </div>

        </div>
    )
}

export default DashboardAdmin
