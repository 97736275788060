import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import { SelectButton } from 'primereact/selectbutton'
import { Editor } from 'primereact/editor'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Calendar } from 'primereact/calendar'
import { addLocale, locale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { post_Create_AdminActions } from '../../reduxComponents/Actions/Gestion_des_DocsActions'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import PdfCOmpo from './PdfCOmpo'
import { pdfjs } from 'react-pdf';


const CreateDocument = () => {

    const [titre, setTire] = useState('')
    const navigate = useNavigate()

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false);

    const [DataCreateDoc, setDataCreateDoc] = useState()
    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)

    const admin_id = useSelector((state) => state.user.user_id);


    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url,
    ).toString();



    const selectDocEnregistre = useSelector(state => state.Data_DocumentCreate)

    const options = ['Oui', 'Non'];
    const [type_document, setType_document] = useState('')
    const [date_adoption, setDate_adoption] = useState('')
    const [documents, setDocuments] = useState('')
    const [is_activeDocument, setIs_activeDocument] = useState(options[0])

    const [cat_documents, setCat_document]=useState(null)
    const [sous_cat_document, setSous_Cat_document]=useState(null)


    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);

        // Prévisualisation du fichier PDF sélectionné
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewURL(reader.result);
        };
        reader.readAsDataURL(file);
    };

    const types_documentsNationauxOption = [
        'Ordonnances',
        'Décrets',
        'Lois',
        
        'Arrêtés',
    ];

    const types_documentsCommunautaireOption = [
        "Traités",
        "Directives",
        'Regléments',
    ];

    const types_documentsAvisOption = [
        "Consultatifs",
        "Contentieux",
    ];


    const cat_options = [
        'TEXTES COMMUNAUTAIRES',
        'TEXTES NATIONAUX',
        'AVIS'

    ]


    const sous_cat_optionsCommunautaire = [
        'CEDEAO',
        'UEMOA'
    ]


    useEffect(() => {

        if (selectDocEnregistre?.DataDocCreate?.date_adoption && loading) {
            setCreateSucces(true)

        }
        if (selectDocEnregistre?.error?.detail === 'Authentication credentials were not provided.') {
            setErrorCreate(true)
        }

    }, [selectDocEnregistre])


    const [errors, setErrors] = useState({});


    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['jan', 'feb', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec'],
        today: "Maintenant",
        clear: 'Nettoyer'
    })

    locale("fr")

    const OnSubmitDOcument = (event) => {
        event.preventDefault()
       setLoading(true)
        const is_active = is_activeDocument === 'Oui' ? true : false


        let body
        if (cat_documents==='TEXTES COMMUNAUTAIRES') {
            body = {
                titre: titre,
                date_adoption: date_adoption,
                documents: previewURL,
                cats_document : cat_documents,
                sous_cat_document : sous_cat_document,
                type_document: type_document,
                is_active: is_active,
                administrateur : admin_id
            }
        }else{
            body = {
                titre: titre,
                date_adoption: date_adoption,
                documents: previewURL,
                cats_document : cat_documents,
                sous_cat_document : null,
                type_document: type_document,
                is_active: is_active,
                administrateur : admin_id
            }
        }


      

        

        console.log("bodybodybodybodybody : ", body)

        dispatch(post_Create_AdminActions(body))

    }

    const AllerListeDoc = () => {

        setCreateSucces(false)

 
        if (type_document === 'Arrêtés') {
            navigate('/admin/textes-reglementaires/arretes')
        }
        if (type_document === "Traités") {
            navigate('/admin/textes-reglementaires/traite')
        }
        if (type_document === "Directives") {
            navigate('/admin/textes-reglementaires/directives')
        }
        if (type_document === 'Regléments') {
            navigate('/admin/textes-reglementaires/reglements')
        }
        
        if (type_document === 'Ordonnaces') {
            navigate('/admin/textes-reglementaires/ordonnaces')
        }
        if (type_document ===  'Lois') {
            navigate('/admin/textes-reglementaires/lois')
        }
        if (type_document ===  'Décrets') {
            navigate('/admin/textes-reglementaires/decrets')
        }

        if (cat_documents === 'AVIS') {
            if (type_document ==='Consultatifs') {
                navigate('/admin/avis/avis_consultatifs')
            }
            if (type_document ==='Contentieux') {
                navigate('/admin/avis/avis_contentieux')
            }
        }
      
        

        

    }

    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );


    return (
        <div>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <ProgressSpinner
                        style={{ width: '50px', height: '50px' }}
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                    />
                </div>
            )}
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                   
                    <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                            <div class="p-6 bg-white border-b border-gray-200">
                                <div>
                                    <h2 className='py-4'><span className='font-bold text-teal-600'>Ajouter un nouveau document</span></h2>
                                </div>


                                <div class="mb-4">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="username font-bold"><span className='font-bold'>Titre du document </span> <span className='font-bold text-red-500'>*</span> </label>
                                        <InputText id="username" value={titre} aria-describedby="username-help" onChange={(e) => setTire(e.target.value)} />

                                    </div>
                                </div>
                                <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="Nom" className='font-bold'>Catégorie du document <span className='font-bold text-red-500'>*</span> </label>
                                        <Dropdown value={cat_documents} onChange={(e) => setCat_document(e.value)} options={cat_options}
                                            placeholder="Selectionner le type de document" className={errors.cat_documents ? 'border-2 border-red-500' : ''} />
                                    </div>

                                    {
                                        cat_documents === 'TEXTES COMMUNAUTAIRES' ? (
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="Nom" className='font-bold'>Sous catégorie : TEXTES COMMUNAUTAIRES <span className='font-bold text-red-500'>*</span> </label>
                                                <Dropdown value={sous_cat_document} onChange={(e) => setSous_Cat_document(e.value)} options={sous_cat_optionsCommunautaire}
                                                    placeholder="Selectionner la sous catégorie du document" className={errors.sous_cat_document ? 'border-2 border-red-500' : ''} />
                                            </div>
                                        ) : (
                                            <>
                                                {
                                                    cat_documents === 'AVIS'  ? (
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="Nom" className='font-bold'>Type du document (AVIS) <span className='font-bold text-red-500'>*</span> </label>
                                                            <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsAvisOption}
                                                                placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                        </div>
                                                    ) : (
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="Nom" className='font-bold'>Type du document (TEXTES NATIONAUX) :  <span className='font-bold text-red-500'>*</span> </label>
                                                            <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsNationauxOption}
                                                                placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                        </div>
                                                    )
                                                }
                                            </>
                                            
                                        )
                                    }



 

                                  

                                </div>

                                {
                                     cat_documents === 'TEXTES COMMUNAUTAIRES' && (
                                        <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-1">
                                    
                                                <div className="flex flex-column gap-2">
                                                    <label htmlFor="Nom" className='font-bold'>type du document : "TEXTES COMMUNAUTAIRES" <span className='font-bold text-red-500'>*</span> </label>
                                                    <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsCommunautaireOption}
                                                    placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                </div>

                                            </div>
                                     )
                                }

                                
                                <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-1">
                                    
                                    <div className="flex flex-column gap-2">
                                        <label htmlFor="Nom" className='font-bold'>Date d'adoption <span className='font-bold text-red-500'>*</span> </label>
                                        <Calendar id="Nom" dateFormat='dd/mm/yy' className={errors.date_adoption ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={date_adoption} onChange={(e) => setDate_adoption(e.target.value)} />

                                    </div>

                                </div>

                                <div class="mb-4">
                                    <div>
 
                                        <input type="file" accept=".pdf" onChange={handleFileChange} />
                                    </div>
                                    {/* {selectedFile && (

                                        <PdfCOmpo isForWhatSPace='admin' pdfFile={previewURL} className='w-full' />

                                        )} */}
                                                                        </div>
                                <div class="mb-4">
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>


                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username">Activer la visibilité du document par les visiteurs</label>
                                            <SelectButton value={is_activeDocument} onChange={(e) => setIs_activeDocument(e.value)} options={options} />

                                        </div> </div>
                                </div>
              

                                {  (titre&& date_adoption && previewURL && cat_documents && type_document ) ? (
                                    <div class="mb-4">
                                    <Button onClick={(event) => OnSubmitDOcument(event)} className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' label='Ajouter le nouveau document' type='submit' />
                                </div>
                                ):(
                                    <div class="mb-4">
                                    <Button onClick={(event) => OnSubmitDOcument(event)} className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' label='Ajouter le nouveau document' type='submit' disabled />
                                </div>
                                )
 
                                }
                                

                            </div>
                        </div>
                    </div>


                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Document crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CreateDocument




