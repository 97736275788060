import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders'
import { InputText } from 'primereact/inputtext'
import { Editor } from "primereact/editor";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BackendEntete } from '../../../VariablesConfig';
import { get_GalerieVideosDetailAction, put_GallerieVideo_UpdateAction } from '../../../reduxComponents/Actions/Gestion_VideosActions';
import LoaderComponent from '../../../LoaderComponent';
import { Dialog } from 'primereact/dialog';

const UpdateVideo = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const params = useParams()

    const params_id = atob(params?.id)

    const [loading, setLoading] = useState(false)
    const DataDetailStore = useSelector(state => state.get_Details_GalerieVideo)
    const DatUpdateVideoStore = useSelector(state=>state.put_Update_GalerieVideo)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id
    const [DataDetail, setDataDetail] = useState(null)


    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)

    useEffect(() => {
        if (DataDetailStore?.DataDetailVideo?.id) {
            setTitle_pub(DataDetailStore?.DataDetailVideo?.titre)
            const lien_video = BackendEntete + DataDetailStore?.DataDetailVideo?.lien_video
            setPreviewVideo(lien_video)
            const imageCover = BackendEntete + DataDetailStore?.DataDetailVideo?.image_miniature
            setCoverImage(imageCover)
            const isActive = DataDetailStore?.DataDetailVideo?.is_active===true ? 'Oui' : 'Non'
            setis_active_pub(isActive)
           
        }

    }, [DataDetailStore?.DataDetailVideo?.id])

    useEffect(()=>{
        if (DatUpdateVideoStore?.DataUpdateVideo?.id && loading) {
            setLoading(false)
            setCreateSucces(true)
        }
    
    },[DatUpdateVideoStore?.DataUpdateVideo?.id])


    const [text, setText] = useState('');
    const [Title_pub, setTitle_pub] = useState(null)

    const [coverImage, setCoverImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        dispatch(get_GalerieVideosDetailAction(params_id))
    }, [params_id])

    const options = ['Oui', 'Non'];
    const [is_active_pub, setis_active_pub] = useState(options[0]);

    const [selectedVideo, setSelectedVideo] = useState(null);
    const [previewVideo, setPreviewVideo] = useState(null);

    const handleVideoChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setSelectedVideo(file);
            setPreviewVideo(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleRemoveVideo = () => {
        setSelectedVideo(null);
        setPreviewVideo(null);
    };

    const handleSaveVideo = () => {
        // Enregistrer la vidéo dans un emplacement spécifié
        console.log(selectedVideo);
    };




    const SubmitPublications = (event) => {
        event.preventDefault();
        setLoading(true)

        const lienImgdeBack = BackendEntete + DataDetailStore?.DataDetailVideo?.image_miniature
        const SameImageCover = lienImgdeBack === coverImage ? true : false

        const lienVideodeBack = BackendEntete + DataDetailStore?.DataDetailVideo?.lien_video
        const SameVideoCover = lienVideodeBack === previewVideo ? true : false


        const is_active = is_active_pub === 'Oui' ? true : false
        let body
        if (SameImageCover) {
            if (SameVideoCover) {
                body = {
                    titre: Title_pub,
                    is_active : is_active,
                    administrateur : admin_id,
                }
            }else{
                body = {
                    titre: Title_pub,
                    is_active : is_active,
                    lien_video : previewVideo,
                    administrateur : admin_id,
                }
            }
        }else{
           
            if (SameVideoCover) {
                body = {
                    titre: Title_pub,
                    is_active : is_active,
                    image_miniature:coverImage,
                    administrateur : admin_id
                }
            }else{
                body = {
                    titre: Title_pub,
                    is_active : is_active,
                    lien_video : previewVideo,
                    image_miniature:coverImage,
                    administrateur : admin_id
                }
            }




             
        }
       



        dispatch(put_GallerieVideo_UpdateAction(params_id, body))

       
    }

    const AllerListeDoc = () => {

        setCreateSucces(false)
        window.location.reload();
       
        
       
    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );

    return (
        <div>
            <LoaderComponent loading={loading}/>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>

                    </div>
                    <div>

                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg ">
                                <div class="p-6 bg-white border-b border-gray-200">
                                    <div>
                                        <h2 className='py-4'><span className='font-bold text-teal-600'>Modifier : Video</span></h2>
                                        <span></span>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="coverImage" className="block font-medium text-gray-700">Photo de couverture <span className=' text-red-500'>*</span><span className='font-bold text-red-500'>*</span></label>
                                        <input
                                            type="file"
                                            id="coverImage"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        />
                                    </div>
                                    {coverImage && (
                                        <div className="mb-4">
                                            <label className="block font-medium text-gray-700 font-bold">Prévisualisation de la photo de couverture</label>
                                            <img
                                                src={coverImage}
                                                alt="Cover Preview"
                                                className="mt-1 w-full h-96 object-cover border border-gray-300 rounded-md"
                                            />
                                        </div>
                                    )}
                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username font-bold"><span className='font-bold'>Titre de la vidéo <span className=' text-red-500'>*</span></span> <span className='font-bold text-red-500'>*</span> </label>
                                            <InputText id="username" value={Title_pub} aria-describedby="username-help" onChange={(e) => setTitle_pub(e.target.value)} />

                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="container mx-auto mt-10">
                                            <input
                                                type="file"
                                                accept="video/*"
                                                onChange={handleVideoChange}
                                                className="mb-4"
                                            />
                                            <span className=' text-red-500'>*</span>

                                            {previewVideo && (
                                                <div className="mb-4">
                                                    <video controls className="w-full">
                                                        <source src={previewVideo} type="video/mp4" />
                                                        
                                                    </video>
                                                    {/* <button
                                                        onClick={handleRemoveVideo}
                                                        className="mt-2 bg-red-500 text-white px-4 py-2 rounded"
                                                    >
                                                        Supprimer la vidéo
                                                    </button> */}
                                                </div>
                                            )}

                                          
                                        </div>
                                    </div>


                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username font-bold"><span className='font-bold'>Activer la vidéo</span> </label>
                                            <SelectButton value={is_active_pub} onChange={(e) => setis_active_pub(e.value)} options={options} />

                                        </div>
                                    </div>

                                    titre: Title_pub,
                    is_active : is_active,
                    lien_video : previewVideo,
                    image_miniature:coverImage

                    {
                        (Title_pub && previewVideo && coverImage ) ? (
<div class="mb-4">
                                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' onClick={(event) => SubmitPublications(event)} label='Publier la video' type='submit' />
                                    </div>
                        ):(
                            <div class="mb-4">
                                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01'  label='Publier la video' type='submit' disabled/>
                                    </div>
                        )
                    }
                                    

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Publication crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>




                </div>

            </div>
        </div>
    )
}

export default UpdateVideo




