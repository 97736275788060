import { InputText } from 'primereact/inputtext'
import React, { useEffect, useState } from 'react'
import TextEditor from '../Parametre/TextEditor'
import { Calendar } from 'primereact/calendar'
import { addLocale, locale } from 'primereact/api'
import { Button } from 'primereact/button'
import { Dropdown } from 'primereact/dropdown'
import { useDispatch, useSelector } from 'react-redux'
import { post_create_Activite_Actions } from '../../reduxComponents/Actions/Activites_Actions'
import LoaderComponent from '../../LoaderComponent'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'

const FormulaireActivite = () => {

    const [loading, setLoading] = useState()

    const navigate = useNavigate()

    const [Titre, setTitre] = useState('')
    const [TypeActivite, setTypeActivite] = useState('')
    const [Cat_Activite, setCat_Activite] = useState('')
    const [Lieu, setLieu] = useState('')
    const [Data_Activite, setData_Activite] = useState('')
    const [Contenu, setContenu] = useState('')

    const dispatch = useDispatch()
    const SelectorActivite = useSelector(state=>state.post_create_activite_Store)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id


    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)
    


    const [errors, setErrors] = useState({})

    const CatOptions = [
        'Ateliers',
        'Formations',
        'Campagne de sensibilisation',
        'Journée portes ouvertes',
    ]


    useEffect(()=>{

        if (SelectorActivite?.DataCreateActivite?.id && loading) {
            setLoading(false)
            setCreateSucces(true)
        }

    },[SelectorActivite?.DataCreateActivite])


    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['jan', 'feb', 'mar', 'avri', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec'],
        today: "Maintenant",
        clear: 'Nettoyer'
    })

    locale("fr")

    const OnSubmitActivite = (event)=>{
        event.preventDefault()
        

        const newErrors = {};
        if (!Titre) newErrors.Titre = true;
        if (!Cat_Activite) newErrors.Cat_Activite = true;
        if (!TypeActivite) newErrors.TypeActivite = true;
        if (!Lieu) newErrors.Lieu = true;
        if (!Contenu) newErrors.Contenu = true;
        if (!Data_Activite) newErrors.Data_Activite = true;
        

        let body
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)

            body = {
                titre : Titre,
                type_activite : TypeActivite,
                categorie_activite : Cat_Activite,
                lieux_activite : Lieu,
                date_activite : Data_Activite,
                contenu : Contenu,
                administrateur : admin_id
    
            }


            dispatch(post_create_Activite_Actions(body))
            
        }


       

        
    }


    const AllerListeDoc = () => {

        setCreateSucces(false)
        navigate('/admin/activites')


    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );


    return (
        <div>

            <LoaderComponent loading={loading} />
            <div>
                <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2 px-4'>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="Nom">Titre de l'activité <span className=' text-red-500'>*</span></label>
                        <InputText id="Nom" className={errors?.Titre ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Titre} onChange={(e) => setTitre(e.target.value)} />

                    </div>

                    <div className="flex flex-column gap-2">
                        <label htmlFor="Prenom">Catégorie de l'activité <span className=' text-red-500'>*</span></label>
                        <Dropdown className={errors?.Cat_Activite ? 'border-2 border-red-500' : ''} value={Cat_Activite} onChange={(e) => setCat_Activite(e.value)} options={CatOptions} 
                            placeholder="Selectionner une catégorie"  />
                    </div>
                </div>
                
            </div>
            <div>
                <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2 px-4'>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="Nom">Type <span className=' text-red-500'>*</span></label>
                        <InputText id="Nom" className={errors?.TypeActivite ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={TypeActivite} onChange={(e) => setTypeActivite(e.target.value)} />

                    </div>

                    <div className="flex flex-column gap-2">
                        <label htmlFor="Prenom">Lieu <span className=' text-red-500'>*</span> </label>
                        <InputText id="Prenom" className={errors?.Lieu ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Lieu} onChange={(e) => setLieu(e.target.value)} />

                    </div>
                </div>
                
            </div>
            <div>
                <div className='grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1 px-4'>
                    <div className="flex flex-column gap-2">
                        <label htmlFor="Nom">Date <span className=' text-red-500'>*</span> </label>
                        <Calendar id="Data_Activite" dateFormat='dd/mm/yy' className={errors.Data_Activite ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Data_Activite} onChange={(e) => setData_Activite(e.target.value)} />

                    </div>
                </div>
                
            </div>
            <div>
                <div className='grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1 px-4'>
                  
                    <div className="flex flex-column gap-2">
                        <label htmlFor="Prenom">Contenu <span className=' text-red-500'>*</span> </label>
                        <TextEditor className={errors.Contenu ? 'border-2 border-red-500' : ''} content ={Contenu} setContent={setContenu} />
                    </div>
                </div>
                
            </div>

            <div>
                <div className='grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1 px-4'>
                  
                <div class="mb-4">
                    <Button onClick={(event) => OnSubmitActivite(event)} className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' label="Enregistrer l'activité" type='submit' />
                </div>
                </div>
                
            </div>


            <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Activité crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>

        </div>
    )
}

export default FormulaireActivite
