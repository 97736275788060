import { ParametrePagesTypes } from "../Types/ParametrePagesTypes";

const initialCreateMotPresident = {
    isLoading: false,
    DataCreateVideo: [],
    error: "",
};


const intitialDetailMOtPresident = {
    isLoading: false,
    DataDetailMotPresident: [],
    error: "",
};


const initialUpdateMotDupresident = {
    isLoading: false,
    DataUpdateMotDuPresident: [],
    error: "",
};

const initialCreatePartenaires = {
    isLoading: false,
    DataCreatePartenaire: [],
    error: "",
};


const initialListePartenaires = {
    isLoading: false,
    DataListPartenaire: [],
    error: "",
};

const initialUpdatePartenaires = {
    isLoading: false,
    DataUpdatePartenaire: [],
    error: "",
};

const initialArchive = {
    isLoading: false,
    DataArchive: [],
    error: "",
};

export const Put_Update_Partenaire_Reducers = (state=initialUpdatePartenaires, action) => {

    switch (action.type) {
        case ParametrePagesTypes.UPDATE_PARTENAIRES_START:
            return {
                ...state,
                isLoading: true,
                DataUpdatePartenaire : null,
                error : null
            };
        case ParametrePagesTypes.UPDATE_PARTENAIRES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdatePartenaire: action.payload,
                error : null
            };


        case ParametrePagesTypes.UPDATE_PARTENAIRES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdatePartenaire : null
            };
        default:
            return state;
    }
}

export const Put_Archive_Partenaire_Reducers = (state=initialArchive, action) => {

    switch (action.type) {
        case ParametrePagesTypes.ARCHIVE_PARTENAIRES_START:
            return {
                ...state,
                isLoading: true,
                DataArchive : null,
                error : null
            };
        case ParametrePagesTypes.ARCHIVE_PARTENAIRES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataArchive: action.payload,
                error : null
            };


        case ParametrePagesTypes.ARCHIVE_PARTENAIRES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataArchive : null
            };
        default:
            return state;
    }
}

export const Get_List_partenaire_reducers = (state=initialListePartenaires, action) => {

    switch (action.type) {
        case ParametrePagesTypes.LIST_PARTENAIRES_START:
            return {
                ...state,
                isLoading: true,
                DataListPartenaire : null,
                error : null
            };
        case ParametrePagesTypes.LIST_PARTENAIRES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListPartenaire: action.payload,
                error : null
            };


        case ParametrePagesTypes.LIST_PARTENAIRES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListPartenaire : null
            };
        default:
            return state;
    }
}


export const Post_Create_Partenaire_Reducers = (state=initialCreatePartenaires, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_PARTENAIRES_START:
            return {
                ...state,
                isLoading: true,
                DataCreatePartenaire : null,
                error : null
            };
        case ParametrePagesTypes.CREATE_PARTENAIRES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreatePartenaire: action.payload,
                error : null
            };


        case ParametrePagesTypes.CREATE_PARTENAIRES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreatePartenaire : null
            };
        default:
            return state;
    }
}


export const PosteCreateMotPresidentReducers = (state=initialCreateMotPresident, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_START:
            return {
                ...state,
                isLoading: true,
                DataCreateVideo : null,
                error : null
            };
        case ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateVideo: action.payload,
                error : null
            };


        case ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateVideo : null
            };
        default:
            return state;
    }
}


export const Get_Detail_MotDUPresident_Reducers = (state=intitialDetailMOtPresident, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DataDetailMotPresident : null,
                error:null
            };
        case ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDetailMotPresident: action.payload,
                error:null
            };


        case ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailMotPresident : null,
            };
        default:
            return state;
    }
}


export const Put_ModifierMotDUPresidentReducer = (state=initialUpdateMotDupresident, action) => {

    switch (action.type) {
        case ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_START:
            return {
                ...state,
                isLoading: true,
                DataUpdateMotDuPresident : null,
                error: null,
            };
        case ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdateMotDuPresident: action.payload,
                error: null,
            };


        case ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdateMotDuPresident : null
            };
        default:
            return state;
    }
}




const initialCreateContact = {
    isLoading: false,
    DataContact: [],
    error: "",
};

const initialListeContact = {
    isLoading: false,
    DataListContact: [],
    error: "",
};

const initial_Contact_One = {
    isLoading: false,
    DataOne_View: [],
    error: "",
};

export const Get_One_Contact_Reducers = (state=initial_Contact_One, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_ONE_CONTACTS_START:
            return {
                ...state,
                isLoading: true,
                DataOne_View : null,
                error: null,
            };
        case ParametrePagesTypes.GET_ONE_CONTACTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataOne_View: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_ONE_CONTACTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataOne_View : null
            };
        default:
            return state;
    }
}

export const Get_Liste_Contact_Reducers = (state=initialListeContact, action) => {

    switch (action.type) {
        case ParametrePagesTypes.LISTE_CONTACTS_START:
            return {
                ...state,
                isLoading: true,
                DataListContact : null,
                error: null,
            };
        case ParametrePagesTypes.LISTE_CONTACTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListContact: action.payload,
                error: null,
            };


        case ParametrePagesTypes.LISTE_CONTACTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListContact : null
            };
        default:
            return state;
    }
}
export const Create_Contact_Reducers = (state=initialCreateContact, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_CONTACTS_START:
            return {
                ...state,
                isLoading: true,
                DataContact : null,
                error: null,
            };
        case ParametrePagesTypes.CREATE_CONTACTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataContact: action.payload,
                error: null,
            };


        case ParametrePagesTypes.CREATE_CONTACTS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataContact : null
            };
        default:
            return state;
    }
}


const initialCreate_Abon_news = {
    isLoading: false,
    DataNewABon: [],
    error: "",
};


export const Create_Abonne_news_Reducers = (state=initialCreate_Abon_news, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_START:
            return {
                ...state,
                isLoading: true,
                DataNewABon : null,
                error: null,
            };
        case ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataNewABon: action.payload,
                error: null,
            };


        case ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataNewABon : null
            };
        default:
            return state;
    }
}



const intial_visite = {
    isLoading: false,
    DataVisiteInitial: [],
    error: "",
};

export const Create_visite_Reducers = (state=intial_visite, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_START:
            return {
                ...state,
                isLoading: true,
                DataVisiteInitial : null,
                error: null,
            };
        case ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataVisiteInitial: action.payload,
                error: null,
            };


        case ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataVisiteInitial : null
            };
        default:
            return state;
    }
}



const initial_liste_News = {
    isLoading: false,
    DataListNewsletters: [],
    error: "",
};

export const Liste_Newsletters_Reducers = (state=initial_liste_News, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_START:
            return {
                ...state,
                isLoading: true,
                DataListNewsletters : null,
                error: null,
            };
        case ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListNewsletters: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListNewsletters : null
            };
        default:
            return state;
    }
}


const initial_Compte_view = {
    isLoading: false,
    DataCompteView: [],
    error: "",
};


export const get_Compte_is_View_Reducers = (state=initial_Compte_view, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_COMPTE_IS_VIEWS_START:
            return {
                ...state,
                isLoading: true,
                DataCompteView : null,
                error: null,
            };
        case ParametrePagesTypes.GET_COMPTE_IS_VIEWS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCompteView: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_COMPTE_IS_VIEWS_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCompteView : null
            };
        default:
            return state;
    }
}


const initial_State_Dashboard = {
    isLoading: false,
    DataDashboardState: [],
    error: "",
};


export const get_stats_dashboard_reducers = (state=initial_State_Dashboard, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_STATE_DASHBOARD_START:
            return {
                ...state,
                isLoading: true,
                DataDashboardState : null,
                error: null,
            };
        case ParametrePagesTypes.GET_STATE_DASHBOARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataDashboardState: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_STATE_DASHBOARD_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDashboardState : null
            };
        default:
            return state;
    }
}


const initial_chart_mois = {
    isLoading: false,
    DataChartMois: [],
    error: "",
};

export const get_stats_mois_chart_reducers = (state=initial_chart_mois, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_STATE_MOIS_START:
            return {
                ...state,
                isLoading: true,
                DataChartMois : null,
                error: null,
            };
        case ParametrePagesTypes.GET_STATE_MOIS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataChartMois: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_STATE_MOIS_FAILED:
            console.log('reducers : ',action.payload)
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataChartMois : null
            };
        default:
            return state;
    }
}


const initialCOntenuNewsletters = {
    isLoading: false,
    DataCOnNewsletters: [],
    error: "",
};


export const create_contenu_newsletters_reducers = (state=initialCOntenuNewsletters, action) => {

    switch (action.type) {
        case ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_START:
            return {
                ...state,
                isLoading: true,
                DataCOnNewsletters : null,
                error: null,
            };
        case ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCOnNewsletters: action.payload,
                error: null,
            };


        case ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_FAILED:
           
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCOnNewsletters : null
            };
        default:
            return state;
    }
}


const intiial_list_con_newsleters = {
    isLoading: false,
    Data_list_Con_Newsl: [],
    error: "",
};

export const get_list_contenu_newsletters_reducers = (state=initialCOntenuNewsletters, action) => {

    switch (action.type) {
        case ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_START:
            return {
                ...state,
                isLoading: true,
                Data_list_Con_Newsl : null,
                error: null,
            };
        case ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_list_Con_Newsl: action.payload,
                error: null,
            };


        case ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_FAILED:
            
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_list_Con_Newsl : null
            };
        default:
            return state;
    }
}
