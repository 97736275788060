export const Gestions_Des_Docs = {
    CREATE_DOC_REGLEMENT_START : "CREATE_DOC_REGLEMENT_START",
    CREATE_DOC_REGLEMENT_SUCCESS : "CREATE_DOC_REGLEMENT_SUCCESS",
    CREATE_DOC_REGLEMENT_FAILED : "CREATE_DOC_REGLEMENT_FAILED",


    GET_LIST_DOC_START : "GET_LIST_DOC_START",
    GET_LIST_DOC_SUCCESS : "GET_LIST_DOC_SUCCESS",
    GET_LIST_DOC_FAILED : "GET_LIST_DOC_FAILED",

    GET_DETAILS_START : "GET_DETAILS_START",
    GET_DETAILS_SUCCESS : "GET_DETAILS_SUCCESS",
    GET_DETAILS_FAILED : "GET_LIST_DOC_FAILED",


    GET_LAST_DOC_START : "GET_LAST_DOC_START",
    GET_LAST_DOC_SUCCESS : "GET_LAST_DOC_SUCCESS",
    GET_LAST_DOC_FAILED : "GET_LAST_DOC_FAILED",


    GET_DOC_BY_CAT_COMMUNAUTAIRE_START : "GET_DOC_BY_CAT_COMMUNAUTAIRE_START",
    GET_DOC_BY_CAT_COMMUNAUTAIRE_SUCCESS : "GET_DOC_BY_CAT_COMMUNAUTAIRE_SUCCESS",
    GET_DOC_BY_CAT_COMMUNAUTAIRE_FAILED : "GET_DOC_BY_CAT_COMMUNAUTAIRE_FAILED",

    PUT_UPDATE_DOC_START : "PUT_UPDATE_DOC_START",
    PUT_UPDATE_DOC_SUCCESS : "PUT_UPDATE_DOC_SUCCESS",
    PUT_UPDATE_DOC_FAILED : "GET_LIST_DOC_FAILED",


    
}