import { Gestion_Plainte_Types } from "../Types/TypesPlaintes"; 


const initial_Plaintes_Create = {
    isLoading: false,
    DataCreate_View: [],
    error: "",
};


const initial_Plaintes_liste = {
    isLoading: false,
    DataListe_View: [],
    error: "",
};

const initial_Plaintes_One = {
    isLoading: false,
    DataOne_View: [],
    error: "",
};

export const get_One_Plainte_Reducers = (state=initial_Plaintes_One, action) => {

    switch (action.type) {
        case Gestion_Plainte_Types.GET_ONE_PLAINTE_START:
            return {
                ...state,
                isLoading: true,
                DataOne_View : null,
                error: null,
            };
        case Gestion_Plainte_Types.GET_ONE_PLAINTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataOne_View: action.payload,
                error: null,
            };


        case Gestion_Plainte_Types.GET_ONE_PLAINTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataOne_View : null
            };
        default:
            return state;
    }
}


export const get_liste_Plainte_Reducers = (state=initial_Plaintes_liste, action) => {

    switch (action.type) {
        case Gestion_Plainte_Types.LISTE_PLAINTE_START:
            return {
                ...state,
                isLoading: true,
                DataListe_View : null,
                error: null,
            };
        case Gestion_Plainte_Types.LISTE_PLAINTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataListe_View: action.payload,
                error: null,
            };


        case Gestion_Plainte_Types.LISTE_PLAINTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListe_View : null
            };
        default:
            return state;
    }
}


export const post_Create_Plainte_Reducers = (state=initial_Plaintes_Create, action) => {

    switch (action.type) {
        case Gestion_Plainte_Types.CREATE_PLAINTE_START:
            return {
                ...state,
                isLoading: true,
                DataCreate_View : null,
                error: null,
            };
        case Gestion_Plainte_Types.CREATE_PLAINTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreate_View: action.payload,
                error: null,
            };


        case Gestion_Plainte_Types.CREATE_PLAINTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreate_View : null
            };
        default:
            return state;
    }
}

