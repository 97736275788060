import React from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import ActivitesDataLIst from './ActiviteDataList'
import FormulaireActivite from './FormulaireActivite'

const NewActivite = () => {
    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                   

                 
                    <div className="grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1">
                      
                       <div className='py-8 px-4'>
                            <h1><span className='font-bold text-teal-500'>Formulaire de création d'une nouvelle activité</span></h1>
                       </div>
                       <div>
                        <FormulaireActivite />
                       </div>
                    </div>










                </div>

            </div>
        </div>
    )
}

export default NewActivite


