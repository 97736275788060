import React, { useEffect, useState } from 'react'

import coverImage from '../../../images/imagecover.png'
import { useDispatch, useSelector } from 'react-redux'
import { Get_Dernier_pub_Actions } from '../../../reduxComponents/Actions/PublicationsActions'
import { BackendEntete } from '../../../VariablesConfig'
import { useNavigate } from 'react-router-dom'




const Articles = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const DataDernierStore = useSelector(state=>state.dernier_pub_store)
  const [DataDernierPub, setDataDernierPub] =useState(null)
  useEffect(()=>{
    setDataDernierPub(DataDernierStore?.DataDernierPub)
  },[DataDernierStore?.DataDernierPub])

  useEffect(()=>{
    dispatch(Get_Dernier_pub_Actions())
  },[])


  const RedirectTo = (id)=>{
    const idcry = btoa(id)
    navigate(`/actualites/publication/${idcry}`)

  }

  return ( 
    <>
    {
      DataDernierPub && (
        <div className="relative bg-gray-200 rounded-lg overflow-hidden cursor-pointer sectionAnime04" onClick={()=>RedirectTo(DataDernierPub[0]?.id)} >
              <img
                  className="w-full h-64 object-cover object-center"
                  src={BackendEntete + DataDernierPub[0]?.image_cover}
                  alt={DataDernierPub[0]?.titre}
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-teal-900 opacity-90"></div>
              <div className="absolute bottom-0 left-0 w-full px-6 pb-3 text-white">
                  <p className="font-bold">{DataDernierPub[0]?.titre}</p>
              </div>
          </div>
      )
    }
      
    </>
    
    
  )
}

export default Articles
