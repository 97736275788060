import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import DataListContacts from './DataListContacts'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get_Details_contactsActions } from '../../reduxComponents/Actions/ParametrePagesActions'

const ContactsAdminAdminDetail = () => {

    const params = useParams()
    const params_id = atob(params?.id)
    const dispatch = useDispatch()
    const DataDetailContactStore = useSelector(state => state.get_one_contact_store)
    const [DataDetail, setDataDetail] = useState(null)

    useEffect(() => {
        if (DataDetailContactStore?.DataOne_View) {
            setDataDetail(DataDetailContactStore?.DataOne_View)
        }
    }, [DataDetailContactStore?.DataOne_View])

    useEffect(()=>{
        dispatch(get_Details_contactsActions(params_id))
    },[params_id])
    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>

                    </div>

                    <div className='mt-8 mx-4'>
                        <section class=" py-1 bg-blueGray-50">
                            <div class="w-full lg:w-full px-4 mx-auto mt-6">
                                <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
                                    
                                    <div class="flex-auto px-2 lg:px-3 py-3 pt-0">
                                        
                                            <h6 class="text-blueGray-400 p-3  text-sm mt-3 mb-6 font-bold uppercase">
                                               <span className='font-bold text-teal-500'>Information d'identification </span> 
                                            </h6>
                                            <div class="flex flex-wrap">
                                                <div class="w-full lg:w-6/12 px-4">
                                                    <div class="relative w-full mb-3">
                                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                                            Nom et prenom
                                                        </label>
                                                        <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value={DataDetail?.nom_prenoms} disabled/>
                                                    </div>
                                                </div>
                                                <div class="w-full lg:w-6/12 px-4">
                                                    <div class="relative w-full mb-3">
                                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                                            Email 
                                                        </label>
                                                        <input type="email" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value={DataDetail?.email}  disabled/>
                                                    </div>
                                                </div>
                                                <div class="w-full lg:w_full px-4">
                                                    <div class="relative w-full mb-3">
                                                        <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlfor="grid-password">
                                                            Contact
                                                        </label>
                                                        <input type="text" class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150" value={DataDetail?.numero_tel} disabled/>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                           

                                               
                                                <hr class="mt-6 border-b-1 border-blueGray-300" />

                                                <h6 class="text-blueGray-400 p-3  text-sm mt-3 mb-6 font-bold uppercase">
                                                <span className='font-bold text-orange-500'>Message </span> 
                                                </h6>
                                                    <div class="flex flex-wrap">
                                                        <div class="w-full lg:w-12/12 px-4">
                                                            <div class="relative w-full mb-3">
                                                               
                                                                <textarea type="text" value={DataDetail?.description} class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm  focus:outline-none focus:ring w-full ease-linear transition-all duration-150" rows="4" disabled></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                            </div>
                                    </div>
                                    
                                </div>
                        </section>
                    </div>




                </div>

            </div>
        </div>
    )
}

export default ContactsAdminAdminDetail


