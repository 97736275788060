import { Gestions_Des_Docs } from "../Types/Gestion_Des_DOC_Types";


const initialCreateDocs = {
    isLoading: false,
    DataDocCreate: [],
    error: "",
};

const initialDataListeDoc = {
    isLoading: false,
    DataListeDoc: [],
    error: "",
};


const initialDataDetailDoc = {
    isLoading: false,
    DatDetailDoc: [],
    error: "",
};

const initialDataUpdateDoc = {
    isLoading: false,
    DataUpdateDoc: [],
    error: "",
};

const initialListelasteDoc = {
    isLoading: false,
    DataLasteDocc: [],
    error: "",
};

const initialCommunautaire = {
    isLoading: false,
    DataListCommunautaire: [],
    error: "",
};

export const Put_Update_DOcumentReducer = (state=initialDataUpdateDoc, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.PUT_UPDATE_DOC_START:
            return {
                ...state,
                isLoading: true,
                DataUpdateDoc : null,
                error: null,
            };
        case Gestions_Des_Docs.PUT_UPDATE_DOC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdateDoc: action.payload,
                error: null,
            };


        case Gestions_Des_Docs.PUT_UPDATE_DOC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdateDoc : null
            };
        default:
            return state;
    }
}

export const get_listDOc_Communautaire = (state=initialCommunautaire, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_START:
            return {
                ...state,
                isLoading: true,
                DataListCommunautaire : null,
                error:null
            };
        case Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataListCommunautaire: action.payload,
                error:null
            };


        case Gestions_Des_Docs.GET_DOC_BY_CAT_COMMUNAUTAIRE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListCommunautaire : null,
            };
        default:
            return state;
    }
}

export const Get_Detail_DocReducer = (state=initialDataDetailDoc, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.GET_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DatDetailDoc : null,
                error:null
            };
        case Gestions_Des_Docs.GET_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DatDetailDoc: action.payload,
                error:null
            };


        case Gestions_Des_Docs.GET_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DatDetailDoc : null,
            };
        default:
            return state;
    }
}


export const Get_LastDoc_DocReducer = (state=initialListelasteDoc, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.GET_LAST_DOC_START:
            return {
                ...state,
                isLoading: true,
                DataLasteDocc : null,
                error:null
            };
        case Gestions_Des_Docs.GET_LAST_DOC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataLasteDocc: action.payload,
                error:null
            };


        case Gestions_Des_Docs.GET_LAST_DOC_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataLasteDocc : null,
            };
        default:
            return state;
    }
}



export const Get_DocumentListeReducers = (state=initialDataListeDoc, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.GET_LIST_DOC_START:
            return {
                ...state,
                isLoading: true,
                DataListeDoc : null,
                error:null
            };
        case Gestions_Des_Docs.GET_LIST_DOC_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataListeDoc: action.payload,
                error:null
            };


        case Gestions_Des_Docs.GET_LIST_DOC_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListeDoc : null,
            };
        default:
            return state;
    }
}


export const Post_create_adminReducers = (state=initialCreateDocs, action) => {

    switch (action.type) {
        case Gestions_Des_Docs.CREATE_DOC_REGLEMENT_START:
            return {
                ...state,
                isLoading: true,
                DataDocCreate : [],
                error:  []
            };
        case Gestions_Des_Docs.CREATE_DOC_REGLEMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataDocCreate: action.payload,
                error:  []
            };


        case Gestions_Des_Docs.CREATE_DOC_REGLEMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDocCreate : []
            };
        default:
            return state;
    }
}
