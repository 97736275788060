
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_CommuniquePresse_liste, put_Archive_CommuniqueActions } from '../../../reduxComponents/Actions/Gestion_CommuniquePActions';
import { BackendEntete } from '../../../VariablesConfig';
import { formatDateBon } from '../../../app_Visiteurs/sections/activites/ListeActivites';

const DataCommuniquePresses = () => {
    const dispatch = useDispatch()
    const DataListeStore = useSelector(state=>state.get_Liste_CommuniquePresse)
    const DataArchiveStore = useSelector(state=>state.put_archive_store) 

    const [DataListeCommunique, setDataListeCommunique] = useState(null)
    const navigate = useNavigate()

    useEffect(()=>{
        if (DataArchiveStore?.DataArchiveCommunique?.succes && loading) {
            dispatch(get_CommuniquePresse_liste())
            setVisibleDialogue(false)
            setLoading(false)
        }

    },[DataArchiveStore?.DataArchiveCommunique])


   

    useEffect(()=>{
        dispatch(get_CommuniquePresse_liste())
    }, [])

    useEffect(()=>{
        if (DataListeStore?.DataListeCPReducers) {
            setDataListeCommunique(DataListeStore?.DataListeCPReducers)
            setLoading(false)
        }
    }, [DataListeStore?.DataListeCPReducers])

    const ArchiverAction = ()=>{
        setLoading(true)
        dispatch(put_Archive_CommuniqueActions(Params_id))
    }
    

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le communiqué" icon="pi pi-check" onClick={() => ArchiverAction(true)} autoFocus />
        </div>
    );


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/actualites/creationcommunique')
    }

    const renderHeader = () => {
        return (
            <>
             <div className='py-3'>
                <h3 > <span className='font-bold text-teal-600'>Listes des communiqués de presse enregistrés</span> </h3>
             </div>
            <div className='flex justify-between'>
               
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un communiqué de presse" />
                </span>
                </div>
            <div className="flex justify-content-end">
            <Button label="Créer une nouvelle publication" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
            </>
            
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={BackendEntete + rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/communique/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/communique/modifier/${idBtoa}`, {replace : false})
    }
    const [Params_id, setParams_id] =useState(null)
    const [Titre, setTitre] =useState(null)

    const DialogueArchive = (rowData)=>{
        setTitre(rowData?.titre)
        setParams_id(rowData?.id)
        setVisibleDialogue(true)
        
    }

    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData.id)} ><i class="fi fi-sr-eye"></i></button>
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive(rowData)} text className={`${rowData?.is_archive ? 'text-teal-500' : 'text-red-500'}`}><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }


    const bodyData = (rowData)=>{
        return(
            <div>
                {
                    formatDateBon(rowData?.date_creation)
                }
            </div>
        )
    }

    const ArchiverBody = (rowData)=>{
        return(
         <div>
             {
             rowData?.is_archive === true ? (
                 <div className='text-red-500 font-bold'> archivé</div>
             ) : (
                 <div className='text-teal-500 font-bold'> non archivé</div>
             )
         }
         </div>
        )
     }
 

    return (
        <div className="card">
            <DataTable value={DataListeCommunique} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['name', 'Cat_communique', 'titre', 'image_cover', 'date_creation']} header={header} emptyMessage="Aucun communiqué de presse trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                <Column field="image_cover" header="Image de couverture" body={photoBody} style={{ minWidth: '9rem' }} />
                <Column header="Titre publications" field='titre' style={{ minWidth: '9rem' }} />
                <Column header="Catégorie" field='Cat_communique' style={{ minWidth: '9rem' }} />
                <Column header="Date de publications" field='date_creation' body={bodyData} style={{ minWidth: '9rem' }} />
                <Column header="Archivé" field='is_archive' body={ArchiverBody} style={{ minWidth: '9rem' }} />
                <Column header="Nombre de vue" field='vues' style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                 <p className="m-0">
                    Vous souhaité archiver le communiqué de presse : <b>{Titre} ?</b>
                </p>
            </Dialog>
        </div>
        </div>
    )
}

export default DataCommuniquePresses


