import React, { useEffect, useRef, useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders'
import ArticlesActivites from '../Articles'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { Galleria } from 'primereact/galleria'
import { useDispatch, useSelector } from 'react-redux'
import { get_GaleriePhotoDetailAction } from '../../../reduxComponents/Actions/GaleriePhotoActions'
import { BackendEntete } from '../../../VariablesConfig'

const Voir_Galerie = () => {
    const navigate = useNavigate()
    const params = useParams()

    const params_id = atob(params?.id)

    const dispatch = useDispatch()

    const DetailGaleriePhotoStore = useSelector(state => state.get_detail_galeriePhotoStore)
    const [DataDetailGP, setDataDetailGP] = useState()

    const [images, setImages] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);



    const [DataPhoto, setDataPhoto] = useState(null)

    const redirectModif = () => {
        const id = btoa(params_id)
        navigate(`/admin/actualites/galerie-photo/modifier/${id}`)
    }


    useEffect(() => {
        setDataDetailGP(DetailGaleriePhotoStore?.DataDetailGaleriePhoto)
        setDataPhoto(DetailGaleriePhotoStore?.DataDetailGaleriePhoto?.autres_photo)
    }, [DetailGaleriePhotoStore?.DataDetailGaleriePhoto])

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '960px',
            numVisible: 4
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const itemTemplate = (item) => {
        return <img src={BackendEntete + item} alt={item.alt} style={{ width: '100%', display: 'block' }} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={BackendEntete + item} alt={item.alt} style={{ display: 'block', height: '75px' }} />
    }



    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    useEffect(() => {
        dispatch(get_GaleriePhotoDetailAction(params_id))
    }, [params_id])





    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content displayLIgneColForce'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div className='grid gap-3 mb-8 md:grid-cols-2 xl:grid-cols-4 p-4 md:24 border-2 mx-4'>
                        <div>

                        </div>
                        <div>

                        </div>

                        <div>
                            <Button className='w-full bg-gray-500 border-none hover:bg-gray-600 btnBacground03' onClick={() => redirectModif()} label='Modifier le communiqué' />
                        </div>
                        <div>
                            <Button className='bg-orange-500 border-none w-full hover:bg-orange-700 btnBacground01' onClick={() => setVisibleDialogue(true)} label='Archiver le communiqué' />
                        </div>
                    </div>
                    <div className='py-4'>
                        <span className='font-bold text-3xl bg-teal-600 text-white p-3'> <span className='text-orange-200'><i class="fi fi-sr-gallery"></i></span>Galerie photo</span>
                    </div>

                    <div>
                        <div>
                            <Galleria className='p-12 bg-gray-800' value={DataPhoto} responsiveOptions={responsiveOptions} numVisible={7} circular style={{ maxWidth: '800px' }}
                                item={itemTemplate} thumbnail={thumbnailTemplate} />
                        </div>
                    </div>





                </div>

            </div>

            <div className="card flex justify-content-center">

                <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                    <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Dialog>
            </div>
        </div>
    )
}

export default Voir_Galerie

