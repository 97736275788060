import React from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import DataListeAdministrateur from './AdminsComponent/DataListeAdministrateur'

const Administrateurs = () => {
  return (
    <div>
       <div>
      <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>
                        <div className='pt-6 pl-4'>
                            <h1 className='text-slate-500	text-4xl font-bold'> <span className='bg-green-500 p-3 text-white rounded-full mr-3'><i class="fi fi-ss-user-gear"></i></span> <span >Gestion des utilisateurs</span></h1>
                        </div>
                    </div>

                    <div className='mt-12'>
                        <div>
                            <h5>
                                <span className='font-bold'>Liste des administrateurs enregistrées</span>
                            </h5>
                        </div>
                        <div>
                            <DataListeAdministrateur />
                        </div>
                    </div>





                </div>

            </div>
    </div>
    </div>
  )
}

export default Administrateurs
