import React, { useEffect, useRef, useState } from "react";
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import TextEditor from "../../../app_admin/Parametre/TextEditor";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { Tooltip } from "primereact/tooltip";
import { Tag } from "primereact/tag";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { post_create_plaintes_actions } from "../../../reduxComponents/Actions/Gestion_Plaintes_Actions";
import LoaderComponent from "../../../LoaderComponent";
import { Dialog } from "primereact/dialog";
import { validateEmail } from "../Accueil/SectionsAccueil/Newsletter";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ViewPdfPLainte from "../../../app_admin/saine/ViewPdfPLainte";

const FormSaisine = () => {
    const stepperRef = useRef(null)

    const dispatch = useDispatch()

    const DataEnvoyeStore = useSelector(state => state.post_create_plainte)

    const [IsVoirDocument, setIsVoirDocument] = useState(false)

    const [Entreprise, setEntreprise] = useState()
    const [AdressePostale, setAdressePostale] = useState()
    const [Ville, setVille] = useState()
    const [CodePostal, setCodePostal] = useState()
    const [NomPrenom, setNomPrenom] = useState()
    const [SiteWeb, setSiteWeb] = useState()
    const [Telephone, setTelephone] = useState()
    const [AdresseElectronique, setAdresseElectronique] = useState()
    const [QualiteSignataire, setQualiteSignataire] = useState()
    const [TelFix, setTelFix] = useState()
    const [TelMobile, setTelMobile] = useState()


    const options = ['de mon entreprise/organisation', 'd’une entreprise/organisation tierce'];

    const [PourCompteEntreprise, setPourCompteEntreprise] = useState(options[0])
    const [ContreEntreprise, setContreEntreprise] = useState()
    const [ContreAdressePostaleEntreprise, setContreAdressePostaleEntreprise] = useState()
    const [ContreTelpersonne, setContreTelpersonne] = useState()
    const [ObjetPlainte, setObjetPlainte] = useState('')


    const optionsAnonymat = ['Oui', 'Non'];
    const [IsHaveAnonymat, setIsHaveAnonymat] = useState(optionsAnonymat[1])
    const [PourquoiIsHaveAnonymat, setPourquoiIsHaveAnonymat] = useState()
    const [IsHaveMesuresCon, setIsMesuresCon] = useState(optionsAnonymat[0])
    const [PourquoiIsHaveMesuresCon, setPourquoiIsHaveMesuresCon] = useState()
    const [QuellesMesures, setQuellesMesures] = useState()
    const [PreuveLegaleExiste, setPreuveLegaleExiste] = useState(optionsAnonymat[0])
    const [HabilitationPreuveSignataire, setHabilitationPreuveSignataire] = useState(optionsAnonymat[0])

    const [DateDeSaisine, setDateDeSaisine] = useState()
    const [Signatures, setSignatures] = useState()


    const [errorMessage, setErrorMessage] = useState("");

    const toast = useRef(null);
    const [totalSize, setTotalSize] = useState(0);
    const fileUploadRef = useRef(null);

    const [DataListDocAdd, setDataListDocAdd] = useState(null)

    const [loading, setLoading] = useState(false)



    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;
        const newDataList = [];
        const reader = new FileReader();

        Object.keys(files).forEach((key) => {
            const file = files[key];
            const reader = new FileReader();

            reader.onloadend = function () {
                // Convertir le contenu du fichier en base64
                const base64Data = reader.result.split(',')[1];

                // Ajouter les données encodées au format base64 à la nouvelle liste de données
                newDataList.push({
                    data: base64Data
                });

                // Mettre à jour dataListDocAdd avec la nouvelle liste de données
                setDataListDocAdd(newDataList);
            };

            // Lire le contenu du fichier en tant que blob
            reader.readAsDataURL(file);

            _totalSize += file.size || 0;
        });
        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'Fichier ajouté' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        const newDataList = DataListDocAdd.filter(doc => doc.name !== file.name);
        setDataListDocAdd(newDataList);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };


    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex align-items-center gap-3 ml-auto">
                    <span>{formatedValue} / 10 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };



    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Depsez vos documents ici
                </span>
            </div>
        );
    };

    const [ErreurDispath, setErreurDispath] = useState(false)

    const chooseOptions = { icon: 'pi pi-fw pi-file', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined ' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined hidden' };

    const [IsSuccesEnvoiePlainte, setIsSuccesEnvoiePlainte] = useState(false)

    useEffect(() => {
        if (DataEnvoyeStore?.DataCreate_View?.id && loading) {
            setIsSuccesEnvoiePlainte(true)
            setLoading(false)
        }



    }, [DataEnvoyeStore?.DataCreate_View])


    useEffect(() => {


        if (DataEnvoyeStore?.error && loading) {
            setErreurDispath(true)
        }

    }, [DataEnvoyeStore?.error])

    const SubmitDataPlainte = (event) => {
        event.preventDefault()

        if (validateEmail(AdresseElectronique)) {
            setLoading(true)
            const body = {

                entreprise: Entreprise || null,
                adresse_postale: AdressePostale,
                ville: Ville,
                code_postal: CodePostal,
                nom_prenom: NomPrenom,
                ste_web: SiteWeb || null,
                telephone: Telephone,
                adresse_electronique: AdresseElectronique || null,
                qualites_signataire: QualiteSignataire,
                tel_fix: TelFix || null,
                tel_mobile: TelMobile,



                pour_compte_entreprise: PourCompteEntreprise,
                contre_entreprise: ContreEntreprise,
                contre_adresse_postal_entreprise: ContreAdressePostaleEntreprise,
                contre_telephone: ContreTelpersonne,
                object_plainte: ObjetPlainte,


                is_have_anonymat: IsHaveAnonymat,
                pourquoi_is_have_anonymat: PourquoiIsHaveAnonymat,
                is_have_mesures_con: IsHaveMesuresCon,
                pourquoi_is_have_mesures_con: PourquoiIsHaveMesuresCon,
                quelles_mesures: QuellesMesures,
                preuves_legale_existe: PreuveLegaleExiste,
                habilitation_preuves_signataire: HabilitationPreuveSignataire,
                list_documents_plainte: DataListDocAdd,
                document_plainte : PreviewURLPlainte,



            }

            console.log(body)

            dispatch(post_create_plaintes_actions(body))
        } else {
            setErrorMessage("Veuillez entrer une adresse e-mail valide.");
        }



    }

    const [SelectedFilePlainte, setSelectedFilePlainte] =useState(null)
    const [PreviewURLPlainte, setPreviewURLPlainte] = useState(null)

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFilePlainte(file);

        // Prévisualisation du fichier PDF sélectionné
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewURLPlainte(reader.result);
        };
        reader.readAsDataURL(file);
    };




    const footerContent = (
        <div className="flex gap-10 justify-between">
            
           


                <div>
                <Button label="Fermer" className='bg-orange-200 border-none text-orange-500' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
                </div>

                <div>
                
                <PDFDownloadLink
                document={<ViewPdfPLainte datainfo={ DataEnvoyeStore?.DataCreate_View} />}
                fileName={"plainte N°"+  DataEnvoyeStore?.DataCreate_View?.reference_plainte + ".pdf"}
                style={{
                    textDecoration: 'none',
                    padding: '15px',
                    paddingRight : '100px',
                    paddingLeft : '100px',
                    color: '#fff',
                    backgroundColor: '#36805B',
                    border: 'none',
                    borderRadius: '5px',
                }}
                >
                {({ loading }) => (loading ? 'Génération du document pdf de la plainte...' : 'Télécharger la plainte')}
                </PDFDownloadLink>
            </div>
        </div>
    );

    const AllerListeDoc = () => {

        window.location.reload();


    }




    return (
        <>
            <div className=" flex justify-content-center">
                <LoaderComponent loading={loading} />
                <Stepper ref={stepperRef} className='w-full'>
                    <StepperPanel header="Etape 01">
                        <div className=" flex-column h-12rem">
                            <div className="border-2 p-4 border-dashed surface-border border-round surface-ground   justify-content-center align-items-center ">
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Entreprise, organisation :</span>  </label>
                                            <InputText id="username" value={Entreprise} aria-describedby="username-help" onChange={(e) => setEntreprise(e.target.value)} />

                                        </div>
                                    </div>



                                </div>



                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-3">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Adresse postale</span> <span className=' text-red-500'>*</span> </label>
                                            <InputText id="username" value={AdressePostale} aria-describedby="username-help" onChange={(e) => setAdressePostale(e.target.value)} />

                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Ville</span> <span className=' text-red-500'>*</span> </label>
                                            <InputText id="username" value={Ville} aria-describedby="username-help" onChange={(e) => setVille(e.target.value)} />

                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Code postal</span>  </label>
                                            <InputText id="username" value={CodePostal} aria-describedby="username-help" onChange={(e) => setCodePostal(e.target.value)} />

                                        </div>
                                    </div>

                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Adresse email : </span> <span className=' text-red-500'>*</span>  </label>
                                            <InputText id="username" value={AdresseElectronique} aria-describedby="username-help" onChange={(e) => setAdresseElectronique(e.target.value)} />
                                            {errorMessage && <p className="text-red-500 p-3 bg-white">{errorMessage}</p>}
                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Site Web :</span> </label>
                                            <InputText id="username" value={SiteWeb} aria-describedby="username-help" onChange={(e) => setSiteWeb(e.target.value)} />

                                        </div>
                                    </div>


                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Nom et prénom du signataire</span> <span className=' text-red-500'>*</span> </label>
                                            <InputText id="username" value={NomPrenom} aria-describedby="username-help" onChange={(e) => setNomPrenom(e.target.value)} />

                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Qualité du signataire</span> <span className=' text-red-500'>*</span> </label>
                                            <InputText id="username" value={QualiteSignataire} aria-describedby="username-help" onChange={(e) => setQualiteSignataire(e.target.value)} />

                                        </div>
                                    </div>



                                </div>


                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">



                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Tel fixe :</span>  </label>
                                            <InputText id="username" value={TelFix} aria-describedby="username-help" onChange={(e) => setTelFix(e.target.value)} />

                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Tél mobile : </span> <span className=' text-red-500'>*</span> </label>
                                            <InputText id="username" value={TelMobile} aria-describedby="username-help" onChange={(e) => setTelMobile(e.target.value)} />

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="flex pt-4 justify-content-end">
                            <Button label="Suivant" icon="pi pi-arrow-right" className="bg-orange-400 hover:bg-orange-500 border-none" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                    </StepperPanel>
                    <StepperPanel header="Etape 02">
                        <div className="flex flex-column h-12rem">
                            <div className="border-2 p-4 border-dashed surface-border border-round surface-ground   justify-content-center align-items-center ">

                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <label htmlFor="username "><span className=''>Je porte plainte pour le compte :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <SelectButton value={PourCompteEntreprise} onChange={(e) => setPourCompteEntreprise(e.value)} options={options} className="OptionPourEntreprise" />

                                        </div>
                                    </div>



                                </div>
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Personne/entreprise/organisation contre laquelle je porte plainte : </span> <span className='font-bold text-red-500'>*</span> </label>
                                            <InputText id="username" value={ContreEntreprise} aria-describedby="username-help" onChange={(e) => setContreEntreprise(e.target.value)} />

                                        </div>
                                    </div>



                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Adresse postale de la Personne/entreprise/organisation contre laquelle je porte plainte : </span> <span className='font-bold text-red-500'>*</span> </label>
                                            <InputText id="username" value={ContreAdressePostaleEntreprise} aria-describedby="username-help" onChange={(e) => setContreAdressePostaleEntreprise(e.target.value)} />

                                        </div>
                                    </div>



                                </div>

                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Tel de la Personne/entreprise/organisation contre laquelle je porte plainte : (Si elle est connue).</span> </label>
                                            <InputText id="username" value={ContreTelpersonne} aria-describedby="username-help" onChange={(e) => setContreTelpersonne(e.target.value)} />

                                        </div>
                                    </div>



                                </div>



                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username "><span className=''>Objet de la plainte :</span> <span className='font-bold text-red-500'>*</span> </label>

                                            <InputTextarea id="username" value={ObjetPlainte} onChange={(e) => setObjetPlainte(e.target.value)} rows={5} cols={250} />

                                        </div>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <div className="flex pt-4 justify-content-between">
                            <Button label="Retour" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                            <Button label="Suivant" icon="pi pi-arrow-right" className="bg-orange-400 hover:bg-orange-500 border-none" iconPos="right" onClick={() => stepperRef.current.nextCallback()} />
                        </div>
                    </StepperPanel>
                    <StepperPanel header="Etape 03">
                        <div className="flex flex-column h-12rem">
                            <div className="border-2 p-4 border-dashed surface-border border-round surface-ground   justify-content-center align-items-center ">


                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <label htmlFor="username "><span className=''>Je souhaite que mon anonymat soit préservé et ne soit divulgué à aucun moment de la procédure  :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <SelectButton value={IsHaveAnonymat} onChange={(e) => setIsHaveAnonymat(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" />

                                        </div>
                                    </div>



                                </div>


                                {
                                    IsHaveAnonymat === 'Oui' && (
                                        <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                            <div class="mb-4">
                                                <div className="flex flex-column gap-2 w-full ">
                                                    <label htmlFor="username "><span className=''>SI Oui, Pourquoi ? :</span> <span className='font-bold text-red-500'>*</span> </label>
                                                    <InputTextarea id="username" value={PourquoiIsHaveAnonymat} onChange={(e) => setPourquoiIsHaveAnonymat(e.target.value)} rows={5} cols={250} />
                                                </div>
                                            </div>

                                        </div>
                                    )
                                }




                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <label htmlFor="username "><span className=''>Je souhaite que des mesures conservatoires soient appliquées* :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <SelectButton value={IsHaveMesuresCon} onChange={(e) => setIsMesuresCon(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" />

                                        </div>
                                    </div>



                                </div>

                                {
                                    IsHaveMesuresCon === "Oui" && (
                                        <>
                                            <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                                <div class="mb-4">
                                                    <div className="flex flex-column gap-2 w-full ">
                                                        <label htmlFor="username "><span className=''>SI Oui, Pourquoi ? :</span> <span className='font-bold text-red-500'>*</span> </label>
                                                        <InputTextarea id="username" value={PourquoiIsHaveMesuresCon} onChange={(e) => setPourquoiIsHaveMesuresCon(e.target.value)} rows={5} cols={250} />
                                                    </div>
                                                </div>

                                                <div class="mb-4">
                                                    <div className="flex flex-column gap-2 w-full ">
                                                        <label htmlFor="username "><span className=''>Si oui quelles mesures ? :</span> <span className='font-bold text-red-500'>*</span> </label>
                                                        <InputTextarea id="username" value={QuellesMesures} onChange={(e) => setQuellesMesures(e.target.value)} rows={5} cols={250} />
                                                    </div>
                                                </div>

                                            </div>



                                        </>
                                    )
                                }
                                 <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                            <div class="mb-4">
                                <div className="flex flex-column gap-2 w-full ">
                                <div>
 
                                    
                                </div>
                                    <label htmlFor="username "><span className='text-red-500 font-bold'>Ajouter le document plainte  :</span> <span className='font-bold text-red-500'>*</span> </label>
                                    {/* <SelectButton value={IsHaveMesuresCon} onChange={(e) => setIsMesuresCon(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" /> */}
                                    <input type="file" accept=".pdf" onChange={handleFileChange} />
                                    <span onClick={()=>setIsVoirDocument(!IsVoirDocument)} className="font-bold text-blue-400 underline cursor-pointer">Cliquer ici, pour voir comment le document doit etre organiser.</span>
                                </div>
                            </div>



                            </div>
                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-1">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <Toast ref={toast}></Toast>

                                            <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                                            <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                                            <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />
                                            <label htmlFor="username "><span className=''>Documents annexés au dossier de plainte :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            {/* <SelectButton value={IsHaveMesuresCon} onChange={(e) => setIsMesuresCon(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" /> */}

                                            <FileUpload ref={fileUploadRef} name="demo[]" url="/api/upload" multiple accept="application/pdf" maxFileSize={10000000}
                                                onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                                headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                                chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />
                                        </div>
                                    </div>



                                </div>


                                <div class="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <label htmlFor="username "><span className=''>Preuve d’existence légale ? : :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <SelectButton value={PreuveLegaleExiste} onChange={(e) => setPreuveLegaleExiste(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" />

                                        </div>
                                    </div>


                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2 w-full ">
                                            <label htmlFor="username "><span className=''>Preuve d’habilitation pour le signataire ?  :</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <SelectButton value={HabilitationPreuveSignataire} onChange={(e) => setHabilitationPreuveSignataire(e.value)} options={optionsAnonymat} className="OptionPourEntreprise" />

                                        </div>
                                    </div>





                                </div>


                            </div>
                        </div>
                        <div className="flex pt-4  justify-content-between">
                            <Button label="Retour" severity="secondary" icon="pi pi-arrow-left" onClick={() => stepperRef.current.prevCallback()} />
                            {
                                (AdressePostale && PreviewURLPlainte && DataListDocAdd && DataListDocAdd && NomPrenom && QualiteSignataire && TelMobile && PourCompteEntreprise && ContreEntreprise && ContreAdressePostaleEntreprise  && ObjetPlainte && IsHaveAnonymat && IsHaveMesuresCon && PreuveLegaleExiste && HabilitationPreuveSignataire) ? (
                                    <Button label="Envoyer la plainte" icon="pi pi-check" className="bg-teal-600 hover:bg-teal-500 border-none" iconPos="right" onClick={(event) => SubmitDataPlainte(event)} />

                                ) : (
                                    <Button disabled label="Envoyer la plainte" icon="pi pi-check" className="bg-teal-600 hover:bg-teal-500 border-none" iconPos="right" />

                                )

                            }

                        </div>
                    </StepperPanel>
                </Stepper>
            </div>

            <div className="card flex justify-content-center">
                <Dialog visible={IsSuccesEnvoiePlainte} modal footer={footerContent} style={{ width: '50rem' }} onHide={() => setIsSuccesEnvoiePlainte()}>

                    <div className="bg-white rounded-md  px-4 py-6">
                        <div className=" flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-green-600"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>

                        <div className="text-lg  text-gray-800 text-center mt-3">
                            {" "}
                            Votre plainte a été enregistrée Sous le <b className="text-orange-500">N° {DataEnvoyeStore?.DataCreate_View?.reference_plainte}</b> . Par ailleurs vous recevrez dans un délai de sept (07) jours ouvrables une notification de recevabilité ou  d'irrecevabilité.
                        
                        </div>


                    </div>


                </Dialog>

                {/* <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
            <p className="m-0 text-red-500 text-center">
              votre temps de connexion est epuisé
            </p>
          </Dialog> */}
            </div>



            <div className="card flex justify-content-center">
                <Dialog visible={ErreurDispath} modal style={{ width: '25rem' }} onHide={() => {
                    setLoading(false)
                    setErreurDispath(false)
                }}>

                    <div className="bg-white rounded-md  px-4 py-6">
                        <div className=" flex items-center text-red-500 text-3Xl  justify-center mx-auto rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <g clip-path="url(#clip0_705_24107)">
                                    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#D72828" />
                                    <path d="M29 50H35V44H29V50ZM29 12V38H35V12H29Z" fill="#E6E6E6" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_705_24107">
                                        <rect width="64" height="64" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className="text-lg font-medium text-red-600 font-bold text-center mt-3">
                            {" "}
                            oups une erreur s'est produite. veuillez réessayer plus tard
                        </div>


                    </div>


                </Dialog>

                <Dialog header="Organisation d'un document de plainte" visible={IsVoirDocument} style={{ width: '65vw' }} onHide={() => setIsVoirDocument(!IsVoirDocument)}>
                    <p className="m-0">
                            Le dossier de plainte doit obligatoirement comprendre les éléments suivants :
                    </p>
                    <br />
                    <ul>
                        <li> Raison sociale de l’entreprise ou nom de l’organisation ;</li>
                        <li> Adresse postale complète ;</li>
                        <li> Coordonnées téléphoniques ;</li>
                        <li> Adresse électronique ;</li>
                        <li> Preuve d’existence légale ;</li>
                        <li> Preuve d’habilitation pour la personne signataire ;</li>
                        <li> Description des faits ;</li>
                        <li> Début de preuve, matérialisé par tous documents, physiques ou
                        immatériels, témoignages …</li>
                        <li className="font-bold text-orange-400"> <i>Signature de la personne et cachet de la structure</i></li>

                    </ul>
                </Dialog>

                {/* <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
            <p className="m-0 text-red-500 text-center">
              votre temps de connexion est epuisé
            </p>
          </Dialog> */}
            </div>


        </>
    )
}

export default FormSaisine
