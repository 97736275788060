import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import SousSections from '../SousSections.jsx/SousSections'
import Footers from '../../footers/Footers'
import coverImage from '../../../images/imagecover.png'
import ArticleAccueil from '../SousSections.jsx/ArticleAccueil'
import imgaePub from '../../../images/imagecover.png'
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { BackendEntete } from '../../../VariablesConfig'
import { Get_Last_List_Actions, Get_detail_PublicationsActions } from '../../../reduxComponents/Actions/PublicationsActions'
import { formatDateBon } from '../activites/ListeActivites'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'



const ArticleDetail = () => {
    const navigate = useNavigate()

    const params = useParams()
    const params_id = atob(params?.id)

    const dispatch = useDispatch()

    const DataDetailStore = useSelector(state=>state.get_detail_publications)
    const DataListPublication = useSelector(state => state.Last_LIstSotre)

  
    const [DataDetail, setDataDetail] = useState()

    const [DataListPub, setDataListPub] = useState()



    useEffect(() => {
      if (DataListPublication?.DataListLast) {
        setDataListPub(DataListPublication?.DataListLast)
      }
  
      console.log('tchaiiiii : ', DataListPublication?.DataListLast)
    }, [DataListPublication?.DataListLast])
  
    useEffect(()=>{
      
        if (DataDetailStore?.DataDetailPublications?.id) {
            setDataDetail(DataDetailStore?.DataDetailPublications)
        }
      
    }, [DataDetailStore?.DataDetailPublications?.id])
  
    useEffect(()=>{
   
      dispatch(Get_detail_PublicationsActions(params_id))
      dispatch(Get_Last_List_Actions())
    },[params_id])



    const redirect = ()=>{
        
        navigate(`/actualites/publication`)
    }
    const PublicationListe = [
        {
          imgaePub: imgaePub,
          title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
          date: "12 mars 2023",
          id: 1
        },
        {
          imgaePub: imgaePub,
          title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
          date: "12 mars 2023",
          id: 1
        },
        {
          imgaePub: imgaePub,
          title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
          date: "12 mars 2023",
          id: 1
        }
    
    
      ]

      const ContenuHTML = ({ contenu }) => {
        return <div className='text-justify' dangerouslySetInnerHTML={{ __html: contenu }} />;
      }
    
    return (
        <>
            <div>
                <Headers PositionParent="Actualités" PositionEnfant="Détail Article" PositionPetitEnfant="" />



            </div>

            <div className=' flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                {
                    DataDetail && (
                        <div className='col-span-1 lg:col-span-9 px-12 '>
                        <div>
                            <div className='pt-6  text-center'>
                                <div className='flex items-center justify-center'>
                                    <span className='font-bold text-3xl text-teal-500 '>{DataDetail.titre}</span>
    
                                </div>
                                <div className='pt-3'>
                                    <span>Publier le : <b className='text-teal-600'>{formatDateBon(DataDetail.date_pub)}</b></span>
    
                                </div>
                            </div>
                            <div className="flex-1">
                                <div className='relative pt-4 h-full'>
                                    <div className="flex items-center justify-center w-full">
                                        <img src={BackendEntete + DataDetail.image_cover} className='h-full w-full  object-cover object-center' alt="Commission de la concurrence" />
                                    </div>
                                </div>
                                <div className='py-6 mt-4 '>
                                    

                                    <ContenuHTML contenu={DataDetail.contenu} />
                                </div>
                            </div>
    
    
                        </div>
                        <div className='py-6'>
                            <div className='mb-4'>
                                <span className='font-bold text-xl bg-orange-400 p-3 rounded-lg text-white hover:bg-orange-500 cursor-pointer 'onClick={()=>redirect()}>Voir plus d'articles</span>
                            </div>
                            <div>
                            <div>

                                    {
                                        DataListPub && (
                                        <ArticleAccueil DataListe={DataListPub} />
                                        )
                                    }

                                    </div>
                            </div>
    
                        </div>
                        <div>
    
                        </div>
                    </div>
                    )
                }
               
                <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
                    <SousSections />
                </div>
            </div>
            <Reseau_Sociaux />

            <div>
            </div>
            <div>
                <Footers />
            </div>
        </>
    )
}

export default ArticleDetail
