import React from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import OrganigrammeItems from './OrganigrameItems'
import EssaieOrga from './EssaieOrga'
import ImageOrgi from '../../../images/oragnigrame.svg'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const GuideUtilisation = () => {
    return (
        <div>
            <>
                <div>
                <Headers PositionParent="Présentation" PositionEnfant="Guides d'utilisation" PositionPetitEnfant="" />



                </div>

                <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4'>
                <div className='col-span-1 lg:col-span-9 '>
      <div>
        <div className='pt-12'>
        <PagesEnCOnstructions />

        </div>
    </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>

     <Reseau_Sociaux />
            </>
            <div>
    <Footers />
    </div>
        </div>
    )
}

export default GuideUtilisation


