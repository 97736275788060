import { InputText } from "primereact/inputtext";
import Editor from "quill/core/editor";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import TextEditor from "../Parametre/TextEditor";
import { useDispatch, useSelector } from "react-redux";
import { create_contenu_newsletters_actions } from "../../reduxComponents/Actions/ParametrePagesActions";
import LoaderComponent from "../../LoaderComponent";
import { Dialog } from "primereact/dialog";
import sendEMailnewsletters from '../../images/emailsend.gif'

const FormulaireNewsletters = () => {
  const [Sujet, setSujet] = useState(null);
  const [ContenuNews, setContenuNews] = useState(null);
  const dispatch = useDispatch();

  const DataCreateNewslettr = useSelector(
    (state) => state?.create_contenu_newsletters_store
  );
  const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id

  const [loading, setLoading] = useState(false);
  const [newslettersSend, setnewslettersSend] = useState(false);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (DataCreateNewslettr?.DataCOnNewsletters?.id && loading) {
      setLoading(false);

      setnewslettersSend(true);
    }
  }, [DataCreateNewslettr?.DataCOnNewsletters]);

  const submitENvoieMail = (event) => {
    event.preventDefault();
    
    const newErrors = {};
    if (!Sujet) newErrors.Sujet = true;
    if (!ContenuNews) newErrors.ContenuNews = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setLoading(true);
      const body = {
        sujet: Sujet,
        contenu: ContenuNews,
        administrateur : admin_id
      };

      dispatch(create_contenu_newsletters_actions(body));
    }
  };

  const stylesIcon = {
    width : "250px",
    height : '250px'
  }

  return (
    <div>
      <LoaderComponent loading={loading} />
      <div>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
            <div class="p-6 bg-white border-b border-gray-200">
              <div class="mb-4">
                <div className="flex flex-column gap-2">
                  <label htmlFor="username font-bold">
                    <span className="font-bold">Titre de la publications <span className=' text-red-500'>*</span></span>{" "}
                    <span className="font-bold text-red-500">*</span>{" "}
                  </label>
                  <InputText
                    id="username"
                    className={errors.Sujet ? "border-2 border-red-500" : ""}
                    value={Sujet}
                    aria-describedby="username-help"
                    onChange={(e) => setSujet(e.target.value)}
                  />
                </div>
              </div>

              <div class="mb-4">
                <div className="flex flex-column gap-2">
                  <label htmlFor="editored font-bold">
                    <span className="font-bold">
                      Contenu de la publications <span className=' text-red-500'>*</span>
                    </span>
                  </label>
                  {/* <Editor value={ContenuNews} onTextChange={(e) => setContenuNews(e.htmlValue)} style={{ height: '320px' }} /> */}
                  <TextEditor
                    content={ContenuNews}
                    setContent={setContenuNews}
                  />

                  {errors.Sujet && (
                    <div>
                      <label htmlFor="editored" className="text-red-500">
                        <i>Le champs contenu ne peut être vide !</i>
                      </label>
                    </div>
                  )}
                </div>
              </div>

              <div class="mb-4">
                <Button
                  className="w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01"
                  onClick={(event) => submitENvoieMail(event)}
                  type="submit"
                >
                  {" "}
                  <span className="font-bold text-white">
                    Envoyer la newsletters
                  </span>{" "}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        
        visible={newslettersSend}
        style={{ width: "50vw" }}
        onHide={() => {
          if (!newslettersSend) return;
          setnewslettersSend(false);
        }}
      >
        <div className="text-center">
          <div className="text-center">
            {/* <script src="https://cdn.lordicon.com/lordicon.js"></script>
            <lord-icon
              src="https://cdn.lordicon.com/aycieyht.json" 
              trigger="hover"
              style={stylesIcon}
            ></lord-icon> */}
            <img src={sendEMailnewsletters} alt="envoyer la newslett" />
          </div>
          <div className="font-bold text-green-700"> 
                Newsletters envoyé avec succès
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default FormulaireNewsletters;
