
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Delete_activite_Actions, get_liste_activite_Actions, put_archive_activite_actions } from '../../reduxComponents/Actions/Activites_Actions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';
import LoaderComponent from '../../LoaderComponent';

const ActivitesDataLIst = () => {

    const dispatch = useDispatch()
    const DataListe = useSelector(state=>state.get_liste_ActionsStore)
    const DataDelete = useSelector(state=>state.DeleteActivite_Store)
    const DataListeArchiveSTore = useSelector(state=>state.archive_activite_store)

    const [DataListeActivite, setDataListeActivite] = useState()
    const [DataActiviteSelect, setDataActiviteSelect] = useState()
    
    

    useEffect(()=>{
        dispatch(get_liste_activite_Actions())
    },[])

    useEffect(()=>{
        if (DataListeArchiveSTore?.DataArchiveActivite?.succes && loading) {
            dispatch(get_liste_activite_Actions())
            setLoading(false)
            setVisibleDialogue(false)
        }
    },[DataListeArchiveSTore?.DataArchiveActivite])

    useEffect(()=>{
        
        if (DataDelete?.DataDeleteActivite?.success) {
            dispatch(get_liste_activite_Actions())
            setDataActiviteSelect(null)
            setVisibleDialogue(false)
        }

    },[DataDelete?.DataDeleteActivite])

    useEffect(()=>{
        
        if (DataListe?.DataListeActivite) {
            setDataListeActivite(DataListe?.DataListeActivite)
        }
    },[DataListe?.DataListeActivite])

    const navigate = useNavigate()
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                
                titre: `titre ${i}`,
                categorie_activite: `cat${i}`,
                type_activite: `types ${i % 5 + 1}`,
                lieux_activite: `${i % 5 + 1}`,
                date_activite: `12/01/2024 à 13H4${i % 10}`,
                status_activite: `activite ${i % 5 + 1}`,
            });
        }

        return newData;
    };

    const ArchiveActivite = ()=>{
        setLoading(true)
        dispatch(put_archive_activite_actions(Params_id))
    }

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver l'activité" icon="pi pi-check" onClick={() => ArchiveActivite()} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/activites/new-activity')
    }

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher une activité" />
                </span>
            </div>
            <div className="flex justify-content-end">

                <Button label="Créer une activité" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (idactivite)=>{
        const idBtoa = btoa(idactivite)
        navigate(`/admin/activites/voir-activite/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = ()=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/update-publication/${idBtoa}`, {replace : false})
    }

    const [Params_id, setParams_id] =useState(null)
    const [Titre, setTitre] =useState(null)

    const DialogueArchive = (rowData)=>{
        setTitre(rowData?.titre)
        setParams_id(rowData?.id)
        setVisibleDialogue(true)
        
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt"onClick={()=>DialogueArchive(rowData)} text className={`${rowData?.is_archive ? 'text-teal-500' : 'text-red-500'}`}><i class="fi fi-sr-trash"></i></button>
            </div>
        )
    }

    const bodyStatut = (rowData)=>{
        return (
            <>
            {
                rowData?.is_archive === true ? (
                    <div className='font-bold text-red-500'>
                        Archivé
                    </div>
                ) : (

                    <div className='font-bold text-teal-500'>
                        Non archivé
                    </div>

                )
            }
            </>
        )
    }


    const bodyDate = (rowData)=>{
        return(
            <div>
                {formatDateBon(rowData?.date_activite)}
            </div>
        )
    }

    return (
        <div className="card">
            <LoaderComponent loading={loading} />
            <DataTable value={DataListeActivite} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['name', 'username', 'representative.name', 'status']} header={header} emptyMessage="Aucune trouvée.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                <Column header="Titre publications" field='titre' style={{ minWidth: '9rem' }} />
                <Column header="Catégorie" field='categorie_activite' style={{ minWidth: '9rem' }} />
                <Column header="Type activites" field='type_activite' style={{ minWidth: '9rem' }} />
                <Column header="Lieu" field='lieux_activite' style={{ minWidth: '9rem' }} />
                <Column header="Date" field='date_activite' body={bodyDate} style={{ minWidth: '9rem' }} />
                <Column header="Statut" field='is_archive' body={bodyStatut} style={{ minWidth: '9rem' }} />
                
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

          

            <div className="card flex justify-content-center">
           
            {/* <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '30rem' }} onHide={() => setVisibleDialogue(false)}>
                <div className='text-center'>
                    <p className="m-0">
                    Vous souhaité supprimer l'activité <b> <i>{DataActiviteSelect?.titre}</i></b>
                    </p>
                    <p className='font-bold text-red-500'>
                        <i>
                            Cette action est irreversible
                        </i>
                    </p>
                </div>
            </Dialog> */}
        </div>
        <Dialog visible={VisibleDialogue} loading={loading} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                 <p className="m-0">
                    Vous souhaité archivé l'activité  : <b>{Titre} ?</b>
                </p>
            </Dialog>
        </div>
    )
}

export default ActivitesDataLIst



