import { Gestions_Admin_Types } from "../Types/GestionAdmin_types";
import { SET_USER_DATA, CLEAR_USER_DATA , EEROR_SERVER_SURVENIR } from "../Actions/Gestion_Admin_Actions";



const initialAdminPost = {
    isLoading: false,
    DataAdminCreate: [],
    error: "",
};

const initialAdminLogin = {
    isLoading: false,
    DataLoginAccess: [],
    error: "",
};



const initial_liste_admin = {
    isLoading: false,
    datalisteadmin: [],
    error: "",
};

export const get_liste_admins_REDUCERS = (state=initial_liste_admin, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.GET_LISTE_ADMIN_START:
            return {
                ...state,
                isLoading: true,
                datalisteadmin : null,
                error : null,
            };
        case Gestions_Admin_Types.GET_LISTE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                datalisteadmin: action.payload,
                error : null
            };


        case Gestions_Admin_Types.GET_LISTE_ADMIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                datalisteadmin : null
            };
        default:
            return state;
    }
}


export const Post_create_admin = (state=initialAdminPost, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.POST_CREATE_ADMIN_START:
            return {
                ...state,
                isLoading: true,
                DataAdminCreate : null,
                error : null,
            };
        case Gestions_Admin_Types.POST_CREATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataAdminCreate: action.payload,
                error : null
            };


        case Gestions_Admin_Types.POST_CREATE_ADMIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataAdminCreate : null
            };
        default:
            return state;
    }
}


export const Post_login_Admin = (state=initialAdminLogin, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.POST_LOGIN_ADMIN_START:
            return {
                ...state,
                isLoading: true,
                DataLoginAccess : null,
                error : null
            };
        case Gestions_Admin_Types.POST_LOGIN_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataLoginAccess: action.payload,
                error : null
            };


        case Gestions_Admin_Types.POST_LOGIN_ADMIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataLoginAccess : null
            };
        default:
            return state;
    }
}


const initial_update_admin = {
    isLoading: false,
    DataUpdte_Admine: [],
    error: "",
};

export const put_update_admin_reducers = (state=initial_update_admin, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_START:
            return {
                ...state,
                isLoading: true,
                DataUpdte_Admine : null,
                error : null
            };
        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdte_Admine: action.payload,
                error : null
            };


        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdte_Admine : null

            };
        default:
            return state;
    }
}

const initial_desactiver_user = {
    isLoading: false,
    Data_Desactiver_User: [],
    error: "",
};

export const patch_desactiver_user_reducers = (state=initial_desactiver_user, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_START:
            return {
                ...state,
                isLoading: true,
                Data_Desactiver_User : null,
                error : null
            };
        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                Data_Desactiver_User: action.payload,
                error : null
            };


        case Gestions_Admin_Types.PUT_UPDATE_ADMIN_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                Data_Desactiver_User : null

            };
        default:
            return state;
    }
}


const initialState = () => {
    const savedUser = localStorage.getItem('user');
    return savedUser
        ? JSON.parse(savedUser)
        : {
              autreContact: '',
              token: '',
              autreContact: '',
              contact: '',
              image: false,
              matricule: '',
              nom: '',
              prenoms: '',
              sexe : '',
          };
};









const userReducer = (state = initialState(), action) => {
    switch (action.type) {
        case SET_USER_DATA:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_USER_DATA:
            return initialState();
        default:
            return state;
    }
};

export default userReducer;


const initialErrors = {
    isLoading: false,
    DataEroors: null,
    error: "",
};


export const ServerErors = (state = initialErrors, action) => {
    switch (action.type) {
        case EEROR_SERVER_SURVENIR:
            return {
                ...state,
                DataEroors : action.payload,
            };
        
        default:
            return state;
    }
};




const initialGetInfoUserConnecter = {
    isLoading: false,
    DataUserconnetcer: [],
    error: "",
};


export const get_info_user_connecter_reducers = (state=initialGetInfoUserConnecter, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.GET_INFO_USER_CONNECTE_START:
            return {
                ...state,
                isLoading: true,
                DataUserconnetcer : null,
                error : null
            };
        case Gestions_Admin_Types.GET_INFO_USER_CONNECTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUserconnetcer: action.payload,
                error : null
            };


        case Gestions_Admin_Types.GET_INFO_USER_CONNECTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUserconnetcer : null

            };
        default:
            return state;
    }
}


const initial_put_info_user_co = {
    isLoading: false,
    DataUser_Cono: [],
    error: "",
};


export const put_info_user_connecter_reducers = (state=initialGetInfoUserConnecter, action) => {

    switch (action.type) {
        case Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_START:
            return {
                ...state,
                isLoading: true,
                DataUserconnetcer : null,
                error : null
            };
        case Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUserconnetcer: action.payload,
                error : null
            };


        case Gestions_Admin_Types.PUT_UPDATE_USER_CONNECTER_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUserconnetcer : null

            };
        default:
            return state;
    }
}





