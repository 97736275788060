import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'

import { InputText } from 'primereact/inputtext'
import { Editor } from 'primereact/editor'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { get_DetailMot_Du_PresidentAction, post_MotDuPresident_Actions, put_UpdateMotDuPresidenteActions } from '../../reduxComponents/Actions/ParametrePagesActions'
import { BackendEntete } from '../../VariablesConfig'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import TextEditor, { QuillEditor } from '../Parametre/TextEditor'
import LoaderComponent from '../../LoaderComponent'

const Mot_du_president = () => {

    const [errors, setErrors] = useState({});
    const navigate = useNavigate()

    const [contentText, setContentText] = useState('');

    const handleChange = (newContent) => {
        setContentText(newContent);
      };


    const DataDetailStore = useSelector(state => state.get_Detail_MotDuPresident)

    const DataUpdateStore = useSelector(state => state.update_Mot_Du_president)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id


    const dispatch = useDispatch()

    const [DataLienImage, setDataLienImage] = useState(null)

    const [loading, setLoading] = useState(true)
    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)



    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (DataUpdateStore?.DataUpdateMotDuPresident?.id && loading) {
            
            setLoading(false)
            setCreateSucces(true)
        }
        console.log('dfdsfsdfdsfdsfndskjfhdsijfsnjfhds : ', DataUpdateStore)
    }, [DataUpdateStore?.DataUpdateMotDuPresident])

    useEffect(() => {
        if (DataDetailStore?.DataDetailMotPresident?.id) {
            setLoading(false)
            setNomPresident(DataDetailStore?.DataDetailMotPresident?.nom)
            setPrenomPresident(DataDetailStore?.DataDetailMotPresident?.prenom)
            setMotDuPresidentPresident(DataDetailStore?.DataDetailMotPresident?.mot_president)
            setContentText(DataDetailStore?.DataDetailMotPresident?.mot_president)
            setPostePresident(DataDetailStore?.DataDetailMotPresident?.poste)
            setDataLienImage(DataDetailStore?.DataDetailMotPresident?.image_cover)
            const coverImage = BackendEntete + DataDetailStore?.DataDetailMotPresident?.image_cover
            setImageUrl(coverImage)

        }
    }, [DataDetailStore?.DataDetailMotPresident?.id])


    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        dispatch(get_DetailMot_Du_PresidentAction(1))
    }, [])


    const [NomPresident, setNomPresident] = useState('')
    const [PrenomPresident, setPrenomPresident] = useState('')
    const [PostePresident, setPostePresident] = useState('')
    const [MotDuPresidentPresident, setMotDuPresidentPresident] = useState('')


    const SubmitPublications = (event) => {
        event.preventDefault()
        setLoading(true)
        const newErrors = {};


        // Validation des champs vides
        if (!NomPresident) newErrors.NomPresident = true;
        if (!PrenomPresident) newErrors.PrenomPresident = true;
        if (!PostePresident) newErrors.PostePresident = true;
        if (!MotDuPresidentPresident) newErrors.MotDuPresidentPresident = true;
        if (!imageUrl) newErrors.imageUrl = true;


        let body
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {

            const lienImgdeBack = BackendEntete + DataLienImage
            console.log('lienImgdeBacklienImgdeBack : ', lienImgdeBack)
            console.log('imageUrlimageUrl : ', imageUrl)
            const SameImageCover = lienImgdeBack === imageUrl ? true : false


            if (SameImageCover) {
                body = {
                    nom: NomPresident,
                    prenom: PrenomPresident,
                    poste: PostePresident,
                    mot_president: MotDuPresidentPresident,
                    biographie: 'Nan',
                    administrateur : admin_id

                }
            } else {
                body = {
                    nom: NomPresident,
                    prenom: PrenomPresident,
                    poste: PostePresident,
                    mot_president: MotDuPresidentPresident,
                    image_cover: imageUrl,
                    biographie: 'Nan',
                    administrateur : admin_id

                }
            }

           
          
            dispatch(put_UpdateMotDuPresidenteActions(1, body))
        }




    }


    const AllerListeDoc = () => {

        setCreateSucces(false)
        // window.location.reload();



    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );



    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content px-4'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <LoaderComponent loading={loading} />

                    <div className='text-center p-4 my-3 bg-teal-600 font-bold text-white'>
                        <h2>Formulaire de modification "Mot de la presidente"</h2>
                    </div>

                    <div className="photoROw pt-6 " >

                        <label htmlFor="profile-photo" style={{ cursor: 'pointer' }}>
                            <img
                                src={imageUrl} // URL de l'image sélectionnée ou de l'avatar par défaut
                                alt="Profile"
                                className='imageAddUser border-3'
                                style={{
                                    width: '300px',
                                    height: '300px',
                                    borderRadius: '12px',
                                    objectFit: 'cover',
                                    cursor: 'pointer' // Redimensionne l'image pour s'adapter au cercle
                                }}
                            /> <span className=' text-red-500'>*</span>
                        </label>
                        <input
                            id="profile-photo"
                            type="file"
                            accept="image/jpeg, image/jpg, image/png " // Accepte uniquement les fichiers image
                            style={{ display: 'none' }}
                            onChange={handleFileChange} // Gestionnaire d'événement pour le changement de fichier

                        />

                    </div>
                    <div className='pb-6'>
                        <span className='mt-3 text-red-500	font-bold'>
                            Cliquer sur l'image pour la modifier 
                        </span>
                    </div>

                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="Nom">Nom <span className=' text-red-500'>*</span></label>
                            <InputText id="Nom" className={errors.NomPresident ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={NomPresident} onChange={(e) => setNomPresident(e.target.value)} />

                        </div>

                        <div className="flex flex-column gap-2">
                            <label htmlFor="Prenom">Prenom <span className=' text-red-500'>*</span></label>
                            <InputText id="Prenom" className={errors.PrenomPresident ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={PrenomPresident} onChange={(e) => setPrenomPresident(e.target.value)} />

                        </div>




                    </div>
                    <div className='grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1'>
                        <div className="flex flex-column gap-2">
                            <label htmlFor="Nom">Poste <span className=' text-red-500'>*</span></label>
                            <InputText id="Nom" className={errors.PostePresident ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={PostePresident} onChange={(e) => setPostePresident(e.target.value)} />

                        </div>
                    </div>
 
                        {
                            MotDuPresidentPresident && (
                                <div className='grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1'>
                                <div className="flex flex-column gap-2">
                                    <label htmlFor="Nom">Mot du president <span className=' text-red-500'>*</span></label>
                                    {/* <Editor  value={MotDuPresidentPresident} onTextChange={(e) => setMotDuPresidentPresident(e.htmlValue)} style={{ height: '320px' }} /> */}
                                    
                                    <TextEditor className={errors.MotDuPresidentPresident ? 'border-2 border-red-500 ' : ''} content={MotDuPresidentPresident} setContent={setMotDuPresidentPresident} />
                                </div>
                            </div>
                            )
                        }
                            

         

                    <div class="mb-6 pb-12">
                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' onClick={(event) => SubmitPublications(event)} label='Modifier le mot de la Presidente' type='submit' />
                    </div>

                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Publication crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default Mot_du_president
