import React, { useEffect } from "react";
import { useState } from "react";

import logoCNRA from '../../../images/logoCC.svg'
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { DeconnexionBtn } from "../../loginadmins/Deconnexion";
import { useDispatch, useSelector, useStore } from "react-redux";
import { get_compte_is_view_actions } from "../../../reduxComponents/Actions/ParametrePagesActions";
import { Badge } from "primereact/badge";
import { logoutUser } from "../../../reduxComponents/Actions/Gestion_Admin_Actions";
import { Dialog } from "primereact/dialog";



// NavLink component






// Sections List
const SectionsList = ({ items }) => (
    <div className='md:px-2 text-decoration-none'>
        <ul className="pt-3">
            {items?.map((item, idx) => (
                <li key={idx}>
                    <NavLink
                        to={item?.path}
                        active='text-green-900 border-indigo-600'
                        className={item?.isActive ? 'block w-full px-4  border-l border-green-600 text-green-500 duration-150 text-decoration-none font-bold text-success' : 'block w-full py-2 px-4 border-l text-teal-600 text-secondary hover:border-teal-600 hover:text-orange-500 duration-150 hover:font-bold text-decoration-none'}>
                        {item?.name}
                    </NavLink>
                </li>
            ))}
        </ul>
    </div>
);




const NavbarAdmin = () => {

    const dispatch = useDispatch()
    



    const DataCompteViewStore = useSelector(state => state?.get_compte_view_Store)
    const DataERrorServer = useSelector((state)=>state?.EerrorServerStore)

    const [isErrorServer, setIsErrorServer] = useState(false)


    const DataUserRoles = useSelector((state) => state?.user?.roles)
    const DataConnexion = useSelector((state) => state?.user?.access)

    const postData = useSelector(state => state.loginAdmin_acces)

    const [viewUserItems, setviewUserItems] = useState(false)
    const [CountData, setCountData] = useState(null)

    const navigate = useNavigate()

    const [IsPublicationActive, setIsPublicationActive] = useState(false)
    const [IsCommuniqueActive, setIsCommuniqueActive] = useState(false)
    const [IsGaleriePhoto, setIsGaleriePhoto] = useState(false)
    const [IsGalerieVideo, setIsGalerieVideo] = useState(false)
    const [IsadminiActive, setIsadminiActive] = useState(false)
    const [IsDepotdepalinte, setIsDepotdepalinte] = useState(false)

    const [IsFlash, setIsFlash] = useState(false)
    const [IsPartenaire, setPartenaire] = useState(false)
    const [IsSlogan, setIsSlogan] = useState(false)

 
    const handleLogout =  () => {

        dispatch(logoutUser());

        localStorage.removeItem('access_token_cc');
        localStorage.removeItem('refresh_token_cc');
        

        navigate('/admin-login')
      

        
        
        
    };


    useEffect(()=>{
        if (DataERrorServer?.DataEroors?.code) {
          setIsErrorServer(true)
        }
      },[DataERrorServer?.DataEroors])


  

    useEffect(() => {
        

        if (DataUserRoles.includes('admin')) {
            console.log('yes  rolessssssssssssssssssssssssssssssssss', DataUserRoles)
        }

       
    }, [DataUserRoles])



    const [ViewPresentation, setViewPresentation] = useState(false)
    const [ViewParametre, setViewParametre] = useState(false)
    const [ViewFonctionnement, setViewFonctionnement] = useState(false)
    const [ViewActivites, setViewActivites] = useState(false)
    const [viewActualites, setviewActualites] = useState(false)
    const [ViewAvis, setViewAvis] = useState(false)
    const [ViewTextReglementaires, setViewTextReglementaires] = useState(false)



    useEffect(() => {
        console.log('postDatapostDatapostDatapostData : ', postData)
    }, [postData?.DataLoginAccess])


    const [CreateUserActive, setCreateUserActive] = useState(false)
    const [ListeUserActive, setListeUserActive] = useState(false)
    const [ListePersonnelActive, setListePersonnelActive] = useState(false)



    const [isPresentationActive, setisPresentationActive] = useState(false)
    const [IsFonctionnementActive, setIsFonctionnementActive] = useState(false)
    const [IsAvisActive, setIsAvisActive] = useState(false)
    const [IsTextesReglementairesActive, setIsTextesReglementairesActive] = useState(false)
    const [IsActivitesActive, setIsActivitesActive] = useState(false)
    const [IsActualites, setIsActualites] = useState(false)
    const [IsNewsletters, setIsNewsletters] = useState(false)
    const [IsParametre, setIsParametre] = useState(false)
    const [IsContact, setIsContact] = useState(false)

    const [IsCOntactAdmin, setIsCOntactAdmin] = useState(false)









    const [IsNavActive, setIsNavActive] = useState(true)



    useEffect(() => {
        const accesBienDef = localStorage.getItem('access_token_cc')
        if (!accesBienDef) {
            navigate('/admin-login/')
        }
        dispatch(get_compte_is_view_actions())
        const pathname = window.location.pathname;
        const containsUsercontacts = pathname.includes('contacts');
        const containsUserWelcome = pathname.includes('userwelcome');
        const containsNewsletters = pathname.includes('newsletters')
        const containsPresentation = pathname.includes("presentation")
        const containsFonctionnement = pathname.includes('fonctionnement')
        const containsText_reglementaire = pathname.includes('textes-reglementaires')
        const containsAVis = pathname.includes('avis')
        const containsactivites = pathname.includes('activites')
        const containsActualite = pathname.includes('actualites')
        const publications = pathname.includes('publications')
        const Communique = pathname.includes('communique')
        const containsparametre = pathname.includes('parametre')
        const galeriephoto = pathname.includes('galerie-photo')
        const galerievideo = pathname.includes('galerie-videos')
        const admins = pathname.includes('administrateur')
        const depot_plainte = pathname.includes('depot-de-plainte')


        const flash = pathname.includes('flashInfo')
        const partanire = pathname.includes('partenaires')
        const slogan = pathname.includes('slogan')





        if (containsUsercontacts) {
            setIsCOntactAdmin(true)
        }
        if (depot_plainte) {
            setIsDepotdepalinte(true)
        }
        if (containsUserWelcome) {
            setviewUserItems(true)
            setIsNavActive(true)
        } else {
            setviewUserItems(true)
            setIsNavActive(false)
        }

        if (containsparametre) {
            setIsParametre(true)
            setViewParametre(true)
        }


        if (containsNewsletters) {
            setIsNewsletters(true)
        }
        if (flash) {
            setIsFlash(true)
        }
        if (partanire) {
            setPartenaire(true)
        }
        if (slogan) {
            setIsSlogan(true)
        }

        if (admins) {
            setIsadminiActive(true)
        }

        if (containsPresentation) {
            setisPresentationActive(true)
            setViewPresentation(true)
        }

        if (containsFonctionnement) {
            setIsFonctionnementActive(true)
            setViewFonctionnement(true)
        }
        if (containsText_reglementaire) {
            setIsTextesReglementairesActive(true)
            setViewTextReglementaires(true)
        }
        if (containsAVis) {
            setIsAvisActive(true)
            setViewAvis(true)
        }
        if (containsactivites) {
            setIsActivitesActive(true)
            setViewActivites(true)
        }
        if (containsActualite) {
            setIsActualites(true)
            setviewActualites(true)
        }
        if (publications) {
            setIsPublicationActive(true)
        }
        if (galeriephoto) {
            setIsGaleriePhoto(true)
        }

        if (Communique) {
            setIsCommuniqueActive(true)
        }
        if (galerievideo) {
            setIsGalerieVideo(true)
        }

    }, []);


    const DeconnexionBtn = () => {
        // Supprimer les tokens du localStorage

       




    }

    useEffect(() => {
        if (DataCompteViewStore?.DataCompteView) {
            setCountData(DataCompteViewStore?.DataCompteView)
        }

    }, [DataCompteViewStore?.DataCompteView])

    const lessons = {
        SousSectionUser: [{ name: "Personnels", path: "/userwelcome/listepersonnelles", isActive: ListePersonnelActive }, { name: "LIste des utilisateurs", path: "/userwelcome/listeuser", isActive: ListeUserActive }],

        SousPresentation: [
            { name: "Mot du president", path: "/admin/presentation/mot_president", isActive: ListePersonnelActive },
            // { name: "Commission de la concurrence", path: "/admin/presentation/commission_conncurrene", isActive : ListeUserActive },
            // { name: "Les commissaires", path: "/admin/presentation/commissaires", isActive : ListePersonnelActive },
            // { name: "Secrétariat Général", path: "/admin/presentation/secretariat_general", isActive : ListePersonnelActive },        
        ],


        SousFonctionnement: [
            { name: "Pratiques réprimées", path: "/admin/fonctionnement/pratiques_reprimes", isActive: ListePersonnelActive },
            { name: "Qui peut saisir la commision ?", path: "/admin/fonctionnement/qui_peut_saisir_la_commission", isActive: ListeUserActive },
            { name: "Comment saisir la commission ?", path: "/admin/fonctionnement/comment_saisir_la_commission", isActive: ListeUserActive },
            { name: "Saisine ?", path: "/admin/fonctionnement/saisine", isActive: ListeUserActive },






        ],
        SousTextReglementaire: [
            { name: "Traité", path: "/admin/textes-reglementaires/traite", isActive: ListeUserActive },
            { name: "Directives", path: "/admin/textes-reglementaires/directives", isActive: ListeUserActive },
            { name: "Règlements", path: "/admin/textes-reglementaires/reglements", isActive: ListePersonnelActive },
            { name: <div><br /></div>, path: "/admin/textes-reglementaires/reglements", isActive: ListePersonnelActive },
            { name: "Ordonnances", path: "/admin/textes-reglementaires/ordonnaces", isActive: ListeUserActive },
            { name: "Décrets", path: "/admin/textes-reglementaires/decrets", isActive: ListeUserActive },
            { name: "Lois", path: "/admin/textes-reglementaires/lois", isActive: ListeUserActive },
            { name: "Arrêté", path: "/admin/textes-reglementaires/arretes", isActive: ListeUserActive },
        ],
        SousAvis: [
            { name: "Avis consultatifs", path: "/admin/avis/avis_consultatifs", isActive: ListePersonnelActive },
            { name: "Avis contentieux", path: "/admin/avis/avis_contentieux", isActive: ListeUserActive }

        ],
        SousActivites: [
            { name: "Activités", path: "/admin/activites", isActive: ListePersonnelActive },
            // { name: "Formations", path: "/admin/presentation/formations", isActive : ListeUserActive },
            // { name: " Campagne de sensibilisation", path: "/admin/presentation/campagne-de-sensibilisation", isActive : ListeUserActive },
            // { name: "Journée portes ouvertes", path: "/admin/presentation/journe-portes-ouvertes", isActive : ListeUserActive },
        ],




        SousActualites: [
            { name: "Publications", path: "/admin/actualites/publications", isActive: IsPublicationActive },
            { name: "Communiqué de presse", path: "/admin/actualites/communique", isActive: IsCommuniqueActive },
            { name: "Galerie vidéo", path: "/admin/actualites/galerie-videos", isActive: IsGalerieVideo },
            { name: "Galerie photos", path: "/admin/actualites/galerie-photo", isActive: IsGaleriePhoto },
            // { name: "Archives des articles", path: "/admin/actualites/archives-des-articles", isActive: ListePersonnelActive },

        ],

        Sousparametre: [
            { name: "Utilisateurs", path: "/admin/parametre/administrateur", isActive: IsadminiActive },
            { name: "Flash Info", path: "/admin/parametre/flashInfo", isActive: IsFlash },
            { name: "Partenaires", path: "/admin/parametre/partenaires", isActive: IsPartenaire },
           
        ],


    }

    return (
        <>
            <nav
                className="no-underline z-40 top-0 left-0 w-full h-full border-r bg-white space-y-8 overflow-auto sm:w-80 hauteurTotal largeurNavLateral">
                <div className="sticky top-0 space-y-8 bg-white">
                    <div className='h-40 flex items-center justify-center px-4 border-b md:px-8'>
                        <a href='javascript:void(0)' className='flex-none pt-8'>
                            <img src={logoCNRA} width={200} className="mx-auto" />
                        </a>

                    </div>

                </div>
                <div className='text-[0.9rem] space-y-8 px-4'>
                    <>
                        {

                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Dashboard')) && (
                                <div >
                                    <NavLink to="/admin/dashboard">
                                        {({ isActive }) => (
                                            <div className={isActive ? "activeNavLInk no-underline flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between" : " no-underline flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"}>
                                                <div className="flex items-center ">
                                                    <div>
                                                        <i class="fi fi-sr-home"></i>
                                                    </div>
                                                    <div className="ml-5 ">
                                                        Dashboard
                                                    </div>
                                                </div>
                                                <div><i className="fi fi-rr-angle-small-down"></i></div>


                                            </div>
                                        )}
                                    </NavLink>

                                </div>
                            )
                        }


                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Gestion des plainte')) && (
                                <div>



                                    <NavLink to="/admin/depot-de-plainte">

                                        <div className={IsDepotdepalinte ? "activeNavLInk flex text-gray-700 font-bold hover:text-teal-200 items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"}>
                                            <div className="flex items-center font-bold hover:text-gray-400 ">
                                                <div>
                                                    <i class="fi fi-sr-envelope"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                Dépôt de plainte
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    CountData?.plaintes_non_vues ? (
                                                        <Badge value={CountData?.plaintes_non_vues} severity="danger"></Badge>
                                                    ) : (
                                                        <i className="fi fi-rr-angle-small-down"></i>
                                                    )
                                                }


                                            </div>


                                        </div>

                                    </NavLink>


                                </div>
                            )
                        }


                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Presentation')) && (
                                <div>

                                    <NavLink onClick={() => setViewPresentation(!ViewPresentation)}>


                                    <div className={isPresentationActive ? "activeNavLInk flex  font-bold hover:text-teal-200 items-center justify-between font-bold hover:text-gray-400" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3 font-bold hover:text-gray-400"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-chart-user"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                    Présentations
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        ViewPresentation && (
                                            <>
                                                <SectionsList items={lessons.SousPresentation} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>
                            )
                        }




                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Textes reglementaires')) && (

                                <div>

                                    <NavLink onClick={() => setViewTextReglementaires(!ViewTextReglementaires)}>


                                        <div className={IsTextesReglementairesActive ? "activeNavLInk flex  font-bold hover:text-teal-100 items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-memo-circle-check"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                Textes réglementaires
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        ViewTextReglementaires && (
                                            <>
                                                <SectionsList items={lessons.SousTextReglementaire} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>


                            )
                        }


                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Avis')) && (
                                <div>

                                    <NavLink onClick={() => setViewAvis(!ViewAvis)}>


                                        <div className={IsAvisActive ? "activeNavLInk flex text-gray-700 font-bold hover:text-teal-200 items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-features-alt"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                    Avis
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        ViewAvis && (
                                            <>
                                                <SectionsList items={lessons.SousAvis} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Activites')) && (
                                <div>

                                    <NavLink onClick={() => setViewActivites(!ViewActivites)}>


                                        <div className={IsActivitesActive ? "activeNavLInk text-white font-bold hover:text-teal-200 flex items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-calendar-star"></i>
                                                </div>
                                                <div className="ml-5  ">
                                                    Activités
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        ViewActivites && (
                                            <>
                                                <SectionsList items={lessons.SousActivites} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>
                            )
                        }


                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Actualites')) && (
                                <div>

                                    <NavLink onClick={() => setviewActualites(!viewActualites)}>


                                        <div className={IsActualites ? "activeNavLInk flex items-center justify-betweenb text-gray-700 font-bold hover:text-teal-200" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-newspaper"></i>
                                                </div>
                                                <div className="ml-5  ">
                                                    Actualités
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        viewActualites && (
                                            <>
                                                <SectionsList items={lessons.SousActualites} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>
                            )
                        }

                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('newsletters')) && (
                                <div>



                                    <NavLink to="/admin/newsletters">

                                        <div className={IsNewsletters ? "activeNavLInk flex text-gray-700 font-bold hover:text-teal-200 items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"}>
                                            <div className="flex items-center font-bold hover:text-gray-400 ">
                                                <div>
                                                    <i class="fi fi-sr-envelope"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                    Newsletters
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>


                                </div>
                            )
                        }

                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Contact')) && (
                                <div>



                                    <NavLink to="/admin/contacts">

                                        <div className={IsCOntactAdmin ? "activeNavLInk flex text-gray-700 font-bold hover:text-teal-200 items-center justify-between" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"}>
                                            <div className="flex items-center font-bold hover:text-gray-400 ">
                                                <div>
                                                    <i class="fi fi-sr-phone-call"></i>
                                                </div>
                                                <div className="ml-5 ">
                                                    Contacts
                                                </div>
                                            </div>
                                            <div>
                                                {
                                                    CountData?.contacts_non_vus ? (
                                                        <Badge value={CountData?.contacts_non_vus} severity="danger"></Badge>
                                                    ) : (
                                                        <i className="fi fi-rr-angle-small-down"></i>
                                                    )
                                                }</div>


                                        </div>

                                    </NavLink>


                                </div>
                            )
                        }


                        {
                            (DataUserRoles.includes('admin') || DataUserRoles.includes('Configurations')) && (
                                <div>

                                    <NavLink onClick={() => setViewParametre(!ViewParametre)}>


                                        <div className={IsParametre ? "activeNavLInk flex items-center justify-betweenb text-gray-700 font-bold hover:text-teal-200" : "flex text-gray-700 font-bold hover:text-gray-400 items-center justify-between px-3"} >
                                            <div className="flex items-center ">
                                                <div>
                                                    <i class="fi fi-sr-settings"></i>
                                                </div>
                                                <div className="ml-5  ">
                                                    Configurations
                                                </div>
                                            </div>
                                            <div><i className="fi fi-rr-angle-small-down"></i></div>


                                        </div>

                                    </NavLink>
                                    {
                                        ViewParametre && (
                                            <>
                                                <SectionsList items={lessons.Sousparametre} isActive={CreateUserActive} />

                                            </>
                                        )
                                    }

                                </div>
                            )
                        }


































                        <div>

                            <NavLink onClick={()=>handleLogout()}>


                                <div className="flex bg-red-600 py-3 rounded-lg text-red-100 font-bold hover:text-white hover:bg-red-700 items-center justify-between px-3" >
                                    <div className="flex items-center ">
                                        <div>
                                            <i class="fi fi-sr-sign-out-alt"></i>
                                        </div>
                                        <div className="ml-5  ">
                                            Deconnexion
                                        </div>
                                    </div>
                                    <div><i className="fi fi-rr-angle-small-down"></i></div>


                                </div>

                            </NavLink>


                        </div>


                        <div>



                            {/*
                            <NavLink to="/zones">
                                {({ isActive}) => (
                                    <div className={isActive ? "activeNavLInk flex items-center justify-between" : "flex items-center justify-between px-3"}>
                                        <div className="flex items-center ">
                                            <div>
                                            <i className="fi fi-rr-land-layer-location tailleIcons"></i>
                                            </div>
                                            <div className="ml-5">
                                            Zones
                                            </div>
                                        </div>
                                        <div><i className="fi fi-rr-angle-small-down"></i></div>


                                    </div>
                                )}
                            </NavLink> */}

                        </div>
                    </>
                </div>
            </nav>

            <Dialog visible={isErrorServer} modal style={{ width: '25rem' }} onHide={() => {
                    
                    window.location.reload();
                }}>

                    <div className="bg-white rounded-md  px-4 py-6">
                        <div className=" flex items-center text-red-500 text-3Xl  justify-center mx-auto rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <g clip-path="url(#clip0_705_24107)">
                                    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#D72828" />
                                    <path d="M29 50H35V44H29V50ZM29 12V38H35V12H29Z" fill="#E6E6E6" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_705_24107">
                                        <rect width="64" height="64" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className="text-lg font-medium text-red-600 font-bold text-center mt-3">
                            {" "}
                            oups une erreur s'est produite au niveau du <b>serveur</b>. 
                            <br />
                            <i >
                                <b>
                                Veuillez réessayer plus tard.
                                </b>
                                </i>
                        </div>


                    </div>


                </Dialog>
        </>
    );
};



export default NavbarAdmin