import { Gestion_GALERIEPHOTO_Types } from "../Types/GaleriePhoto_Types";



const intitialPOstCreateGaleriePhoto = {
    isLoading: false,
    DataCreateGaleriePhoto: [],
    error: "",
};


const intitiallisteGaleriePhoto = {
    isLoading: false,
    DataListeGaleriePhoto: [],
    error: "",
};

const initialDeatilGaleriePhoto = {
    isLoading: false,
    DataDetailGaleriePhoto: [],
    error: "",
};

const initialUpdateGaleriePhoto = {
    isLoading: false,
    DataUpdateSucces: [],
    error: "",
};

export const PosteCreateGaleriePhotoReducers = (state=intitialPOstCreateGaleriePhoto, action) => {

    switch (action.type) {
        case Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_START:
            return {
                ...state,
                isLoading: true,
                DataCreateGaleriePhoto : null,
                error : null
            };
        case Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateGaleriePhoto: action.payload,
                error : null
            };


        case Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateGaleriePhoto : null
            };
        default:
            return state;
    }
}

export const Get_liste_GaleriePhotoReducers = (state=intitiallisteGaleriePhoto, action) => {

    switch (action.type) {
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_START:
            return {
                ...state,
                isLoading: true,
                DataListeGaleriePhoto :null,
                error : null
                
            };
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : null,
                DataListeGaleriePhoto: action.payload,
            };


        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListeGaleriePhoto : null,
            };
        default:
            return state;
    }
}


export const Get_Detail_GaleriePhotoReducers = (state=initialDeatilGaleriePhoto, action) => {

    switch (action.type) {
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DataDetailGaleriePhoto : null,
                error:null
            };
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDetailGaleriePhoto: action.payload,
                error:null
            };


        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailGaleriePhoto : null,
            };
        default:
            return state;
    }
}

export const Put_Update_GaleriePhotoReducers = (state=initialUpdateGaleriePhoto, action) => {

    switch (action.type) {
        case Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_START:
            return {
                ...state,
                isLoading: true,
                DataUpdateSucces : null,
                error: null,
            };
        case Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdateSucces: action.payload,
                error: null,
            };


        case Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdateSucces : null
            };
        default:
            return state;
    }
}


const initialtroisphoto = {
    isLoading: false,
    DataTroisPhotos: [],
    error: "",
};


export const get_liste_trois_dernier_gal_reducers = (state=initialtroisphoto, action) => {

    switch (action.type) {
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_START:
            return {
                ...state,
                isLoading: true,
                DataTroisPhotos : null,
                error: null,
            };
        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataTroisPhotos: action.payload,
                error: null,
            };


        case Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataTroisPhotos : null
            };
        default:
            return state;
    }
}

