import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get_plainte_detailAction } from '../../reduxComponents/Actions/Gestion_Plaintes_Actions'
import LoaderComponent from '../../LoaderComponent'
import ViewPdfPLainte from './ViewPdfPLainte'
import { PDFViewer } from '@react-pdf/renderer'
import { Button } from 'primereact/button'
import { BackendEntete } from '../../VariablesConfig'
import axios from 'axios';
import { configHeadersToken } from '../../reduxComponents/Actions/Gestion_Admin_Actions'



const DetailPlainte = () => {


    const dispatch = useDispatch()
    const params = useParams()
    const params_id = atob(params?.id)
    const [Loading, setLoading] = useState(false)
    const [Datadetail, setDatadetail] = useState(null)
    const [isLoading, setisLoading] = useState(false)
    const [isErreur, setisErreur] = useState(false)

    const DataDetailPlainteStore = useSelector(state => state?.get_one_Plainte)

    useEffect(() => {

        if (DataDetailPlainteStore?.DataOne_View && Loading) {
            setDatadetail(DataDetailPlainteStore?.DataOne_View)
            setLoading(false)
        }

    }, [DataDetailPlainteStore?.DataOne_View])

    useEffect(() => {
        setLoading(true)
        dispatch(get_plainte_detailAction(params_id))
    }, [params_id])



    const DownloadDossier = async () => {
        setisLoading(true);
        try {
            const response = await axios.get(
                `${BackendEntete}app_commission/plainte/documents-telecharge-dossier/${params_id}/`,
                {
                  ...configHeadersToken(),
                  responseType: 'blob', 

                }
              );
      
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
      
            const contentDisposition = response.headers['content-disposition'];
            let fileName = `Dossier_ ${Datadetail?.nom_prenom}_${Datadetail?.reference_plainte}.zip`;
            if (contentDisposition) {
              const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
              if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
            }
      
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setisLoading(false)
            setisErreur(false)
          } catch (error) {
            setisErreur(true)
            setisLoading(false)
            console.error('Erreur lors du téléchargement du fichier', error);
          }
      };

    return (
        <div>

            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <LoaderComponent loading={Loading} />
                    {/* <div>
                        <div className='pt-6 pl-4'>
                            <h1 className='text-slate-500	text-4xl font-bold'> <span className='bg-green-500 p-3 text-white rounded-full mr-3'><i class="fi fi-ss-user-gear"></i></span>Informations de la plainte </h1>
                        </div>
                    </div> */}


                        <div className='py-4'>
                        {Datadetail ?    (
                       <>
                       <div>
                       
                       </div>
                       <div className='w-full font-bold px-4'>
                            <Button label="Télécharger le(s) document(s) associé(s)" icon="pi pi-download" className='w-full font-bold bg-teal-600 hover:bg-teal-700' onClick={()=>DownloadDossier()} loading={isLoading} />
                            {
                                isErreur&&(
                                   <div className='text-red-500'>
                                     <span>
                                        Téléchargement echouée
                                    </span>
                                   </div>
                                )
                            }
                        </div>
                         <div className='p-4 '>
                            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                                <ViewPdfPLainte datainfo={Datadetail} className='w-full' />
                            </PDFViewer>
                            
                        </div> 

                        
                       </>
                    ) : (
                        <div>
                            <LoaderComponent loading={true} />
                        </div>
                    )
                
                }

                        </div>
                   





                </div>

            </div>
        </div>
    )
}

export default DetailPlainte
