export const Gestion_GALERIEPHOTO_Types = {
    CREATE_GALPHOTO_START : "CREATE_GALPHOTO_START",
    CREATE_GALPHOTO_SUCCESS : "CREATE_GALPHOTO_SUCCESS",
    CREATE_GALPHOTO_FAILED : "CREATE_GALPHOTO_FAILED",

    PUT_UPDATE_GALPHOTOS_START : " PUT_UPDATE_GALPHOTOS_START",
    PUT_UPDATE_GALPHOTOS_SUCCESS : "PUT_UPDATE_GALPHOTOS_SUCCESS",
    PUT_UPDATE_GALPHOTOS_FAILED : "PUT_UPDATE_GALPHOTOS_FAILED",


    GET_GALPHOTOS_DETAILS_START : " GET_GALPHOTOS_DETAILS_START",
    GET_GALPHOTOS_DETAILS_SUCCESS : "GET_GALPHOTOS_DETAILS_SUCCESS",
    GET_GALPHOTOS_DETAILS_FAILED : "GET_GALPHOTOS_DETAILS_FAILED",

    GET_GALPHOTOS_LISTE_START : " GET_GALPHOTOS_LISTE_START",
    GET_GALPHOTOS_LISTE_SUCCESS : "GET_GALPHOTOS_LISTE_SUCCESS",
    GET_GALPHOTOS_LISTE_FAILED : "GET_GALPHOTOS_LISTE_FAILED",

    GET_GALPHOTOS_LISTE_TROIS_START : " GET_GALPHOTOS_LISTE_TROIS_START",
    GET_GALPHOTOS_LISTE_TROIS_SUCCESS : "GET_GALPHOTOS_LISTE_TROIS_SUCCESS",
    GET_GALPHOTOS_LISTE_TROIS_FAILED : "GET_GALPHOTOS_LISTE_TROIS_FAILED",

    
}