import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import ArticleAccueil from '../SousSections.jsx/ArticleAccueil'
import imgaePub from '../../../images/imagecover.png'


import ImagePub01 from '../../../images/ImagesCommission/Publication  0F7A0855.jpeg'
import ImagePub02 from '../../../images/ImagesCommission/Publication  0F7A0913.jpeg'
import ImagePub03 from '../../../images/ImagesCommission/Publication 0F7A0822.jpeg'
import { useDispatch, useSelector } from 'react-redux'
import { get_Pub_listeEssaie } from '../../../reduxComponents/Actions/EssayeActions'
import { Get_Last_List_Actions } from '../../../reduxComponents/Actions/PublicationsActions'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

 

const Publications = () => {
  const dispatch = useDispatch()


  const DataListeStore = useSelector(state => state.get_publications)


  const [DataListe, setDataListe] = useState()
 


  useEffect(() => {

    if (DataListeStore?.DataInfoReducers) {
      const activeAndNotArchivedItems = DataListeStore?.DataInfoReducers.filter(item => item.is_active && !item.is_archive);

      setDataListe(activeAndNotArchivedItems)
    }
  }, [DataListeStore?.DataInfoReducers])

  useEffect(() => {
    
    dispatch(get_Pub_listeEssaie())
  }, [])


  return (
    <>
      <div>
        <Headers PositionParent="Actualités" PositionEnfant="Publications" PositionPetitEnfant="" />


      </div>

      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 '>
          <div className='py-8'>
            <span className='font-bold text-3xl text-teal-600'>Publications</span>
          </div>

          <div className='pt-4'>
            <ArticleAccueil DataListe={DataListe} />
          </div>
          <div>

          </div>
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>
      <Reseau_Sociaux />

      <div>
      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default Publications
