import React, { useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import DataListePublications from './DataListePublications'
import ArticlesActivites from './Articles'
import { Button } from 'primereact/button'
import { useNavigate, useParams } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { Get_detail_PublicationsActions } from '../../reduxComponents/Actions/PublicationsActions'

const Voir_Publications = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const params = useParams()
    const params_id = atob(params?.id)

   
    const DataDetailPublication = useSelector(state=>state.get_detail_publications)

    const [DataDetailPublications, setDataDetailPublications] = useState()


    const redirectModif = ()=>{
        const id = btoa(params_id)
        navigate(`/admin/actualites/update-publication/${id}`)
    }

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    useEffect(()=>{
        dispatch(Get_detail_PublicationsActions(params_id))
    },[])

    useEffect(()=>{
        setDataDetailPublications(DataDetailPublication?.DataDetailPublications)

    }, [DataDetailPublication?.DataDetailPublications?.id])

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );


  return (
    <div>
      <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div className='grid gap-3 mb-8 md:grid-cols-2 xl:grid-cols-4 p-4 md:24 border-2 mx-4'>
                        <div>
                            
                        </div>
                        <div>
                            
                            </div>
    
                        <div>
                            <Button className='w-full bg-gray-500 border-none hover:bg-gray-600 btnBacground03' onClick={()=>redirectModif()} label='Modifier la publication' />
                        </div>
                        <div>
                            <Button className='bg-orange-500 border-none w-full hover:bg-orange-700 btnBacground01' onClick={()=>setVisibleDialogue(true)} label='Archiver la publication' />
                        </div>
                    </div>
                    {
                        DataDetailPublications && (
                            <div className='px-4 md:px-24  pb-12'>
                                <ArticlesActivites DataDetailPublications={DataDetailPublications}  />
                            </div>
                        )
                    }
                    





                </div>

            </div>

            <div className="card flex justify-content-center">
           
           <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
               <p className="m-0">
                   Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                   consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
               </p>
           </Dialog>
       </div>
    </div>
  )
}

export default Voir_Publications


