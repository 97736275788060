import React, { useEffect, useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import { useDispatch, useSelector } from 'react-redux'
import ArticleAccueil from '../SousSections.jsx/ArticleAccueil'
import { get_Pub_listeEssaie } from '../../../reduxComponents/Actions/EssayeActions'
import { get_CommuniquePresse_liste } from '../../../reduxComponents/Actions/Gestion_CommuniquePActions'
import ArticleCommunique from '../SousSections.jsx/ArticleCOmmunique'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const CommuniquePress = () => {
  const dispatch = useDispatch()

  const DataListeStore = useSelector(state=>state.get_Liste_CommuniquePresse)

  const [DataListe, setDataListe] = useState()

  useEffect(()=>{
    const activeAndNotArchivedItems = DataListeStore?.DataListeCPReducers.filter(item => !item.is_archive);
    setDataListe(activeAndNotArchivedItems)
  }, [DataListeStore?.DataListeCPReducers])

  useEffect(()=>{
    dispatch(get_CommuniquePresse_liste())
  },[])
  return (
    <>
    <div>
    <Headers PositionParent="Actualités" PositionEnfant="Communiqué de presse" PositionPetitEnfant="" />



    </div>

    <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
              
                <div className='pt-4'>
              <ArticleCommunique DataListe={DataListe} />
            </div>
      <div>
    </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>
     <Reseau_Sociaux />

    <div>
    </div>
    <div>
    <Footers />
    </div>
</>
  )
}

export default CommuniquePress
