import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import './navbar.css';
import { Divider } from 'primereact/divider';

const NavbarApp = () => {
    const [activeItem, setActiveItem] = useState('');
    const location = useLocation();
    const [showDropdown, setShowDropdown] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    const navigate = useNavigate()

    const handleSetActiveItem = (urls) => {


    };

    

    useEffect(() => {
        const pathname = location.pathname;
        setActiveItem(pathname);
    }, [location.pathname]);

    const presentationActive = activeItem.includes('presentation');
    const fonctionnementActive = activeItem.includes('fonctionnement');
    const noussaisiractive = activeItem.includes('nous-saisir')
    const textregleActive = activeItem.includes('textes-reglementaires');
    const avisActive = activeItem.includes('avis');
    const activitesActive = activeItem.includes('activites');
    const actualitesActive = activeItem.includes('actualites');
    const contactActive = activeItem.includes('contact');
    const accueilActive = activeItem === '/';

    const redirectionFunction = (urls) => {
        navigate(urls);
        setShowDropdown(false); // Close dropdown after navigation
    };
    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen); // Inverser l'état du menu
    };

    const [OptionsCommunautaire, setOptionsCommunautaire] = useState('')
    const [IsCEDEOView, setIsCEDEOView] = useState(true)
    const [IsUemoaView, setIsUemoaView] = useState(false)





    return (
        <nav className="navbar menu">
            <div className="menu-toggle" onClick={handleMenuToggle}>
                <button>
                    {
                        !isMenuOpen ? (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64" fill="none">
                            <g clip-path="url(#clip0_386_24126)">
                                <path d="M60.3828 55.5503H3.61719C1.62284 55.5503 0 53.9275 0 51.9331V46.6892C0 44.6946 1.62284 43.0718 3.61719 43.0718H60.3828C62.3772 43.0718 64 44.6946 64 46.6892V51.9331C64 53.9275 62.3772 55.5503 60.3828 55.5503Z" fill="#007F6D" />
                                <path d="M60.3828 38.2393H3.61719C1.62284 38.2393 0 36.6164 0 34.6221V29.378C0 27.3836 1.62284 25.7608 3.61719 25.7608H60.3828C62.3772 25.7608 64 27.3836 64 29.378V34.6221C64 36.6164 62.3772 38.2393 60.3828 38.2393Z" fill="#007F6D" />
                                <path d="M60.3828 20.9277H3.61719C1.62284 20.9277 0 19.3051 0 17.3103V12.0664C0 10.0721 1.62284 8.44924 3.61719 8.44924H60.3828C62.3772 8.44924 64 10.0721 64 12.0664V17.3103C64 19.3051 62.3772 20.9277 60.3828 20.9277Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_386_24126">
                                    <rect width="64" height="64" fill="white" transform="matrix(1 0 0 -1 0 64)" />
                                </clipPath>
                            </defs>
                        </svg>) : (<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 76 76" fill="none">
                            <g clip-path="url(#clip0_387_24152)">
                                <path d="M37.9999 0C17.0137 0 0.000488281 17.0134 0.000488281 37.9996C0.000488281 58.985 17.0134 76 37.9993 76C58.9853 76 76 58.985 76 37.9996C76 17.0134 58.9855 0 37.9999 0ZM52.3343 52.4828C50.8171 53.9987 48.3593 53.9987 46.8434 52.4828L38.1967 43.8361L29.1563 52.8765C27.6399 54.3924 25.1818 54.3924 23.6661 52.8765C22.1489 51.3588 22.1489 48.9015 23.6661 47.3843L32.7057 38.3447L24.2891 29.9285C22.7732 28.4108 22.7732 25.9535 24.2891 24.4368C25.8055 22.9204 28.2641 22.9204 29.7808 24.4368L38.1969 32.853L46.2202 24.8292C47.7366 23.3133 50.196 23.3128 51.7111 24.8292C53.2283 26.3469 53.2283 28.8057 51.7111 30.3209L43.6886 38.3441L52.3348 46.9908C53.8508 48.5065 53.8515 50.9659 52.3343 52.4828Z" fill="#E69534" />
                            </g>
                            <defs>
                                <clipPath id="clip0_387_24152">
                                    <rect width="76" height="76" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>)
                    }


                </button>
            </div>
            <ul className={`nav-list ${isMenuOpen ? 'open' : ''}`}>
                <li className={`nav-item ${accueilActive ? 'activeitem' : 'navbartitle'}`} onClick={() => redirectionFunction('/')}>
                    Accueil
                </li>
                <li className="nav-item" >
                    <div className={` ${(presentationActive) ? 'activeitem' : 'navbartitle'}`}>Présentation</div>
                    <ul className={`subnav-list ${presentationActive ? 'activeitem' : ''}`}>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/mot_du_president')}>Mot du président</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/historique')}>Historique</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/missions')}>Missions et Objectifs</li>

                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/fonctionnement')}>Fonctionnement</li>
                        {/* <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/secretariatgeneral')}>Secrétariat Général</li>  */}
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/organigrame')}>Organigramme</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/manuel-procedure')}>Manuel de procédure</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/presentation/guide-utilisation')}>Guides d'utilisation</li>
                    </ul>
                </li>
                <li className="nav-item " >
                    <div className={`${noussaisiractive ? 'activeitem' : 'navbartitle'} PasAlaligne`}> <span translate='no'>Nous Saisir</span> </div>
                    <ul className={`subnav-list ${noussaisiractive ? 'activeitem' : ''}`}>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/nous-saisir/pratiques-reprimees')}>Pratiques réprimées</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/nous-saisir/qui-peut-saisir-lacommission')}>Qui peut saisir la commission ?</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/nous-saisir/comment-saisir-la-commission')} >Comment saisir la commission ?</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/nous-saisir/faire-une-saisine')}>Formulaire de depot de plainte</li>
                    </ul>
                </li>


                <li className="nav-item" >
                    <div className={` ${(textregleActive) ? 'activeitem' : 'navbartitle'} PasAlaligne`}> Textes réglementaires</div>
                    <ul className={`subnav-list ${textregleActive ? 'activeitem' : ''}`}>

                        <li className='itemsnavbartitlte font-bold' >TEXTES COMMUNAUTAIRES</li>


                        <li className='itemsnavbarSOustitlte font-bold pl-2 hover:bg-teal-500 hover:text-white hover:py-3' onClick={() => setOptionsCommunautaire('CEDEAO')} >
                            <div className='flex justify-between'>
                                <div>
                                    CEDEAO
                                </div>
                                <div>
                                    {
                                        OptionsCommunautaire === 'CEDEAO' ? (
                                            <i class="fi fi-sr-angle-double-small-up"></i>

                                        ) : (
                                            <i class="fi fi-sr-angle-double-small-down"></i>
                                        )
                                    }


                                </div>
                            </div>
                        </li>
                        {
                            OptionsCommunautaire === 'CEDEAO' && (
                                <>
                                    <li className='itemsnavbar pl-4' onClick={() => redirectionFunction('/textes-reglementaires/cedeao/traite')}>Traité</li>
                                    <li className='itemsnavbar pl-4' onClick={() => redirectionFunction('/textes-reglementaires/cedeao/directives')}>Directives</li>
                                    <li className='itemsnavbar pl-4' onClick={() => redirectionFunction('/textes-reglementaires/cedeao/reglements')}>Règlements</li>
                                </>
                            )
                        }


                        <li className='itemsnavbarSOustitlte font-bold  pl-2 hover:bg-teal-500 hover:text-white hover:py-3  ' onClick={() => setOptionsCommunautaire('UEMOA')} >
                            <div className='flex justify-between'>
                                <div >
                                    UEMOA
                                </div>
                                <div>
                                    {
                                        OptionsCommunautaire === 'UEMOA' ? (
                                            <i class="fi fi-sr-angle-double-small-up"></i>

                                        ) : (
                                            <i class="fi fi-sr-angle-double-small-down"></i>
                                        )
                                    }


                                </div>
                            </div>


                        </li>

                        {
                            OptionsCommunautaire === 'UEMOA' && (
                                <>
                                    <li className='itemsnavbar pl-4 ' onClick={() => redirectionFunction('/textes-reglementaires/uemoa/traite')}>Traité</li>
                                    <li className='itemsnavbar pl-4' onClick={() => redirectionFunction('/textes-reglementaires/uemoa/directives')}>Directives</li>
                                    <li className='itemsnavbar pl-4' onClick={() => redirectionFunction('/textes-reglementaires/uemoa/reglements')}>Règlements</li>
                                </>
                            )

                        }

                        <Divider />



                        <li className='itemsnavbartitlte font-bold py-1 ' >TEXTES NATIONAUX</li>
                        <li className='itemsnavbar ' onClick={() => redirectionFunction('/textes-reglementaires/ordonnaces')}>Ordonnances</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/lois')}>Lois</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/decrets')} >Décrets</li>

                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/arretes')}>Arrêtés</li>
                    </ul>
                </li>




                {/* <li className="nav-item" >
                    <div className={` ${(textregleActive) ? 'activeitem' : 'navbartitle'} PasAlaligne`}> Textes réglementaires</div>
                    <ul className={`subnav-list ${textregleActive ? 'activeitem' : ''}`}>

                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/traite-uemoa')}>Traité de l'UEMOA</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/directives')}>Directives</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/reglements')}>Règlements</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/ordonnaces')}>Ordonnances</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/lois')}>Lois</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/decrets')} >Décrets</li>
                       
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/textes-reglementaires/arretes')}>Arrêtés</li>
                    </ul>
                </li> */}
                <li className="nav-item " >
                    <div className={` ${(avisActive) ? 'activeitem' : 'navbartitle'}`}> Avis</div>
                    <ul className={`subnav-list ${avisActive ? 'activeitem' : ''}`}>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/avis/consultatifs')} >Avis consultatifs</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/avis/contentieux')}>Avis contentieux</li>
                    </ul>
                </li>
                <li className="nav-item " >
                    <div className={` ${(activitesActive) ? 'activeitem' : 'navbartitle'}`}> Activités</div>
                    <ul className={`subnav-list ${activitesActive ? 'activeitem' : ''}`}>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/activites/ateliers')}  >Ateliers</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/activites/formations')}>Formations</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/activites/campagnesensibilisation')}>Campagne de sensibilisation</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/activites/journeeporteouverte')}>Journée portes ouvertes</li>
                    </ul> 
                </li>
                <li className="nav-item " >
                    <div className={` ${(actualitesActive) ? 'activeitem' : 'navbartitle'}`}> Actualités</div>
                    <ul className={`subnav-list ${actualitesActive ? 'activeitem' : ''}`}>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/actualites/publication')} >Publications</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/actualites/communiquepresse')}>Communiqué de presse</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/actualites/galerievideo')}>Galerie vidéo</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/actualites/galeriephoto')}>Galerie photos</li>
                        <li className='itemsnavbar' onClick={() => redirectionFunction('/actualites/archivearticles')}>Archives des articles</li>
                    </ul>
                </li>
                <li className={`nav-item ${contactActive ? 'activeitem' : 'navbartitle'}`} onClick={() => redirectionFunction('/contact')}>
                    Contacts
                </li>
            </ul>
        </nav>
    );
};

export default NavbarApp;
