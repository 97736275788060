import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin';
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Password } from 'primereact/password';
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { InputMask } from 'primereact/inputmask';
import { useDispatch, useSelector } from 'react-redux';
import { get_info_user_connecter_actions, put_info_user_connecter_actions } from '../../../reduxComponents/Actions/Gestion_Admin_Actions';

const ProfilUser = () => {
    const DataUser = useSelector((state) =>state?.user);
    const params_id = useSelector((state) =>state?.user?.user_id)
    const DataInfoUserCOsTORE = useSelector(state => state?.get_info_user_connecter_store)
    const DataUpdateUser = useSelector(state=>state?.put_info_user_connecter_store)
    const dispatch = useDispatch()
    const [errors, setErrors] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const [Nom, setNom] = useState(null)
    const [Prenom, setPrenom] = useState(null)
    const [Username, setUsername] = useState(null)
    const [Email, setEmail] = useState(null)
    const [Contact, setContact] = useState(null)
    const [FixContact, setFixContact] = useState('')
    const [loading, setLoading] = useState(false);
    const [AdminCreateSuccess, setAdminCreateSuccess] = useState(false)
    const [SectionTerminer, setSectionTerminer] = useState(false)

    


    const [PasswordAdmin, setPasswordAdmin] = useState(null);
    const [ConfirmePassword, setConfirmePassword] = useState(null);


    useEffect(()=>{
dispatch(get_info_user_connecter_actions(params_id))
    },[params_id])

    useEffect(()=>{
        if (DataInfoUserCOsTORE?.DataUserconnetcer?.nom) {
            setNom(DataInfoUserCOsTORE?.DataUserconnetcer?.nom)
            setPrenom(DataInfoUserCOsTORE?.DataUserconnetcer?.prenom)
            setEmail(DataInfoUserCOsTORE?.DataUserconnetcer?.email)
            setUsername(DataInfoUserCOsTORE?.DataUserconnetcer?.username)
            setContact(DataInfoUserCOsTORE?.DataUserconnetcer?.contact)
            setPasswordAdmin(null)
            setConfirmePassword(null)
        }
    },[DataInfoUserCOsTORE?.DataUserconnetcer])

    useEffect(()=>{
        if (DataUpdateUser?.DataUserconnetcer?.nom && loading) {
            dispatch(get_info_user_connecter_actions(params_id))
            setLoading(false)
            setAdminCreateSuccess(true)
        }
    },[DataUpdateUser?.DataUserconnetcer])
   


   
    const SubmitPublications = (event) => {
        event.preventDefault();
        
        const newErrors = {};

        


        // Validation des champs vides
        if (!Nom) newErrors.Nom = true;
        if (!Prenom) newErrors.Prenom = true;
        if (!Username) newErrors.Username = true;
        if (!Email) newErrors.Email = true;
        if (!Contact) newErrors.Contact = true;
        
        

        if (PasswordAdmin !== ConfirmePassword) {
            newErrors.PasswordAdmin = true;
            newErrors.ConfirmePassword = true;
        }


        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            console.log('error')

        } else {
            if (PasswordAdmin) {
                setLoading(true);
           
            const data_admin = {
                
                    nom: Nom,
                    prenom: Prenom,
                    username: Username,
                    password: PasswordAdmin,
                    email: Email,
                    contact: Contact,
                    image_personne: 'non_image',
                    compte_is_actif: DataUser?.compte_is_actif
               
                
            }

           
            console.log('vaec password : ', data_admin)

            dispatch(put_info_user_connecter_actions(DataUser?.user_id,data_admin ))
            }else{
                setLoading(true);
           
            const data_admin = {
              
                    nom: Nom,
                    prenom: Prenom,
                    username: Username,
                    
                    email: Email,
                    contact: Contact,
                    image_personne: 'non_image',
                    compte_is_actif: DataUser?.compte_is_actif
               
                
            }

            console.log("sans password : ", data_admin)

            dispatch(put_info_user_connecter_actions(DataUser?.user_id,data_admin ))
            }
            
            
           
        }

        





    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
           
            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() =>{
                setAdminCreateSuccess(false)

            }} autoFocus />
        </div>
    );



  
  return (
    <div>
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <ProgressSpinner
                        style={{ width: '50px', height: '50px' }}
                        strokeWidth="8"
                        fill="var(--surface-ground)"
                        animationDuration=".5s"
                    />
                </div>
            )}
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>

                    </div>
                    <div>

                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div class="p-6 bg-white border-b border-gray-200">
                                    {/* <div className="photoROw py-6 " >

                                        <label htmlFor="profile-photo" style={{ cursor: 'pointer' }}>
                                            <img
                                                src={imageUrl || avatarlook} // URL de l'image sélectionnée ou de l'avatar par défaut
                                                alt="Profile"
                                                className='imageAddUser border-3'
                                                style={{
                                                    width: '150px',
                                                    height: '150px',
                                                    borderRadius: '50%', // Style de cercle
                                                    objectFit: 'cover',
                                                    cursor: 'pointer' // Redimensionne l'image pour s'adapter au cercle
                                                }}
                                            />
                                        </label>
                                        <input
                                            id="profile-photo"
                                            type="file"
                                            accept="image/*" // Accepte uniquement les fichiers image
                                            style={{ display: 'none' }}
                                            onChange={handleFileChange} // Gestionnaire d'événement pour le changement de fichier
                                            
                                        />
                                        <span className='mt-3 text-red-500	'>
                                            Photo de l'administrateur
                                        </span>
                                    </div> */}
                                    <div className='text-center p-4 my-3 bg-teal-600 font-bold text-white'>
                                        <h2>Informations de votre profil</h2>
                                    </div>

                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Nom">Nom <span className=' text-red-500'>*</span></label>
                                            <InputText id="Nom" className={errors.Nom ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Nom} onChange={(e) => setNom(e.target.value)} />

                                        </div>

                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Prenom">Prenom <span className=' text-red-500'>*</span></label>
                                            <InputText id="Prenom" className={errors.Prenom ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Prenom} onChange={(e) => setPrenom(e.target.value)} />

                                        </div>


                                    </div>
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Username">Username <span className=' text-red-500'>*</span></label>
                                            <InputText disabled id="Username" className={errors.Username ? 'border-2 border-red-500' : ''} aria-describedby="Username-help" value={Username}  />

                                        </div>

                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Email">Email <span className=' text-red-500'>*</span></label>
                                            <InputText disabled id="Email" className={errors.Email ? 'border-2 border-red-500' : ''} aria-describedby="username-help" keyfilter="email" value={Email}  />

                                        </div>


                                    </div>
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Contact">Contact <span className=' text-red-500'>*</span></label>
                                            <InputMask id="Contact" aria-describedby="username-help" className={errors.Contact ? 'border-2 border-red-500' : ''} value={Contact} onChange={(e) => setContact(e.target.value)} mask="(999) 9999 999 999" placeholder="(225) XXXX XXX XXX "></InputMask>

                                        </div>
                                        {/* <div className="flex flex-column gap-2">
                                            <label htmlFor="FixContact">Fix</label>
                                            <InputMask  id="FixContact" aria-describedby="username-help" value={FixContact} onChange={(e) => setFixContact(e.target.value)} mask="(999) 9999 999 999" placeholder="(225) XXXX XXX XXX "></InputMask>


                                        </div> */}


                                      

                                    </div>
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column w-full gap-2">
                                            <label htmlFor="PasswordAdmin">Nouveau mot de passe </label>
                                            <div className='w-full'>
                                                <Password id="PasswordAdmin" className={errors.PasswordAdmin ? 'border-2 border-red-500 w-full' : 'w-full'} aria-describedby="username-help" toggleMask feedback={false} value={PasswordAdmin} onChange={(e) => setPasswordAdmin(e.target.value)} />

                                            </div>
                                        </div>

                                        <div className="flex flex-column w-full  gap-2">
                                            <label htmlFor="ConfirmePassword">Confirmer mot de passe </label>
                                            <div className='w-full'>
                                                <Password id="ConfirmePassword" className={errors.ConfirmePassword ? 'border-2 border-red-500 w-full' : 'w-full'} aria-describedby="username-help" toggleMask feedback={false} value={ConfirmePassword} onChange={(e) => setConfirmePassword(e.target.value)} />
                                            </div>

                                        </div>



                                    </div>
                                    

                                    <div class="mb-4">
                                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01 font-bold' onClick={(event) => SubmitPublications(event)}  type='submit' >Modifier mon profile</Button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>





                </div>

            </div>
            <div className="card flex justify-content-center">
                <Dialog  visible={AdminCreateSuccess} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => setAdminCreateSuccess(false)}>
                    <p className="m-0 text-teal-500 text-center">
                        MOdification effectué avec succès
                    </p>
                </Dialog>

                {/* <Dialog  visible={SectionTerminer} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                    <p className="m-0 text-red-500 text-center">
                    votre temps de connexion est epuisé
                    </p>
                </Dialog> */}
        </div>
        </div>
  )
}

export default ProfilUser
