import React, { useRef, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import { Button } from 'primereact/button'
import { TabPanel, TabView } from 'primereact/tabview'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'
import { Divider } from 'primereact/divider'
import imagePresidente from '../../../images/photocommision/photocommision/presidente.jpg'
import vicepresident from '../../../images/photocommision/photocommision/vice-presidente.jpg'
import commissaire1 from '../../../images/photocommision/photocommision/commissaire1.jpg'
import commissaire2 from '../../../images/photocommision/photocommision/commissiare2.jpg'
import commissaire3 from '../../../images/photocommision/photocommision/commissaire3.jpg'
import secretairegeneral from '../../../images/photocommision/photocommision/secretairegeneral.jpg'
import rapporteur1 from '../../../images/photocommision/photocommision/rapporteur1.jpg'
import rapporteur2 from '../../../images/photocommision/photocommision/rapporteur2.jpg'
import rapporteur3 from '../../../images/photocommision/photocommision/rapporteur3.jpg'
import rapporteur4 from '../../../images/photocommision/photocommision/rapporteur4.jpg'
import rapporteur5 from '../../../images/photocommision/photocommision/rapporteur5.jpg'
import daf from '../../../images/photocommision/photocommision/daf.jpg'










const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState('0px');
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.scrollHeight; // Utilisez scrollHeight pour obtenir la hauteur totale
    setState(!state);
    setAnswerH(state ? '0px' : `${answerElH}px`); // Changez la hauteur en fonction de l'état
  };

  return (
    <>
      <div
        className="space-y-4  mt-4 overflow-hidden border-b"
        key={idx}
        onClick={handleOpenAnswer}
      >
        <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-700 font-medium">
          {faqsList.q}
          {state ? (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 12H4" />
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          )}
        </h4>
        <div
          ref={answerElRef}
          className="duration-300"
          style={{ height: answerH }}
        >
          <div>
            <p className="text-gray-500">
              {faqsList.a}
            </p>
          </div>
          <div>
            {faqsList?.equipe}
          </div>
        </div>
      </div>
    </>
  );
};

const Fonctionnement = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const PhotoPresident = [
    {
        avatar: imagePresidente,
        name: "ABANET ESSO Blanche",
        title: "Présidente de la commission de la concurrence",
        
    }, 

    {
      avatar: vicepresident,
      name: "N'GUESSAN Kouadio Gaspard Jean-Michel",
      title: "Vice-Président de la commission de la concurrence",
      
  },
  ]


  

  const PhotoSecretaire = [
    {
        avatar: secretairegeneral,
        name: "N'KON Minamou",
        title: "Sécrétaire Général",
    },]

    const PhotoCommissaire = [
      {
          avatar: commissaire2,
          name: "Kouadio Koffi Jean-Baptiste",
          title: "Commissaire",
      },
      {
        avatar: commissaire1,
        name: "Sandrine Groga",
        title: "Commissaire",
    },
    {
      avatar: commissaire3,
      name: "Lona Ouali",
      title: "Commissaire",
  },
    ]


    const PhotoDirecteurFinance = [
      {
          avatar: daf,
          name: "AKESSE Charles Athanse Kablan",
          title: "Directeur des Affaires Financières",
      },

    ]

    const PhotoRapporteur = [
      {
          avatar: rapporteur1,
          name: "Gogoua Atto Guy Roland",
          title: "Rapporteur",
      },
      {
        avatar: rapporteur2,
        name: "KOUAME Kouassi",
        title: "Rapporteur",
    },
    {
      avatar: rapporteur3,
      name: "TOURÉ Offiana Maurice",
      title: "Rapporteur",
  },
 
{
  avatar: rapporteur5,
  name: "Assi Modeste",
  title: "Rapporteur",
},
{
  avatar: rapporteur4,
  name: "KOUASSI N'Guessan Barthélémy",
  title: "Rapporteur",
},

    ]



   

  const faqsList = [
    {
      q: "Le Président",
      a: <div>
        <div>
          <p><b> Le Président de la Commission de la Concurrence(CC)</b> est élu par ses paires commissaires, ceux-ci nommés par  décret pris en Conseil des Ministres, sur présentation du Ministre chargé du commerce pour un <b>mandat de cinq  (05) ans</b>,  renouvelable une seule fois.</p>
          <p>Le Président de la CC, dans l'exercice de ses fonctions, disposes entre autres, des attributions suivantes ;</p>
        </div>
        
        <div className='mt-4'>

          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> L’administration et le contrôle des services de la CC ;</span>
          </div>
          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> La présidence des séances de la Commission ; </span>
          </div>
          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> La représentation de la commission, tant auprès de l'administration que des tiers ;</span>
          </div>
          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> La représentation de la CC en justice.</span>
          </div>


        </div>
       
       
        <div>
          <p>Il exerce toute autre mission à lui confiée par la Commission de la Concurrence.</p>
          <p>Il est assisté par un Vice-président élu dans les mêmes conditions que le Président qui le suplée en cas d'absence ou d'empechement.</p>
        </div>

        <Divider />

        <div className='flex justify-center my-12 '>
        <ul className="grid gap-8 lg:grid-cols-2">
                        {
                            PhotoPresident.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-gray-900 font-extrabold">{item.name}</h4>
                                        <p className="text-teal-600">{item.title}</p>
                                       
                                        
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>


       
      </div>,
     
      
    },
    {
      q: "Les Commissaires",
      a: <div>
        <div>
          <p>
            <b>La Commission de la Concurrence</b>  se compose de dix    (10) membres,
            nommés par décret pris en Conseil des Ministres sur proposition du ministre en charge du commerce. 
            {/* pour un mandat d’une durée de cinq (5) ans  renouvelable une fois. A l’exception du Président et du vice-Président,
            les membres de la commission  n’exercent pas de fonction à titre permanent au sein de la commission. */} Ils exercent leurs fonctions à titre non permanent au sein de la Commission de la Concurrence.
          </p>
          <p>
          Les sessions de la Commission sont convoquées et présidées par le Président, qui en fixe l'ordre du jour.          </p>

          <p>Le Collège des membres doit obligatoirement avoir en son sein :
          </p>
        </div>

        <div className='mt-4'>

          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un Magistrat ;</span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un avocat ; </span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un professionnel du secteur privé ;</span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un professionnel du droit ;</span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un professionnel spécialiste d’économie ;
            </span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Un représentant des organisations des consommateurs.

            </span>
          </div>


        </div>

        <div className='py-2'>
          <p>
            Les autres membres de la Commission sont choisis par le Ministre chargé du Commerce parmi les Hauts fonctionnaires spécialistes en économie et droit de la concurrence, des membres du secteur privé ou de la société civile, sur une liste présentée par leurs organisations patronales, professionnelles ou syndicales.

          </p>
        </div>
        <Divider />
        <div className="my-12">
                    <ul className="grid gap-8 lg:grid-cols-2">
                        {
                            PhotoCommissaire.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-gray-900 font-extrabold">{item.name}</h4>
                                        <p className="text-teal-600">{item.title}</p>
                                       
                                        
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

      </div>
    },

  ]




  const faqsListAdministration = [
    {
      q: "Le Secrétariat Général",
      a: <div className='mb-12'>
        <div>
          <p>
            Pour l'accomplissement de sa mission, la Commission de la Concurrence  dispose d'un Secrétariat Général placé sous l'autorité de son Président et dirigé par un Secrétaire Général.

          </p>
          <p>Le Secrétaire Général est nommé par décret pris en conseil des Ministres, sur proposition du ministre chargé du commerce.
          </p>
          <p><b>Le Secrétaire Général est chargé :</b></p>

        </div>
        <div className='mt-4'>

          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span>D’assurer l’administration et la coordination de l'ensemble des activités de la commission de la concurrence ; </span>
          </div>
          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> De préparer les réunions de la CC, d'en assurer le secrétariat et la tenue des procès-verbaux ; </span>
          </div>
          <div className=' p-4 my-2   bg-orange-50 text-gray-800 font-bold hover:bg-teal-600 hover:text-white border-2 border-orange-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span>De veiller à la mise en œuvre et au suivi des délibérations de La CC ;</span>
          </div>



        </div>
        <div>
          <p>Il est assisté d’un Secrétaire Général adjoint, de cinq Rapporteurs, cinq assistants Rapporteurs et de deux chargés d’Etudes.</p>
          <p> <b>Les Rapporteurs :</b> ils sont chargés de procéder aux études, aux enquêtes complémentaires et à l’instruction des dossiers pour éclairer la commission de la Concurrence et préparer ces avis et décisions.</p>

          <p>Le Secrétariat Général également est assisté de services suivants :
          </p>
        </div>
        <div className='mt-4'>

          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Direction des Affaires Financières ;</span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Le Bureau de la procédure et de la règlementation ;</span>
          </div>
          <div className=' p-4 my-2   bg-teal-50 text-gray-800 font-bold hover:bg-orange-400 hover:text-white border-2 border-teal-300 hover:border-none '>
            <span> <span className='mr-3'>{'> '} </span> Le Service de la Communication, de l’information et de la Documentation.</span>
          </div>
          


        </div>

        <Divider />
        <div className="row my-12">
                    <ul className="grid gap-8 lg:grid-cols-2">
                        {
                            PhotoSecretaire.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-gray-900 font-extrabold">{item.name}</h4>
                                        <p className="text-teal-600">{item.title}</p>
                                       
                                        
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
        </div>

      </div>
    },

    {
      q: "Directeur des Affaires Financières",
      a: <div className=''>
      
        <div className="mb-12">
                    <ul className="grid gap-8 lg:grid-cols-2 ">
                        {
                            PhotoDirecteurFinance.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-gray-900 font-extrabold">{item.name}</h4>
                                        <p className="text-teal-600">{item.title}</p>
                                       
                                        
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
<br />
      </div>
    },

    {
      q: "Rapporteurs",
      a: <div>
        
        
       
        

       
        <div className="my-12">
                    <ul className="grid gap-8 lg:grid-cols-2">
                        {
                            PhotoRapporteur.map((item, idx) => (
                                <li key={idx} className="gap-8 sm:flex">
                                    <div className="w-full h-60">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4 sm:mt-0">
                                        <h4 className="text-gray-900 font-extrabold">{item.name}</h4>
                                        <p className="text-teal-600">{item.title}</p>
                                       
                                        
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>

      </div>
    },



  ]


 


  return (
    <div>
      <>
        <div>
          <Headers PositionParent="Présentation" PositionEnfant="Fonctionnement" PositionPetitEnfant="" />

        </div>

        <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
          <div className='col-span-1 lg:col-span-9 '>
            <div>
              <div className='pt-6'>
                <div>
                  <h4 className="text-xl text-gray-800  font-extrabold md:text-3xl">
                    <span className='bg-teal-600 text-white font-bold p-1'>Fonctionnement</span>
                  </h4>
                </div>
                <div className='py-3'>
                  <p>
                    Conformément au Décret N° 2017-411 du 21 juin 2017 modifié par le Décret N°2023-611 du 15 juin 2023 le fonctionnement de la Commission de la Concurrence se présente comme suit :
                  </p>

                </div>
              </div>
            </div>
            <div className="card ">
              <div className="flex mb-2 gap-2 justify-content-end">
                <Button onClick={() => setActiveIndex(0)} className="w-2rem h-2rem p-0" rounded outlined={activeIndex !== 0} label="1" />
                <Button onClick={() => setActiveIndex(1)} className="w-2rem h-2rem p-0" rounded outlined={activeIndex !== 1} label="2" />
              </div>
              <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                <TabPanel header="LA COMMISSION">
                  <section className="leading-relaxed max-w-screen-xl mt-12  px-4 md:px-8">

                    <div className="mt-14 ">
                      {
                        faqsList.map((item, idx) => (
                          <FaqsCard
                            idx={idx}
                            faqsList={item}
                            
                          />
                        ))
                      }
                    </div>
               
                  </section>
                </TabPanel>
                <TabPanel header="L’ADMINISTRATION">
                  <section className="leading-relaxed max-w-screen-xl mt-12  px-4 md:px-8">

                    <div className="mt-14 ">
                      {
                        faqsListAdministration.map((item, idx) => (
                          <FaqsCard
                            idx={idx}
                            faqsList={item}
                          />
                        ))
                      }
                    </div>
                  </section>
                </TabPanel>

              </TabView>


             
      
          
          


          
 
 

            </div>
          
          </div>
          <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
            <SousSections />
          </div>
        </div>



        <Reseau_Sociaux />
        <div>
          <Footers />
        </div>
      </>

      
    </div>
  )
}

export default Fonctionnement
