import React, { useEffect, useState } from 'react'
import logoPartenaire from '../../../../images/logoCC.svg'
import logo02 from '../../../../images/logo02.svg'
import logo03 from '../../../../images/logo03.svg'

import '../slidelogopartners.css'
import { useDispatch, useSelector } from 'react-redux'
import { get_liste_Partenaires_Actions } from '../../../../reduxComponents/Actions/ParametrePagesActions'
import { BackendEntete } from '../../../../VariablesConfig'




const Partenanaire = () => {

    const dispatch = useDispatch()
    const DataListePartenaire = useSelector(state=>state.List_partenaires_Store)
    const [DataList, setDataList]=useState([])


    useEffect(()=>{
        dispatch(get_liste_Partenaires_Actions())
    },[])

    useEffect(()=>{
        if (DataListePartenaire?.DataListPartenaire) {
            setDataList(DataListePartenaire?.DataListPartenaire)
        }

    },[DataListePartenaire?.DataListPartenaire])


    return (
        <>
            <div className='slider sectionAnime'>
                
                <div className='w-full conteneur-textes'>
                    <div className='texte flex gap-10'>
                        {
                                                DataList.map((image, idex) => (
                                                
                                                        <div key={idex} className='w-full'>
                                                            <a href={image.site_internet} target="_blank" rel="noopener noreferrer">
                                                            <img src={BackendEntete + image.lien_logo} alt={image.NomENtite} className='h-24' />
                                                            </a>
                                                            
                                                        </div>
                                                        
                                                
                                                ))
                                            }
                    </div>

                   
                    
                </div>
            </div>
        </>
    )
}

export default Partenanaire
