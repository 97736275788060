import React, { useEffect, useState } from 'react'
import imageCC from '../../images/ImagesCommission/Commission de la Concurrence.jpg'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password';
import { useDispatch, useSelector } from 'react-redux';
import { Post_Login_Admin, post_Create_Admin } from '../../reduxComponents/Actions/Gestion_Admin_Actions';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../LoaderComponent';
import { Dialog } from 'primereact/dialog';


const LoginAdmin = () => {

  const [PasswordAdmin, setPasswordAdmin]=useState()
  const [Username, setUsername]=useState()
  const [loading, setLoading]=useState(false)

  const [isErrorServer, setIsErrorServer] = useState(false)
  
  const navigate = useNavigate()

  const postData = useSelector(state=>state.loginAdmin_acces)
  const DataERrorServer = useSelector((state)=>state?.EerrorServerStore)
  
  const [errors, setErrors] = useState({});

  useEffect(()=>{
    if (postData?.DataLoginAccess?.access) {
      console.log(postData?.DataLoginAccess)
      navigate('/admin/dashboard')
    }
  },[postData])

  useEffect(()=>{
    const access_token = localStorage.getItem("access_token_cc")
    if (access_token) {
      navigate('/admin/dashboard')
    } else {
    }
    
  }, [])

  useEffect(()=>{
    if (DataERrorServer?.DataEroors?.code && loading) {
      setIsErrorServer(true)
      setLoading(false)
    }
  },[DataERrorServer?.DataEroors])

  const dispatch = useDispatch()

  const OnSubmitConnexion = (event)=>{
    
    event.preventDefault();
    setIsErrorServer(false)
    setLoading(true)
    const newErrors = {};

    

    if (!PasswordAdmin) newErrors.Nom = true;
    if (!Username) newErrors.Prenom = true;

  if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
  } else {

      const body = {
        username : Username,
        password : PasswordAdmin
      }

      dispatch(Post_Login_Admin(body))

     
  }


  }

  return (
    <>
    <section className="flex justify-center items-center h-screen bg-gray-100">
      <LoaderComponent loading={loading} />
    <div className="max-w-md w-full bg-white rounded p-6 space-y-4">
        <div className="mb-4">
            <p className="text-gray-600">Connexion</p>
            <h2 className="text-xl font-bold">Page d'administration</h2>
        </div>
        {
          isErrorServer && (
            <div className='p-4 bg-red-100 text-red-500 font-bold'>
            <label htmlFor="">Nom d'utilisateur ou mot de passe incorrect. Veuillez réessayer.</label>
          </div>
          )
        }
       
        <div>
            <input  type="text" placeholder="Email" />
            <InputText  
            className={`w-full  text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600 ${errors.Username ? 'border-2 border-red-500' : ''}`}
            value={Username} onChange={(e) => setUsername(e.target.value)} />

        
        </div>
        <div>
            <Password 
            className={`w-full  text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-gray-600 ${errors.PasswordAdmin ? 'border-2 border-red-500' : ''}`}
            value={PasswordAdmin} onChange={(e) => setPasswordAdmin(e.target.value)} feedback={false} toggleMask />

        </div>
        <div>
            <button onClick={(event)=>OnSubmitConnexion(event)} className="w-full py-4 bg-teal-600 hover:bg-teal-700 rounded text-sm font-bold text-gray-50 transition duration-200">Se connecter</button>
        </div>

        {/* <Dialog visible={isErrorServer} modal style={{ width: '25rem' }} onHide={() => {
                    setLoading(false)
                    setIsErrorServer(false)
                }}>

                    <div className="bg-white rounded-md  px-4 py-6">
                        <div className=" flex items-center text-red-500 text-3Xl  justify-center mx-auto rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                <g clip-path="url(#clip0_705_24107)">
                                    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#D72828" />
                                    <path d="M29 50H35V44H29V50ZM29 12V38H35V12H29Z" fill="#E6E6E6" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_705_24107">
                                        <rect width="64" height="64" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>

                        <div className="text-lg font-medium text-red-600 font-bold text-center mt-3">
                            {" "}
                            oups une erreur s'est produite. veuillez réessayer plus tard ou contacter de l'agence en charge de la maintenance du site
                        </div>


                    </div>


                </Dialog> */}

       
    </div>
</section>
    </>
  )
}

export default LoginAdmin