import { ProgressSpinner } from 'primereact/progressspinner'
import React from 'react'

const LoaderComponent = ({loading}) => {
  return (
    <div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overlay">
          <ProgressSpinner
            style={{ width: '50px', height: '50px' }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      )}
    </div>
  )
}

export default LoaderComponent
