import React, { useEffect, useState } from 'react'
import './baniere.css'
import { useDispatch, useSelector } from 'react-redux';
import { get_List_Flash_Visiteurs_Actions } from '../../reduxComponents/Actions/Activites_Actions';

const BaniereDef = () => {
     
  const dispatch = useDispatch()
  const DataListFlashStore = useSelector(state=>state.list_Visiteur_Flash_Store)
  const [DataListFlash, setDataListFlash]= useState()

  useEffect(()=>{
    dispatch(get_List_Flash_Visiteurs_Actions())
  }, [])

  useEffect(()=>{

    if (DataListFlashStore?.DataListFlashVisiteur) {
      setDataListFlash(DataListFlashStore?.DataListFlashVisiteur)
    }

  },[DataListFlashStore?.DataListFlashVisiteur])


 


  const [news, setNews] = useState([
    {
      id: 1,
      text: "**Flash Info :** L'équipe nationale de football a remporté la victoire contre l'équipe adverse.",
    },
    {
      id: 2,
      text: "**Alerte météo :** Des pluies torrentielles sont prévues dans la région cet après-midi.",
    },
    {
      id: 3,
      text: "**Événement culturel :** Le festival international de musique aura lieu la semaine prochaine.",
    },
  ]);


  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % DataListFlash.length);
    }, 25000);

    return () => clearInterval(interval);
  }, [DataListFlash]);

  return (
   <>
   
    <div className="flash-info flex items-center w-full">
      <div className='backdefi w-full'>
        {
          DataListFlash && (
             <div className="w-full conteneur-textes">
              <div className='flex texte gap-10'>
            {DataListFlash.map((article, index) => (
                            <div key={article.id} className={`  ${index === currentIndex ? 'active' : ''}`}>
                              <span> <span className='font-bold'>**{article?.cat_flash  + '**  : '} </span>{article?.libelle} </span>
                            </div>
                          ))}
              </div>
              
            </div>
          )
        }
     
      </div>
      
      <div className="flash-info-label flex items-center">
        <span className='blink_me'>Flash Info</span>
      </div>
    </div>
   </>
  )
}

export default BaniereDef
