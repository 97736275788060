import React from 'react'
import image404 from '../images/pageconstruction.svg'

const PagesEnCOnstructions = ({title}) => {
  return (
    <div>
       <main>
            <div className="max-w-screen-xlo px-4 flex items-center justify-start  md:px-8">
                <div className="max-w-lg mx-auto text-center">
                    <div className="pb-6">
                        <img src={image404} width={400} className="mx-auto" />
                    </div>
                    <h3 className="text-green-800 text-4xl font-semibold sm:text-5xl">
                        Page en construction <span className='text-orange-800'>{title}</span>
                    </h3>
                    <p className="text-gray-600 mt-3">
                        désolé !, cette page est encore en construction
                    </p>
                </div>
            </div>
        </main>
    </div>
  )
}

export default PagesEnCOnstructions
