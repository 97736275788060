import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import LoaderComponent from '../../LoaderComponent'
import { Get_detail_DocAction, put_Update_DocumentActions } from '../../reduxComponents/Actions/Gestion_des_DocsActions'
import { Button } from 'primereact/button'
import { SelectButton } from 'primereact/selectbutton'
import { Calendar } from 'primereact/calendar'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { addLocale, locale } from 'primereact/api'
import { pdfjs } from 'react-pdf';
import { BackendEntete } from '../../VariablesConfig'
import PdfCOmpo from './PdfCOmpo'
import { Dialog } from 'primereact/dialog'


const UpdateDocument = () => {
    const params = useParams()
    const params_id = atob(params?.id)
    const navigate = useNavigate()


    const dispatch = useDispatch()
    const DataDetailStore = useSelector(state => state.get_doc_detail)
    const [DataUpdateDocSucess, setDataUpdateDocSucess] = useState()

    const DataUpdateDocStore = useSelector(state => state.put_UpdateDOc)

    const [DataDetailDocument, setDataDetailDocument] = useState(null)
    const [loading, setLoading] = useState(true);
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id



    pdfjs.GlobalWorkerOptions.workerSrc = new URL(
        'pdfjs-dist/build/pdf.worker.min.js',
        import.meta.url,
    ).toString();


   


    const types_documentsNationauxOption = [
        'Ordonnances',
        'Décrets',
        'Lois',
        
        'Arrêtés',
    ];

    const types_documentsCommunautaireOption = [
        "Traités",
        "Directives",
        'Regléments',
    ];

    const types_documentsAvisOption = [
        "Consultatifs",
        "Contentieux",
    ];


    const cat_options = [
        'TEXTES COMMUNAUTAIRES',
        'TEXTES NATIONAUX',
        'AVIS'

    ]


    const sous_cat_optionsCommunautaire = [
        'CEDEAO',
        'UEMOA'
    ]


    const [errors, setErrors] = useState({});



    const [selectedFile, setSelectedFile] = useState(null);
    const [previewURL, setPreviewURL] = useState('');


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);


        // Prévisualisation du fichier PDF sélectionné
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreviewURL(reader.result);
        };
        reader.readAsDataURL(file);
    };


    const options = ['Oui', 'Non'];
    const [titre, setTire] = useState('')
    const [type_document, setType_document] = useState('')
    const [date_adoption, setDate_adoption] = useState('')
    const [documents, setDocuments] = useState('')
    const [is_activeDocument, setIs_activeDocument] = useState(options[0])
    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)
    const [cat_documents, setCat_document] = useState('')
    const [sous_cat_document, setSous_Cat_document] = useState('')




    useEffect(() => {
        dispatch(Get_detail_DocAction(params_id))
    }, [params_id])

    useEffect(() => {

        if (DataDetailStore?.DatDetailDoc) {
            setDataDetailDocument(DataDetailStore?.DatDetailDoc)
            setLoading(false)
        }

    }, [DataDetailStore?.DatDetailDoc])

    useEffect(() => {
        if (DataDetailDocument && DataDetailDocument[0]?.type_document) {
            console.log("DataDetailDocument[0]", DataDetailDocument[0])
            setType_document(DataDetailDocument[0]?.type_document)
            const DateAdoption = new Date(DataDetailDocument[0]?.date_adoption)
            setDate_adoption(DateAdoption)
            setDocuments(DataDetailDocument[0]?.documents)
            setSous_Cat_document(DataDetailDocument[0]?.sous_cat_document)
            setCat_document(DataDetailDocument[0]?.cats_document)
            if (DataDetailDocument[0]?.is_active === true) {
                setIs_activeDocument("Oui")
            } else {
                setIs_activeDocument("Non")
            }

            setTire(DataDetailDocument[0]?.titre)
            const document = BackendEntete + DataDetailDocument[0]?.documents
            setSelectedFile(document)

        }
    }, [DataDetailDocument])

    useEffect(() => {
        if (DataUpdateDocStore?.DataUpdateDoc?.date_adoption) {
            setLoading(false)
            setCreateSucces(true)

        }
    }, [DataUpdateDocStore?.DataUpdateDoc?.date_adoption])

    useEffect(() => {
        console.log("DataUpdateDocStoreDataUpdateDocStore : ", DataUpdateDocStore)
    }, [DataUpdateDocStore])

    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        monthNamesShort: ['jan', 'feb', 'mar', 'avr', 'mai', 'jui', 'jui', 'aou', 'sep', 'oct', 'nov', 'dec'],
        today: "Maintenant",
        clear: 'Nettoyer'
    })

    locale("fr")

    const AllerListeDoc = () => {

        dispatch(Get_detail_DocAction(params_id))
        setCreateSucces(false)

    }

    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );


    const OnSubmitDOcument = (event) => {
        event.preventDefault()
        setLoading(true)
        let body
        const isString = typeof selectedFile === 'string';
        const is_active = is_activeDocument === "Oui" ? true : false
        if (isString) {
            body = {
                titre: titre,
                date_adoption: date_adoption,

                type_document: type_document,
                is_active: is_active,
                administrateur : admin_id

            }
        } else {
            body = {
                titre: titre,
                date_adoption: date_adoption,
                documents: previewURL,
                type_document: type_document,
                is_active: is_active,
                administrateur : admin_id

            }
        }







        dispatch(put_Update_DocumentActions(params_id, body))
    }
    return (
        <div>
            <LoaderComponent loading={loading} />

            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>
                        <div className='pt-6 pl-4 pb-12'>
                            <h1 className='text-slate-500	text-4xl font-bold'> <span className='bg-green-500 p-3 text-white rounded-full mr-3'><i class="fi fi-ss-user-gear"></i></span>Formulaire de Modification du document</h1>
                        </div>
                    </div>

                    {
                        DataDetailDocument && (
                            <>
                                <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                                    <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                        <div class="p-6 bg-white border-b border-gray-200">
                                            <div>
                                                <h2 className='py-4'><span className='font-bold text-teal-600'>Modification du document</span></h2>
                                            </div>


                                            <div class="mb-4">
                                                <div className="flex flex-column gap-2">
                                                    <label htmlFor="username font-bold"><span className='font-bold'>Titre de la publications</span> <span className='font-bold text-red-500'>*</span> </label>
                                                    <InputText id="username" value={titre} aria-describedby="username-help" onChange={(e) => setTire(e.target.value)} />

                                                </div>
                                            </div>

                                            <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2">
                                                <div className="flex flex-column gap-2">
                                                    <label htmlFor="Nom" className='font-bold'>Catégorie du document <span className='font-bold text-red-500'>*</span> </label>
                                                    <Dropdown value={cat_documents} onChange={(e) => setCat_document(e.value)} options={cat_options}
                                                        placeholder="Selectionner le type de document" className={errors.cat_documents ? 'border-2 border-red-500' : ''} />
                                                </div>

                                                {
                                                    cat_documents === 'TEXTES COMMUNAUTAIRES' ? (
                                                        <div className="flex flex-column gap-2">
                                                            <label htmlFor="Nom" className='font-bold'>Sous catégorie : TEXTES COMMUNAUTAIRES <span className='font-bold text-red-500'>*</span> </label>
                                                            <Dropdown value={sous_cat_document} onChange={(e) => setSous_Cat_document(e.value)} options={sous_cat_optionsCommunautaire}
                                                                placeholder="Selectionner la sous catégorie du document" className={errors.sous_cat_document ? 'border-2 border-red-500' : ''} />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {
                                                                cat_documents === 'AVIS' ? (
                                                                    <div className="flex flex-column gap-2">
                                                                        <label htmlFor="Nom" className='font-bold'>Type document (AVIS) <span className='font-bold text-red-500'>*</span> </label>
                                                                        <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsAvisOption}
                                                                            placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                                    </div>
                                                                ) : (
                                                                    <div className="flex flex-column gap-2">
                                                                        <label htmlFor="Nom" className='font-bold'>Type documentn (TEXTES NATIONAUX) :  <span className='font-bold text-red-500'>*</span> </label>
                                                                        <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsNationauxOption}
                                                                            placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                                    </div>
                                                                )
                                                            }
                                                        </>

                                                    )
                                                }


                                                {
                                                    cat_documents === 'TEXTES COMMUNAUTAIRES' && (
                                                        <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-1">

                                                            <div className="flex flex-column gap-2">
                                                                <label htmlFor="Nom" className='font-bold'>type documents : "TEXTES COMMUNAUTAIRES" <span className='font-bold text-red-500'>*</span> </label>
                                                                <Dropdown value={type_document} onChange={(e) => setType_document(e.value)} options={types_documentsCommunautaireOption}
                                                                    placeholder="Selectionner le type de document" className={errors.type_document ? 'border-2 border-red-500' : ''} />
                                                            </div>

                                                        </div>
                                                    )
                                                }







                                            </div>


                                            <div class="mb-4 grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-1">

                                                <div className="flex flex-column gap-2">
                                                    <label htmlFor="Nom" className='font-bold'>Date d'adoption <span className='font-bold text-red-500'>*</span> </label>
                                                    <Calendar id="Nom" dateFormat='dd/mm/yy' className={errors.date_adoption ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={date_adoption} onChange={(e) => setDate_adoption(e.target.value)} />

                                                </div>

                                            </div>

                                            <div class="mb-4">
                                                <div>

                                                    <input type="file" accept=".pdf" onChange={handleFileChange} />
                                                </div>
                                                {/* {selectedFile && (

                                                    <PdfCOmpo pdfFile={selectedFile}  className='w-full' />

                                                )} */}
                                            </div>
                                            <div class="mb-4">
                                                <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>


                                                    <div className="flex flex-column gap-2">
                                                        <label htmlFor="username">Activer le compte</label>
                                                        <SelectButton value={is_activeDocument} onChange={(e) => setIs_activeDocument(e.value)} options={options} />

                                                    </div> </div>
                                            </div>
                                            <div class="mb-4">
                                                <Button onClick={(event) => OnSubmitDOcument(event)} className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' label='Modifier le document' type='submit' />
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    }

                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Modification du document effectué avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>




                </div>

            </div>
        </div>
    )
}

export default UpdateDocument


