import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import LoaderComponent from "../../../../LoaderComponent"
import { InputText } from "primereact/inputtext"
import { Dialog } from "primereact/dialog"
import { Button } from "primereact/button"
import { post_create_new_Abon_Newsletters_actions } from "../../../../reduxComponents/Actions/ParametrePagesActions"


export const validateEmail = (email) => {
    // Expression régulière pour valider le format d'email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
};

const Newsletter = () => {

    const dispatch = useDispatch()
    const DataNewslettersAbonne = useSelector(state => state.create_news_Abon_newsletters)

    const [loading, setLoading] = useState(false)
    const [IsCreateSuccess, setIsCreateSuccess] = useState()
    const [IsEmailExiste, setIsEmailExiste] = useState()

    const [ErreurDispath, setErreurDispath] = useState(false)

    const [Email, setEmail] = useState()

    const [errorMessage, setErrorMessage] = useState("");






    useEffect(() => {
        if (DataNewslettersAbonne?.DataNewABon?.id && loading) {
            setIsCreateSuccess(true)
        }
    }, [DataNewslettersAbonne?.DataNewABon])

    useEffect(() => {
        if (DataNewslettersAbonne?.error?.email) {
            setLoading(false)
            setIsEmailExiste(true)
        } else {
            if (DataNewslettersAbonne?.error) {
                
                setErreurDispath(true)

            }
        }
        
    }, [DataNewslettersAbonne?.error])


    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );

    const AllerListeDoc = () => {
        setEmail("")
        setLoading(false)
        setIsCreateSuccess(false)
    }

    const SubmitABonnement = (e) => {
        e.preventDefault()
        setLoading(true)

        if (validateEmail(Email)) {
            const body = {
                email: Email
            }

            dispatch(post_create_new_Abon_Newsletters_actions(body))

            setErrorMessage("");
        } else {
            setErrorMessage("Veuillez entrer une adresse e-mail valide.");
        }
    };



    return (
        <section className="py-14   mx-auto sectionAnime">
            <LoaderComponent loading={loading} />
            <div className="relative overflow-hidden px-4  py-14 rounded-2xl bg-teal-800 ">
                <div className="relative z-10 max-w-xl mx-auto sm:text-center">
                    <div className="space-y-3">

                        <h3 className="text-3xl text-white font-bold">
                            <b>Abonnez-vous à notre newsletter</b>
                        </h3>
                        <p className="text-blue-100 leading-relaxed">
                            Restez informé de l'avancement de la feuille de route, des annonces
                            et des remises exclusives, n'hésitez pas à vous inscrire avec votre e-mail
                        </p>
                    </div>
                    <div className="mt-6">
                        <div

                            className="flex items-center justify-center bg-white rounded-lg p-1 sm:max-w-md sm:mx-auto">

                            <InputText
                                placeholder="Entrer votre email "
                                className="text-gray-500 w-full p-2 outline-none border-none"
                                value={Email} onChange={(e) => setEmail(e.target.value)} />
                            <button onClick={(e) => SubmitABonnement(e)}
                                className="p-2 px-3 rounded-lg font-medium text-white bg-orange-400 hover:bg-orange-500 active:bg-orange-600 rounded-lg duration-150 outline-none shadow-md focus:shadow-none sm:px-4"
                            >
                                S'abonner
                            </button>
                        </div>

                        {errorMessage && <p className="text-red-500 p-3 bg-white">{errorMessage}</p>}
                        {IsEmailExiste && <p className="text-red-500 p-3 bg-white mt-3 font-bold">Cette adresse email est déjà abonnée a notre newsletter.</p>}
                    </div>
                </div>
                <div className="absolute inset-0 w-full h-full bg-teal-600" ></div>
            </div>

            <div className="card flex justify-content-center ">
                <Dialog visible={IsCreateSuccess} modal footer={footerContent} style={{ width: '25rem' }} onHide={() => setIsCreateSuccess()}>

                    <div className="bg-white rounded-md  px-4 py-6 dialogCompo">
                        <div className=" flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-5 h-5 text-green-600"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </div>

                        <div className="text-lg font-medium text-gray-800 text-center mt-3">
                            {" "}
                            Abonnement effectué avec succès
                        </div>


                    </div>


                </Dialog>


                <div className="card flex justify-content-center">
                    <Dialog visible={ErreurDispath} modal style={{ width: '25rem' }} onHide={() => {
                        setLoading(false)
                        setErreurDispath(false)
                    }}>

                        <div className="bg-white rounded-md  px-4 py-6">
                            <div className=" flex items-center text-red-500 text-3Xl  justify-center mx-auto rounded-full">
                                <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                                    <g clip-path="url(#clip0_705_24107)">
                                        <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#D72828" />
                                        <path d="M29 50H35V44H29V50ZM29 12V38H35V12H29Z" fill="#E6E6E6" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_705_24107">
                                            <rect width="64" height="64" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>

                            <div className="text-lg font-medium text-red-600 font-bold text-center mt-3">
                                {" "}
                                oups une erreur s'est produite. veuillez réessayer plus tard
                            </div>


                        </div>


                    </Dialog>

                    {/* <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
            <p className="m-0 text-red-500 text-center">
              votre temps de connexion est epuisé
            </p>
          </Dialog> */}
                </div>

                {/* <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
            <p className="m-0 text-red-500 text-center">
              votre temps de connexion est epuisé
            </p>
          </Dialog> */}
            </div>

        </section>
    )
}
export default Newsletter
