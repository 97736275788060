import React from "react"
import { useState } from "react"
import commissionCOonccurence from '../../../../images/ImagesCommission/Commission de la Concurrence.jpg'
import videoPresentation from '../../../../images/ImagesCommission/presentationcc.mp4'

const BanniereAccueil = () => {
   

    const [isVideoPoppedUp, setVideoPopUp] = useState(false)

    return (
        <section>
           <div className="max-w-full mx-auto py-12 gap-12 text-gray-600  xl:flex">
  <div className="flex-1">
    <div className="relative h-96">
      <img src={commissionCOonccurence} className="rounded-lg h-full w-full object-cover object-center" alt="Commission de la conccurence" />
      <div className="absolute inset-0 bg-teal-500 opacity-80"></div>
      <button className="absolute w-16 h-16 rounded-full inset-0 m-auto duration-150 bg-orange-400 hover:bg-orange-600 ring-offset-2 focus:ring text-white borderArrondie" onClick={() => setVideoPopUp(true)}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-6 h-6 m-auto">
          <path d="M6.3 2.841A1.5 1.5 0 004 4.11V15.89a1.5 1.5 0 002.3 1.269l9.344-5.89a1.5 1.5 0 000-2.538L6.3 2.84z" />
        </svg>
      </button>
    </div>
  </div>
</div>


            {
                isVideoPoppedUp ? (
                    <div className="fixed inset-0 w-full h-full flex items-center justify-center">
                        <div className="absolute inset-0 w-full h-full bg-black/80" onClick={() => setVideoPopUp(false)}></div>
                        <div className="px-4 relative">
                            <button
                                className="w-12 h-12 mb-5 borderArrondie duration-150 bg-teal-500 hover:bg-teal-700 text-white"
                                onClick={() => setVideoPopUp(false)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 m-auto">
                                    <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
                                </svg>
                            </button>
                            <video className="rounded-lg w-full max-w-2xl" controls autoPlay={true}>
                                <source src={videoPresentation} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                ) : ""
            }
        </section>
    )
}

export default BanniereAccueil
