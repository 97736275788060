import { essaye_typesListe } from "../Types/EssaieTypes";



const initialStateInfoReducer = {
    isLoading: false,
    DataInfoReducers: [],
    error: "",
};


export const Get_Pub_Essaie = (state=initialStateInfoReducer, action) => {

    switch (action.type) {
        case essaye_typesListe.GET_ESSAIE_START:
            return {
                ...state,
                isLoading: true,
                DataInfoReducers : []
                
            };
        case essaye_typesListe.GET_ESSAIE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataInfoReducers: action.payload,
            };


        case essaye_typesListe.GET_ESSAIE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataInfoReducers : null,
            };
        default:
            return state;
    }
}