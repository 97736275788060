import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import SousSections from '../SousSections.jsx/SousSections'
import Footers from '../../footers/Footers'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Get_detail_DocAction } from '../../../reduxComponents/Actions/Gestion_des_DocsActions'
import { pdfjs } from 'react-pdf'
import { BackendEntete } from '../../../VariablesConfig'
import PdfCOmpo from '../../../app_admin/Text-reglementaire/PdfCOmpo'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'





const ViewDocument = () => {


  const dispatch = useDispatch()

  const params = useParams()
  const params_id = atob(params?.id)
  console.log("params_idparams_idparams_idparams_id : ", params_id)



  const DataStoreDetailDOc = useSelector(state => state.get_doc_detail)
  const [LienPDF, setLienPDF] = useState(null)


  const [DataDetailDOc, setDataDetailDOc] = useState(null)
  const [loading, setLoading] = useState(true);


  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();


  


  useEffect(() => {
    dispatch(Get_detail_DocAction(params_id))
  }, [params_id])

  useEffect(() => {
    if (DataStoreDetailDOc?.DatDetailDoc) {
      setDataDetailDOc(DataStoreDetailDOc?.DatDetailDoc)
      setLoading(false)
    }

  }, [DataStoreDetailDOc?.DatDetailDoc])

  useEffect(() => {
    if (DataDetailDOc && DataDetailDOc[0]?.documents) {
      const document = BackendEntete + DataDetailDOc[0]?.documents
      console.log('documentdocumentdocument : ', document)
      setLienPDF(document)
    }
  }, [DataDetailDOc])

  const formatDate = (date) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('fr-FR', options);
    return formattedDate;
  };

  return (
    <>
      <div>
        <Headers PositionParent="Textes réglémentaires" PositionEnfant="Doc" PositionPetitEnfant="" />



      </div>

      <div className='marginContinaierWeb flex grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 text-center'>
          
        <div>
            <div className=' flex gap-10 text-center'>
              {
                DataDetailDOc && DataDetailDOc[0]?.titre && (
                  <div className=''>
                  <div className=''>
                    <div className='pt-4'>
                      <span className='font-bold text-2xl text-teal-500'>{ DataDetailDOc[0].titre}</span>
                    </div>
                    <div className='flex gap-4 py-4'>
                      <div className='bg-orange- p-2 text-orange-600'>
                        <span> Adopter le  :  <span className='font-bold'>{ formatDate( DataDetailDOc[0].date_adoption)}</span></span>
                      </div>
                      
                     
                    </div>
                  </div>
                 
                
                    
                    
                 
                 
                  <div>
                  </div>
                </div>
                )
              } 
             

            </div>

          </div>
          <div className='w-full'>
            {
              LienPDF && (
                <>
                 <PdfCOmpo pdfFile = {LienPDF} filePath={DataDetailDOc[0]?.documents} className='w-full' />
                </>
               
              )
            }
           
          </div>
         
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>
      

      <div>

      </div>
      <Reseau_Sociaux />
      <div>
        <Footers />
      </div>

     
    </>
  )
}

export default ViewDocument
