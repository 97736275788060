import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import ActivitesDataLIst from './ActiviteDataList'
import FormulaireActivite from './FormulaireActivite'
import { useDispatch, useSelector } from 'react-redux'
import { get_Detail_activite_Actions } from '../../reduxComponents/Actions/Activites_Actions'
import { useParams } from 'react-router-dom'
import FormUpdate from './FormUpdate'

const DetailActivite = () => {

    
    return (
        <div>
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>
                        <div className='pt-6 pl-4'>
                            <h1 className='text-slate-500	text-4xl font-bold'> <span className='bg-green-500 p-3 text-white rounded-full mr-3'><i class="fi fi-ss-user-gear"></i></span><span className='font-bold text-teal-600'>Modifier une activités</span> <span ></span></h1>
                        </div>
                    </div>


                    <div className="grid gap-6 mb-8 md:grid-cols-1 xl:grid-cols-1">

                        

                        <div className='px-4'>

                           <FormUpdate />



                        </div>
                    </div>










                </div>

            </div>
        </div>
    )
}

export default DetailActivite



