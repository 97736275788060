import { Gestion_desPublications_Types } from "../Types/Gestion_Publication_Type";


const initialDataCreatePublications = {
    isLoading: false,
    DataCreatePublications: [],
    error: "",
};

const initialDataDetailsPublications = {
    isLoading: false,
    DataDetailPublications: [],
    error: "",
};

const initialDataUPDATEPublications = {
    isLoading: false,
    DataUpdatePub: [],
    error: "",
};


const initialDataListLast = {
    isLoading: false,
    DataListLast: [],
    error: "",
};

const inititalLastListCarrousel = {
    isLoading: false,
    DataListCarrousel: [],
    error: "",
};



export const POst_Create_PublicationsReducers = (state=initialDataCreatePublications, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.CREATE_PUBLICATION_START:
            return {
                ...state,
                isLoading: true,
                DataCreatePublications : null
            };
        case Gestion_desPublications_Types.CREATE_PUBLICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreatePublications: action.payload,
                error : null
            };


        case Gestion_desPublications_Types.CREATE_PUBLICATION_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreatePublications : null
            };
        default:
            return state;
    }
}


export const Get_Detail_PublicationsReducers = (state=initialDataDetailsPublications, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DataDetailPublications : null,
                error:null
            };
        case Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDetailPublications: action.payload,
                error:null
            };


        case Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailPublications : null,
            };
        default:
            return state;
    }
}


export const Get_Last_List_Reducers = (state=initialDataListLast, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.GET_Last_Publication_START:
            return {
                ...state,
                isLoading: true,
                DataListLast : null,
                error:null
            };
        case Gestion_desPublications_Types.GET_Last_Publication_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataListLast: action.payload,
                error:null
            };


        case Gestion_desPublications_Types.GET_Last_Publication_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListLast : null,
            };
        default:
            return state;
    }
}

export const Get_Last_List_CAROUSELS_Reducers = (state=inititalLastListCarrousel, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.GET_Last_CAROUSELS_START:
            return {
                ...state,
                isLoading: true,
                DataListCarrousel : null,
                error:null
            };
        case Gestion_desPublications_Types.GET_Last_CAROUSELS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataListCarrousel: action.payload,
                error:null
            };


        case Gestion_desPublications_Types.GET_Last_CAROUSELS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListCarrousel : null,
            };
        default:
            return state;
    }
}



export const Put_Update_PublicationReducer = (state=initialDataUPDATEPublications, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_START:
            return {
                ...state,
                isLoading: true,
                DataUpdatePub : null,
                error: null,
            };
        case Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdatePub: action.payload,
                error: null,
            };


        case Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdatePub : null
            };
        default:
            return state;
    }
}


const initialDerniersPubPublications = {
    isLoading: false,
    DataDernierPub: [],
    error: "",
};


export const Get_Dernier_Pub_Reducers = (state=initialDerniersPubPublications, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.GET_DERNIER_PUB_START:
            return {
                ...state,
                isLoading: true,
                DataDernierPub : null,
                error:null
            };
        case Gestion_desPublications_Types.GET_DERNIER_PUB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDernierPub: action.payload,
                error:null
            };


        case Gestion_desPublications_Types.GET_DERNIER_PUB_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDernierPub : null,
            };
        default:
            return state;
    }
}



const intialarchiveArticle = {
    isLoading: false,
    DataArchiveArticle: [],
    error: "",
};

export const put_archive_pub_reducers = (state=intialarchiveArticle, action) => {

    switch (action.type) {
        case Gestion_desPublications_Types.PUT_ARCHIVE_PUB_START:
            return {
                ...state,
                isLoading: true,
                DataArchiveArticle : null,
                error:null
            };
        case Gestion_desPublications_Types.PUT_ARCHIVE_PUB_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataArchiveArticle: action.payload,
                error:null
            };


        case Gestion_desPublications_Types.PUT_ARCHIVE_PUB_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataArchiveArticle : null,
            };
        default:
            return state;
    }
}
