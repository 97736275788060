import { Gestion_COMMUNIQUEPRESSE_Types } from "../Types/Gestion_des_CommuniqueType";


const initialPOstCreateCommunique = {
    isLoading: false,
    DataCreateCommunique: [],
    error: "",
};


const initialListeCommunique = {
    isLoading: false,
    DataListeCPReducers: [],
    error: "",
};

const initialDataUpdateCommuniquePresse = {
    isLoading: false,
    DataCommuniqueUpdate: [],
    error: "",
};

const initialDetailCommunique = {
    isLoading: false,
    DataDetailCommunique: [],
    error: "",
};

const initialArchiveCommunique = {
    isLoading: false,
    DataArchiveCommunique: [],
    error: "",
};

export const put_Archive_Communique_Reducers = (state=initialArchiveCommunique, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_START:
            return {
                ...state,
                isLoading: true,
                DataArchiveCommunique : null,
                error : null
            };
        case Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataArchiveCommunique: action.payload,
                error : null
            };


        case Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataArchiveCommunique : null
            };
        default:
            return state;
    }
}


export const PosteCreateCommuniquePresseReducers = (state=initialPOstCreateCommunique, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_START:
            return {
                ...state,
                isLoading: true,
                DataCreateCommunique : null,
                error : null
            };
        case Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateCommunique: action.payload,
                error : null
            };


        case Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateCommunique : null
            };
        default:
            return state;
    }
}

export const Get_liste_CommuniqueReducers = (state=initialListeCommunique, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_START:
            return {
                ...state,
                isLoading: true,
                DataListeCPReducers : [],
                error : null
                
            };
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : null,
                DataListeCPReducers: action.payload,
            };


        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListeCPReducers : null,
            };
        default:
            return state;
    }
}

export const Get_Detail_CommuniquePresseReducers = (state=initialDetailCommunique, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DataDetailCommunique : null,
                error:null
            };
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDetailCommunique: action.payload,
                error:null
            };


        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailCommunique : null,
            };
        default:
            return state;
    }
}

export const Put_Update_CommuniquePresse = (state=initialDataUpdateCommuniquePresse, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_START:
            return {
                ...state,
                isLoading: true,
                DataCommuniqueUpdate : null,
                error: null,
            };
        case Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCommuniqueUpdate: action.payload,
                error: null,
            };


        case Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCommuniqueUpdate : null
            };
        default:
            return state;
    }
}



const initial_lastCommunique = {
    isLoading: false,
    DataLastCommunique: [],
    error: "",
};


export const Get_last_communique_Reducers = (state=initial_lastCommunique, action) => {

    switch (action.type) {
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_START:
            return {
                ...state,
                isLoading: true,
                DataLastCommunique : null,
                error:null
            };
        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataLastCommunique: action.payload,
                error:null
            };


        case Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataLastCommunique : null,
            };
        default:
            return state;
    }
}