
import { Gestion_desPublications_Types } from '../Types/Gestion_Publication_Type'; 
import axios from '../axios/axios'
import { configHeadersToken } from './Gestion_Admin_Actions';



export const get_Publication_Update_Actions = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_START,
      });
      axios
        .get(`app_commission/texte_reglementaire/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


export const post_Create_PublicationsActions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_desPublications_Types.CREATE_PUBLICATION_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/publications/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_desPublications_Types.CREATE_PUBLICATION_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_desPublications_Types.CREATE_PUBLICATION_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_desPublications_Types.CREATE_PUBLICATION_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  export const Get_detail_PublicationsActions = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_START,
      });
      axios
        .get(`app_commission/publications/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_PUBLICATIONS_DETAILS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const Get_Last_List_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_desPublications_Types.GET_Last_Publication_START,
      });
      axios
        .get(`app_commission/publications/lastpub/`)
        .then((response) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_Last_Publication_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_Last_Publication_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const Get_Last_List_Caroussels_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_desPublications_Types.GET_Last_CAROUSELS_START,
      });
      axios
        .get(`app_commission/publications/lastpubfive/`)
        .then((response) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_Last_CAROUSELS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_Last_CAROUSELS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const Get_Dernier_pub_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_desPublications_Types.GET_DERNIER_PUB_START,
      });
      axios
        .get(`app_commission/publications/dernier/`)
        .then((response) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_DERNIER_PUB_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_desPublications_Types.GET_DERNIER_PUB_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const put_update_PublicationsActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_START });
      // console.log(body);
      console.log("idididididididid  : ", id)
      axios
        .put(`app_commission/publications/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_desPublications_Types.PUT_UPDATE_PUBLICATIONS_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };


  export const put_Archive_PublicationsActions = (id, body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_desPublications_Types.PUT_ARCHIVE_PUB_START });
      // console.log(body);
      console.log("idididididididid  : ", id)
      axios
        .put(`app_commission/publications/archive/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_desPublications_Types.PUT_ARCHIVE_PUB_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_desPublications_Types.PUT_ARCHIVE_PUB_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_desPublications_Types.PUT_ARCHIVE_PUB_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };
  
  
