
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_Pub_listeEssaie } from '../../reduxComponents/Actions/EssayeActions';
import { BackendEntete } from '../../VariablesConfig';
import LoaderComponent from '../../LoaderComponent';
import { put_Archive_PublicationsActions } from '../../reduxComponents/Actions/PublicationsActions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const DataListePublications = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const DataLIsteStore = useSelector(state=>state.get_publications)
    const DataArchiveStore = useSelector(state=>state.Archive_APub_store)

    const [DataListePublications, setDataListePublications] = useState(null)
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };
    useEffect(()=>{
        if (DataArchiveStore?.DataArchiveArticle?.succes && loading) {
            
            dispatch(get_Pub_listeEssaie())
            setVisibleDialogue(false)
            setLoading(false)
            
        }
        
    },[DataArchiveStore?.DataArchiveArticle])

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    useEffect(()=>{
        dispatch(get_Pub_listeEssaie())
    }, [])

    useEffect(()=>{
        if (DataLIsteStore?.DataInfoReducers) {
            setDataListePublications(DataLIsteStore?.DataInfoReducers)
        }
        
    }, [DataLIsteStore?.DataInfoReducers])

    const ArchiverAction = ()=>{
        setLoading(true)
        dispatch(put_Archive_PublicationsActions(Params_id))
    }

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => ArchiverAction()} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/actualites/create_publications')
    }

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher une publication" />
                </span>
            </div>
            <div className="flex justify-content-end">
            <Button label="Créer une nouvelle publication" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        const lienImgaeCover = BackendEntete + rowData.image_cover
        console.log("lienImgaeCoverlienImgaeCoverlienImgaeCover : ", lienImgaeCover)
        return (
            <div className="flex align-items-center gap-2">
                <img alt="commission concurence" src={lienImgaeCover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (id)=>{
        const idBtoa = btoa(id)
        console.log("idBtoaidBtoa: ", id)
        navigate(`/admin/actualites/publications/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/update-publication/${idBtoa}`, {replace : false})
    }

    const [Params_id, setParams_id] =useState(null)
    const [Titre, setTitre] =useState(null)

    const DialogueArchive = (rowData)=>{
        setTitre(rowData?.titre)
        setParams_id(rowData?.id)
        setVisibleDialogue(true)
        
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData?.id)} ><i class="fi fi-sr-eye"></i></button>
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData?.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive(rowData)} text className={`${rowData?.is_archive ? 'text-teal-500' : 'text-red-500'}`}><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }

    const ArchiverBody = (rowData)=>{
       return(
        <div>
            {
            rowData?.is_archive === true ? (
                <div className='text-red-500 font-bold'> archivé</div>
            ) : (
                <div className='text-teal-500 font-bold'> non archivé</div>
            )
        }
        </div>
       )
    }

    const bodyData = (rowData)=>{
        return(
            <div>
                {
                    formatDateBon(rowData?.date_pub)
                }
            </div>
        )
    }



    return (
        <div className="card">
            <LoaderComponent loading={loading} />
            <DataTable value={DataListePublications} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['titre', 'date_pub', 'image_cover']} header={header} emptyMessage="Aucune publication trouvée.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                <Column field="image_cover" header="Image de couverture" body={photoBody} style={{ minWidth: '6rem' }} />
                <Column header="Titre publications" field='titre' style={{ minWidth: '12rem' }} />
                {/* <Column header="Contact" field='contact' style={{ minWidth: '9rem' }} /> */}
                <Column header="Date de publications" field='date_pub' body={bodyData} style={{ minWidth: '9rem' }} />
                <Column header="Nombre de vue" field='vues' style={{ minWidth: '9rem' }} />
                <Column header="Archivé" field='is_archive' body={ArchiverBody} style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Vous souhaité archiver la publication : <b>{Titre}</b>
                </p>
            </Dialog>
        </div>
        </div>
    )
}

export default DataListePublications


