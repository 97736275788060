import React from "react";
import { useNavigate } from "react-router-dom";
import { BackendEntete } from "../../../VariablesConfig";


const GaleroiePhotoItems = ({ListeImageGalerie}) => {
    const navigate = useNavigate()

    const redirect = (idparams)=>{
        const id = btoa(idparams)
        navigate(`/actualites/galeriephoto/${id}`)
    }
    return (
        <section className="">
            <div >
                <div className="mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-3">
                        {
                            ListeImageGalerie.map((item, idx) => (
                                <li key={idx} onClick={()=>redirect(item.id)} className="cursor-pointer">
                                    <div className="w-full h-60 sm:h-52 md:h-56">
                                        <img
                                            src={BackendEntete  + item.image_cover}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4">
                                        <p className="text-lg text-gray-500 font-semibold text-justify">{item.titre}</p>
                                     
                                       
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default GaleroiePhotoItems;