import axios from "axios"
import { config } from "../../../Constants"
import { errorServerActions, logoutUser } from "../Actions/Gestion_Admin_Actions"
import { store } from "../Store"; // Assurez-vous que le chemin est correct

// import env from "react-dotenv"

var SERVER_URL = config.virtual.URL

const instance = axios.create({
  baseURL: SERVER_URL 
})

instance.defaults.headers.post["Content-Type"] = "application/json"


instance.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 401) {
      // Token expiré ou utilisateur non autorisé
      store.dispatch(logoutUser());
      // Afficher le dialogue de réauthentification
      
    }else{
      if (error?.code || error?.code ==='ERR_NETWORK' || error?.messages ==='Network Error' ) {
        console.log('errorerrorerrorerrorerrorerror :', error)
        store.dispatch(errorServerActions(error));
      }
    }
    return Promise.reject(error);
  }
);

export const baseURL = SERVER_URL

export const server_url = SERVER_URL

export default instance