import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import TextesItems from './TextesItems'
import { useDispatch, useSelector } from 'react-redux'
import { get_doc_Liste_Document } from '../../../reduxComponents/Actions/Gestion_des_DocsActions'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const Arretes = () => {
  const dispatch = useDispatch()
  const DataListeArreteDoc = useSelector(state=>state.get_liste_docBY_Type)

  const [DataListeDoc, setDataListeDoc] = useState()
  useEffect(()=>{
    dispatch(get_doc_Liste_Document('Arrêtés'))
  }, [])

  useEffect(()=>{
    if (DataListeArreteDoc?.DataListeDoc) {
      setDataListeDoc(DataListeArreteDoc?.DataListeDoc)
    }
  }, [DataListeArreteDoc?.DataListeDoc])

  return (
    <>
    <div>
    <Headers PositionParent="Textes réglémentaires" PositionEnfant="Arrêtés" PositionPetitEnfant="" />



    </div>

    <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
      <div>
            <div className='pt-4'>
              <span className='font-bold text-3xl text-teal-500'>ARRÊTÉS</span>
            </div>
          </div>
          <div>
          {
            DataListeDoc ?  (
                <div>
                <TextesItems DataListeDoc={DataListeDoc} isNonRegement={true} />
              </div>
             
            ) : (
                <div>
                    <p>Aucun document trouvé</p>
                </div>
            )
          }
          </div>
      <div>
    </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>
    

    <div>
        
    </div>
    <Reseau_Sociaux />
    <div>
    <Footers />
    </div>
</>
  )
}

export default Arretes
