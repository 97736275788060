import { Divider } from 'primereact/divider';
import React from 'react'
import { useNavigate } from 'react-router-dom';


export const formatDateBon = (dateRecu) => {
    const date = new Date(dateRecu)
    const mois = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthName = mois[monthIndex];

    return `${day} ${monthName} ${year}`;
}


const truncateText = (text, maxWords) => {

    const words = text.split(/\s+/);
    if (words.length > maxWords) {
        return words.slice(0, maxWords).join(' ') + '...';
    } else {
        return text;
    }
}


export const ComponentTronca = ({ longText, nbreTexte }) => {


    const truncatedText = truncateText(longText, nbreTexte);

    return (
        <div>
            {truncatedText}
        </div>
    );
}


const ListeActivites = ({ DataList }) => {

    const navigate = useNavigate()


    const currentDate = new Date();


    const listeInferieure = DataList.filter(item => new Date(item.date_activite) < currentDate);
    const listeSuperieureOuEgale = DataList.filter(item => new Date(item.date_activite) >= currentDate);




    const RedirectTo = (id) => {
        const idCrypt = btoa(id)

        navigate(`/activites/voiractivite/${idCrypt}`)
    }


    const ComponentTroncaHTML = ({ longText, nbreTexte }) => {

        var contenuTexte = longText.replace(/<[^>]*>/g, '');
        const truncatedText = truncateText(contenuTexte, nbreTexte);

        return (
            <div>
                {truncatedText}
            </div>
        );
    }



    return (
        <>
            <div>

                <div>
                    <span className='py-2 font-bold'>À venir</span>
                </div>
                {
                    listeSuperieureOuEgale.map((item, idx) => (



                        <>

                            <div class="mt-6 cursor-pointer" onClick={()=>RedirectTo(item?.id)}>
                                <div className="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                                    <div className="flex items-center justify-between"><span className="font-light text-gray-600">
                                        {formatDateBon(item?.date_activite)}

                                    </span><span
                                        className="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">{item?.type_activite}</span>
                                    </div>
                                    <div className="mt-2"><span className="text-2xl font-bold text-teal-500 hover:underline">
                                        <span>
                                            <ComponentTronca longText={item?.titre} nbreTexte={15} />
                                        </span>
                                    </span>
                                        <p className="mt-2 text-gray-600">
                                            <ComponentTroncaHTML longText={item?.contenu} nbreTexte={100} />

                                        </p>
                                    </div>

                                    <div class="flex items-center justify-between mt-4">
                                        <div className='ml-3'>
                                            <span className='font-bold'> <span className='text-orange-400 font-bold'>Lieu : </span> {item?.lieux_activite} </span>
                                        </div>

                                    </div>

                                </div>
                            </div>










                        </>










                    ))}



            </div>

            <Divider />


            <div>

                <div>
                    <span className='py-2 font-bold text-red-500'>Déjà effectués</span>
                </div>
                {
                    listeInferieure.map((item, idx) => (
                        <div class="mt-6 cursor-pointer" onClick={()=>RedirectTo(item?.id)}>
                            <div className="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                                <div className="flex items-center justify-between"><span className="font-light text-gray-600">
                                    {formatDateBon(item?.date_activite)}

                                </span><span
                                    className="px-2 py-1 font-bold text-red-100 bg-red-600 rounded hover:bg-red-700">{item?.type_activite}</span>
                                </div>
                                <div className="mt-2"><span className="text-2xl font-bold text-red-500 hover:underline">
                                    <span>
                                        <ComponentTronca longText={item?.titre} nbreTexte={15} />
                                    </span>
                                </span>
                                    <p className="mt-2 text-gray-600">
                                        <ComponentTroncaHTML longText={item?.contenu} nbreTexte={100} />

                                    </p>
                                </div>

                                <div class="flex items-center justify-between mt-4">
                                    <div className='ml-3'>
                                        <span className='font-bold'> <span className='text-orange-400 font-bold'>Lieu : </span> {item?.lieux_activite} </span>
                                    </div>

                                </div>

                            </div>
                        </div>

                    ))}



            </div>
        </>

    )
}

export default ListeActivites
