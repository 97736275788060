
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_doc_Liste_Document, post_Create_AdminActions } from '../../reduxComponents/Actions/Gestion_des_DocsActions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const DataListeDocuments = ({DocumentTypeProps}) => {

    const navigate = useNavigate()
    const id = 12
    const dispatch=useDispatch()
    const DataListeDocument = useSelector(state=>state.get_liste_docBY_Type)

    const [DataListeDocumentByType, setDataListeDocumentByType] = useState()

    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                titre: `Document ${i}`,
                date_ajoute: `agent${i}@example.com`,
                nbre_lecture : `${i}`
                
            });
        }

        return newData;
    };
    useEffect(()=>{
        dispatch(get_doc_Liste_Document(DocumentTypeProps))
        console.log('DocumentTypeP: ', DocumentTypeProps)
    }, [DocumentTypeProps])
    useEffect(()=>{
        console.log('DataListeDocumentDataListeDocumentDataListeDocument : ', DataListeDocument)
        if (DataListeDocument?.DataListeDoc) {
            setDataListeDocumentByType(DataListeDocument?.DataListeDoc)
        }

    }, [DataListeDocument])

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le communiqué" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/textes-reglementaires/createdocument')
    }

    const renderHeader = () => {
        return (
            <>
             <div className='py-3'>
                <h3 > <span className='font-bold text-teal-600'>Listes des documents <span className='bg-orange-500 text-white px-3'>{DocumentTypeProps}</span></span> </h3>
             </div>
            <div className='flex justify-between'>
               
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un document" />
                </span>
                </div>
            <div className="flex justify-content-end">
            <Button label="Créer un document" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
            </>
            
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (id)=>{
        
        const idBtoa = btoa(id)
        
        navigate(`/admin/textes-reglementaires/document/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (iddoc)=>{
        const idBtoa = btoa(iddoc)
       
        navigate(`/admin/textes-reglementaires/document/update/${idBtoa}`, {replace : false})
    }

    const [Params_id, setParams_id] =useState(null)
    const [Titre, setTitre] =useState(null)

    const DialogueArchive = (rowData)=>{
        setTitre(rowData?.titre)
        setParams_id(rowData?.id)
        setVisibleDialogue(true)
        
    }

    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData.id)} ><i class="fi fi-sr-eye"></i></button>
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive(rowData)} text className={`${rowData?.is_archive ? 'text-teal-500' : 'text-red-500'}`}><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }

    const bodyDate = (rowData)=>{
        return(
            <div>
                {formatDateBon(rowData?.date_ajoute)}
            </div>
        )
    }


    const Souscat_body = (rowData)=>{
        
        return(
            <div>

                {
                    rowData?.sous_cat_document === 'UEMOA' ? (
                        <div className='p-3 bg-orange-400 text-white text-center'>
                            {rowData?.sous_cat_document}
                        </div>
                    ) : (
                        <div>
                            {
                                 rowData?.sous_cat_document === 'CEDEAO' ? (
                                    <div className='p-3 bg-teal-500 text-white text-center'>
                                        {rowData?.sous_cat_document}
                                        </div>
                                 ) : (
                                    <div>
                                        TEXTES NATIONAUX
                                    </div>
                                 )
                            }
                        </div>


                    )
                }

            </div>
        )
    }


    return (
        <div className="pt-12">
            <DataTable value={DataListeDocumentByType} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['titre', 'date_ajoute','nbre_lecture']} header={header} emptyMessage="Aucun document trouvé.">
                <Column field="titre" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

               
                <Column header="Titre du document" field='titre' style={{ minWidth: '9rem' }} />
                <Column header="Date de publications" field='date_ajoute' body={bodyDate} style={{ minWidth: '9rem' }} />
                <Column header="Organisation " field='sous_cat_document' body={Souscat_body} style={{ minWidth: '9rem' }} />
                <Column header="Actions"  body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog>
        </div>

        <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                 <p className="m-0">
                    Vous souhaité archivé le communiqué de presse : <b>{Titre} ?</b>
                </p>
            </Dialog>
        </div>
    )
}

export default DataListeDocuments



