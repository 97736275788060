import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders'
import { InputText } from 'primereact/inputtext'
import { Editor } from "primereact/editor";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { post_Create_CommunePresseActions } from '../../../reduxComponents/Actions/Gestion_CommuniquePActions';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import LoaderComponent from '../../../LoaderComponent';

const CreateCommunique = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [text, setText] = useState('');
    const [Title_pub, setTitle_pub] = useState(null)
    const [cat_cp, setCat_Cp] = useState(null)



    const [loading, setLoading] = useState(false);
    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)

    const DataCreateCommunique = useSelector(state=>state.post_Create_CommuniquePresse)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id


    const [coverImage, setCoverImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(()=>{
            if (DataCreateCommunique?.DataCreateCommunique?.id && loading) {
                setLoading(false)
                setCreateSucces(true)
            }
    }, [DataCreateCommunique?.DataCreateCommunique?.id])

    const options = ['Oui', 'Non'];
    const [is_active_pub, setis_active_pub] = useState(options[0]);

    const SubmitPublications = (event)=>{
        event.preventDefault();
        setLoading(true)

        
        const is_active = is_active_pub === 'Oui' ? true : false
        const body = 
            {
                titre : Title_pub,
                contenu : text,
                image_cover : coverImage,
                is_active : is_active,
                Cat_communique : cat_cp,
                administrateur : admin_id
            }



        
            dispatch(post_Create_CommunePresseActions(body))
    }

    const AllerListeDoc = () => {

        setCreateSucces(false)
        navigate('/admin/actualites/publications')
        
       
    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );

    return (
        <div>
            <LoaderComponent loading={loading} />
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>
                       
                    </div>
                    <div>
                        
                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div class="p-6 bg-white border-b border-gray-200">
                                <div>
                            <h2 className='py-4'><span className='font-bold text-teal-600'>Ajouter un nouveau communiqué de presse</span></h2>
                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="coverImage" className="block font-medium text-gray-700">Photo de couverture <span className=' text-red-500'>*</span> <span className='font-bold text-red-500'>*</span></label>
                                            <input
                                                type="file"
                                                id="coverImage"
                                                accept="image/jpeg, image/jpg, image/png"
                                                onChange={handleImageChange}
                                                className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                            />
                                        </div>
                                        {coverImage && (
                                            <div className="mb-4">
                                                <label className="block font-medium text-gray-700 font-bold">Prévisualisation de la photo de couverture</label>
                                                <img
                                                    src={coverImage}
                                                    alt="Cover Preview"
                                                    className="mt-1 w-full h-96 object-cover border border-gray-300 rounded-md"
                                                />
                                            </div>
                                        )}
                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Titre du communiqué <span className=' text-red-500'>*</span></span> <span className='font-bold text-red-500'>*</span> </label>
                                                <InputText id="username" value={Title_pub} aria-describedby="username-help"  onChange={(e) => setTitle_pub(e.target.value)} />

                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Catégorie communiqué <span className=' text-red-500'>*</span></span> <span className='font-bold text-red-500'>*</span> </label>
                                                <InputText id="username" value={cat_cp} aria-describedby="username-help"  onChange={(e) => setCat_Cp(e.target.value)} />

                                            </div>
                                        </div>

                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Contenu <span className=' text-red-500'>*</span></span></label>
                                                <Editor value={text} onTextChange={(e) => setText(e.htmlValue)} style={{ height: '320px' }} />

                                            </div>
                                        </div>
                                        <div class="mb-4">
                                            <div className="flex flex-column gap-2">
                                                <label htmlFor="username font-bold"><span className='font-bold'>Activer le communiqué <span className=' text-red-500'>*</span></span> </label>
                                                <SelectButton  value={is_active_pub} onChange={(e) => setis_active_pub(e.value)} options={options} />

                                            </div>
                                        </div>


        

                {
                    (Title_pub && text && coverImage && cat_cp) ? (
                        <div class="mb-4">
                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' onClick={(event)=>SubmitPublications(event)} label='Publier le communiqué' type='submit' />
                    </div>

                    ):(
                        <div class="mb-4">
                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' label='Publier le communiqué' type='submit' disabled />
                    </div>

                    )
                }
                                       
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Publication crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default CreateCommunique

