import React, { useEffect, useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import imgaePub from '../../../images/images02.png'
import { useNavigate } from "react-router-dom";

import video01 from '../../../images/video01.mp4'
import { get_GalerieVideo_liste } from '../../../reduxComponents/Actions/Gestion_VideosActions'
import { useDispatch, useSelector } from 'react-redux'
import { BackendEntete } from '../../../VariablesConfig'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'



const GalerieVideo = () => {


  const dispatch = useDispatch()

  const DataListeStore = useSelector(state=>state.get_Liste_GalerieVideo)

  const [DataListe, setDataListe] = useState(null)

  useEffect(()=>{
    
    setDataListe(DataListeStore?.DataListeVideo)
  }, [DataListeStore?.DataListeVideo])

  useEffect(()=>{
    dispatch(get_GalerieVideo_liste())
  },[])

  const navigate = useNavigate()

  const redirect = (idparams)=>{
      const id = btoa(idparams)
      navigate(`/actualites/galeriephoto/${id}`)
  }



  return (
    <>
    <div>
    <Headers PositionParent="Actualités" PositionEnfant="Galerie video" PositionPetitEnfant="" />



    </div>
    <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
      <div>



     <div>
     <section className="">
            <div >
                <div className="mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 md:grid-cols-2">
                        {
                          DataListe && (
                            <>
                              {
                                DataListe.map((item, idx) => (
                                  <li key={idx} onClick={()=>redirect(item.id)} className="cursor-pointer">
                                      <div className="w-full ">
                                      <video className="rounded-lg w-full " controls autoPlay={false}>
                                        <source src={BackendEntete + item.lien_video} type="video/mp4" />
                                    </video>
                                      </div>
                                      <div className="mt-4">
                                          <p className="text-lg text-gray-500 font-semibold text-justify">{item.titre}</p>
                                       
                                         
                                      </div>
                                  </li>
                              ))
                              }
                            </>
                          ) 
                            
                        }
                    </ul>
                </div>
            </div>
        </section>
     </div>




    </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>

     <Reseau_Sociaux />
    <div>
    </div>
    <div>
    <Footers />
    </div>
</>
  )
}

export default GalerieVideo
