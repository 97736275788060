import { Gestion_COMMUNIQUEPRESSE_Types } from "../Types/Gestion_des_CommuniqueType";
import axios from '../axios/axios'
import { configHeadersToken } from './Gestion_Admin_Actions';



export const Get_detail_CommuniqueActions = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_START,
      });
      axios
        .get(`app_commission/communiquepresse/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_DETAILS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

export const get_CommuniquePresse_liste = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_START,
      });
      axios
        .get(`app_commission/communiquepresse/`)
        .then((response) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LISTE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const get_last_communique_actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_START,
      });
      axios
        .get(`app_commission/communiquepresse/lastcommunique`)
        .then((response) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.GET_COMMUNIQUES_LAST_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const post_Create_CommunePresseActions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/communiquepresse/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.CREATE_COMMUNIQUE_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  


  export const put_Archive_CommuniqueActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_START });
      // console.log(body);
      axios
        .put(`app_commission/communiquepresse/archive/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.PUT_ARCHIVE_COMMUNIQUES_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };


  export const put_UpdateCommuniqueActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_START });
      // console.log(body);
      axios
        .put(`app_commission/communiquepresse/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_COMMUNIQUEPRESSE_Types.PUT_UPDATE_COMMUNIQUES_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };
