import React from 'react'
import { useNavigate } from "react-router-dom";
import { formatDateBon } from '../activites/ListeActivites';


const TextesItems = ({ Documents, isNonRegement, DataListeDoc }) => {
    const navigate = useNavigate()

    console.log("rentrerDataListeDoc : ", DataListeDoc)
    const redirection = (id_doc)=>{
        console.log("id_docid_doc : ", id_doc)
        const id= btoa(id_doc)
        navigate(`/textes-reglementaires/doc/${id}`)

    }

    const  truncateText = (text, maxWords) =>{

        const words = text.split(/\s+/);
        if (words.length > maxWords) {
          return words.slice(0, maxWords).join(' ') + '...';
        } else {
          return text;
        }
      }


      const ComponentTronca = ({longText,nbreTexte}) =>{


        const truncatedText = truncateText(longText, nbreTexte); 
      
        return (
          <div>
            {truncatedText}
          </div>
        );
      }
    return (
        <div>
            {
                DataListeDoc && (
                    <div className="relative mt-12 sectionAnime" >
                    <ul className="grid gap-3 sm:grid-cols-2 lg:grid-cols-3 content-between">
                        {
                            DataListeDoc.map((item, idx) => (
                                <li key={idx} className="bg-white  p-4 border rounded-lg grid   content-between">
                                    <div className="text-indigo-600 pb-3 content-between">
                                        {
    
                                            isNonRegement ? (
                                                <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="32" cy="32" r="32" fill="#FFF4E6" />
                                                    <g clip-path="url(#clip0_0_1)">
                                                        <path d="M25.4262 36.5879C25.4262 36.0564 25.0575 35.7395 24.4069 35.7395C24.1412 35.7395 23.9612 35.7657 23.8671 35.7908V37.4959C23.9786 37.521 24.1156 37.5293 24.3044 37.5293C24.9979 37.5293 25.4262 37.179 25.4262 36.5879Z" fill="#E69534" />
                                                        <path d="M29.4539 35.7573C29.1626 35.7573 28.9743 35.7825 28.8629 35.8086V39.5848C28.9744 39.6109 29.1543 39.6109 29.317 39.6109C30.499 39.6193 31.2699 38.9687 31.2699 37.59C31.2782 36.3912 30.5757 35.7573 29.4539 35.7573Z" fill="#E69534" />
                                                        <path d="M36.9372 16H24.0847C22.2071 16 20.6789 17.5292 20.6789 19.4058V31.9999H20.3462C19.5884 31.9999 18.9738 32.6139 18.9738 33.3724V41.6956C18.9738 42.454 19.5883 43.0679 20.3462 43.0679H20.6789V44.5941C20.6789 46.4727 22.2071 47.9999 24.0847 47.9999H41.6214C43.4978 47.9999 45.0262 46.4727 45.0262 44.5941V24.0607L36.9372 16ZM22.5742 34.8745C22.9764 34.8065 23.5418 34.7552 24.3383 34.7552C25.1432 34.7552 25.717 34.9089 26.1024 35.2175C26.4707 35.5084 26.7192 35.9884 26.7192 36.5534C26.7192 37.1182 26.5309 37.5984 26.1883 37.9237C25.7426 38.3431 25.0836 38.5314 24.3127 38.5314C24.1412 38.5314 23.9874 38.5229 23.8671 38.5063V40.5701H22.5742V34.8745ZM41.6214 45.9142H24.0847C23.3577 45.9142 22.7656 45.3221 22.7656 44.5941V43.0679H39.1134C39.8714 43.0679 40.4859 42.454 40.4859 41.6956V33.3724C40.4859 32.6139 39.8714 31.9999 39.1134 31.9999H22.7656V19.4058C22.7656 18.6799 23.3577 18.0878 24.0847 18.0878L36.1569 18.0752V22.5375C36.1569 23.8409 37.2144 24.8995 38.5188 24.8995L42.8901 24.8869L42.9393 44.5941C42.9393 45.3221 42.3483 45.9142 41.6214 45.9142ZM27.5528 40.544V34.8745C28.0324 34.7981 28.6574 34.7552 29.317 34.7552C30.4132 34.7552 31.124 34.9519 31.681 35.3713C32.2804 35.8169 32.6569 36.5272 32.6569 37.547C32.6569 38.6516 32.2547 39.4142 31.6976 39.8848C31.0899 40.3901 30.1647 40.6297 29.0345 40.6297C28.3576 40.6297 27.8781 40.5868 27.5528 40.544ZM36.8996 37.1873V38.2489H34.8269V40.5701H33.5167V34.7981H37.045V35.8682H34.8269V37.1873H36.8996Z" fill="#E69534" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_0_1">
                                                            <rect width="32" height="32" fill="white" transform="translate(16 16)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
    
    
                                            ) : (<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="32" cy="32" r="32" fill="#E6F6F4" />
                                                <g clip-path="url(#clip0_0_1)">
                                                    <path d="M25.4262 36.5879C25.4262 36.0564 25.0575 35.7395 24.4069 35.7395C24.1412 35.7395 23.9612 35.7657 23.8671 35.7908V37.4959C23.9786 37.521 24.1156 37.5293 24.3044 37.5293C24.9979 37.5293 25.4262 37.179 25.4262 36.5879Z" fill="#008774" />
                                                    <path d="M29.4539 35.7573C29.1626 35.7573 28.9743 35.7825 28.8629 35.8086V39.5848C28.9744 39.6109 29.1543 39.6109 29.317 39.6109C30.499 39.6193 31.2699 38.9687 31.2699 37.59C31.2782 36.3912 30.5757 35.7573 29.4539 35.7573Z" fill="#008774" />
                                                    <path d="M36.9372 16H24.0847C22.2071 16 20.6789 17.5292 20.6789 19.4058V31.9999H20.3462C19.5884 31.9999 18.9738 32.6139 18.9738 33.3724V41.6956C18.9738 42.454 19.5883 43.0679 20.3462 43.0679H20.6789V44.5941C20.6789 46.4727 22.2071 47.9999 24.0847 47.9999H41.6214C43.4978 47.9999 45.0262 46.4727 45.0262 44.5941V24.0607L36.9372 16ZM22.5742 34.8745C22.9764 34.8065 23.5418 34.7552 24.3383 34.7552C25.1432 34.7552 25.717 34.9089 26.1024 35.2175C26.4707 35.5084 26.7192 35.9884 26.7192 36.5534C26.7192 37.1182 26.5309 37.5984 26.1883 37.9237C25.7426 38.3431 25.0836 38.5314 24.3127 38.5314C24.1412 38.5314 23.9874 38.5229 23.8671 38.5063V40.5701H22.5742V34.8745ZM41.6214 45.9142H24.0847C23.3577 45.9142 22.7656 45.3221 22.7656 44.5941V43.0679H39.1134C39.8714 43.0679 40.4859 42.454 40.4859 41.6956V33.3724C40.4859 32.6139 39.8714 31.9999 39.1134 31.9999H22.7656V19.4058C22.7656 18.6799 23.3577 18.0878 24.0847 18.0878L36.1569 18.0752V22.5375C36.1569 23.8409 37.2144 24.8995 38.5188 24.8995L42.8901 24.8869L42.9393 44.5941C42.9393 45.3221 42.3483 45.9142 41.6214 45.9142ZM27.5528 40.544V34.8745C28.0324 34.7981 28.6574 34.7552 29.317 34.7552C30.4132 34.7552 31.124 34.9519 31.681 35.3713C32.2804 35.8169 32.6569 36.5272 32.6569 37.547C32.6569 38.6516 32.2547 39.4142 31.6976 39.8848C31.0899 40.3901 30.1647 40.6297 29.0345 40.6297C28.3576 40.6297 27.8781 40.5868 27.5528 40.544ZM36.8996 37.1873V38.2489H34.8269V40.5701H33.5167V34.7981H37.045V35.8682H34.8269V37.1873H36.8996Z" fill="#008774" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_0_1">
                                                        <rect width="32" height="32" fill="white" transform="translate(16 16)" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            )
    
    
                                        }
                                    </div>
                                    <h5 className="text-lg text-gray-800 font-semibold text-justify">
                                         <ComponentTronca longText={item.titre} nbreTexte={15} />
                                    </h5>
    
    
    
                                    <div className='flex'>
                                        <p className='text-xs text-teal-600 px-2 flex gap-1 items-stretch'> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="9" fill="#E6F6F4" />
                                            <path d="M12.56 8.08H5.28C5.126 8.08 5 8.206 5 8.36V12.56C5 13.022 5.378 13.4 5.84 13.4H12C12.462 13.4 12.84 13.022 12.84 12.56V8.36C12.84 8.206 12.714 8.08 12.56 8.08ZM7.52 12C7.52 12.154 7.394 12.28 7.24 12.28H6.68C6.526 12.28 6.4 12.154 6.4 12V11.44C6.4 11.286 6.526 11.16 6.68 11.16H7.24C7.394 11.16 7.52 11.286 7.52 11.44V12ZM9.48 12C9.48 12.154 9.354 12.28 9.2 12.28H8.64C8.486 12.28 8.36 12.154 8.36 12V11.44C8.36 11.286 8.486 11.16 8.64 11.16H9.2C9.354 11.16 9.48 11.286 9.48 11.44V12ZM9.48 10.04C9.48 10.194 9.354 10.32 9.2 10.32H8.64C8.486 10.32 8.36 10.194 8.36 10.04V9.48C8.36 9.326 8.486 9.2 8.64 9.2H9.2C9.354 9.2 9.48 9.326 9.48 9.48V10.04ZM11.44 10.04C11.44 10.194 11.314 10.32 11.16 10.32H10.6C10.446 10.32 10.32 10.194 10.32 10.04V9.48C10.32 9.326 10.446 9.2 10.6 9.2H11.16C11.314 9.2 11.44 9.326 11.44 9.48V10.04ZM12 5.84H11.3V5.56C11.3 5.252 11.048 5 10.74 5C10.432 5 10.18 5.252 10.18 5.56V5.84H7.66V5.56C7.66 5.252 7.408 5 7.1 5C6.792 5 6.54 5.252 6.54 5.56V5.84H5.84C5.378 5.84 5 6.218 5 6.68V6.96C5 7.114 5.126 7.24 5.28 7.24H12.56C12.714 7.24 12.84 7.114 12.84 6.96V6.68C12.84 6.218 12.462 5.84 12 5.84Z" fill="#008774" />
                                        </svg> {formatDateBon(item.date_adoption)}</p>
                                        <p className='text-xs text-teal-600 px-2 flex gap-1 items-stretch'> <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="9" fill="#E6F6F4" />
                                            <path d="M12.56 8.08H5.28C5.126 8.08 5 8.206 5 8.36V12.56C5 13.022 5.378 13.4 5.84 13.4H12C12.462 13.4 12.84 13.022 12.84 12.56V8.36C12.84 8.206 12.714 8.08 12.56 8.08ZM7.52 12C7.52 12.154 7.394 12.28 7.24 12.28H6.68C6.526 12.28 6.4 12.154 6.4 12V11.44C6.4 11.286 6.526 11.16 6.68 11.16H7.24C7.394 11.16 7.52 11.286 7.52 11.44V12ZM9.48 12C9.48 12.154 9.354 12.28 9.2 12.28H8.64C8.486 12.28 8.36 12.154 8.36 12V11.44C8.36 11.286 8.486 11.16 8.64 11.16H9.2C9.354 11.16 9.48 11.286 9.48 11.44V12ZM9.48 10.04C9.48 10.194 9.354 10.32 9.2 10.32H8.64C8.486 10.32 8.36 10.194 8.36 10.04V9.48C8.36 9.326 8.486 9.2 8.64 9.2H9.2C9.354 9.2 9.48 9.326 9.48 9.48V10.04ZM11.44 10.04C11.44 10.194 11.314 10.32 11.16 10.32H10.6C10.446 10.32 10.32 10.194 10.32 10.04V9.48C10.32 9.326 10.446 9.2 10.6 9.2H11.16C11.314 9.2 11.44 9.326 11.44 9.48V10.04ZM12 5.84H11.3V5.56C11.3 5.252 11.048 5 10.74 5C10.432 5 10.18 5.252 10.18 5.56V5.84H7.66V5.56C7.66 5.252 7.408 5 7.1 5C6.792 5 6.54 5.252 6.54 5.56V5.84H5.84C5.378 5.84 5 6.218 5 6.68V6.96C5 7.114 5.126 7.24 5.28 7.24H12.56C12.714 7.24 12.84 7.114 12.84 6.96V6.68C12.84 6.218 12.462 5.84 12 5.84Z" fill="#008774" />
                                        </svg> {item.type_document}</p>
    
                                    </div>
    
                                    <div className="flex-1 flex items-end">
                                        {
                                            isNonRegement ? (<button  onClick={()=>redirection(item.id)} className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-orange-500 hover:bg-orange-600 active:bg-orange-700'>
                                            Consulter
                                        </button>) : (<button  onClick={()=>redirection(item.id)} className='px-3 py-3 rounded-lg w-full font-semibold text-sm duration-150 text-white bg-teal-600 hover:bg-teal-500 active:bg-teal-700'>
                                            Consulter
                                        </button>)
                                        }
                                    </div>
    
                                </li>
                            ))
                        }
                    </ul>
                </div>
                )
            }
          
        </div>
    )
}

export default TextesItems
