import { Gestion_Plainte_Types } from "../Types/TypesPlaintes"; 
import axios from '../axios/axios'
import { configHeadersToken } from "./Gestion_Admin_Actions";


export const post_create_plaintes_actions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_Plainte_Types.CREATE_PLAINTE_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/plaintes/create", body)
        .then((res) => {
          dispatch({
            type: Gestion_Plainte_Types.CREATE_PLAINTE_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
            console.log("err : ", err)
        
          if(err?.response?.status === 400){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Plainte_Types.CREATE_PLAINTE_FAILED,
              payload: "La ressource est introuvable !!!",
            });


        
           
          }
          
          
          if(err?.code === "ERR_NETWORK"){
              console.log("errrororrrrr err network")
              dispatch({
                type: Gestion_Plainte_Types.CREATE_PLAINTE_FAILED,
                payload: "Probleme de reseau",
              });


        
           
          }else{

            
            dispatch({
              type: Gestion_Plainte_Types.CREATE_PARTENAIRES_FAILED,
              payload: err?.response?.data,
            });
            
          }


          
        });
        
  
  
    };
  };

  export const get_plainte_liste = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_Plainte_Types.LISTE_PLAINTE_START,
      });
      axios
        .get(`app_commission/plaintes/listeplainte`, configHeadersToken())
        .then((response) => {
          dispatch({
            type: Gestion_Plainte_Types.LISTE_PLAINTE_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_Plainte_Types.LISTE_PLAINTE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };



export const get_plainte_detailAction = (id) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    dispatch({
      type: Gestion_Plainte_Types.GET_ONE_PLAINTE_START,
    });
    axios
      .get(`app_commission/plaintes/detail/${id}`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: Gestion_Plainte_Types.GET_ONE_PLAINTE_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestion_Plainte_Types.GET_ONE_PLAINTE_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};
