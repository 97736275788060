export const ParametrePagesTypes = {
    CREATE_MOT_DU_PRESIDENT_START : "CREATE_MOT_DU_PRESIDENT_START",
    CREATE_MOT_DU_PRESIDENT_SUCCESS : "CREATE_MOT_DU_PRESIDENT_SUCCESS",
    CREATE_MOT_DU_PRESIDENT_FAILED : "CREATE_MOT_DU_PRESIDENT_FAILED",

    GET_MOT_PRESIDENT_DETAILS_START : " GET_MOT_PRESIDENT_DETAILS_START",
    GET_MOT_PRESIDENT_DETAILS_SUCCESS : "GET_MOT_PRESIDENT_DETAILS_SUCCESS",
    GET_MOT_PRESIDENT_DETAILS_FAILED : "GET_MOT_PRESIDENT_DETAILS_FAILED",

    PUT_UPDATE_MOTDUPRESIDENT_START : " PUT_UPDATE_MOTDUPRESIDENT_START",
    PUT_UPDATE_MOTDUPRESIDENT_SUCCESS : "PUT_UPDATE_MOTDUPRESIDENT_SUCCESS",
    PUT_UPDATE_MOTDUPRESIDENT_FAILED : "PUT_UPDATE_MOTDUPRESIDENT_FAILED",



    CREATE_PARTENAIRES_START : "CREATE_PARTENAIRES_START",
    CREATE_PARTENAIRES_SUCCESS : "CREATE_PARTENAIRES_SUCCESS",
    CREATE_PARTENAIRES_FAILED : "CREATE_PARTENAIRES_FAILED",

    LIST_PARTENAIRES_START : "LIST_PARTENAIRES_START",
    LIST_PARTENAIRES_SUCCESS : "LIST_PARTENAIRES_SUCCESS",
    LIST_PARTENAIRES_FAILED : "LIST_PARTENAIRES_FAILED",

    UPDATE_PARTENAIRES_START : "UPDATE_PARTENAIRES_START",
    UPDATE_PARTENAIRES_SUCCESS : "UPDATE_PARTENAIRES_SUCCESS",
    UPDATE_PARTENAIRES_FAILED : "UPDATE_PARTENAIRES_FAILED",

    ARCHIVE_PARTENAIRES_START : "ARCHIVE_PARTENAIRES_START",
    ARCHIVE_PARTENAIRES_SUCCESS : "ARCHIVE_PARTENAIRES_SUCCESS",
    ARCHIVE_PARTENAIRES_FAILED : "ARCHIVE_PARTENAIRES_FAILED",


    CREATE_STATISTIQUES_VISITE_START : "CREATE_STATISTIQUES_VISITE_START",
    CREATE_STATISTIQUES_VISITE_SUCCESS : "CREATE_STATISTIQUES_VISITE_SUCCESS",
    CREATE_STATISTIQUES_VISITE_FAILED : "CREATE_STATISTIQUES_VISITE_FAILED",




    CREATE_CONTACTS_START : "CREATE_CONTACTS_START",
    CREATE_CONTACTS_SUCCESS : "CREATE_CONTACTS_SUCCESS",
    CREATE_CONTACTS_FAILED : "CREATE_CONTACTS_FAILED",

    LISTE_CONTACTS_START : "LISTE_CONTACTS_START",
    LISTE_CONTACTS_SUCCESS : "LISTE_CONTACTS_SUCCESS",
    LISTE_CONTACTS_FAILED : "LISTE_CONTACTS_FAILED",

    GET_ONE_CONTACTS_START : "GET_ONE_CONTACTS_START",
    GET_ONE_CONTACTS_SUCCESS : "GET_ONE_CONTACTS_SUCCESS",
    GET_ONE_CONTACTS_FAILED : "GET_ONE_CONTACTS_FAILED",



    CREATE_ABON_NEWSLETTERS_START : "CREATE_ABON_NEWSLETTERS_START",
    CREATE_ABON_NEWSLETTERS_SUCCESS : "CREATE_ABON_NEWSLETTERS_SUCCESS",
    CREATE_ABON_NEWSLETTERS_FAILED : "CREATE_ABON_NEWSLETTERS_FAILED",


    GET_LISTE_ABON_NEWSLETTERS_START : "GET_LISTE_ABON_NEWSLETTERS_START",
    GET_LISTE_ABON_NEWSLETTERS_SUCCESS : "GET_LISTE_ABON_NEWSLETTERS_SUCCESS",
    GET_LISTE_ABON_NEWSLETTERS_FAILED : "GET_LISTE_ABON_NEWSLETTERS_FAILED",


    GET_COMPTE_IS_VIEWS_START : "GET_COMPTE_IS_VIEWS_START",
    GET_COMPTE_IS_VIEWS_SUCCESS : "GET_COMPTE_IS_VIEWS_SUCCESS",
    GET_COMPTE_IS_VIEWS_FAILED : "GET_COMPTE_IS_VIEWS_FAILED",


    GET_STATE_DASHBOARD_START : "GET_STATE_DASHBOARD_START",
    GET_STATE_DASHBOARD_SUCCESS : "GET_STATE_DASHBOARD_SUCCESS",
    GET_STATE_DASHBOARD_FAILED : "GET_STATE_DASHBOARD_FAILED",


    GET_STATE_MOIS_START : "GET_STATE_MOIS_START",
    GET_STATE_MOIS_SUCCESS : "GET_STATE_MOIS_SUCCESS",
    GET_STATE_MOIS_FAILED : "GET_STATE_MOIS_FAILED",


    CREATE_CONTENU_NEWSLETTERS_START : "CREATE_CONTENU_NEWSLETTERS_START",
    CREATE_CONTENU_NEWSLETTERS_SUCCESS : "CREATE_CONTENU_NEWSLETTERS_SUCCESS",
    CREATE_CONTENU_NEWSLETTERS_FAILED : "CREATE_CONTENU_NEWSLETTERS_FAILED",

    GET_LISTE_CON_NEWSLETTERS_START : "GET_LISTE_CON_NEWSLETTERS_START",
    GET_LISTE_CON_NEWSLETTERS_SUCCESS : "GET_LISTE_CON_NEWSLETTERS_SUCCESS",
    GET_LISTE_CON_NEWSLETTERS_FAILED : "GET_LISTE_CON_NEWSLETTERS_FAILED",
}
