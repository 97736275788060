import React from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const CommentSaisirCommission = () => {
  return (
    <>
      <div>
        <Headers PositionParent="Fonctionnement" PositionEnfant="COmment saisir la commission ?" PositionPetitEnfant="" />



      </div>

      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 '>
          <div className='mt-8'>
            <div  className=''>
              <span className='font-bold text-3xl  text-teal-500 tailleTitrePratiques18'>COMMENT SAISIR LA COMMISSION DE LA CONCURRENCE ?</span>
            </div>
            <div>
              <p className='text-justify text-gray-600 font-bold pt-2'>
                <p>
                (article 25 du Décret N 2017-411 du 21 juin 2017 portant attribution, organisation, et fonctionnement de la Commission de la Concurrence)                </p>
              </p>
            </div>
          </div>
          <div className='pt-3'>
            <p className='bg-teal-50 p-3 font-bold my-2'>
            Par lettre recommandée avec accusé de réception;
              </p>

              <p className='bg-teal-50 p-3 font-bold my-2'>
              Par dépôt au siège de la Commission de la Concurrence contre remise de récépissé
              </p>

              <p className='bg-teal-50 p-3 font-bold my-2'>
              Par courrier électronique à l’adresse email de la Commission de la Concurrence

              </p>
          </div>

          <div className='pt-4'>
            <p className='bg-orange-400 p-3'>
              <span className='font-bold text-white'>NB : </span>
            </p>
            <p>
              1. La saisine doit être motivée

            </p>
            <p>
              2. la Commission de la Concurrence ne peut pas être saisie des affaires remontant à plus de 10 ans.
            </p>
          </div>
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>
      <Reseau_Sociaux />

      <div>
      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default CommentSaisirCommission
