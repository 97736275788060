
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_plainte_liste } from '../../reduxComponents/Actions/Gestion_Plaintes_Actions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const ListeDataSaisine = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const DataListePLainteStore = useSelector(state=>state?.get_liste_plainte)
    const [DataListePlainte, setDataListePlainte]=useState(null)
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    useEffect(()=>{
        if (DataListePLainteStore?.DataListe_View) {
            let Data = DataListePLainteStore?.DataListe_View
            //const DataLIste = Data.reverse()
            setDataListePlainte(Data)
            setLoading(false)
        }
        
    },[DataListePLainteStore?.DataListe_View])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');



    useEffect(()=>{
        setLoading(true)
        dispatch(get_plainte_liste())
    },[])



    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/parametre/administrateur/create')
    }

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher une plainte" />
                </span>
            </div>
            <div className="flex justify-content-end">
            

            </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (idsend)=>{
        console.log(idsend)
        const idBtoa = btoa(idsend)
        navigate(`/admin/depot-de-plainte/infos_sup/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = ()=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/update-publication/${idBtoa}`, {replace : false})
    }

    const DialogueArchive = ()=>{
        setVisibleDialogue(true)
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData?.id)} ><i class="fi fi-sr-eye"></i></button>
            </div>
        )
    }

    const anonymatBody = (rowData)=>{
        return (
            <div>
                {
                    rowData?.is_have_anonymat ? (
                        <div>
                            Oui
                        </div>
                    ):(
                        <div>
                            Non
                        </div>
                    )
                }
            </div>
        )
    }

    const TelephoneBody = (rowData)=>{
        return (
            <div>
                {
                  rowData?.tel_mobile ? (
                    <div>
                        {rowData?.tel_mobile}
                    </div>
                ):(
                    <div className='text-red'>
                        Non renseigné
                    </div>
                )  
                }
            </div>
        )
    }


    const NomPrenomBody = (rowData)=>{
        return(
            <div>
                {
                    rowData?.is_view ? (
                        <div>
                            {rowData?.nom_prenom}
                        </div>
                    ):(
                        

                    <div className='flex gap-2'>
                    <div className='text-red-500'>
                    <i class="fi fi-sr-bahai"></i>  
                    </div>
                    <div className='font-bold'>
                        {rowData?.nom_prenom}
                    </div>
                    </div>
                    )
                }
            </div>
        )
    }

   

   

    const dateEnvoie = (rowData
    )=>{
        return(
            <div>
                {
                    formatDateBon(rowData?.date_envoi)
                }
            </div>
        )

    }

    const truncateText = (text, maxWords) => {

        const words = text.split(/\s+/);
        if (words.length > maxWords) {
          return words.slice(0, maxWords).join(' ') + '...';
        } else {
          return text;
        }
      }

    const objetPLainte= (rowData)=>{
        return(
            <div>
                {
                    truncateText(rowData?.object_plainte, 25)
                }
            </div>
        )
    }


    return (
        <div className="card">
            <DataTable value={DataListePlainte} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={[
                    'nom_prenom', 'telephone', 'reference_plainte', 'contre_entreprise', 'object_plainte',
                    'adresse_electronique', 'adresse_postale', 'code_postal', 'contre_adresse_postal_entreprise',
                    'contre_entreprise', 'contre_telephone', 'entreprise', 'pourquoi_is_have_anonymat',
                    'pourquoi_is_have_mesures_con', 'qualites_signataire', 'quelles_mesures', 'ste_web',
                    'tel_fix', 'tel_mobile', 'telephone', 'ville',

                ]} header={header} emptyMessage="Aucune plainte trouvée.">
                <Column field="nom_prenom" header="N°" style={{ minWidth: '3rem' }} body={(DataListePlainte, props) => props?.rowIndex + 1} />

               
                <Column header="Nom et Prenom" field='nom_prenom' body={NomPrenomBody} style={{ minWidth: '16rem' }} />
                <Column header="Telephone" field='tel_mobile' body ={TelephoneBody} style={{ minWidth: '9rem' }} />
                <Column header="Plainte contre" field='contre_entreprise' style={{ minWidth: '9rem' }} />
                <Column header="Envoyé le " field='date_envoi' body={dateEnvoie} style={{ minWidth: '9rem' }} />
                <Column header="Object" field='object_plainte' body={objetPLainte} style={{ minWidth: '32rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            {/* <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog> */}
        </div>
        </div>
    )
}

export default ListeDataSaisine





