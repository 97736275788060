import React, { useEffect, useRef, useState } from 'react'
import Headers from '../../headers/Headers'
import SousSections from '../SousSections.jsx/SousSections'
import Footers from '../../footers/Footers'
import image01 from '../../../images/image03.png'
import image02 from '../../../images/images01.png'
import image03 from '../../../images/images02.png'


import { Galleria } from 'primereact/galleria';
import GaleroiePhotoItems from './GaleroPhotoItems'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { get_GaleriePhotoDetailAction } from '../../../reduxComponents/Actions/GaleriePhotoActions'
import { BackendEntete } from '../../../VariablesConfig'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'


const ViewPhotos = () => {

    const params = useParams()
    const params_id = atob(params?.id)

    const [targetRef, setTargetRef] = useState(null)
    const targetRefeffect = useRef(null);

    const dispatch = useDispatch()

    const DataDetailStore = useSelector(state=>state.get_detail_galeriePhotoStore)
  
    const [DataDetail, setDataDetail] = useState()
    const [DataImages, setDataImages] = useState(null)
  
    useEffect(()=>{
      
        if (DataDetailStore?.DataDetailGaleriePhoto?.id) {
            setDataDetail(DataDetailStore?.DataDetailGaleriePhoto)
            setDataImages(DataDetailStore?.DataDetailGaleriePhoto?.autres_photo)
        }
      
    }, [DataDetailStore?.DataDetailGaleriePhoto?.id])
  
    useEffect(()=>{
        setTargetRef(targetRefeffect)
      dispatch(get_GaleriePhotoDetailAction(params_id))
    },[params_id])


    const images = [
        {
            src: image01,
            alt: "commission de la conccurence"
        },
        {
            src: image02,
            alt: "commission de la conccurence"
        },
        {
            src: image03,
            alt: "commission de la conccurence"
        },
        {
            src: image01,
            alt: "commission de la conccurence"
        },
        {
            src: image03,
            alt: "commission de la conccurence"
        },

        {
            src: image02,
            alt: "commission de la conccurence"
        },
        {
            src: image01,
            alt: "commission de la conccurence"
        },
        {
            src: image02,
            alt: "commission de la conccurence"
        },
        {
            src: image03,
            alt: "commission de la conccurence"
        },
        {
            src: image01,
            alt: "commission de la conccurence"
        },
        {
            src: image03,
            alt: "commission de la conccurence"
        },

        {
            src: image02,
            alt: "commission de la conccurence"
        },
    ];

    const responsiveOptions = [
        {
            breakpoint: '991px',
            numVisible: 4
        },
        {
            breakpoint: '767px',
            numVisible: 3
        },
        {
            breakpoint: '575px',
            numVisible: 1
        }
    ];
    const itemTemplate = (item) => {
        return <img src={ BackendEntete + item} alt={DataDetail.titre } style={{ width: '100%' }} />
    }

    const thumbnailTemplate = (item) => {
        return <img src={ BackendEntete + item} alt={DataDetail.titre } />
    }

    const team = [
        {
            avatar: image01,
            id: 2,
            title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


        },
        {
            avatar: image01,
            id: 2,
            title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


        },
        {
            avatar: image01,
            id: 2,
            title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


        }



    ]

    


    return (
        <div ref={targetRef}>
            <>
                <div>
                    <Headers PositionParent="Actualités" PositionEnfant="Galérie Photo" PositionPetitEnfant="" />



                </div>

                <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
                        <div>
                            <div className='pt-4 pb-6'>
                                <span className='font-bold text-1xl text-teal-500'> {DataDetail?.titre} </span>
                            </div>
                        </div>



                            {
                                DataImages && (
                                    <div className='w-full grid justify-items-center pt-6 bg-black'>
                                        <div className="bg-green-500">
                                            <Galleria className='' value={DataImages} responsiveOptions={responsiveOptions} numVisible={5} style={{ maxWidth: '640px' }}
                                                item={itemTemplate} thumbnail={thumbnailTemplate} />
                                        </div>

                                    </div>
                                )
                            }
                        
                        <div>
                   
                            {/* <div>
                                <GaleroiePhotoItems  ListeImageGalerie={team} />

                            </div> */}
                        </div>
                    </div>
                    <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
                        <SousSections />
                    </div>
                </div>

                <Reseau_Sociaux />

            </>
            <div>
                <Footers />
            </div>
        </div>
    )
}

export default ViewPhotos
