export const Gestion_COMMUNIQUEPRESSE_Types = {
    CREATE_COMMUNIQUE_START : "CREATE_COMMUNIQUE_START",
    CREATE_COMMUNIQUE_SUCCESS : "CREATE_COMMUNIQUE_SUCCESS",
    CREATE_COMMUNIQUE_FAILED : "CREATE_COMMUNIQUE_FAILED",

    PUT_UPDATE_COMMUNIQUES_START : " PUT_UPDATE_COMMUNIQUES_START",
    PUT_UPDATE_COMMUNIQUES_SUCCESS : "PUT_UPDATE_COMMUNIQUES_SUCCESS",
    PUT_UPDATE_COMMUNIQUES_FAILED : "PUT_UPDATE_COMMUNIQUES_FAILED",


    GET_COMMUNIQUES_DETAILS_START : " GET_COMMUNIQUES_DETAILS_START",
    GET_COMMUNIQUES_DETAILS_SUCCESS : "GET_COMMUNIQUES_DETAILS_SUCCESS",
    GET_COMMUNIQUES_DETAILS_FAILED : "GET_COMMUNIQUES_DETAILS_FAILED",

    GET_COMMUNIQUES_LISTE_START : " GET_COMMUNIQUES_LISTE_START",
    GET_COMMUNIQUES_LISTE_SUCCESS : "GET_COMMUNIQUES_LISTE_SUCCESS",
    GET_COMMUNIQUES_LISTE_FAILED : "GET_COMMUNIQUES_LISTE_FAILED",


    PUT_ARCHIVE_COMMUNIQUES_START : " PUT_ARCHIVE_COMMUNIQUES_START",
    PUT_ARCHIVE_COMMUNIQUES_SUCCESS : "PUT_ARCHIVE_COMMUNIQUES_SUCCESS",
    PUT_ARCHIVE_COMMUNIQUES_FAILED : "PUT_ARCHIVE_COMMUNIQUES_FAILED",



    GET_COMMUNIQUES_LAST_START : " GET_COMMUNIQUES_LAST_START",
    GET_COMMUNIQUES_LAST_SUCCESS : "GET_COMMUNIQUES_LAST_SUCCESS",
    GET_COMMUNIQUES_LAST_FAILED : "GET_COMMUNIQUES_LAST_FAILED"

    

    
}