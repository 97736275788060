import React, { useEffect, useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import ArticleAccueil from '../SousSections.jsx/ArticleAccueil'
import imgaePub from '../../../images/imagecover.png'
import { TabPanel, TabView } from 'primereact/tabview'
import { useDispatch, useSelector } from 'react-redux'
import { get_Pub_listeEssaie } from '../../../reduxComponents/Actions/EssayeActions'
import { get_CommuniquePresse_liste } from '../../../reduxComponents/Actions/Gestion_CommuniquePActions'
import ArticleCommunique from '../SousSections.jsx/ArticleCOmmunique'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'
import ListeActivites from '../activites/ListeActivites'
import { get_liste_activite_Actions } from '../../../reduxComponents/Actions/Activites_Actions'


const ArchivesArticles = () => {
  const dispatch = useDispatch()
  const DataListeStore = useSelector(state => state.get_publications)
  const DataListeCommunqiueStore = useSelector(state=>state.get_Liste_CommuniquePresse)
  const DataListeActivite = useSelector(state=>state.get_liste_ActionsStore)

  const [DataListe, setDataListe] = useState()
  const [DataListeCommunique, setDataListeCommunique] = useState()
  const [DataListeActiviteArchive, setDataListeActiviteArchive] = useState()

  useEffect(()=>{
    
    if (DataListeCommunqiueStore?.DataListeCPReducers) {
      const activeAndNotArchivedItems = DataListeCommunqiueStore?.DataListeCPReducers.filter(item => item.is_archive);
      setDataListeCommunique(activeAndNotArchivedItems)
    }
   
  }, [DataListeCommunqiueStore?.DataListeCPReducers])


  useEffect(()=>{
        
    if (DataListeActivite?.DataListeActivite) {
      const activeAndNotArchivedItems = DataListeActivite?.DataListeActivite.filter(item => item.is_archive);
      setDataListeActiviteArchive(activeAndNotArchivedItems)
    }
},[DataListeActivite?.DataListeActivite])

  useEffect(() => {

    if (DataListeStore?.DataInfoReducers) {
      const activeAndNotArchivedItems = DataListeStore?.DataInfoReducers.filter(item => item.is_archive);

      setDataListe(activeAndNotArchivedItems)
    }
  }, [DataListeStore?.DataInfoReducers])

  useEffect(() => {
    dispatch(get_CommuniquePresse_liste())
    dispatch(get_Pub_listeEssaie())
    dispatch(get_liste_activite_Actions())
  }, [])


  const PublicationListe = [
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },
    {
      imgaePub: imgaePub,
      title: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus, earum.",
      date: "12 mars 2023",
      id: 1
    },



  ]


  return (
    <>
      <div>
        <Headers PositionParent="Actualités" PositionEnfant="Archives des articles" PositionPetitEnfant="" />



      </div>
      <Reseau_Sociaux />
      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
          <div className='pt-4'>
            <span className='font-bold text-3xl text-teal-600 '>Archives des articles</span>
          </div>

          <div>

          <div className="card mt-4">
            <TabView>
                <TabPanel header="Archive des publications">
                 <ArticleAccueil DataListe={DataListe} />
                </TabPanel>
                <TabPanel header="Archive des communiqués de presse">
                 <ArticleCommunique DataListe={DataListeCommunique} />
                </TabPanel>
                <TabPanel header="Archive des activités">
                {
                      DataListeActiviteArchive && (
                        <ListeActivites DataList={DataListeActiviteArchive} />
                      )
                    }

                </TabPanel>
            </TabView>
        </div>

          </div>

          <div className='pt-4 h-56'>
            <div>
              <ArticleAccueil PublicationListe={PublicationListe} isArchive={true} />
            </div>
          </div>
          <div>

          </div>
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>

      <Reseau_Sociaux />
      <div>
      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default ArchivesArticles
