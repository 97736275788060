import { Gestion_DesVideosTypes } from "../Types/Gestion_Des_VideoTypes";
import { configHeadersToken } from "./Gestion_Admin_Actions";

import axios from '../axios/axios'



export const post_GalerieVideo_Actions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_DesVideosTypes.CREATE_GALVIDEO_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/galerie_video/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_DesVideosTypes.CREATE_GALVIDEO_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_DesVideosTypes.CREATE_GALVIDEO_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_DesVideosTypes.CREATE_GALVIDEO_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };

  export const get_GalerieVideo_liste = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_START,
      });
      axios
        .get(`app_commission/galerie_video/`)
        .then((response) => {
          dispatch({
            type: Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const get_GalerieVideosDetailAction = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_START,
      });
      axios
        .get(`app_commission/galerie_video/detail/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };



  export const put_GallerieVideo_UpdateAction = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_START });
      // console.log(body);
      axios
        .put(`app_commission/galerie_video/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };