export const Gestion_Plainte_Types = {
    

    CREATE_PLAINTE_START : "CREATE_PLAINTE_START",
    CREATE_PLAINTE_SUCCESS : "CREATE_PLAINTE_SUCCESS",
    CREATE_PLAINTE_FAILED : "CREATE_PLAINTE_FAILED",

    LISTE_PLAINTE_START : "LISTE_PLAINTE_START",
    LISTE_PLAINTE_SUCCESS : "LISTE_PLAINTE_SUCCESS",
    LISTE_PLAINTE_FAILED : "LISTE_PLAINTE_FAILED",

    GET_ONE_PLAINTE_START : "GET_ONE_PLAINTE_START",
    GET_ONE_PLAINTE_SUCCESS : "GET_ONE_PLAINTE_SUCCESS",
    GET_ONE_PLAINTE_FAILED : "GET_ONE_PLAINTE_FAILED",

}