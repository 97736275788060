import React from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const QuiPeutSaireCommission = () => {
  return (
    <>
      <div>
        <Headers PositionParent="Fonctionnement" PositionEnfant="Qui peut saisir la Commission ?" PositionPetitEnfant="" />



      </div>
      <Reseau_Sociaux />
      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 '>
          <div>
            <div className='pt-4'>
              <span className='font-bold text-3xl text-teal-500 tailleTitrePratiques'>QUI PEUT SAISIR LA COMMISSION DE LA CONCURRENCE ?</span>
            </div>
            <div>
              <p className='text-justify text-gray-600 pt-2'>
                <p className='font-bold'>
                  (article 24 du Décret N 2017-411 du 21 juin 2017 portant attribution, organisation, et fonctionnement de la Commission de la Concurrence)
                </p>
              </p>
            </div>

            <div className='pt-12'>
              <p className='bg-orange-50 p-3 font-bold my-2'>
                La commission de l’UEMOA ;
              </p>
              <p className='bg-orange-50 p-3 font-bold my-2'>
              Le ministre chargé du commerce ;

              </p>
              <p className='bg-orange-50 p-3 font-bold my-2'>
              Les entreprises ou groupement d’entreprises formellement constitués ;
              </p>
              <p className='bg-orange-50 p-3 font-bold my-2'>
              Les collectivités territoriales, les organisations de consommateurs agréées, les chambres consulaires pour ce qui concerne les intérêts dont elles ont la charge.

              </p>

            </div>

            <div>
              

            </div>
          </div>
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>


      <div>
      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default QuiPeutSaireCommission
