import { essaye_typesListe } from "../Types/EssaieTypes";
import axios from '../axios/axios'
import { configHeadersToken } from "./Gestion_Admin_Actions";

export const get_Pub_listeEssaie = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: essaye_typesListe.GET_ESSAIE_START,
      });
      axios
        .get(`app_commission/publications/`)
        .then((response) => {
          dispatch({
            type: essaye_typesListe.GET_ESSAIE_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: essaye_typesListe.GET_ESSAIE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };