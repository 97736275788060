export const Gestions_Admin_Types = {
    POST_CREATE_ADMIN_START : "POST_CREATE_ADMIN_START",
    POST_CREATE_ADMIN_SUCCESS : "POST_CREATE_ADMIN_SUCCESS",
    POST_CREATE_ADMIN_FAILED : "POST_CREATE_ADMIN_FAILED",

    POST_LOGIN_ADMIN_START : "POST_LOGIN_ADMIN_START",
    POST_LOGIN_ADMIN_SUCCESS : "POST_LOGIN_ADMIN_SUCCESS",
    POST_LOGIN_ADMIN_FAILED : "POST_LOGIN_ADMIN_FAILED",


    GET_LISTE_ADMIN_START : "GET_LISTE_ADMIN_START",
    GET_LISTE_ADMIN_SUCCESS : "GET_LISTE_ADMIN_SUCCESS",
    GET_LISTE_ADMIN_FAILED : "GET_LISTE_ADMIN_FAILED",


    PUT_UPDATE_ADMIN_START : "PUT_UPDATE_ADMIN_START",
    PUT_UPDATE_ADMIN_SUCCESS : "PUT_UPDATE_ADMIN_SUCCESS",
    PUT_UPDATE_ADMIN_FAILED : "PUT_UPDATE_ADMIN_FAILED",


    PUT_UPDATE_USER_CONNECTER_START : "PUT_UPDATE_USER_CONNECTER_START",
    PUT_UPDATE_USER_CONNECTER_SUCCESS : "PUT_UPDATE_USER_CONNECTER_SUCCESS",
    PUT_UPDATE_USER_CONNECTER_FAILED : "PUT_UPDATE_USER_CONNECTER_FAILED",

    GET_INFO_USER_CONNECTE_START : "GET_INFO_USER_CONNECTE_START",
    GET_INFO_USER_CONNECTE_SUCCESS : "GET_INFO_USER_CONNECTE_SUCCESS",
    GET_INFO_USER_CONNECTE_FAILED : "GET_INFO_USER_CONNECTE_FAILED",


    PATHC_DESACTIVER_USER_START : "PATHC_DESACTIVER_USER_START",
    PATHC_DESACTIVER_USER_SUCCESS : "PATHC_DESACTIVER_USER_SUCCESS",
    PATHC_DESACTIVER_USER_FAILED : "PATHC_DESACTIVER_USER_FAILED",
}
