import React from 'react'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import ListeDataSaisine from './ListeDataSaisine'

const SaineComponent = () => {
  return (
    <div>
    <div className='main-container  '>
              <div className='  navbar'>
                  <NavbarAdmin />
              </div>
              <div className='py-2 content'>

                  <div className='py-2'>
                      <NavbarHeaders />
                  </div>
                  <div>
                      <hr />
                  </div>

                  <div>
                      <div className='pt-6 pl-4'>
                          <h1 className='text-slate-500	text-4xl font-bold'> <span className='bg-green-500 p-3 text-white rounded-full mr-3'><i class="fi fi-ss-user-gear"></i></span>Liste des plaintes </h1>
                      </div>
                  </div>

                


                <div className='p-4'>
                    <ListeDataSaisine />
                </div>



              </div>

          </div>
  </div>
  )
}

export default SaineComponent
