import { createChatBotMessage } from 'react-chatbot-kit';
import QuestionsOptions from '../QuestionOPtions/QuestionsOptionsWelcome';
import QuestionsOptionsWelcome from '../QuestionOPtions/QuestionsOptionsWelcome';

const botName = 'Commission de la concurrence'

const Config = {
    
    initialMessages: [
        createChatBotMessage(`Bonjour à vous, je suis le chatbot CC`),
        createChatBotMessage(`bienvenue sur notre site officiel. Pour aider à repondre à vos préoccupations, Chosissez une options ci-dessous `, {widget : "QuestionOptionsWelcome"}), 
    ],
    widgets: [
        {
          widgetName: 'QuestionOptionsWelcome',
          widgetFunc: (props) => <QuestionsOptionsWelcome isQuelWidget="QuestionOptionsWelcome" {...props} />,
        },
        {
            widgetName: 'QuestionSurLaCommission',
            widgetFunc: (props) => <QuestionsOptionsWelcome isQuelWidget="QuestionSurLaCommission" {...props} />,
          },
          {
            widgetName: 'PresidentLaCommission',
            widgetFunc: (props) => <QuestionsOptionsWelcome isQuelWidget="PresidentLaCommission" {...props} />,
          },
      ],


};


export default Config;