import presidentImage from '../../../images/image03.png'

const QuestionsOptionsWelcome = (props)=>{



    


    if (props.isQuelWidget === "QuestionOptionsWelcome") {

        const optionsWelcomes = [
            {
                text : "Q01 : Qu’est-ce que la concurrence déloyale ?" ,
                handler : props.actionProvider.QuestionCommissionWelcome,
                id : 1
            },
            {
                text:"Q02 : Que recouvre la notion de concurrence déloyale ?", handler:props.actionProvider.Question02Response, id : 2,
            },
            {
                text:"Q03 : QuQu’est-ce que le dénigrement ? ",handler: props.actionProvider.Question03Response, id : 3
            }
            ,
            {
                text:"Q04 : Qu’est-ce que l’imitation fautive ? ",handler: props.actionProvider.Question04Response, id : 4
            }
            ,
            {
                text:"Q05 : Qu’est-ce que la désorganisation ? ",handler: props.actionProvider.Question05Response, id : 5
            }
            ,
            {
                text:"Q06 : Qu’est-ce que le parasitisme ?",handler: props.actionProvider.Question06Response, id : 6
            }
            ,
            {
                text:"Q07 : Qu’est-ce qu’une pratique restrictive de la concurrence ? ",handler: props.actionProvider.Question07Response, id : 7
            }
            ,
            {
                text:"Q08 :Les pratiques restrictives de la concurrence sont :",handler: props.actionProvider.Question08Response, id : 8
            },
            {
                text:"Q09 : Sanctions relatives aux pratiques de concurrence déloyale",handler: props.actionProvider.Question09Response, id : 9
            },
            {
                text:"Q10 : Quelle est la durée de traitement d’une plainte ?",handler: props.actionProvider.Question10Response, id : 10
            }
        ]

        const buttonMarkup = (
            <div className="flex-col text-center mb-3">
               { optionsWelcomes.map((option)=>(
            <button key={option.id} onClick={option.handler} className="optionsQuestion-button p-2 rounded-lg border-2 border-teal-60  hover:bg-orange-400 hover:text-white" type="button">
                {option.text}
            </button>
        ))}
            </div>
        )
    
        return <div className="options-container">{buttonMarkup}</div>
    }


    if (props.isQuelWidget === "QuestionSurLaCommission") {
        const options = [
            {
                text : "Q01 : Qui est l'actuel president de la commission de la concurrence ?",
                handler : props.actionProvider.QuestionPresidentCommission,
                id : 1
            },
            {
                text:"Q02 : Ou se trouve le siège de la Commission ?", handler:props.actionProvider.QuestionSiege, id : 2,
            },
            {
                text:"Q03 :EN quelle année la commission à été crée ?",handler: props.actionProvider.QuestionAnneCommission, id : 3
            }
        ]
        const buttonMarkup = (
            <div className="flex-col text-center pb-3">
               { options.map((option)=>(
            <button key={option.id} onClick={option.handler} className="optionsQuestion-button p-2 rounded-lg border-2 border-teal-60 mt-2 hover:bg-orange-400 hover:text-white" type="button">
                {option.text}
            </button>
        ))}
            </div>
        )
    
        return <div className="options-container">{buttonMarkup}</div>
        
    }

    if (props.isQuelWidget === "PresidentLaCommission") {
    
        return <div className="options-container">
            <img src={presidentImage} height={100} alt="Commission de la concurrence"  />
        </div>
        
    }
    

    


    
}

export default QuestionsOptionsWelcome