import {Gestion_Des_Types_Activites} from '../Types/GestionDesActivites'
import axios from '../axios/axios'
import { configHeadersToken } from './Gestion_Admin_Actions';




export const get_liste_By_Cat_activite_Actions = (categorie) => {
 
  return (dispatch) => {
    const token = localStorage.getItem("access_token");
    dispatch({
      type: Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_START,
    });
    axios
      .get(`app_commission/activites/listActivite/${categorie}`)
      .then((response) => {
        dispatch({
          type: Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: Gestion_Des_Types_Activites.GET_LISTE_BY_CAT_ACTIVITES_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};



export const post_create_Activite_Actions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/actvvites/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.POST_CREATE_ACTIVITES_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  export const get_liste_activite_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_START,
      });
      axios
        .get(`app_commission/activites/`)
        .then((response) => {
          dispatch({
            type: Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_Des_Types_Activites.GET_LISTE_ACTIVITES_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const Delete_activite_Actions = (id) => {

    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.DELETE_ACTIVITES_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .delete(`app_commission/activites/delete/${id}`, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.DELETE_ACTIVITES_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.DELETE_ACTIVITES_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.DELETE_ACTIVITES_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  export const get_Detail_activite_Actions = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_START,
      });
      axios
        .get(`app_commission/activites/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_Des_Types_Activites.GET_DETAIL_ACTIVITES_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const put_update_Activites_Actions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.PUT_UPDATE_ACTIVITES_START });
      // console.log(body);
      console.log("idididididididid  : ", id)
      axios
        .put(`app_commission/activites/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.PUT_UPDATE_ACTIVITES_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.PUT_UPDATE_PUBLICATIONS_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.PUT_UPDATE_PUBLICATIONS_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };



  export const post_create_FLashInfos_Actions = (body) => {

    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.CREATE_FLAH_INFO_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/flashinfo/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.CREATE_FLAH_INFO_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.CREATE_FLAH_INFO_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.CREATE_FLAH_INFO_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };



  export const get_List_Flash_Admin_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_START,
      });
      axios
        .get(`app_commission/flashinfo/admin/list`, configHeadersToken())
        .then((response) => {
          dispatch({
            type: Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_Des_Types_Activites.LIST_ADMIN_FLAH_INFO_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const get_List_Flash_Visiteurs_Actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_START,
      });
      axios
        .get(`app_commission/flashinfo/visiteur/list`)
        .then((response) => {
          dispatch({
            type: Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_Des_Types_Activites.LIST_VISITEURS_FLAH_INFO_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };



  export const put_update_Flash_Actions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_START });
      // console.log(body);
      console.log("idididididididid  : ", id)
      axios
        .put(`app_commission/flashinfo/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_SUCCESS,
            payload: res.data
          });
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.UPDATE_FLAH_INFO_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };

  export const put_Archive_Flash_Actions = (id) => {
    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_START });
      // console.log(body);
      axios
        .put(`app_commission/flashinfo/archive/${id}`, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_SUCCESS,
            payload: res.data
          });
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.ARCHIVER_FLAH_INFO_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };



  export const put_archive_activite_actions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_START });
      // console.log(body);
      console.log("idididididididid  : ", id)
      axios
        .put(`app_commission/activites/archive/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_SUCCESS,
            payload: res.data
          });
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_Des_Types_Activites.PUT_ARCHIVE_ACTIVITES_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };


