import { configureStore } from '@reduxjs/toolkit'
import { Get_Pub_Essaie } from './Reducers/EssaieReducers'
import userReducer, { Post_create_admin, Post_login_Admin, ServerErors, get_info_user_connecter_reducers, get_liste_admins_REDUCERS, patch_desactiver_user_reducers, put_info_user_connecter_reducers, put_update_admin_reducers } from './Reducers/Gestion_Admin_Reducers'
import { Get_Detail_DocReducer, Get_DocumentListeReducers, Get_LastDoc_DocReducer, Post_create_adminReducers, Put_Update_DOcumentReducer, get_listDOc_Communautaire } from './Reducers/Gestion_Des_Docs_Reducers'
import { Get_Dernier_Pub_Reducers, Get_Detail_PublicationsReducers, Get_Last_List_CAROUSELS_Reducers, Get_Last_List_Reducers, POst_Create_PublicationsReducers, Put_Update_PublicationReducer, put_archive_pub_reducers } from './Reducers/Gestion_des_Publications'
import { Get_Detail_CommuniquePresseReducers, Get_last_communique_Reducers, Get_liste_CommuniqueReducers, PosteCreateCommuniquePresseReducers, Put_Update_CommuniquePresse, put_Archive_Communique_Reducers } from './Reducers/Gestion_Des_CommuniquePresseReducers'
import { Get_Detail_GaleriePhotoReducers, Get_liste_GaleriePhotoReducers, get_liste_trois_dernier_gal_reducers, PosteCreateGaleriePhotoReducers, Put_Update_GaleriePhotoReducers } from './Reducers/GaleriePhotoReducer'
import { Get_Detail_GalerieVideoReducers, Get_liste_GalerieVideoReducers, PostCreateVideoGalReducers, Put_Update_GalerieVideoReducers } from './Reducers/Gestion_VideosReducers'
import { Create_Abonne_news_Reducers, Create_Contact_Reducers, Create_visite_Reducers, Get_Detail_MotDUPresident_Reducers, Get_List_partenaire_reducers, Get_Liste_Contact_Reducers, Get_One_Contact_Reducers, Liste_Newsletters_Reducers, Post_Create_Partenaire_Reducers, PosteCreateMotPresidentReducers, Put_Archive_Partenaire_Reducers, Put_ModifierMotDUPresidentReducer, Put_Update_Partenaire_Reducers, create_contenu_newsletters_reducers, get_Compte_is_View_Reducers, get_list_contenu_newsletters_reducers, get_stats_dashboard_reducers, get_stats_mois_chart_reducers } from './Reducers/ParametrePagesReducers'
import { Delete_Activite_Reducers, Get_Detail_Activite_Reducers, Get_Liste_Activite, Get_Liste_By_Cat_Reducer, Get_list_Admin_FlashReducers, Get_list_Visiteur_Reducers, Post_Create_Activite_Reducers, Post_Create_Flash_Info, Put_Archive_FlashInfo, Put_UPdate_FlashInfo, Put_Update_Activites, put_Archive_Activite_reducers } from './Reducers/GestionDesActivitesReducers'
import { get_One_Plainte_Reducers, get_liste_Plainte_Reducers, post_Create_Plainte_Reducers } from './Reducers/Gestion_Plaintes_Reducers'

export const store = configureStore({
  reducer: {

    createAdminPost: Post_create_admin,
    get_publications : Get_Pub_Essaie,
    loginAdmin_acces : Post_login_Admin,
    get_liste_admin : get_liste_admins_REDUCERS,
    update_admin_store : put_update_admin_reducers,
    get_info_user_connecter_store : get_info_user_connecter_reducers,
    put_info_user_connecter_store : put_info_user_connecter_reducers,
    patch_desactiver_user_store : patch_desactiver_user_reducers,

    Data_DocumentCreate : Post_create_adminReducers,
    get_liste_docBY_Type : Get_DocumentListeReducers,
    get_doc_detail :  Get_Detail_DocReducer,
    put_UpdateDOc : Put_Update_DOcumentReducer,
    get_lastDOcStore : Get_LastDoc_DocReducer,
    get_listCommunautaireSTore : get_listDOc_Communautaire,


    user: userReducer,

    EerrorServerStore : ServerErors,



    get_carrouselsImage : Get_Last_List_CAROUSELS_Reducers,

    createPublication : POst_Create_PublicationsReducers,
    get_detail_publications : Get_Detail_PublicationsReducers,
    update_pub : Put_Update_PublicationReducer,
    Last_LIstSotre : Get_Last_List_Reducers,
    dernier_pub_store : Get_Dernier_Pub_Reducers,
    Archive_APub_store : put_archive_pub_reducers,

    get_Liste_CommuniquePresse : Get_liste_CommuniqueReducers,
    post_Create_CommuniquePresse : PosteCreateCommuniquePresseReducers,
    get_Detail_CommuniqueStore : Get_Detail_CommuniquePresseReducers,
    put_UpdateCommuniqueStore : Put_Update_CommuniquePresse,
    put_archive_store : put_Archive_Communique_Reducers,
    lat_communique_store : Get_last_communique_Reducers,

    getListeGaleriephoto : Get_liste_GaleriePhotoReducers,
    postCreateGaleriePhoto : PosteCreateGaleriePhotoReducers,
    get_detail_galeriePhotoStore : Get_Detail_GaleriePhotoReducers,
    put_update_DataGaleriePhoto : Put_Update_GaleriePhotoReducers,
    get_liste_trois_dernier_gal : get_liste_trois_dernier_gal_reducers,


    postCreateVideoStore : PostCreateVideoGalReducers,
    get_Liste_GalerieVideo : Get_liste_GalerieVideoReducers,
    get_Details_GalerieVideo : Get_Detail_GalerieVideoReducers,
    put_Update_GalerieVideo : Put_Update_GalerieVideoReducers,


    put_Create_MotDuPresidentStore : PosteCreateMotPresidentReducers,
    get_Detail_MotDuPresident : Get_Detail_MotDUPresident_Reducers,
    update_Mot_Du_president : Put_ModifierMotDUPresidentReducer,


    post_create_activite_Store : Post_Create_Activite_Reducers,
    get_liste_ActionsStore : Get_Liste_Activite,
    get_Detail_Activite_Store : Get_Detail_Activite_Reducers,
    put_update_activie_Store : Put_Update_Activites,
    ListeActiviteByCat_Store : Get_Liste_By_Cat_Reducer,
    DeleteActivite_Store : Delete_Activite_Reducers,
    archive_activite_store : put_Archive_Activite_reducers,


    create_contenu_newsletters_store : create_contenu_newsletters_reducers,
    get_list_contenu_newsletters_store :  get_list_contenu_newsletters_reducers,

    CreateFlashInfo : Post_Create_Flash_Info,
    ListeAdminFlashStore : Get_list_Admin_FlashReducers,
    list_Visiteur_Flash_Store : Get_list_Visiteur_Reducers,
    update_flash_infi_Store : Put_UPdate_FlashInfo,
    archive_flash_info_store : Put_Archive_FlashInfo,


    Create_Partenaire_Store : Post_Create_Partenaire_Reducers,
    List_partenaires_Store : Get_List_partenaire_reducers,
    Update_Partenaire_Store : Put_Update_Partenaire_Reducers,
    put_Archive_partenaire_store : Put_Archive_Partenaire_Reducers,

    state_dashboard_store : get_stats_dashboard_reducers,
    get_state_mois_chart_store : get_stats_mois_chart_reducers,



    Post_Create_contact_store :  Create_Contact_Reducers,
    get_one_contact_store : Get_One_Contact_Reducers,
    get_List_Contact_store : Get_Liste_Contact_Reducers,

    get_one_Plainte : get_One_Plainte_Reducers,
    get_liste_plainte : get_liste_Plainte_Reducers,
    post_create_plainte : post_Create_Plainte_Reducers,


    create_news_Abon_newsletters : Create_Abonne_news_Reducers,
    liste_newsletters_store : Liste_Newsletters_Reducers,
    visite_store : Create_visite_Reducers,


    get_compte_view_Store : get_Compte_is_View_Reducers,
  }
})