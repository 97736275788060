
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { useDispatch, useSelector } from 'react-redux';
import { get_state_mois_chart_actions } from '../../../reduxComponents/Actions/ParametrePagesActions';
import LoaderComponent from '../../../LoaderComponent';

export default function ChartDashboard() {
    const dispatch = useDispatch()
    const [chartData, setChartData] = useState({});
    const DataStateChartMois_Store = useSelector(state=>state?.get_state_mois_chart_store)
    const [DataStateMois, setDataStateMois] = useState(null)
    const [chartOptions, setChartOptions] = useState({});
    const [loading, setLoading]=useState(false)
    useEffect(()=>{
        setLoading(true)
        dispatch(get_state_mois_chart_actions())
    },[])

    useEffect(()=>{
        if (DataStateChartMois_Store?.DataChartMois && loading) {
            setDataStateMois(DataStateChartMois_Store?.DataChartMois)
            setLoading(false)
        }
    },[DataStateChartMois_Store?.DataChartMois])

    useEffect(() => {
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        const data = {
            labels: DataStateMois?.labels,
            datasets: [
                {
                    type: 'bar',
                    label: 'Evolution du nombre de visite par mois (6 derniers mois)',
                    backgroundColor: documentStyle.getPropertyValue('--orange-500'),
                    data: DataStateMois?.visiteurs
                },
                {
                    type: 'bar',
                    label: 'Nombre de plainte par mois (6 derniers mois)',
                    backgroundColor: documentStyle.getPropertyValue('--green-500'),
                    borderWidth: 2,
                    fill: false,
                    tension: 0.4,
                    data: DataStateMois?.plaintes
                },
                // {
                //     type: 'bar',
                //     label: 'Dataset 2',
                //     backgroundColor: documentStyle.getPropertyValue('--green-500'),
                //     data: [21, 84, 24, 75, 37, 65, 34],
                //     borderColor: 'white',
                //     borderWidth: 2
                // },
                
            ]
        };
        const options = {
            maintainAspectRatio: false,
            aspectRatio: 0.6,
            plugins: {
                legend: {
                    labels: {
                        color: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder
                    }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [DataStateMois]);
    
    return (
        <div className="card">
            <LoaderComponent loading={loading} />
            <Chart type="line" data={chartData} options={chartOptions} />
        </div>
    )
}
        
