import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SaisineBtnActions from '../SousSections.jsx/SaisineBtnActions'
import Articles from '../SousSections.jsx/DerniersPub'
import SousSections from '../SousSections.jsx/SousSections'
import ImagePresident from '../../../images/images02.png'
import { useDispatch, useSelector } from 'react-redux'
import { get_DetailMot_Du_PresidentAction } from '../../../reduxComponents/Actions/ParametrePagesActions'
import { BackendEntete } from '../../../VariablesConfig'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const Mot_du_President = () => {

    const dispatch = useDispatch()
    const ContenuHTML = ({ contenu }) => {
      return <div dangerouslySetInnerHTML={{ __html: contenu }} />;
    }

    const DataDetailStore = useSelector(state=>state.get_Detail_MotDuPresident)
  
    const [DataDetail, setDataDetail] = useState()
  
    useEffect(()=>{
      
        if (DataDetailStore?.DataDetailMotPresident?.id) {
            setDataDetail(DataDetailStore?.DataDetailMotPresident)
        }
      
    }, [DataDetailStore?.DataDetailMotPresident?.id])
  
    useEffect(()=>{
      dispatch(get_DetailMot_Du_PresidentAction(1))
    },[])
  return (
    <div>
      <>
     <div >
     <Headers PositionParent="Présentation" PositionEnfant="Mot du président" PositionPetitEnfant="" />


        
     </div>

     <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime' >
                <div className='col-span-1 lg:col-span-9 '>
        <div>
        <div className='pt-4'>
          <span className='font-bold text-3xl text-teal-500'>Mot de Madame la presidente</span>
        </div>
        <div className='Content-text'>
          <div className="imageMotPresident">
            <img  src={BackendEntete + DataDetail?.image_cover} className='max-h-96 pt-3 rounded-lg ImageContentTest' alt="Commission de la conccurence" />
          </div>
          <div className="text-box">
            <p className='text-justify'>
               
                <ContenuHTML contenu={DataDetail?.mot_president} />
              </p>
          </div>
        </div>
        </div>
      </div>
      <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
        <SousSections />
      </div>
     </div>
     <Reseau_Sociaux />

     <div>
    <Footers />
    </div>
      </>
    </div>
  )
}

export default Mot_du_President
