import React, { useRef, useState } from 'react'
import Headers from '../../headers/Headers'
import SousSections from '../SousSections.jsx/SousSections'
import Footers from '../../footers/Footers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import { Divider } from 'primereact/divider'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'
import conccurence01 from '../../../images/concurrence01.png'
import conccurence02 from '../../../images/concurrence02.png'
import conccurence03 from '../../../images/concurrence03.png'
import conccurence04 from '../../../images/concurrence04.png'
import conccurence05 from '../../../images/concurrence05.png'
import conccurence06 from '../../../images/auction.png'
import conccurence07 from '../../../images/balanced.png'




const PratiquesReprimes = () => {


    const PratiquesFaq = (props) => {
        const handleOpenAnswer = () => {
            const answerElH = answerElRef.current.childNodes[0].offsetHeight
            setState(!state)
            setAnswerH(`${answerElH + 20}px`)
        }

        const answerElRef = useRef()
        const [state, setState] = useState(false)
        const [answerH, setAnswerH] = useState('0px')
        const { faqsList, idx } = props

        return (
            <div
                className="space-y-3 mt-2 overflow-hidden border-b"
                key={idx}
                onClick={handleOpenAnswer}
            >
                <h4 className="cursor-pointer pb-2 flex items-center justify-between  text-gray-700 ">
                    <p className='font-bold text-gray-500'>{faqsList.q}</p>
                    {
                        state ? (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
                            </svg>
                        ) : (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-500 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                            </svg>
                        )
                    }
                </h4>
                <div
                    ref={answerElRef} className="duration-300"
                    style={state ? { height: answerH } : { height: '0px' }}
                >
                    <div>
                        <p className="text-gray-500">
                            {faqsList.a}
                        </p>
                    </div>
                </div>
            </div>
        )

    }

    const PratiqueslistesPart01 = [
        {
            q: <div className='flex gap-5 items-center'>
                <div>
                   <img src={conccurence01} width={48} alt="CONCURRENCE DELOYALE"  />
                </div>
                <div>CONCURRENCE DELOYALE</div>
            </div>,
            a: <>
                <div>
                    <div className='bg-teal-600 my-3 text-white p-3'>
                        <p> <span className='font-bold'>
                            (article 23 de l’ORDONNANCE 2013-662 du 20 septembre 2013 relative à la concurrence)
                        </span></p>
                    </div>
                    <div>
                        <p className='text-gray-700 text-justify'>
                            la commission de la concurrence et de la lutte contre la vie chère est compétente pour connaitre des pratiques de concurrence déloyale, notamment :

                        </p>

                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold '>{'- '}</span>
                            de tous faits quelconques ayant pour objet ou pour effet de désorganiser tout ou partie substantielle d’un marché ou de la nature à créer une confusion ou une tromperie par n’importe quel moyen, tels que l’imitation de signe distinctifs, noms, appellations, dominations enseignes, emblèmes, marques, dessins ou modèles industriels de produit, service ou d’activités industrielle ou commercial d’un concurrent.
                        </p>
                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold '>{'- '}</span>
                            des allégations fausses dans l’exercice du commerce de nature à discréditer l’établissement, les produits, les services ou les activités industrielles ou commerciale d’un concurrent.
                        </p>

                    </div>
                </div>
                <div>

                </div>

            </>
        },
        {
            q: <div className='flex gap-5 items-center'>
                <div>
                <img src={conccurence02}  width={48} alt="PRATIQUES RESTRICTIVES CONSTITUTIVES DE FAUTE PENALE"  />
                </div>
                <div>PRATIQUES RESTRICTIVES CONSTITUTIVES DE FAUTE PENALE</div>
            </div>,
            a: <>
                <div>

                    <div className='bg-teal-600 my-3 text-white p-3'>
                        <p> <span className='font-bold'>
                            La vente à perte (article 15 de l’ORDONNANCE 2013-662 du 20 septembre 2013 relative à la concurrence)
                        </span></p>
                    </div>
                    <Divider />
                    <p className='text-gray-700 text-justify gap-3 flex bg-teal-50 text-teal-600 font-bold p-3'>
                        Dans la vente à perte, le prix d’achat effectif est présumé être le prix porté sur la facture. Il est déterminé comme suit :
                    </p>

                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        majoration faite des impositions et taxes afférentes audit achat ;
                    </p>
                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        déduction faite des rabais et remises de toute nature consentis par le fournisseur au montant de facturation.

                    </p>

                    <Divider />

                    <p className='text-gray-700 text-justify gap-3 flex bg-teal-50 text-teal-600 font-bold p-3'>
                        Interdiction de la vente à perte ne s’applique pas aux opérations qui ne sont pas faite dans l’intention de  limiter la concurrence, il s’agit notamment :
                    </p>

                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte des produits périssable menacés d’altération rapide ;

                    </p>
                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte des produit dont le commerce présente un caractère saisonnier marqué lorsque la vente a lieu soit pendant la période terminal de la saison , soit entre deux saisons de vente ;

                    </p>
                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte des produits qui ne répondent plus à la demande générale en raison de l’évolution de la mode et de l’apparition de perfectionnement techniques ;
                    </p>
                    <p className='text-gray-700 text-justify gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte des produits dont le réapprovisionnement s’est effectué en baises ; le prix effectif d’achat est alors remplacer par le prix résultant, soit de la nouvelle facture d’achat, soit de la valeur de réapprovisionnement ;
                    </p>

                    <p className='text-gray-700 text-justify  gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte volontaire ou force à la suite cessation ou de changement d’activité ;                    </p>

                    <p className='text-gray-700 text-justify  gap-3 flex'>
                        <span className='font-bold'><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <g clip-path="url(#clip0_669_24188)">
                                <path d="M23.1731 8.69988C22.6442 8.82045 22.3188 9.3393 22.4408 9.86181C22.6183 10.6182 22.7071 11.4001 22.7071 12.1857C22.7071 14.9224 21.6272 17.4984 19.6672 19.435C17.7071 21.3715 15.1036 22.4384 12.3299 22.4384C9.55621 22.4384 6.95266 21.3715 4.9926 19.435C3.03254 17.4984 1.96006 14.9261 1.96006 12.1857C1.96006 9.44527 3.03994 6.87294 5 4.93639C6.96006 2.99983 9.56361 1.93655 12.3336 1.93655C13.8683 1.93655 15.3476 2.26175 16.7271 2.89752C17.2189 3.12406 17.7996 2.91579 18.0288 2.42983C18.2581 1.94386 18.0473 1.3702 17.5555 1.14366C15.9172 0.383657 14.1605 0 12.3336 0C10.6694 0 9.05326 0.321541 7.53328 0.957315C6.06509 1.57117 4.74482 2.45175 3.61317 3.56984C2.48151 4.68792 1.59024 5.99235 0.968935 7.44294C0.325444 8.94469 0 10.5414 0 12.1857C0 13.8299 0.325444 15.4267 0.968935 16.9284C1.59024 18.379 2.47781 19.6834 3.61317 20.8015C4.74852 21.9196 6.06509 22.8002 7.53328 23.414C9.05326 24.0498 10.6694 24.3713 12.3336 24.3713C13.9978 24.3713 15.6139 24.0498 17.1339 23.414C18.6021 22.8002 19.9223 21.9232 21.054 20.8015C22.1857 19.6834 23.0769 18.379 23.6982 16.9284C24.3417 15.4267 24.6672 13.8299 24.6672 12.1857C24.6672 11.2539 24.5599 10.3259 24.3491 9.42334C24.2271 8.90449 23.7019 8.5793 23.1731 8.69988Z" fill="#007F6D" />
                                <path d="M9.39739 9.96772L5.86189 10.0079L5.65479 10.9068C9.48245 12.5035 12.7702 19.6688 12.7702 19.6688C18.9684 8.32348 25.0002 2.19958 25.0002 2.19958H21.4647C18.177 4.12883 12.5594 12.5547 12.5594 12.5547C12.2672 11.685 9.39739 9.96772 9.39739 9.96772Z" fill="#007F6D" />
                            </g>
                            <defs>
                                <clipPath id="clip0_669_24188">
                                    <rect width="25" height="24.375" fill="white" />
                                </clipPath>
                            </defs>
                        </svg></span>
                        de la vente à la perte résultant de la vente soldes, liquidation ainsi que des autres formes de vente équivalente.


                    </p>





                    <Divider />

                    <div className='bg-red-600 my-3 text-white p-3'>
                        <p> <span className='font-bold'>
                            Sont interdits (article 16 de l’ORDONNANCE 2013-662 du 20 septembre 2013 relative à la concurrence)
                        </span></p>
                    </div>

                    <div>

                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            imposition de prix ;

                        </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            les ventes à prime à l’exclusion de celle relative aux menus objets ou aux services de faible valeur ainsi qu’aux échantillons ;
                        </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            le refus de vente et les ventes subordonnées ;

                        </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            les ventes par le procédé dit de <b> « la boule de neige » ;   </b>
                        </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            les pratiques de non- respect de la réglementation du commerce extérieur.
                        </p>
                    </div>

                    <Divider />

                    <div className='bg-red-800 my-3 text-white p-3'>
                        <p> <span className='font-bold'>
                            Sont considéré comme pratique de non-respect de la réglementation du commerce extérieur (article 17 de l’ORDONNANCE 2013-662 du 20 septembre 2013 relative à la concurrence):
                        </span></p>
                    </div>
                    <div>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            l’importance, l’exportation ou la réexportation sans titre ou sans déclaration en douane des biens et produit soumis à ses régimes ;                    </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            l’importance, l’exportation ou la réexportation de marchandises en violation de la réglementions sur le contrôle des marchandises avant expédition ;                    </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            la détention en vue de la vente ou la distribution à titre gratuit desdits biens, produit en marchandises ;                    </p>
                        <p className='text-gray-700 text-justify  gap-3 flex'>
                            <span className='font-bold'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.5C23 18.299 18.2988 23 12.5 23C6.70117 23 2 18.299 2 12.5C2 6.70105 6.70117 2 12.5 2C18.2988 2 23 6.70105 23 12.5ZM25 12.5C25 19.4036 19.4033 25 12.5 25C5.59668 25 0 19.4036 0 12.5C0 5.59644 5.59668 0 12.5 0C19.4033 0 25 5.59644 25 12.5ZM17.1577 16.6757L13.2183 13.5293L9.05859 17.5L7.67773 16.0533L11.6411 12.2698L7 8.56274L8.24805 7L13.1011 10.8762L17.0811 7.07703L18.4624 8.52368L14.6782 12.1357L18.4058 15.1129L17.1577 16.6757Z" fill="#EE4343" />
                                </svg></span>
                            toute falsification pratiquée sur les documents d’importation, d’exportation ou de réexportation ;
                        </p>

                        <p text-gray-700 text-justify>
                            Toute forme de cessions de titre d’importation, d’exportation ou de réexportation.
                        </p>
                    </div>
                    <Divider />
                    <div className='bg-red-800 my-3 text-white p-3'>
                        <p> <span className='font-bold text-justify'>
                            Sont prohibées des ventes sauvage ou para commercialisme (article 19 de l’ORDONNANCE 2013-662 du 20 septembre 2013 relative à la concurrence)
                        </span></p>
                    </div>

                </div>
                <div>

                </div>

            </>
        },
        {
            q: <div className='flex gap-5 items-center'>
                <div>
                <img src={conccurence03}  width={48} alt="PRATIQUES RESTRICTIVES CONSTITUTIVES DE FAUTE CIVILE"  />
                </div>
                <div>PRATIQUES RESTRICTIVES CONSTITUTIVES DE FAUTE CIVILE</div>
            </div>,
            a: <>
                <div>
                    <div>
                        <p className='text-gray-700 text-justify'>Engage la responsabilité de son auteur et l’oblige à réparer le préjudice causé, le fait, pour tout producteurs commençant, industriel ou artisan :</p>
                    </div>
                    <div>
                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold '>{'- '}</span>
                            De pratiquer à l’égard d’un partenaire économique ou obtenir de lui, des prix, délais de paiement, conditions de vente ou de modalité de vente et d’achat discriminatoire ou non justifier par des contreparties réels en créant, de ce fait, pour ce partenaire, un désavantage de la concurrence ;
                        </p>
                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold '>{'- '}</span>
                            De  refuser de satisfait aux demande d’achat de biens, de produit ou aux demande de prestations de services lorsque ces demande ne présentent aucun caractère anormal et qu’elles sont faite de bonnes fois ;                        </p>
                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold '>{'- '}</span>
                            De subordonner la vente d’un produit ou la prestation d’un service soit à l’achat concomitant d’autres biens ou produit, soit à l’achat d’une qualité imposée, soit à la prestation d’un autre service.                        </p>

                    </div>
                </div>
                <div>
                </div>

            </>
        },





    ]

 

    const PratiqueslistesPart02 = [
        {
            q: <div className='flex gap-5 items-center'>
                <div>
                <img src={conccurence04}  width={48} alt=" ENTENTES ILLICITES"  />

                </div>
                <div>
                    ENTENTES ANTICONCURRENTIELLES
                </div>
            </div>,
            a: <>
                <div>
                    <p>
                        <i className='font-bold'> (Article 3 du RÈGLEMENT N°2/2002/CM/UEMOA du 23 mai 2002 sur les pratiques commerciales anticoncurrentielles)</i>
                    </p>

                    <p className='text-gray-700 text-justify'>
                        Ententes illicites sont incompatibles avec le Marché Commun et interdits, tous accords entre entreprises, décisions d'associations d'entreprises et pratiques concertées entre entreprises, ayant pour objet ou pour effet de restreindre ou de fausser le jeu de la concurrence à l'intérieur de l'Union, et notamment ceux qui consistent en :

                    </p>

                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>a{') '}</span> <span> des accords limitant l'accès au marché ou le libre exercice de la concurrence par d'autres entreprises ;  </span>
                    </p>
                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>b{') '}</span> <span> des accords visant à fixer directement ou indirectement le prix, à contrôler le prix de vente, et de manière générale, à faire obstacle à la fixation des prix par le libre jeu du marché en favorisant artificiellement leur hausse ou leur baisse ; en particulier des accords entre entreprises à différents niveaux de production ou de distribution visant à la fixation du prix de revente ;  </span>
                    </p>
                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>c{') '}</span> <span>  des répartitions des marchés ou des sources d'approvisionnement, en particulier des accords entre entreprises de production ou de distribution portant sur une protection territoriale absolue ;   </span>
                    </p>
                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>d{') '}</span> <span>   des limitations ou des contrôles de la production, des débouchés, du développement technique ou des investissements ; </span>
                    </p>
                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>e{') '}</span> <span>   des discriminations entre partenaires commerciaux au moyen de conditions inégales pour des prestations équivalentes ; </span>
                    </p>
                    <p className='text-gray-700 text-justify'>
                        <span className='font-bold'>f{') '}</span> <span>   des subordinations de la conclusion des contrats à l'acceptation, par les partenaires, de prestations supplémentaires, qui, par leur nature ou selon les usages commerciaux, n'ont pas de lien avec l'objet de ces contrats. </span>
                    </p>
                </div>


            </>
        },
        {
            q: <>
                <div className='flex gap-5 items-center'>
                    <div>
                    <img src={conccurence05}  width={48} alt=" ABUS DE POSITION DOMINANTE"  />
                    </div>
                    <div>ABUS DE POSITION DOMINANTES / LES CONCENTRATIONS</div>

                </div>
            </>,
            a: <>
                <div>
                    <div>
                        <p> <span className='font-bold'> (Article 4 du RÈGLEMENT N°2/2002/CM/UEMOA du 23 mai 2002 sur les pratiques commerciales anticoncurrentielles)</span></p>
                    </div>
                    <div>
                        <p className='text-gray-700 text-justify'>
                            <span className='font-bold'> 4.1 : </span>
                            <span>
                                Est incompatible avec le Marché Commun et interdit, le fait pour une ou plusieurs entreprises d'exploiter de façon abusive une position dominante sur le Marché Commun ou dans une partie significative de celui-ci.
                            </span>
                            <p>
                                Sont frappées de la même interdiction, les pratiques assimilables à l'exploitation abusive d'une position dominante, mises en œuvre par une ou plusieurs entreprises. Constituent une pratique assimilable à un abus de position dominante les opérations de concentration qui créent ou renforcent une position dominante, détenue par une ou plusieurs entreprises, ayant comme conséquence d'entraver de manière significative une concurrence effective à l'intérieur du Marché Commun.

                            </p>
                        </p>

                        <div>
                            <p className='bg-teal-500 text-white p-3 my-2 font-bold'>
                                <span className='font-bold '> 4.1 : </span>
                                <span>
                                    Les pratiques abusives peuvent notamment consister à :
                                </span>
                            </p>


                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      a) "}</span>
                                imposer de façon directe ou indirecte des prix d'achat ou de vente ou d'autres conditions de transactions non équitables ;

                            </p>
                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      b) "}</span>
                                limiter la production, les débouchés ou le développement technique au préjudice des consommateurs ;
                            </p>
                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      c) "}</span>
                                appliquer à l'égard de partenaires commerciaux des conditions inégales à des prestations équivalentes, en leur infligeant de ce fait un désavantage dans la concurrence ;
                            </p>
                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      d) "}</span>
                                subordonner la conclusion de contrats à l'acceptation, par les partenaires, de prestations supplémentaires, qui, par leur nature ou selon les usages commerciaux, n'ont pas de lien avec l'objet de ces contrats.
                            </p>

                        </div>

                        <Divider />

                        <div>
                            <p className='bg-teal-500 text-white p-3 my-2 font-bold'>
                                <span className='font-bold '> 4.2 : </span>
                                <span>
                                    Constituent une concentration au sens de l'article 4.1 alinéa 2 du présent Règlement :
                                </span>
                            </p>


                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      a) "}</span>
                                la fusion entre deux ou plusieurs entreprises antérieurement indépendantes ;
                            </p>
                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      b) "}</span>
                                l'opération par laquelle :  une ou plusieurs personnes détenant déjà le contrôle d'une entreprise au moins, ou  une ou plusieurs entreprises, acquièrent directement ou indirectement, que ce soit par prise de participations au capital ou achat d'éléments d'actifs, contrat ou tout autre moyen, le contrôle de l'ensemble ou de parties d'une ou de plusieurs autres entreprises ;                            </p>
                            <p className='text-gray-700 text-justify'>
                                <span className='font-bold'>{"      c) "}</span>
                                la création d'une entreprise commune accomplissant de manière durable toutes les fonctions d'une entité économique autonome.
                            </p>


                        </div>

                    </div>

                </div>
                <div>

                </div>

            </>
        },
        {
            q: <>
                <div className='flex gap-5 items-center'>
                    <div>
                    <img src={conccurence06}  width={48} alt=" ABUS DE POSITION DOMINANTE"  />
                    </div>
                    <div>AIDE D'ETAT</div>

                </div>
            </>,
            a: <>
                <div>
                    <div>
                    </div>
                    <div>
                        <p className='text-gray-700 text-justify'>
                        Par application des dispositions de l’article 88(c) du Traité de l’UEMOA, sont incompatibles avec le Marché Commun et interdites, les aides accordées par les États ou au moyen de ressources d’État sous quelque forme que ce soit, lorsqu’elles faussent ou sont susceptibles de fausser la concurrence en favorisant certaines entreprises ou certaines productions. Les dispositions du présent article sont précisées par voie de Règlement du Conseil des Ministres.
                        </p>

                      

                        <Divider />

                      
                    </div>

                </div>
                <div>

                </div>

            </>
        },
        {
            q: <>
                <div className='flex gap-5 items-center'>
                    <div>
                    <img src={conccurence07}  width={48} alt=" ABUS DE POSITION DOMINANTE"  />
                    </div>
                    <div>PRATIQUES ANTICONCURRENTIELLES IMPUTABLES AU ETATS MEMBRES</div>

                </div>
            </>,
            a: <>
                <div>
                  
                    <div>
                        {/* <p className='text-gray-700 text-justify'>
                            <span className='font-bold'> 4.1 : </span>
                            <span>
                                Est incompatible avec le Marché Commun et interdit, le fait pour une ou plusieurs entreprises d'exploiter de façon abusive une position dominante sur le Marché Commun ou dans une partie significative de celui-ci.
                            </span>
                            <p>
                                Sont frappées de la même interdiction, les pratiques assimilables à l'exploitation abusive d'une position dominante, mises en œuvre par une ou plusieurs entreprises. Constituent une pratique assimilable à un abus de position dominante les opérations de concentration qui créent ou renforcent une position dominante, détenue par une ou plusieurs entreprises, ayant comme conséquence d'entraver de manière significative une concurrence effective à l'intérieur du Marché Commun.

                            </p>
                        </p> */}

                        <div>
                            <p className='text-gray-700 p-3 my-2 text-justify'>
                                <span className='font-bold '> 6.1 : </span>
                                <span>
                                En application des dispositions des articles 4(a), 7 et 76 (c)du Traité de l’UEMOA, les Etats membres s’abstiennent de toutes mesures susceptibles de faire obstacle à l’application du présent Règlement et des textes subséquents. Ils s’interdisent notamment d’édicter ou de maintenir, en ce qui concerne les entreprises publiques et les entreprises auxquelles ils accordent des droits spéciaux et exclusifs, quelque mesure contraire aux règles et principes prévus à l’article 88 paragraphes (a) et (b) du Traité de l’Union. Les Etats membres s’interdisent en outre, d’édicter des mesures permettant aux entreprises privées de se soustraire aux contraintes imposées par l’article 88 paragraphes (a) et (b) du Traité de l’UEMOA.                                
                                </span>
                            </p>
                            <p className='text-gray-700 p-3 my-2  text-justify'>
                                <span className='font-bold '> 6.2 : </span>
                                <span>
                                Les entreprises chargées de la gestion de services d’intérêt économique général ou présentant le caractère d’un monopole fiscal sont soumises aux règles du Traité relatives à la concurrence. Cependant, dans l’hypothèse où l’application de ces règles fait échec à l’accomplissement en droit ou en fait de la mission particulière qui leur a été impartie, la Commission, conformément à l’article 89 alinéa 3 du Traité de l’UEMOA, peut octroyer des exemptions à l’application de l’article 88 (a) et le cas échéant, de l’article 88 (b) du Traité. Afin de bénéficier des exemptions prévues au paragraphe précédent, les parties intéressées et/ou les Etats membres auxquels elles sont rattachées doivent notifier la pratique à la Commission dans les conditions arrêtées, par voie de Règlement, par le Conseil des Ministres.                                </span>
                            </p>

                            <p className='text-gray-700 p-3 my-2 text-justify'>
                                <span className='font-bold '> 6.3 : </span>
                                <span>
                                La Commission veille à l’application des dispositions du présent article. Elle adresse aux Etats membres, au Conseil des Ministres de l’UEMOA, ainsi qu’aux autres institutions de l’Union, des avis et recommandations relatifs à tous projets de législation nationale ou communautaire susceptibles d’affecter la concurrence à l’intérieur de l’Union, en proposant les modifications opportunes.                        </span>
                            </p>

                            <p className='text-gray-700 p-3 my-2 text-justify'>
                                <span className='font-bold '> 6.4 : </span>
                                <span>
                                Si l’État membre concerné ne se conforme pas à une décision, la Commission peut saisir la Cour de Justice de l’UEMOA, conformément aux articles 5 et 6 du Protocole Additionnel N°1 du Traité.

Cet extrait concerne les projets de législation susceptibles d’affecter la concurrence au sein de l’Union. Il mentionne également la possibilité de modifications proposées et les mesures à prendre si un État membre ne se conforme pas à une décision.

                                </span>
                            </p>




                        </div>

                        <Divider />

                       
                    </div>

                </div>
                <div>

                </div>

            </>
        },



       

    ]






    return (
        <>
            <div className='mt-4'>
                <Headers PositionParent="Fonctionnement" PositionEnfant="Pratiques réprimées" PositionPetitEnfant="" />



            </div>
            <Reseau_Sociaux />
            <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
                    <div className='pt-4'>
                        <span className='font-bold text-3xl text-teal-500 text tailleTitrePratiques'>PRATIQUES REPRIMEES  PAR LA COMMISSION DE LA CONCURRENCE</span>
                    </div>

                    <div className='pt-8 ml-8'>
                        

                        <div>

                            <section className="  mt-2  ">

                                <div className="mt-12  ">
                                    {
                                        PratiqueslistesPart01.map((item, idx) => (
                                            <PratiquesFaq
                                                idx={idx}
                                                faqsList={item}
                                            />
                                        ))
                                    }
                                </div>
                            </section>

                        </div>
                    </div>





                    <div className='mt-12 ml-8'>

                    <div className='pt-4'>
                        <span className='font-bold text-3xl text-teal-500 text tailleTitrePratiques'>PRATIQUES REPRIMEES  PAR LA COMMISSION DE LA CONCURRENCE</span>
                    </div>
                      
                        {/* <div className='font-bold bg-teal-500 text-center  p-3'>
                            <span className='font-bold text-2xl text-center   text-white w-full  '>
                            PRATIQUES REPRIMEES EN COLLABORATION AVEC LA CEDEAO ET UEMOA
                            </span>
                        </div> */}

                        <p className='pt-4 text-gray-700 text-justify'>
                        Conformément au règlement N°02/2002/CM/UEMOA relatif aux pratiques anticoncurrentielles à l'intérieur de l'Union Économique et Monétaire Ouest Africaine, les pratiques suivantes sont réprimées :
                        </p>

                        <div>

                            <section className="  mt-2  ">

                                <div className="mt-12  ">
                                    {
                                        PratiqueslistesPart02.map((item, idx) => (
                                            <PratiquesFaq
                                                idx={idx}
                                                faqsList={item}
                                            />
                                        ))
                                    }
                                </div>
                            </section>

                        </div>
                    </div>










                    <div>

                    </div>
                </div>

                <div className=' col-span-1 md:col-span-3 flex-col gap-5 py-4'>
                    <SousSections />
                </div>
            </div>


            <div>
            </div>
            <div>
                <Footers />
            </div>
        </>
    )
}

export default PratiquesReprimes
