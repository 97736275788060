import React, { useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import FormSaisine from './FormSaisine'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const FormulaireSaisine = () => {

  





  
  return (
    <>
      <div>
        <Headers PositionParent="Fonctionnement" PositionEnfant="Saisine" PositionPetitEnfant="" />



      </div>

      <Reseau_Sociaux />
      <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
        <div className='col-span-1 lg:col-span-9 pt-8'>
          <div>
            <div className=''>
              <span className='font-bold text-3xl  text-teal-500 '>FORMULAIRE DE DEPOT DE PLAINTE</span>
            </div>
          </div>

            <div className='mt-4'>
              <FormSaisine />
            </div>

         
        </div>
        <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
          <SousSections />
        </div>
      </div>


      <div>
      </div>
      <div>
        <Footers />
      </div>
    </>
  )
}

export default FormulaireSaisine
