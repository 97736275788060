import React from 'react'
import Headers from '../../headers/Headers'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import { Timeline } from 'primereact/timeline'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Divider } from 'primereact/divider'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const Historiques = () => {

  const events = [
    { status: 'La première sous période de 1978-1991', date: 'A- De 1978 à 2013', icon: 'pi pi-shopping-cart', color: '#007F6D', 
      content :  'C’est la loi n°78-633 du 28 juillet 1978 relative aux prix, à la poursuite et à la répression des infractions à la législation économique a introduit des dispositions nouvelles.',
      content2 : 'Elles concernent notamment la promotion  de la concurrence par l’interdiction des ententes et des abus de position dominante, la création de la commission technique des ententes. '},
    { status: 'La deuxième sous période 1991 à 2013', date: 'A- De 1978 à 2013', icon: 'pi pi-cog', color: '#007F6D',
      content :  "L'année 1991 est marquée par l'avènement de la loi n°91-999 du 27 décembre 1991 relative à la concurrence.",
      content2 : "Cette loi consacre la Commission de la Concurrence, dont l'organisation et le fonctionnement sont régis par le décret n°96-288 du 3 avril 1996.",
      content3 : "Toutefois, il convient de préciser que cette institution, qui était purement administrative, n'émettait que des avis. Ces avis étaient transmis au Ministre en charge du Commerce pour la prise de décision.",
      content4: "Toutefois, il convient de préciser qu'elle n'émettait que des avis, lesquels étaient transmis au Ministre en charge du Commerce pour la prise de décision.",


  },


  
    
];



const customizedMarker = (item) => {
  return (
      <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-1" style={{ backgroundColor: item.color, height:'25px', width:'25px', borderRadius:'50%' }}>
          
      </span>
  );
};

const customizedContent = (item) => {
  return (
      <Card title={item.status}  className='text-justify'>
          <p>{item?.content}</p>
          <p>{item?.content2}</p>
          <p>{item?.content3}</p>
          <p>{item?.content4}</p>
      </Card>
  );
};

  return (
    <div>
      <>
        <div>
          <Headers PositionParent="Présentation" PositionEnfant="Historique" PositionPetitEnfant="" />



        </div>

        <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
            <div>
              <div className='pt-6'>
                <div>
                  <h4 className="text-xl text-gray-800  font-extrabold md:text-3xl">
                    <span className='bg-teal-600 text-white font-bold p-1'>Historique</span>
                  </h4>
                  {/* <h2 className="text-4xl text-gray-800 font-extrabold md:text-4xl pt-2">
                    <span className='bg-orange-400	 text-white font-bold p-1'>DE LA CONCCURENCE</span>
                  </h2> */}
                </div>
                <div className='mt-4'>
                <div className="">
                  <div>
                    <p>
                    {/* Deux  périodes sont à prendre en compte dans l’histoire de Commission de la Concurrence en Côte d’Ivoire. Celle allant de 1978 à 2013 et de celle qui part de 2013 à nos jours */}

                    Dès son accession à l'indépendance, la Côte d'Ivoire a opté pour une économie libérale. Celle-ci suppose que le marché s'autorégule.                    </p>

                    <p>
                    Toutefois, de 1960 à 1978, elle s'est caractérisée par une mainmise de l'État sur l'activité économique en raison de la fragilité du tissu économique.
                    </p>

                    <p>
                    C'est à partir de 1978 que l'État met en place les prémices d'une véritable concurrence économique.                    </p>

                    <p>
                    Deux périodes sont à prendre en compte dans l'histoire de la Commission de la Concurrence en Côte d'Ivoire : celle allant de 1978 à 2013 (composée de deux sous-périodes) et celle qui part de 2013 à nos jours.                    </p>

                  </div>
                  <div>
                    <div>
                      <h4><span className='font-bold text-orange-500'>A- De 1978 à 2013</span></h4>
                    </div>
                    <Divider />
                    <div className='cacheElement'>
                     <Timeline value={events} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                      
                    </div>
                    <div className='AfficheElement '>
                      <Card className='mb-3' title="La période de 1978 à 1991">
                          <p className="m-0">
                          C’est la loi n°78-633 du 28 juillet 1978 relative aux prix, à la poursuite et à la répression des infractions à la législation économique a introduit des dispositions nouvelles.
                          </p>
                          <p>
                          Elles concernent notamment la promotion  de la concurrence par l’interdiction des ententes et des abus de position dominante, la création de la commission technique des ententes.
                          </p>
                      </Card>


                      <Card title="La période de 1991 à 2013">
                          <p className="m-0">
                          En 1991, nous avons l’avènement de la loi n°91-999 du 27 décembre 1991 relative à la concurrence. Cette loi consacre l’avènement de la CC et le décret n°96-288 du 03 avril 1996 en précise l’organisation et fonctionnement. </p>
                          <p>
                          Toutefois, il convient de préciser que n’émettait que des Avis, lesquels Avis étaient transmis au Ministre en charge du Commerce pour la prise de Décision.                        </p>
                      </Card>
                                        </div>
                  </div>

                  <br /> <br />
                  

                  <div className='mt-4'>
                    <div>
                      <h4><span className='font-bold text-orange-500'>B- La période de 2013 à nos jours</span></h4>
                    </div>
                    <Divider />
                    <div>
                    <div   className='text-justify'>
                        <p>
                          <p>
                            <b>
                           
                            L'année 2013 est marquée par l'adoption de l'ordonnance n°2013-662 du 20 septembre 2013 relative à la concurrence, ratifiée par la loi n°2013-877 du 23 décembre 2013.
                            
                            </b>
                           
                          </p>
                        Cette loi renforce les pouvoirs de la Commission de la Concurrence en faisant une autorité administrative indépendante.
                        </p>
                    </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
            <SousSections />
          </div>
        </div>
        <Reseau_Sociaux />

        <div>
          <Footers />
        </div>
      </>
    </div>
  )
}

export default Historiques
