
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_liste_newsletters_action } from '../../reduxComponents/Actions/ParametrePagesActions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const ListeNewslleters_abonne = () => {


    const dispatch = useDispatch()
    const DataNewsStore = useSelector(state=>state?.liste_newsletters_store)
    const [DataListNews, setDataListNews] = useState(null)
    const [loading, setLoading] = useState(false);
  






    useEffect(()=>{
        setLoading(true)
        dispatch(get_liste_newsletters_action())
    },[])

    useEffect(()=>{

        if (DataNewsStore?.DataListNewsletters) {
           
            setDataListNews(DataNewsStore?.DataListNewsletters)
            setLoading(false)
        }

    },[DataNewsStore?.DataListNewsletters])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

   
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };



    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un abonné" />
                </span>
            </div>
          
            </div>
        );
    };








    const header = renderHeader();



    const bodyDate= (rowData)=>{
        return(
            <div>
                {
                    formatDateBon(rowData?.date_abonnement)
                }
            </div>
        )
    }


    return (
        <div className="card m-4">
            <DataTable value={DataListNews} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['email', 'date_abonnement']} header={header} emptyMessage="Aucun abonné trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataListNews, props) => props?.rowIndex + 1} />

                <Column field="email" header="Email"  style={{ minWidth: '16rem' }} />
                <Column header="Abonnément effectué le : " field='date_abonnement' body={bodyDate} style={{ minWidth: '16rem' }} />
                
                


            </DataTable>
        </div>
    )
}

export default ListeNewslleters_abonne
