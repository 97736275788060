import { Gestion_GALERIEPHOTO_Types } from "../Types/GaleriePhoto_Types";

import axios from '../axios/axios'
import { configHeadersToken } from './Gestion_Admin_Actions';



export const get_GaleriePhoto_liste = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_START,
      });
      axios
        .get(`app_commission/galerie_photo/`)
        .then((response) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_SUCCESS,
            payload: response?.data, 
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


export const post_GaleriePhoto_Actions = (body) => {

    return (dispatch) => { 
      dispatch({ type: Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/galerie_photo/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: Gestion_GALERIEPHOTO_Types.CREATE_GALPHOTO_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };

export const get_GaleriePhotoDetailAction = (id) => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_START,
      });
      axios
        .get(`app_commission/galerie_photo/detail/${id}`)
        .then((response) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_DETAILS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };

  export const get_liste_trois_dernier_gal_actions = () => {
 
    return (dispatch) => {
      const token = localStorage.getItem("access_token");
      dispatch({
        type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_START,
      });
      axios
        .get(`app_commission/galerie_photo/lastphotos/`)
        .then((response) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.GET_GALPHOTOS_LISTE_TROIS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const put_UpdateGaleriePhotoActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_START });
      // console.log(body);
      axios
        .put(`app_commission/galerie_photo/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: Gestion_GALERIEPHOTO_Types.PUT_UPDATE_GALPHOTOS_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };

