
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_List_Flash_Admin_Actions, post_create_FLashInfos_Actions, put_Archive_Flash_Actions, put_update_Flash_Actions } from '../../../reduxComponents/Actions/Activites_Actions';
import { formatDateBon } from '../../../app_Visiteurs/sections/activites/ListeActivites';
import LoaderComponent from '../../../LoaderComponent';

const DataListFLashInfo = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const DataListFlash = useSelector(state => state.ListeAdminFlashStore)
    const DataCreateFlash = useSelector(state=>state.CreateFlashInfo)
    const DataUpdateFlash = useSelector(state=>state.update_flash_infi_Store)
    const DataArchiveFLash = useSelector(state=>state.archive_flash_info_store)
    const admin_id = useSelector((state) => state.user.user_id);

    const [DataList, setDataList] = useState()

    const [params_id, setParams_id]=useState(null)

    const [isArchive, setisArchive] = useState(false)

    useEffect(() => {
        dispatch(get_List_Flash_Admin_Actions())
    }, [])

    useEffect(()=>{
        if (DataCreateFlash?.DataCreateFlashInfo?.id && loading) {
            dispatch(get_List_Flash_Admin_Actions())
            SetLibelle(null)
            setCat_Libelle(null)
            setIsCreateNewFLash(false)
            setLoading(false)

        }

    }, [DataCreateFlash?.DataCreateFlashInfo])

    useEffect(()=>{
        if (DataUpdateFlash?.DalaListUpdateFlash?.id && loading) {
            dispatch(get_List_Flash_Admin_Actions())
            SetLibelle(null)
            setCat_Libelle(null)
            setIsUpdate (false)
            setLoading(false)
        }
    },[DataUpdateFlash?.DalaListUpdateFlash])

    useEffect(()=>{
        if (DataArchiveFLash?.DataLIsteArchiveFlash?.success && loading) {
            dispatch(get_List_Flash_Admin_Actions())
            SetLibelle(null)
            setCat_Libelle(null)
            setisArchive (false)
            setLoading(false)
        }

    },[DataArchiveFLash?.DataLIsteArchiveFlash])

    const [IsCreateNewFLash, setIsCreateNewFLash] = useState(false)
    const [Libelle, SetLibelle] = useState(null)
    const [Cat_Libelle, setCat_Libelle] = useState(null)

    const [errors, setErrors] = useState({})



    useEffect(() => {

        if (DataListFlash?.DataFlashInfoAdmin) {
            
            setDataList(DataListFlash?.DataFlashInfoAdmin)
        }
    }, [DataListFlash?.DataFlashInfoAdmin])
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [IsUpdate, setIsUpdate] = useState(false)





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = () => {
        navigate('/admin/parametre/administrateur/create')
    } 

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un flash" />
                    </span>
                </div>
                <div className="flex justify-content-end">
                    <Button label="Créer un FLash Info" className='border-none btnBacground01' onClick={() => setIsCreateNewFLash(true)} />

                </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />

            </div>
        )
    }
    const RedirectTo = () => {
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/publications/${idBtoa}`, { replace: false })
    }

    const RedirectToUpdate = (rowData) => {

        setCat_Libelle(rowData?.cat_flash)
        SetLibelle(rowData?.libelle)
        setParams_id(rowData?.id)
        setIsUpdate(true)
        
        
    }

    const DialogueArchive = (rowData) => {
        
        SetLibelle(rowData?.libelle)
        setParams_id(rowData?.id)
        setisArchive(true)
    }


    const ActionsBody = (rowData) => {
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={() => RedirectToUpdate(rowData)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={() => DialogueArchive(rowData)} text className='text-red-500'><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }


    const bodyDate = (rowData) => {
        return (
            <div>
                {
                    formatDateBon(rowData?.date_add)
                }
            </div>
        )
    }
    const StatutFLash = (rowData) => {
        return (
            <div>
                {
                    rowData?.is_archive ? (
                        <div className='py-2 bg-red-500 text-white text-center rounded-lg'>
                            Archivé
                        </div>
                    ) : (
                        <div className='py-2 bg-teal-500 text-white text-center rounded-lg' >
                            Non archivé
                        </div>
                    )
                }
            </div>
        )
    }

    

    const CreateNewFLash = (event) => {
        event.preventDefault()
        

        const newErrors = {};

        if (!Libelle) newErrors.Libelle = true;
        if (!Cat_Libelle) newErrors.Cat_Libelle = true;


        let body



        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            body = {
                libelle: Libelle,
                cat_flash: Cat_Libelle,
                administrateur : admin_id            }


            dispatch(post_create_FLashInfos_Actions(body))

        }

    }


    const ModifierNewFLash = (event) => {
        event.preventDefault()
        

        const newErrors = {};

        if (!Libelle) newErrors.Libelle = true;
        if (!Cat_Libelle) newErrors.Cat_Libelle = true;


        let body



        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setLoading(true)
            body = {
                libelle: Libelle,
                cat_flash: Cat_Libelle,
                administrateur : admin_id
            }


            dispatch(put_update_Flash_Actions(params_id,body))

        }

    }


    const headerElementArchive = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    

    const footerContentArchive = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setisArchive(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver" icon="pi pi-check" onClick={() => ArchiveFLash()} autoFocus />
        </div>
    );

    const ArchiveFLash = ()=>{
        setLoading(true)
        dispatch(put_Archive_Flash_Actions(params_id))
    }


    return (
        <div className="card">
            <LoaderComponent loading={loading} />
            <DataTable value={DataList} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['name', 'username', 'representative.name', 'status']} header={header} emptyMessage="Aucun utilisateur trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />


                <Column header="Libelle" field='libelle' style={{ minWidth: '16rem' }} />
                <Column header="Catégorie" field='cat_flash' style={{ minWidth: '9rem' }} />
                {/* <Column header="Contact" field='contact' style={{ minWidth: '9rem' }} /> */}
                <Column header="Ajouté le" field='date_add' body={bodyDate} style={{ minWidth: '9rem' }} />
                <Column header="Statut" field='is_archive' body={StatutFLash} style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">

                <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                    <p className="m-0">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                </Dialog>
            </div>

            <Dialog header="Creation d'un flash info" visible={IsCreateNewFLash} style={{ width: '50vw' }} onHide={() => {
                setIsCreateNewFLash(false)
            }}>
              
                    <div className="inputForm w-full">
                        <div>
                            <div className=" flex justify-content-center  w-full ">
                                <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">
                                    <div> <label htmlFor="Nom">FLash info</label></div>
                                    <div><InputText value={Libelle} className={errors.Libelle ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => SetLibelle(e.target.value)} aria-describedby="Nom-help" /></div>
                                </div>

                            </div>
                            <div className=" flex justify-content-center  w-full ">
                                <div className="flex flex-column gap-2  inputFormulaire p-3 w-full">
                                    <div> <label htmlFor="Username">Catégorie Flash infos</label></div>
                                    <div>
                                        <div><InputText value={Cat_Libelle} className={errors.Cat_Libelle ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => setCat_Libelle(e.target.value)} aria-describedby="Nom-help" /></div>

                                    </div>

                                </div>


                               

                            </div>
                            <div className=" flex justify-content-center  w-full ">
                                    <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">

                                        <button className='btnDegrad' onClick={(event) => CreateNewFLash(event)} type="button">Créer une nouvelle localité</button>

                                    </div>

                                </div>
                        </div>
                    </div>
            </Dialog>


            <Dialog header="Modification du flash info" visible={IsUpdate} style={{ width: '50vw' }} onHide={() => {
                setIsUpdate(false)
            }}>
              
                    <div className="inputForm w-full">
                        <div>
                            <div className=" flex justify-content-center  w-full ">
                                <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">
                                    <div> <label htmlFor="Nom">FLash info</label></div>
                                    <div><InputText value={Libelle} className={errors.Libelle ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => SetLibelle(e.target.value)} aria-describedby="Nom-help" /></div>
                                </div>

                            </div>
                            <div className=" flex justify-content-center  w-full ">
                                <div className="flex flex-column gap-2  inputFormulaire p-3 w-full">
                                    <div> <label htmlFor="Username">Catégorie Flash infos</label></div>
                                    <div>
                                        <div><InputText value={Cat_Libelle} className={errors.Cat_Libelle ? 'border-2 border-red-500 w-full' : 'w-full'} id="Libelle" onChange={(e) => setCat_Libelle(e.target.value)} aria-describedby="Nom-help" /></div>

                                    </div>

                                </div>


                               

                            </div>
                            <div className=" flex justify-content-center  w-full ">
                                    <div className="flex flex-column gap-2  inputFormulaire p-3 w-full ">

                                        <button className='btnDegrad' onClick={(event) => ModifierNewFLash(event)} type="button">Modifier le flash info</button>

                                    </div>

                                </div>
                        </div>
                    </div>
            </Dialog>


            <div className="card flex justify-content-center">
           
            <Dialog visible={isArchive} modal header={headerElementArchive} footer={footerContentArchive} style={{ width: '30rem' }} onHide={() => setisArchive(false)}>
                <div className='text-center'>
                    <p className="m-0">
                    Vous souhaité archivé le flash info <b> <i>{Libelle}</i></b>
                    </p>
                    <p className='font-bold text-red-500'>
                        <i>
                            Cette action est irreversible
                        </i>
                    </p>
                </div>
            </Dialog>
        </div>

        </div>
    )
}

export default DataListFLashInfo




