import React, { useEffect, useState } from 'react'
import PagesEnCOnstructions from '../../PagesEnCOnstructions'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import SousSections from '../SousSections.jsx/SousSections'
import { useDispatch, useSelector } from 'react-redux'
import { get_Detail_activite_Actions, get_liste_By_Cat_activite_Actions } from '../../../reduxComponents/Actions/Activites_Actions'
import ListeActivites, { ComponentTronca, formatDateBon } from './ListeActivites'
import { useParams } from 'react-router-dom'
import Reseau_Sociaux from '../Accueil/SectionsAccueil/Reseau_Sociaux'

const VoirActivite = () => {


    const dispatch = useDispatch()

    const params = useParams()
    const params_id = atob(params?.id)

    const DataDetailActivie = useSelector(state => state.get_Detail_Activite_Store)
    const [DataDetail, setDataDetail] = useState()

    const ContenuHTML = ({ contenu }) => {
        return <div dangerouslySetInnerHTML={{ __html: contenu }} />;
    }

    useEffect(() => {

        dispatch(get_Detail_activite_Actions(params_id))

    }, [params_id])

    useEffect(() => {

        if (DataDetailActivie?.DataDetailActivite) {
            setDataDetail(DataDetailActivie?.DataDetailActivite)
        }

    }, [DataDetailActivie?.DataDetailActivite])

    return (
        <>

            <div>
                <Headers PositionParent="Activités" PositionEnfant="Activité" PositionPetitEnfant="" />


            </div>


            <div className='marginContinaierWeb flex  grid grid-cols-1 lg:grid-cols-12 gap-4 sectionAnime'>
                <div className='col-span-1 lg:col-span-9 '>
                    <div className='py-8'>
                        <span className='font-bold text-3xl text-teal-600'></span>
                    </div>
                    <div class="mt-6">
                        <div className="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md">
                            <div className="flex items-center justify-between"><span className="font-light text-gray-600">
                                {formatDateBon(DataDetail?.date_activite)}

                            </span>
                                <span>
                                    <div className='ml-3'>
                                        <span className='font-bold'> <span className='text-orange-400 font-bold'>Lieu : </span> {DataDetail?.lieux_activite} </span>
                                    </div>
                                </span>

                                <span
                                    className="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">{DataDetail?.categorie_activite}</span>
                            </div>
                            <div className="mt-2 text-center py-4">
                                <span className="text-2xl font-bold text-teal-500 hover:underline">
                                {DataDetail?.titre}
                            </span>
                                <p className="mt-2 text-gray-600 text-justify">



                                    <ContenuHTML contenu={DataDetail?.contenu} />
                                </p>
                            </div>

                        </div>
                    </div>


                    <div>
                    </div>
                </div>
                <div className='col-span-1 md:col-span-3 flex-col gap-5 py-4'>
                    <SousSections />
                </div>
            </div>

            <Reseau_Sociaux />
            <div>
                <Footers />
            </div>
        </>
    )
}

export default VoirActivite


