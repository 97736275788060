import { ParametrePagesTypes } from "../Types/ParametrePagesTypes";
import axios from '../axios/axios'
import { configHeadersToken } from './Gestion_Admin_Actions';



export const post_create_partanires_actions = (body) => {

  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.CREATE_PARTENAIRES_START });
    const token = localStorage.getItem("token_access");

   
    // console.log(body);
    axios
      .post("app_commission/partenaires/create/", body, configHeadersToken())
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.CREATE_PARTENAIRES_SUCCESS,
          payload: res.data
        });
        console.log("conenxion reussir res.data : ", res.data)
      })
      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.CREATE_PARTENAIRES_FAILED,
            payload: "La ressource est introuvable !!!",
          });
         
        }else{
          dispatch({
            type: ParametrePagesTypes.CREATE_PARTENAIRES_FAILED,
            payload: err?.response?.data,
          });
          
        }
        
      });
      


  };
};

export const put_archive_partenaires_actions = (id) => {
  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.ARCHIVE_PARTENAIRES_START });
    // console.log(body);
    axios
      .put(`app_commission/partenaires/archive/${id}`)
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.ARCHIVE_PARTENAIRES_SUCCESS,
          payload: res.data
        });
      })


      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.ARCHIVE_PARTENAIRES_FAILED,
            payload: "La ressource est introuvable !!!",
          });
        }else{
          dispatch({
            type: ParametrePagesTypes.ARCHIVE_PARTENAIRES_FAILED,
            payload: err?.response?.data,
          });
        }
        console.log(err)
      });
      


  };
};


export const put_update_partenaires_actions = (id,body) => {
  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.UPDATE_PARTENAIRES_START });
    // console.log(body);
    axios
      .put(`app_commission/partenaires/update/${id}`,body, configHeadersToken())
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.UPDATE_PARTENAIRES_SUCCESS,
          payload: res.data
        });
      })


      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.UPDATE_PARTENAIRES_FAILED,
            payload: "La ressource est introuvable !!!",
          });
        }else{
          dispatch({
            type: ParametrePagesTypes.UPDATE_PARTENAIRES_FAILED,
            payload: err?.response?.data,
          });
        }
        console.log(err)
      });
      


  };
};

export const get_liste_Partenaires_Actions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.LIST_PARTENAIRES_START,
    });
    axios
      .get(`app_commission/partenaires/`)
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.LIST_PARTENAIRES_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.LIST_PARTENAIRES_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};


export const post_MotDuPresident_Actions = (body) => {

    return (dispatch) => {
      dispatch({ type: ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_START });
      const token = localStorage.getItem("token_access");
  
     
      // console.log(body);
      axios
        .post("app_commission/mot_president/create/", body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_SUCCESS,
            payload: res.data
          });
          console.log("conenxion reussir res.data : ", res.data)
        })
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_FAILED,
              payload: "La ressource est introuvable !!!",
            });
           
          }else{
            dispatch({
              type: ParametrePagesTypes.CREATE_MOT_DU_PRESIDENT_FAILED,
              payload: err?.response?.data,
            });
            
          }
          
        });
        
  
  
    };
  };


  export const get_DetailMot_Du_PresidentAction = (id) => {
 
    return (dispatch) => {
      
      dispatch({
        type: ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_START,
      });
      axios
        .get(`app_commission/mot_president/detail/${id}`)
        .then((response) => {
          dispatch({
            type: ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_SUCCESS,
            payload: response?.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: ParametrePagesTypes.GET_MOT_PRESIDENT_DETAILS_FAILED,
            payload : error?.response
          });
          console.log("errorerrorerrorerrorInfoparto : ", error)
        });
    };
    
  };


  export const put_UpdateMotDuPresidenteActions = (id,body) => {
    return (dispatch) => {
      dispatch({ type: ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_START });
      // console.log(body);
      axios
        .put(`app_commission/mot_du_president/update/${id}`,body, configHeadersToken())
        .then((res) => {
          dispatch({
            type: ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_SUCCESS,
            payload: res.data
          });
        })
  
  
        .catch((err) => {
          if(err?.response?.status === 404){
            console.log("console.log error 404")
            dispatch({
              type: ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_FAILED,
              payload: "La ressource est introuvable !!!",
            });
          }else{
            dispatch({
              type: ParametrePagesTypes.PUT_UPDATE_MOTDUPRESIDENT_FAILED,
              payload: err?.response?.data,
            });
          }
          console.log(err)
        });
        
  
  
    };
  };



//Gestion des contacts
export const post_Create_Contacts_Actions = (body) => {

  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.CREATE_CONTACTS_START });
    const token = localStorage.getItem("token_access");

   
    // console.log(body);
    axios
      .post("app_commission/contacts/create/", body)
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.CREATE_CONTACTS_SUCCESS,
          payload: res.data
        });
        console.log("conenxion reussir res.data : ", res.data)
      })
      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.CREATE_CONTACTS_FAILED,
            payload: "La ressource est introuvable !!!",
          });
         
        }else{
          dispatch({
            type: ParametrePagesTypes.CREATE_CONTACTS_FAILED,
            payload: err?.response?.data,
          });
          
        }
        
      });
      


  };
};


export const get_liste_contactsActions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.LISTE_CONTACTS_START,
    });
    axios
      .get(`app_commission/contacts/liste-contact/`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.LISTE_CONTACTS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.LISTE_CONTACTS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};

export const get_Details_contactsActions = (id) => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_ONE_CONTACTS_START,
    });
    axios
      .get(`app_commission/contacts/view/${id}`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_ONE_CONTACTS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_ONE_CONTACTS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};


export const post_create_new_Abon_Newsletters_actions = (body) => {

  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_START });
    const token = localStorage.getItem("token_access");

   
    // console.log(body);
    axios
      .post("app_commission/newsletters/create", body)
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_SUCCESS,
          payload: res.data
        });
        console.log("conenxion reussir res.data : ", res.data)
      })
      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_FAILED,
            payload: "La ressource est introuvable !!!",
          });
         
        }else{
          
          dispatch({
            type: ParametrePagesTypes.CREATE_ABON_NEWSLETTERS_FAILED,
            payload: err,
          });
        }
        
      });
      


  };
};


export const post_create_visite_Actions = (body) => {

  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_START });
    const token = localStorage.getItem("token_access");

   
    // console.log(body);
    axios
      .post("app_commission/statistiques/",body)
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_SUCCESS,
          payload: res.data
        });
        console.log("conenxion reussir res.data : ", res.data)
      })
      .catch((err) => {
        if(err?.response?.status === 404){
          console.log("console.log error 404")
          dispatch({
            type: ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_FAILED,
            payload: "La ressource est introuvable !!!",
          });
         
        }else{
          
          dispatch({
            type: ParametrePagesTypes.CREATE_STATISTIQUES_VISITE_FAILED,
            payload: err,
          });
        }
        
      });
      


  };
};


export const get_liste_newsletters_action = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_START,
    });
    axios
      .get(`app_commission/newsletters/liste_abonne`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_LISTE_ABON_NEWSLETTERS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};


export const get_compte_is_view_actions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_COMPTE_IS_VIEWS_START,
    });
    axios
      .get(`app_commission/compte_views/`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_COMPTE_IS_VIEWS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_COMPTE_IS_VIEWS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};


export const get_state_dashboard_actions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_STATE_DASHBOARD_START,
    });
    axios
      .get(`app_commission/stats/dashboard`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_STATE_DASHBOARD_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_STATE_DASHBOARD_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};


export const get_state_mois_chart_actions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_STATE_MOIS_START,
    });
    axios
      .get(`app_commission/stats/monthly-stats`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_STATE_MOIS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_STATE_MOIS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};



export const get_list_contenu_newsletters_actions = () => {
 
  return (dispatch) => {
    
    dispatch({
      type: ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_START,
    });
    axios
      .get(`app_commission/newsletter/list/`, configHeadersToken())
      .then((response) => {
        dispatch({
          type: ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_SUCCESS,
          payload: response?.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ParametrePagesTypes.GET_LISTE_CON_NEWSLETTERS_FAILED,
          payload : error?.response
        });
        console.log("errorerrorerrorerrorInfoparto : ", error)
      });
  };
  
};




export const create_contenu_newsletters_actions = (body) => {

  return (dispatch) => {
    dispatch({ type: ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_START });
   

   
    // console.log(body);
    axios
      .post("app_commission/newsletter/create_contenu_send/",body, configHeadersToken())
      .then((res) => {
        dispatch({
          type: ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_SUCCESS,
          payload: res.data
        });
        
      })
      .catch((err) => {
        if(err?.response?.status === 404){
          
          dispatch({
            type: ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_FAILED,
            payload: "La ressource est introuvable !!!",
          });
         
        }else{
          
          dispatch({
            type: ParametrePagesTypes.CREATE_CONTENU_NEWSLETTERS_FAILED,
            payload: err,
          });
        }
        
      });
      


  };
};









