import React, { useEffect, useState } from 'react'
import Headers from '../../headers/Headers'
import Footers from '../../footers/Footers'
import imgaePub from '../../../images/imagecover.png'
import QuiSommeNous from './SectionsAccueil/QuiSommeNous'
import Newsletter from './SectionsAccueil/Newsletter'
import Partenanaire from './SectionsAccueil/Partenanaire'
import TextesItems from '../textes-reglementaires/TextesItems'
import ArticleAccueil from '../SousSections.jsx/ArticleAccueil'
import BanniereAccueil from './SectionsAccueil/BanniereAccueil'
import GaleroiePhotoItems from '../actualites/GaleroPhotoItems'
import SectionCarou from './SectionsAccueil/SectionCarou'


import Mot_president from './SectionsAccueil/Mot_president'
import { useDispatch, useSelector } from 'react-redux'

import ImageOrgi from '../../../images/oragnigrame.svg'
import { get_Liste_LastDOc } from '../../../reduxComponents/Actions/Gestion_des_DocsActions'
import { Get_Last_List_Actions } from '../../../reduxComponents/Actions/PublicationsActions'
import { get_GaleriePhoto_liste, get_liste_trois_dernier_gal_actions } from '../../../reduxComponents/Actions/GaleriePhotoActions'
import Reseau_Sociaux from './SectionsAccueil/Reseau_Sociaux'
import { get_last_communique_actions } from '../../../reduxComponents/Actions/Gestion_CommuniquePActions'






const Accueil = () => {


  const dispatch = useDispatch()

  const listePub = useSelector(state => state.pub_listes)
  const DataLastListeStore = useSelector(state => state.get_lastDOcStore)
  const DataListPublication = useSelector(state => state.Last_LIstSotre)
  const [DataListDoc, setDataListDoc] = useState()
  const [DataListPub, setDataListPub] = useState()

  


  const DataListeStore = useSelector(state => state.get_liste_trois_dernier_gal)

  const [DataListe, setDataListe] = useState(null)




  useEffect(() => {

    const body = {
      type_document: [
        "Arrêtés",
        "Décrets",
        "Lois",
        "Ordonnances",
        
        "Traités",
        "Directives",
        "Regléments"
      ]
    }



    dispatch(Get_Last_List_Actions())
    dispatch(get_Liste_LastDOc(body))
    dispatch(get_liste_trois_dernier_gal_actions())
    


  }, [])



  useEffect(() => {
    if (DataListPublication?.DataListLast) {
      setDataListPub(DataListPublication?.DataListLast)
    }
  }, [DataListPublication?.DataListLast])


  useEffect(() => {
    if (DataLastListeStore?.DataLasteDocc) {
      console.log("DataLastListeStore?.DataLasteDocc : ", DataLastListeStore?.DataLasteDocc)
      setDataListDoc(DataLastListeStore?.DataLasteDocc)
    }
  }, [DataLastListeStore?.DataLasteDocc])

  useEffect(() => {

    setDataListe(DataListeStore?.DataTroisPhotos)
  }, [DataListeStore?.DataTroisPhotos])



  const features = [
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="41" viewBox="0 0 40 41" fill="none">
          <g clip-path="url(#clip0_364_13744)">
            <rect y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M18.3333 10.5H23.3333L28.8167 4.99996C28.9716 4.84374 29.1559 4.71975 29.359 4.63514C29.5621 4.55052 29.78 4.50696 30 4.50696C30.22 4.50696 30.4379 4.55052 30.641 4.63514C30.8441 4.71975 31.0284 4.84374 31.1833 4.99996L35.4833 9.31662C35.7938 9.62889 35.968 10.0513 35.968 10.4916C35.968 10.9319 35.7938 11.3544 35.4833 11.6666L31.6667 15.5H18.3333V18.8333C18.3333 19.2753 18.1577 19.6992 17.8452 20.0118C17.5326 20.3244 17.1087 20.5 16.6667 20.5C16.2246 20.5 15.8007 20.3244 15.4882 20.0118C15.1756 19.6992 15 19.2753 15 18.8333V13.8333C15 12.9492 15.3512 12.1014 15.9763 11.4763C16.6014 10.8511 17.4493 10.5 18.3333 10.5ZM8.33333 18.8333V25.5L4.51667 29.3166C4.20625 29.6289 4.03201 30.0513 4.03201 30.4916C4.03201 30.9319 4.20625 31.3544 4.51667 31.6666L8.81667 35.9833C8.97161 36.1395 9.15594 36.2635 9.35904 36.3481C9.56214 36.4327 9.77998 36.4763 10 36.4763C10.22 36.4763 10.4379 36.4327 10.641 36.3481C10.8441 36.2635 11.0284 36.1395 11.1833 35.9833L18.3333 28.8333H25C25.442 28.8333 25.866 28.6577 26.1785 28.3451C26.4911 28.0326 26.6667 27.6086 26.6667 27.1666V25.5H28.3333C28.7754 25.5 29.1993 25.3244 29.5118 25.0118C29.8244 24.6992 30 24.2753 30 23.8333V22.1666H31.6667C32.1087 22.1666 32.5326 21.991 32.8452 21.6785C33.1577 21.3659 33.3333 20.942 33.3333 20.5V18.8333H21.6667V20.5C21.6667 21.384 21.3155 22.2319 20.6904 22.857C20.0652 23.4821 19.2174 23.8333 18.3333 23.8333H15C14.1159 23.8333 13.2681 23.4821 12.643 22.857C12.0179 22.2319 11.6667 21.384 11.6667 20.5V15.5L8.33333 18.8333Z" fill="#E69534" />
          </g>
          <defs>
            <clipPath id="clip0_364_13744">
              <rect width="40" height="40" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "Promouvoir une culture de la concurrence en Côte d’Ivoire et en zone UEMOA ;",
      bg: "",


    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <g clip-path="url(#clip0_364_1728)">
            <rect x="0.5" y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M33 5.50004C31.8952 5.50093 30.8359 5.9402 30.0547 6.72141C29.2735 7.50262 28.8342 8.56191 28.8333 9.66671C28.8333 10.2617 28.9633 10.8267 29.19 11.3417L25.9317 15.0067C24.8248 14.2427 23.5116 13.8334 22.1667 13.8334C20.9333 13.8334 19.7933 14.1934 18.8017 14.7767L15.0117 10.9884L14.9733 11.0267C15.3 10.3634 15.5 9.62504 15.5 8.83338C15.5 7.84447 15.2068 6.87777 14.6573 6.05553C14.1079 5.23328 13.327 4.59242 12.4134 4.21398C11.4998 3.83554 10.4945 3.73652 9.52455 3.92945C8.55464 4.12238 7.66373 4.59858 6.96447 5.29784C6.2652 5.99711 5.789 6.88802 5.59607 7.85792C5.40315 8.82783 5.50216 9.83316 5.8806 10.7468C6.25904 11.6604 6.8999 12.4413 7.72215 12.9907C8.54439 13.5401 9.51109 13.8334 10.5 13.8334C11.2917 13.8334 12.0283 13.6334 12.6933 13.3067L12.655 13.345L16.445 17.1334C15.8327 18.1501 15.5062 19.3132 15.5 20.5C15.5 22.1617 16.1333 23.665 17.1417 24.835L12.8467 29.1284C12.3653 28.9357 11.8518 28.8356 11.3333 28.8334C9.03667 28.8334 7.16667 30.7017 7.16667 33C7.16667 35.2984 9.03667 37.1667 11.3333 37.1667C13.63 37.1667 15.5 35.2984 15.5 33C15.5 32.465 15.39 31.9567 15.205 31.485L19.935 26.755C20.6367 27.005 21.38 27.1667 22.1667 27.1667C25.8433 27.1667 28.8333 24.1767 28.8333 20.5C28.8333 19.44 28.5617 18.4517 28.12 17.56L31.6483 13.5884C32.075 13.735 32.525 13.8334 33 13.8334C35.2983 13.8334 37.1667 11.965 37.1667 9.66671C37.1667 7.36838 35.2983 5.50004 33 5.50004ZM22.1667 23.8334C20.3283 23.8334 18.8333 22.3384 18.8333 20.5C18.8333 18.6617 20.3283 17.1667 22.1667 17.1667C24.005 17.1667 25.5 18.6617 25.5 20.5C25.5 22.3384 24.005 23.8334 22.1667 23.8334Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_364_1728">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "Veiller au respect strict du droit de la concurrence en Côte d’Ivoire et en zone communautaire ;",
      bg: "vert"

    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <g clip-path="url(#clip0_364_11136)">
            <rect x="0.5" y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M32.1517 3.83337H15.5167C13.665 3.83337 12.1667 5.33171 12.1667 7.18337V16.5784L4.33001 24.315C4.0952 24.5473 3.93468 24.844 3.86881 25.1676C3.80295 25.4913 3.83471 25.8271 3.96006 26.1327C4.08542 26.4382 4.29872 26.6996 4.5729 26.8837C4.84707 27.0678 5.16976 27.1663 5.50001 27.1667V35.5C5.50001 35.9421 5.6756 36.366 5.98816 36.6786C6.30073 36.9911 6.72465 37.1667 7.16668 37.1667H33.8333C34.2754 37.1667 34.6993 36.9911 35.0119 36.6786C35.3244 36.366 35.5 35.9421 35.5 35.5V7.18171C35.5 5.33171 34.0033 3.83337 32.1517 3.83337ZM18.7033 25.685V33.8334H8.83334V24.5534L13.8117 19.6384L18.7033 24.6434V25.685V25.685ZM22.1667 15.5H18.8333V12.1667H22.1667V15.5ZM28.8333 28.8334H25.5V25.5H28.8333V28.8334ZM28.8333 22.1667H25.5V18.8334H28.8333V22.1667ZM28.8333 15.5H25.5V12.1667H28.8333V15.5Z" fill="#E69534" />
            <path d="M12.1667 25.5H15.5V28.8333H12.1667V25.5Z" fill="#14171F" />
          </g>
          <defs>
            <clipPath id="clip0_364_11136">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "Favoriser l’installation de nouvelles entreprises ;",
      bg: ""
    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <g clip-path="url(#clip0_364_3)">
            <rect x="0.5" y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M30.5 27.1666H27.1667V25.5H13.8333V27.1666H10.5V25.5H3.83334V33.8333H37.1667V25.5H30.5V27.1666ZM33.8333 13.8333H28.8333V10.5C28.8333 8.66663 27.3333 7.16663 25.5 7.16663H15.5C13.6667 7.16663 12.1667 8.66663 12.1667 10.5V13.8333H7.16668C5.33334 13.8333 3.83334 15.3333 3.83334 17.1666V23.8333H10.5V20.5H13.8333V23.8333H27.1667V20.5H30.5V23.8333H37.1667V17.1666C37.1667 15.3333 35.6667 13.8333 33.8333 13.8333ZM25.5 13.8333H15.5V10.5H25.5V13.8333Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_364_3">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "D’avoir une multiplicité d’offres, des biens et services de meilleure qualité ;",
      bg: "vert"

    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <g clip-path="url(#clip0_364_1120)">
            <rect x="0.5" y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M11.1 31.8L11.5167 35.5L14.2167 32.9333L18.8333 20.2667C17.7 19.9833 16.7 19.4167 15.8833 18.6333L11.1 31.8ZM25.1167 18.6333C24.3 19.4167 23.2833 19.9833 22.1667 20.2667L26.7833 32.9333L29.4833 35.5L29.9167 31.8L25.1167 18.6333V18.6333ZM25.4 14.8333C25.9 12.2333 24.4 9.93333 22.1667 9.13333V7.16667C22.1667 6.25 21.4167 5.5 20.5 5.5C19.5833 5.5 18.8333 6.25 18.8333 7.16667V9.13333C16.9 9.83333 15.5 11.6667 15.5 13.8333C15.5 16.9 18.2667 19.3333 21.4333 18.75C23.4 18.3833 25.0167 16.8 25.4 14.8333V14.8333ZM20.5 15.5C19.5833 15.5 18.8333 14.75 18.8333 13.8333C18.8333 12.9167 19.5833 12.1667 20.5 12.1667C21.4167 12.1667 22.1667 12.9167 22.1667 13.8333C22.1667 14.75 21.4167 15.5 20.5 15.5Z" fill="#E69534" />
          </g>
          <defs>
            <clipPath id="clip0_364_1120">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "Rechercher et réprimer les pratiques anticoncurrentielles sous la supervision de la commission de l’UEMOA ;",
      bg: ""

    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
          <g clip-path="url(#clip0_364_13719)">
            <rect x="0.5" y="17.1666" width="23.3333" height="23.3333" fill="#00A991" />
            <path d="M35.5 23.8333C35.5 22.9167 34.75 22.1667 33.8333 22.1667H30.5V25.5H33.8333C34.75 25.5 35.5 24.75 35.5 23.8333ZM33.8333 28.8333H30.5V32.1667H33.8333C34.75 32.1667 35.5 31.4167 35.5 30.5C35.5 29.5833 34.75 28.8333 33.8333 28.8333ZM20.5 23.8333H17.1667V30.5H20.5C20.5 32.3333 22 33.8333 23.8333 33.8333H28.8333V20.5H23.8333C22 20.5 20.5 22 20.5 23.8333Z" fill="white" />
            <path d="M8.83333 22.1666C8.83333 20.3333 10.3333 18.8333 12.1667 18.8333H14.6667C17.8833 18.8333 20.5 16.2166 20.5 13C20.5 9.78329 17.8833 7.16663 14.6667 7.16663H8.83333C7.91667 7.16663 7.16667 7.91663 7.16667 8.83329C7.16667 9.74996 7.91667 10.5 8.83333 10.5H14.6667C16.05 10.5 17.1667 11.6166 17.1667 13C17.1667 14.3833 16.05 15.5 14.6667 15.5H12.1667C8.48333 15.5 5.5 18.4833 5.5 22.1666C5.5 25.85 8.48333 28.8333 12.1667 28.8333H15.5V25.5H12.1667C10.3333 25.5 8.83333 24 8.83333 22.1666Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_364_13719">
              <rect width="40" height="40" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
          </defs>
        </svg>,
      title: "Recherche et réprimer la concurrence déloyale.",
      bg: "vert"


    },
  ]


  const team = [
    {
      avatar: imgaePub,
      id: 2,
      title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


    },
    {
      avatar: imgaePub,
      id: 2,
      title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


    },
    {
      avatar: imgaePub,
      id: 2,
      title: " Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati, fuga?",


    },


  ]

  const Documents = [
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
        </svg>,
      title: "ORDONNANCE N2019-389 Du 08 MAI 2019 MODIFIANT L'ORDONNANCE N2013-662 DU 20 SEPTEMBRE 2013 RELATIVE A LA CONCURRENCE, RATIFIEE PAR LA LOI N2013-877 DU 23 DECEMBRE",
      date: "11 janvier 2022",
      typeDoc: "Reglements",
      id: 1
    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
        </svg>,
      title: "ORDONNANCE N2019-389 Du 08 MAI 2019 MODIFIANT L'ORDONNANCE N2013-662 DU 20 SEPTEMBRE 2013 RELATIVE A LA CONCURRENCE, RATIFIEE PAR LA LOI N2013-877 DU 23 DECEMBRE",
      date: "23 mars 2017",
      typeDoc: "Ordonnaces",
      id: 1

    },
    {
      icon:
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
        </svg>,
      title: "ORDONNANCE N2019-389 Du 08 MAI 2019 MODIFIANT L'ORDONNANCE N2013-662 DU 20 SEPTEMBRE 2013 RELATIVE A LA CONCURRENCE, RATIFIEE PAR LA LOI N2013-877 DU 23 DECEMBRE",
      date: "15 mars 2004",
      typeDoc: "Lois",
      id: 1

    },

  ]

  return (
    <>
      <div className=''>
        <Headers IsAccueil={true} />


      </div>

      <div className='element'>
       
            <SectionCarou />
     
       
      </div>
      <div className="marginContinaierWeb pt-24" >
        <div>
          <h4 ><span className='text-teal-600 text-2xl font-bold '> Mot du President</span></h4>
        </div>
        <div className='pt-12 '>
          <Mot_president className='element' />
        </div>

      </div>
      <div className='pt-10  '>
        <QuiSommeNous />
      </div>

      <div>
       
      </div>
      <div>
        <section className="py-14 bg-teal-50">
          <div className="max-w-screen-xl mx-auto px-4 text-center text-gray-600 md:px-8">
            <div className="max-w-2xl mx-auto">
              <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                <span className='font-bold text-orange-500'>Les missions de la commission de la concurrence</span>
              </h3>

            </div>
            <div className="mt-12">
              <ul className="grid gap-y-8 gap-x-12 sm:grid-cols-2 lg:grid-cols-3">
                {
                  features.map((item, idx) => (
                    <li key={idx} className={`space-y-3 missionshadow ${item.bg === "vert" ? "bg-teal-600 p-10 text-white rounded-lg flex-col content-center" : "bg-white p-10 flex-col rounded-lg content-center"}`}>
                      <div className="w-12 h-12 mx-auto  text-indigo-600 rounded-full flex items-center justify-center">
                        {item.icon}
                      </div>
                      <h5 className="text-lg text-gray-800 py-4">
                        {item.title}
                      </h5>

                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
        </section>
      </div>
      <div className="marginContinaierWeb pt-12" >
        <div>
          <h4 ><span className='text-teal-600 text-2xl font-bold'> Textes réglementaires</span></h4>
        </div>
        <div>
          {
            DataListDoc && (
              <TextesItems DataListeDoc={DataListDoc} isNonRegement={true} />
            )
          }


        </div>
      </div>
      <div className="px-8 md:px-28 py-12 bg-teal-50" >
        <div>
          <h4 ><span className='text-teal-600 text-2xl font-bold '> Publications</span></h4>
        </div>
        <div>

          {
            DataListPub && (
              <ArticleAccueil DataListe={DataListPub} />
            )
          }

        </div>
      </div>
      <div className='marginContinaierWeb pt-12'>
        <div>
          <h4 ><span className='text-teal-600 text-2xl font-bold '> Organigramme de notre institution</span></h4>
        </div>
        <div>
          <div className='pt-12'>
            <img className='w-full' src={ImageOrgi} alt="Organigramme de la commission de la concurrence" />
          </div>
        </div>

      </div>
      <div>
        <BanniereAccueil />
      </div>

      <div className='marginContinaierWeb'>
        <div className="" >
          <h4 ><span className='text-teal-600 text-2xl font-bold '>  Galerie photo</span></h4>
        </div>

        {
          DataListe && (
            <GaleroiePhotoItems ListeImageGalerie={DataListe} />
          )
        }

      </div>
      <div>
        <div className="marginContinaierWeb py-12" >
          <h4 ><span className='text-teal-600 text-2xl font-bold '> Nos partenaires</span></h4>
        </div>
        <Partenanaire />
      </div>



      <div className='marginContinaierWeb'>
        <Newsletter />
      </div>




       <div>
        <Reseau_Sociaux />
       </div>




      <div>
        <Footers IsAcceuil = {true} />
      </div>
    </>
  )
}

export default Accueil
