
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_liste_admins_actions, patch_desactiver_user_actions, put_update_admins_actions } from '../../../reduxComponents/Actions/Gestion_Admin_Actions';
import { InputMask } from 'primereact/inputmask';
import { MultiSelect } from 'primereact/multiselect';
import { Password } from 'primereact/password';
import { SelectButton } from 'primereact/selectbutton';
import LoaderComponent from '../../../LoaderComponent';

const DataListeAdministrateur = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [errors, setErrors] = useState({});
    const [imageUrl, setImageUrl] = useState('');
    const [Nom, setNom] = useState('')
    const [Prenom, setPrenom] = useState('')
    const [Username, setUsername] = useState('')
    const [Email, setEmail] = useState('')
    const [Contact, setContact] = useState('')
    const [FixContact, setFixContact] = useState('')
    const [params_id, setParams_id]=useState(null)


    const DataListeAdminStore = useSelector(state=>state?.get_liste_admin)
    const DataUpdateAdminStore = useSelector(state=>state?.update_admin_store)
    const [DatalisteAdmin, setDatalisteAdmin] = useState(null)
    const [isForUpdate, setisForUpdate]=useState(false)
    const id = 12


    const options = ['Oui', 'Non'];
    const [Compte_Is_Active, setCompte_Is_Active] = useState(options[0]);

    const [setelectRolesAdmin, setSetelectRolesAdmin] = useState(null);
    const Roles = [
        'admin',
        'Contact',
        'Presentation',
        'Fonctionneemnt',
        'Textes reglementaires',
        'Avis',
        'Activites',
        'Actualites',
        'newsletters',
        'Parametres',
        
    ];


    const [IsForDesactiverUser,setIsForDesactiverUser] = useState(false)
    const [DataUserDesactiver, setDataUserDesactiver] = useState(null)

    const [PasswordAdmin, setPasswordAdmin] = useState('');
    const [ConfirmePassword, setConfirmePassword] = useState('');

    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );
    const headerElementUpdate = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-teal-500"> Modification d'un utilisateur</span>
        </div>
    );

    const footerContentUpdate = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setisForUpdate(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Modifier l'utilisateur" icon="pi pi-check" onClick={(event) => ModifierUserFunction(event)} autoFocus />

        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setIsForDesactiverUser(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Valider" icon="pi pi-check" onClick={() => DesactiverCompteUser()} autoFocus />
        </div>
    );

    const DesactiverCompteUser = ()=>{
        const body = {
            compte_is_actif : !DataUserDesactiver?.personne?.compte_is_actif
        }
        dispatch(patch_desactiver_user_actions(DataUserDesactiver?.pk, body))
        setLoading(true)
        dispatch(get_liste_admins_actions())

        window.location.reload()
    }

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    useEffect(()=>{
        if (DataUpdateAdminStore?.DataUpdte_Admine && loading) {
            setisForUpdate(false)
            
            dispatch(get_liste_admins_actions())
            setNom(null)
            setParams_id(null)
            setPrenom(null)
            setUsername(null)
            setContact(null)
            setEmail(null)
            setSetelectRolesAdmin(null)
            setLoading(false)
        }

    },[DataUpdateAdminStore?.DataUpdte_Admine])

    useEffect(()=>{
        if (DataListeAdminStore?.datalisteadmin) {
            setDatalisteAdmin(DataListeAdminStore?.datalisteadmin)
            setLoading(false)
        }
    },[DataListeAdminStore?.datalisteadmin])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/parametre/administrateur/create')
    }

    useEffect(()=>{
        dispatch(get_liste_admins_actions())
    },[])

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un utilisateur" />
                </span>
            </div>
            <div className="flex justify-content-end">
            <Button label="Créer un utilisateur" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = ()=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/publications/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (rowData)=>{
        setNom(rowData?.personne?.nom)
        setParams_id(rowData?.pk)
        setPrenom(rowData?.personne?.prenom)
        setUsername(rowData?.personne?.username)
        setContact(rowData?.personne?.contact)
        setEmail(rowData?.personne?.email)
        setSetelectRolesAdmin(rowData?.roles)
        const userActive =  rowData?.personne?.compte_is_actif ? 'Oui' : 'Non'
        setCompte_Is_Active(userActive)
        setisForUpdate(true)
    }

    const DialogueArchive = (rowData)=>{
       setDataUserDesactiver(rowData)
       setIsForDesactiverUser(true)
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                {/* <button label="Primary pointer" text onClick={()=>RedirectTo()} ><i class="fi fi-sr-eye"></i></button> */}
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive(rowData)} text className={`${!rowData?.personne?.compte_is_actif ? 'text-teal-500' : 'text-red-500'}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
  <path fillRule="evenodd" d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z" clipRule="evenodd" />
</svg>
</button> 

            </div>
        )
    }

    const bodyNom = (rowData)=>{
        
        return(
            <div>
                {
                    rowData?.personne?.nom + ' ' + rowData?.personne?.prenom
                }
            </div>
        )
    }



    const ModifierUserFunction = (event) => {
        event.preventDefault();
        setLoading(true);
        const newErrors = {};

        


        // Validation des champs vides
        if (!Nom) newErrors.Nom = true;
        if (!Prenom) newErrors.Prenom = true;
        if (!Username) newErrors.Username = true;
        if (!Email) newErrors.Email = true;
        if (!Contact) newErrors.Contact = true;
       
        if (Compte_Is_Active === null) newErrors.Compte_Is_Active = true;

      

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            console.log('error')

        } else {

            const compte_is_actif = Compte_Is_Active === 'Oui' ? true : false
            const data_admin = {
                personne : {
                    nom: Nom,
                    prenom: Prenom,
                    username: Username,
                    password: PasswordAdmin,
                    email: Email,
                    contact: Contact,
                    image_personne: 'non_image',
                    compte_is_actif: compte_is_actif
                },
                roles : setelectRolesAdmin,
                
            }

            console.log("donner admin : ", data_admin)
            dispatch(put_update_admins_actions(params_id,data_admin ))
            
           
        }

        





    }


    const comptebody=(rowData)=>{
        console.log("rowDatarowDatarowData : ", rowData)
        return(
            <div>
                {
                    rowData?.personne?.compte_is_actif ? (
                        <div className=' font-bold p-4 text-teal-500'>
                            Actif
                        </div>
                    ):(
                        <div className=' font-bold p-4 text-red-500'>
                            Désactivé
                        </div>
                    )

                }
            </div>
        )
    }


    return (
        <div className="card">
            <DataTable value={DatalisteAdmin} paginator showGridlines   rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['personne.nom', 'personne.prenom', 'personne.username', 'pk']} header={header} emptyMessage="Aucun utilisateur trouvé.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />

                
                <Column header="Nom et Prenom" field='personne.nom' body={bodyNom} style={{ minWidth: '12rem' }} />
                <Column header="Roles" field='roles' style={{ minWidth: '9rem' }} />
                {/* <Column header="Contact" field='contact' style={{ minWidth: '9rem' }} /> */}
                <Column header="Username" field='personne.username' style={{ minWidth: '9rem' }} />
                <Column header="Compte" field='personne.compte_is_actif' body={comptebody} style={{ minWidth: '9rem' }} />
                <Column header="Contact" field='personne.contact' style={{ minWidth: '12rem' }} />
               
               

              
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={IsForDesactiverUser} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => setIsForDesactiverUser(false)}>
                <p className="m-0">
                    Vous souhaitez <b>{DataUserDesactiver?.personne?.compte_is_actif ? 'désactiver' : 'activer'}</b> le compte de l'utilisateur <b>{DataUserDesactiver?.personne?.nom + " " + DataUserDesactiver?.personne?.prenom }</b> ayant le username <b> {DataUserDesactiver?.personne?.username}</b> 
                </p>
            </Dialog>

            <LoaderComponent loading={loading} />

            <Dialog visible={isForUpdate} modal header={headerElementUpdate} footer={footerContentUpdate} style={{ width: '50rem' }} onHide={() => setisForUpdate(false)}>
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                                <div class="p-6 bg-white border-b border-gray-200">
            
                                 
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Nom">Nom</label>
                                            <InputText id="Nom" className={errors.Nom ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Nom} onChange={(e) => setNom(e.target.value)} />

                                        </div>

                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Prenom">Prenom</label>
                                            <InputText id="Prenom" className={errors.Prenom ? 'border-2 border-red-500' : ''} aria-describedby="username-help" value={Prenom} onChange={(e) => setPrenom(e.target.value)} />

                                        </div>


                                    </div>
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Username">Username</label>
                                            <InputText id="Username" className={errors.Username ? 'border-2 border-red-500' : ''} aria-describedby="Username-help" value={Username} onChange={(e) => setUsername(e.target.value)} />

                                        </div>

                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Email">Email</label>
                                            <InputText id="Email" className={errors.Email ? 'border-2 border-red-500' : ''} aria-describedby="username-help" keyfilter="email" value={Email} onChange={(e) => setEmail(e.target.value)} />

                                        </div>


                                    </div>
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="Contact">Contact</label>
                                            <InputMask id="Contact" aria-describedby="username-help" className={errors.Contact ? 'border-2 border-red-500' : ''} value={Contact} onChange={(e) => setContact(e.target.value)} mask="(999) 9999 999 999" placeholder="(225) XXXX XXX XXX "></InputMask>

                                        </div>
                                        {/* <div className="flex flex-column gap-2">
                                            <label htmlFor="FixContact">Fix</label>
                                            <InputMask  id="FixContact" aria-describedby="username-help" value={FixContact} onChange={(e) => setFixContact(e.target.value)} mask="(999) 9999 999 999" placeholder="(225) XXXX XXX XXX "></InputMask>


                                        </div> */}


                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username">Rubriques</label>
                                            <MultiSelect value={setelectRolesAdmin} onChange={(e) => setSetelectRolesAdmin(e.value)} options={Roles}
                                                filter placeholder="Attribuer des rubriques" className={errors.setSetelectRolesAdmin ? 'border-2 border-red-500 w-full md:w-20rem' : 'w-full md:w-20rem'} />
                                        </div>


                                    </div>
                                    
                                    <div className='grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-2'>


                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username">Activer le compte</label>
                                            <SelectButton value={Compte_Is_Active} onChange={(e) => setCompte_Is_Active(e.value)} options={options} />

                                        </div>

                                        {/* 
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username">Username</label>
                                            <InputText id="username" aria-describedby="username-help" />

                                        </div>
 */}

                                    </div>

                                  
                                </div>
                            </div>
                        </div>
            </Dialog>
        </div>
        </div>
    )
}

export default DataListeAdministrateur



