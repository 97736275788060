import React, { useState } from 'react'
import NavbarAdmin from '../../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../../AdminSections/NavBar/NavbarHeaders'
import { InputText } from 'primereact/inputtext'
import { Editor } from "primereact/editor";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { useNavigate } from 'react-router-dom';
import { post_GaleriePhoto_Actions } from '../../../reduxComponents/Actions/GaleriePhotoActions';
import LoaderComponent from '../../../LoaderComponent';

const CreateGalerie = () => {
    const navigate = useNavigate()
    const [text, setText] = useState('');
    const [Title_pub, setTitle_pub] = useState(null)

    const dispatch = useDispatch()
    const DataCreateGalerie = useSelector(state=>state.postCreateGaleriePhoto)
    const admin_id = useSelector((state) => state.user.user_id);
    // administrateur : admin_id

    const [coverImage, setCoverImage] = useState(null);

    const [loading, setLoading] = useState(false);
    const [CreateSucces, setCreateSucces] = useState(false)
    const [ErrorCreate, setErrorCreate] = useState(false)


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCoverImage(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    useEffect(()=>{
        if (DataCreateGalerie?.DataCreateGaleriePhoto?.titre && loading) {
            
            setLoading(false)
            setCreateSucces(true)
        }
        console.log('hooooo : ', DataCreateGalerie?.DataCreateGaleriePhoto)
    },[DataCreateGalerie?.DataCreateGaleriePhoto])

    const options = ['Oui', 'Non'];
    const [is_active_pub, setis_active_pub] = useState(options[0]);


    const [selectedImages, setSelectedImages] = useState([]);
    const [previewImages, setPreviewImages] = useState([]);

    const handleImageChangeMultiple = (e) => {
        const files = e.target.files;
        const newSelectedImages = [...selectedImages];
        const newPreviewImages = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const reader = new FileReader();

            reader.onloadend = () => {
                newSelectedImages.push(file);
                newPreviewImages.push(reader.result);
                setSelectedImages(newSelectedImages);
                setPreviewImages(newPreviewImages);
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = (index) => {
        const newSelectedImages = [...selectedImages];
        const newPreviewImages = [...previewImages];

        newSelectedImages.splice(index, 1);
        newPreviewImages.splice(index, 1);

        setSelectedImages(newSelectedImages);
        setPreviewImages(newPreviewImages);
    };

    const handleSaveImages = () => {
        // Enregistrer la liste des images dans un tableau ou envoyer vers un backend
        console.log(selectedImages);
    };









    const SubmitPublications = (event) => {
        event.preventDefault();
        setLoading(true)

        const is_active = is_active_pub === 'Oui' ? true : false
        const body =
        {
            titre: Title_pub,
            autres_photo: previewImages,
            image_cover: coverImage,
            is_active: is_active,
            administrateur : admin_id,
        }


        dispatch(post_GaleriePhoto_Actions(body))



        
    }

    const AllerListeDoc = () => {

        setCreateSucces(false)
        navigate('/admin/actualites/galerie-photo')
        
       
    }


    const AllerListeAdminDeconnection = () => {
        localStorage.removeItem('access_token_cc')
        localStorage.removeItem('refresh_token_cc')
        setErrorCreate(false)
        navigate('/admin-login')
    }

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-teal-500">effectué avec succès</span>
        </div>
    );

    const footerContent = (
        <div>
            <Button label="Fermer" className='bg-teal-600 border-none' icon="pi pi-check" onClick={() => AllerListeDoc()} autoFocus />
        </div>
    );



    const headerElementerror = (
        <div className="inline-flex align-items-center justify-content-center gap-2">

            <span className="font-bold white-space-nowrap500 text-red-500">Session Terminer</span>
        </div>
    );

    const footerContenterror = (
        <div>
            <Button label="Deconnexion" className='bg-red-600 border-none' icon="pi pi-cross" onClick={() => AllerListeAdminDeconnection()} autoFocus />
        </div>
    );

    return (
        <div>
            <LoaderComponent loading={loading} />
            <div className='main-container  '>
                <div className='  navbar'>
                    <NavbarAdmin />
                </div>
                <div className='py-2 content'>

                    <div className='py-2'>
                        <NavbarHeaders />
                    </div>
                    <div>
                        <hr />
                    </div>

                    <div>

                    </div>
                    <div>

                        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                            <div class="bg-white overflow-hidden shadow-sm sm:rounded-lg ">
                                <div class="p-6 bg-white border-b border-gray-200">
                                    <div>
                                        <h2 className='py-4'><span className='font-bold text-teal-600'>Ajouter une nouvelle galerie photo</span></h2>
                                        <span></span>
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="coverImage" className="block font-medium text-gray-700">Photo de couverture <span className='font-bold text-red-500'>*</span></label>
                                        <input
                                            type="file"
                                            id="coverImage"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                                        />
                                    </div>
                                    {coverImage && (
                                        <div className="mb-4">
                                            <label className="block font-medium text-gray-700 font-bold">Prévisualisation de la photo de couverture</label>
                                            <img
                                                src={coverImage}
                                                alt="Cover Preview"
                                                className="mt-1 w-full h-96 object-cover border border-gray-300 rounded-md"
                                            />
                                        </div>
                                    )}
                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username font-bold"><span className='font-bold'>Titre de la galerie</span> <span className='font-bold text-red-500'>*</span> </label>
                                            <InputText id="username" value={Title_pub} aria-describedby="username-help" onChange={(e) => setTitle_pub(e.target.value)} />

                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="container mx-auto mt-10">
                                            <div>                                        <label htmlFor="coverImage" className="block font-medium text-gray-700">Selectionner les images jointes <span className='font-bold text-red-500'>*</span></label>
</div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                multiple
                                                onChange={handleImageChangeMultiple}
                                                className="mb-4"
                                            />

                                            <div className="grid grid-cols-3 gap-4">
                                                {previewImages.map((preview, index) => (
                                                    <div key={index} className="relative">
                                                        <img src={preview} alt={`Preview ${index}`} className="w-full" />
                                                        <button
                                                            onClick={() => handleRemoveImage(index)}
                                                            className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                                                        >
                                                            X
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>


                                        </div>
                                    </div>

                                    <div class="mb-4">
                                        <div className="flex flex-column gap-2">
                                            <label htmlFor="username font-bold"><span className='font-bold'>Activer la galerie</span> </label>
                                            <SelectButton value={is_active_pub} onChange={(e) => setis_active_pub(e.value)} options={options} />

                                        </div>
                                    </div>
         

            {
               ( Title_pub && previewImages && coverImage) ? ( <div class="mb-4">
                <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01' onClick={(event) => SubmitPublications(event)} label='AJouter la galerie' type='submit' />
            </div>) : ( <div class="mb-4">
                                        <Button className='w-full rounded-lg bg-teal-600 border-none hover:bg-teal-700 btnBacground01'  label='AJouter la galerie' type='submit' disabled />
                                    </div>)
            }
                                   

                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="card flex justify-content-center">
                        <Dialog visible={CreateSucces} modal header={headerElement} footer={footerContent} style={{ width: '25rem' }} onHide={() => AllerListeDoc()}>
                            <p className="m-0 text-teal-500 text-center">
                                Publication crée avec succès
                            </p>
                        </Dialog>

                        <Dialog visible={ErrorCreate} modal header={headerElementerror} footer={footerContenterror} style={{ width: '25rem' }} onHide={() => AllerListeAdminDeconnection()}>
                            <p className="m-0 text-red-500 text-center">
                                votre temps de connexion est epuisé
                            </p>
                        </Dialog>
                    </div>


                </div>

            </div>
        </div>
    )
}

export default CreateGalerie



