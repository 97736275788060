import React, { useEffect, useState } from 'react'
import CarouselSLide from './CarouselSLide'
import imgaePub from '../../../../images/ImagesCommission/Commission de la Concurrence.jpg'
import { useNavigate } from 'react-router-dom'
import { BackendEntete } from '../../../../VariablesConfig'
import { useDispatch, useSelector } from 'react-redux'
import { get_last_communique_actions } from '../../../../reduxComponents/Actions/Gestion_CommuniquePActions'


const SectionCarou = ({}) => {
  
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const DataLastCommunique = useSelector(state=>state?.lat_communique_store)
    const [DataLastCom, setDataLastCom]= useState(null)


    useEffect(()=>{
        dispatch(get_last_communique_actions())
    },[])

    useEffect(()=>{
        if (DataLastCommunique?.DataLastCommunique) {
          setDataLastCom(DataLastCommunique?.DataLastCommunique)
        }
      },[DataLastCommunique?.DataLastCommunique])


    return (
        <>
            <div className=' marginContinaierWeb grid grid-cols-1 md:grid-cols-4 gap-2 sectionAnime cacheElement' >
                {
                   DataLastCom &&  DataLastCom[0]?.titre ? (
                        <div className="col-span-1 md:col-span-1 flex-shrink-0">
                        <div className="relative  h-full rounded-md overflow-hidden">
                            <img
                                src={BackendEntete + DataLastCom[0]?.image_cover}
                                alt={DataLastCom[0]?.titre}
                                className="object-cover h-full w-full rounded-md              "
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-green-500 to-yellow-500 opacity-70 rounded-md  "></div>
                            <div className="absolute bottom-0 text-center left-0 right-0 p-4 text-white">
                                <h2 className="text-xl font-bold">{DataLastCom[0]?.titre}</h2>
                            </div>
                        </div>
                    </div>
                    ):(
                        <div className="col-span-1 md:col-span-1 flex-shrink-0">
                        <div className="relative  h-full rounded-md overflow-hidden">
                            <img
                                src={imgaePub}
                                alt="Commission de la concurrence de la Côte d'Ivoire"
                                className="object-cover h-full w-full rounded-md              "
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-green-500 to-yellow-500 opacity-70 rounded-md  "></div>
                            <div className="absolute bottom-0 text-center left-0 right-0 p-4 text-white">
                                <h2 className="font-bold">Aucun communiqué de presse</h2>
                            </div>
                        </div>
                    </div>
                    )
                }
               
                <div className="col-span-2 md:col-span-2 flex-grow sectionAnime"><CarouselSLide /></div>
                <div className="col-span-1 md:col-span-1 rounded-md border border-teal-600 text-center">
                    <div>
                        <p className='text-xl font-bold  bg-teal-100 border-b-4 border-teal-600 py-2 text-teal-600'>DEPOT DE PLAINTE</p>
                    </div>
                    <div className='border-b-2 border-gray-100 sectionAnime'>
                        <div className='text-center'>
                            <p className=' px-4'>Commission de la Concurrence, Régulateur de l’activité économique.</p>
                        </div>

                        <div className='flex flex-col gap-1 pb-4'>
                            <div><button type="button" className='btn-action-OrangeSaisine' onClick={()=>navigate('/fonctionnement/qui-peut-saisir-lacommission')}>QUI PEUT SAISIR LA COMMISSION ? </button></div>
                            <div><button type="button" className=' btn-action-BlackSaisine' onClick={()=>navigate('/fonctionnement/comment-saisir-la-commission')}>COMMENT SAISIR LA COMMISSION ?</button></div>
                            <div><button type="button" className='btn-action-greenSaisine' onClick={()=>navigate('/fonctionnement/faire-une-saisine')}>FAIRE UN DEPOT DE PLAINTE</button></div>
                        
                        </div>

                    </div>
                    {/* <div className='content-center flex-col mt-2 content-center items-center sectionAnime'>
                        <div className='rounded-3xl pb-2'>
                            <button type="button" className='bg-orange-200 px-20 rounded-3xl py-2 font-bold text-orange-400 hover:bg-orange-500 hover:text-white'> SE CONNECTER</button>
                        </div>

                        <div className='rounded-3xl'>
                            <button type="button" className='bg-teal-100 px-20 rounded-3xl py-2 font-bold text-teal-600 hover:bg-teal-500 hover:text-white'> SE CONNECTER</button>


                        </div>

                    </div> */}


                </div>

            </div>



            <div className=' marginContinaierWeb grid grid-cols-1 md:grid-cols-4 gap-2 sectionAnime AfficheElement pousserHaut' >
                <div className="col-span-2 md:col-span-2 flex-grow sectionAnime pourserENBas10 pourserENBas10Tablette"><CarouselSLide /></div>

                <div className="col-span-1 md:col-span-1 rounded-md border border-teal-600 text-center pourserENBas10Tablette">
                    <div>
                        <p className='text-xl font-bold  bg-teal-100 border-b-4 border-teal-600 py-2 text-teal-600'>DEPOT DE PLAINTE</p>
                    </div>
                    <div className='border-b-2 border-gray-100 sectionAnime'>
                        <div className='text-center'>
                            <p className=' px-4'>Le Lorem Ipsum est simplement du faux texte employé dans la composition et la mise en page avant impression.</p>
                        </div>

                        <div className='flex flex-col gap-1 pb-4'>
                            <div><button type="button" className='btn-action-OrangeSaisine' onClick={()=>navigate('/fonctionnement/qui-peut-saisir-lacommission')}>QUI PEUT SAISIR LA COMMISSION ? </button></div>
                            <div><button type="button" className=' btn-action-BlackSaisine' onClick={()=>navigate('/fonctionnement/comment-saisir-la-commission')}>COMMENT SAISIR LA COMMISSION ?</button></div>
                            <div><button type="button" className='btn-action-greenSaisine' onClick={()=>navigate('/fonctionnement/faire-une-saisine')}>FAIRE UN DEPOT DE PLAINTE</button></div>
                        
                        </div>

                    </div>
                    {/* <div className='content-center flex-col mt-2 content-center items-center sectionAnime'>
                        <div className='rounded-3xl pb-2'>
                            <button type="button" className='bg-orange-200 px-20 rounded-3xl py-2 font-bold text-orange-400 hover:bg-orange-500 hover:text-white'> SE CONNECTER</button>
                        </div>

                        <div className='rounded-3xl'>
                            <button type="button" className='bg-teal-100 px-20 rounded-3xl py-2 font-bold text-teal-600 hover:bg-teal-500 hover:text-white'> SE CONNECTER</button>


                        </div>

                    </div> */}


                </div>
                {
                   DataLastCom &&  DataLastCom[0]?.titre ? (
                        <div className="col-span-1 md:col-span-1 flex-shrink-0 pourserENBas10">
                        <div className="relative  h-full rounded-md overflow-hidden">
                            <img
                                src={BackendEntete + DataLastCom[0]?.image_cover}
                                alt={DataLastCom[0]?.titre}
                                className="object-cover h-full w-full rounded-md              "
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-green-500 to-yellow-500 opacity-70 rounded-md  "></div>
                            <div className="absolute bottom-0 text-center left-0 right-0 p-4 text-white">
                                <h2 className="text-xl font-bold">{DataLastCom[0]?.titre}</h2>
                            </div>
                        </div>
                    </div>
                    ):(
                        <div className="col-span-1 md:col-span-1 flex-shrink- pourserENBas10">
                        <div className="relative  h-full rounded-md overflow-hidden">
                            <img
                                src={imgaePub}
                                alt="Commission de la concurrence de la Côte d'Ivoire"
                                className="object-cover h-full w-full rounded-md              "
                            />
                            <div className="absolute inset-0 bg-gradient-to-t from-green-500 to-yellow-500 opacity-70 rounded-md  "></div>
                            <div className="absolute bottom-0 text-center left-0 right-0 p-4 text-white">
                                <h2 className="font-bold">Aucun communiqué de presse</h2>
                            </div>
                        </div>
                    </div>
                    )
                }
               
               

            </div>
        </>
    )
}

export default SectionCarou
