import { Gestion_DesVideosTypes } from "../Types/Gestion_Des_VideoTypes";


const initialCreateVideoGalerie = {
    isLoading: false,
    DataCreateVideo: [],
    error: "",
};


const initialListeVideoGalerie = {
    isLoading: false,
    DataListeVideo: [],
    error: "",
};

const initialUpdateVideo = {
    isLoading: false,
    DataUpdateVideo: [],
    error: "",
};



const initialDetailVideoGalerie = {
    isLoading: false,
    DataDetailVideo: [],
    error: "",
};




export const PostCreateVideoGalReducers = (state=initialCreateVideoGalerie, action) => {

    switch (action.type) {
        case Gestion_DesVideosTypes.CREATE_GALVIDEO_START:
            return {
                ...state,
                isLoading: true,
                DataCreateVideo : null,
                error : null
            };
        case Gestion_DesVideosTypes.CREATE_GALVIDEO_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataCreateVideo: action.payload,
                error : null
            };


        case Gestion_DesVideosTypes.CREATE_GALVIDEO_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataCreateVideo : null
            };
        default:
            return state;
    }
}


export const Get_liste_GalerieVideoReducers = (state=initialListeVideoGalerie, action) => {

    switch (action.type) {
        case Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_START:
            return {
                ...state,
                isLoading: true,
                DataListeVideo :null,
                error : null
                
            };
        case Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : null,
                DataListeVideo: action.payload,
            };


        case Gestion_DesVideosTypes.GET_GALVIDEOS_LISTE_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataListeVideo : null,
            };
        default:
            return state;
    }
}


export const Get_Detail_GalerieVideoReducers = (state=initialDetailVideoGalerie, action) => {

    switch (action.type) {
        case Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_START:
            return {
                ...state,
                isLoading: true,
                DataDetailVideo : null,
                error:null
            };
        case Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error : '',
                DataDetailVideo: action.payload,
                error:null
            };


        case Gestion_DesVideosTypes.GET_GALVIDEOS_DETAILS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataDetailVideo : null,
            };
        default:
            return state;
    }
}


export const Put_Update_GalerieVideoReducers = (state=initialUpdateVideo, action) => {

    switch (action.type) {
        case Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_START:
            return {
                ...state,
                isLoading: true,
                DataUpdateVideo : null,
                error: null,
            };
        case Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                DataUpdateVideo: action.payload,
                error: null,
            };


        case Gestion_DesVideosTypes.PUT_UPDATE_GALVIDEOS_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
                DataUpdateVideo : null
            };
        default:
            return state;
    }
}

