import { useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf';
import pdf from './ordonnace.pdf'
import { PDFViewer } from '@react-pdf/renderer';
import { BackendEntete } from '../../VariablesConfig';




function PdfCOmpo(props) {
  console.log("propspropspropsprops : ", props.pdfFile)
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [pdfUrl, setPdfUrl] = useState(null);

  const fetchPdf = async () => {
        try {
            // const encodageLien = encodeURIComponent(props.filePath)
            const response = await fetch(`${BackendEntete}app_commission/pdftext/${props.filePath}`);
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                setPdfUrl(url);
            } else {
                console.error('Erreur lors de la récupération du PDF:', response.status);
            }
        } catch (error) {
            console.error('Erreur lors de la récupération du PDF:', error);
        }
    };

    useEffect(() => {
      fetchPdf();
    }, [props.filePath]);






  

  return (
    <>

<div>
    {props.filePath ? (
      <iframe src={BackendEntete + 'app_commission/pdftext/'+props.filePath} width="100%" height="1000px" className='mb-6' title="PDF Viewer" />
    ) : (
      <p>Chargement du PDF...</p>
    )}
  </div>
      {/* <div className='pdf-div mb-6' style={{ height: '720px', overflowY: 'auto' }}>
        <p>
          Page {pageNumber} of {numPages}
        </p>

           
      
        <Document file={props.pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.apply(null, Array(numPages)).map((x, i) => i + 1).map(page => {
            return (
              <Page
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}

              />
            )
          })}

        </Document>
      </div> */}

      </>

  );
}

export default PdfCOmpo


