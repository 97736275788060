import React, { useEffect, useState } from 'react'
import NavbarAdmin from '../AdminSections/NavBar/NavbarAdmin'
import NavbarHeaders from '../AdminSections/NavBar/NavbarHeaders'
import DataListeDocuement from './DataListeDocuement'
import DataListeDocuments from './DataListeDocuement'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from 'react-redux'
import { Get_detail_DocAction } from '../../reduxComponents/Actions/Gestion_des_DocsActions'
import { ProgressSpinner } from 'primereact/progressspinner'

import axios from '../../../components/reduxComponents/axios/axios'
import { BackendEntete } from '../../VariablesConfig'
import PDFViewer from './PDFReader'

import { pdfjs } from 'react-pdf';
import PdfCOmpo from './PdfCOmpo'

 
 

const VoirDocument = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const params = useParams()
  const params_id = atob(params?.id)

  const DataStoreDetailDOc = useSelector(state => state.get_doc_detail)
  const [DataDetailDOc, setDataDetailDOc] = useState(null)
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [LienPDF, setLienPDF] = useState(null)


  pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
  ).toString();


  const redirectModif = () => {
    const id = btoa(params_id)
    navigate(`/admin/textes-reglementaires/document/update/${id}`)
  }
  const [VisibleDialogue, setVisibleDialogue] = useState(false);

  useEffect(() => {
    dispatch(Get_detail_DocAction(params_id))
  }, [params_id])

  useEffect(() => {
    if (DataStoreDetailDOc?.DatDetailDoc) {
      setDataDetailDOc(DataStoreDetailDOc?.DatDetailDoc)
      setLoading(false)
    }

  }, [DataStoreDetailDOc?.DatDetailDoc])

  useEffect(()=>{
    if (DataDetailDOc && DataDetailDOc[0]?.documents) {
      console.log("DataDetailDOcDataDetailDOcDataDetailDOcDataDetailDOc : ", BackendEntete +  DataDetailDOc[0]?.documents)
      const document = BackendEntete +  DataDetailDOc[0]?.documents
      setLienPDF(document)
    }
  },[DataDetailDOc])

  useEffect(()=>{
    console.log("LienPDFLienPDFLienPDFLienPDF : ",LienPDF)
  },[LienPDF])


  const AfficherDocument = (LienDocument)=>{
    const fileExtension = LienDocument.LienDocument;
    console.log("fileExtensionfileExtensionfileExtension : ", fileExtension)

  let fileViewer;

  if (fileExtension) {
    fileViewer = <embed src={fileExtension} type="application/pdf"   />;
  } else {
    // Gérer d'autres types de fichiers si nécessaire
    fileViewer = <p>Document non trouvé</p>;
  }

  return (
    <div>
      {fileViewer}
    </div>
  );
  }

  return (
    <div>
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <ProgressSpinner
            style={{ width: '50px', height: '50px' }}
            strokeWidth="8"
            fill="var(--surface-ground)"
            animationDuration=".5s"
          />
        </div>
      )}
      <div className='main-container  '>
        <div className='  navbar'>
          <NavbarAdmin />
        </div>
        <div className='py-2 content'>

          <div className='py-2'>
            <NavbarHeaders />
          </div>
          <div>
            <hr />
          </div>
          <div className='grid gap-3 mb-8 md:grid-cols-2 xl:grid-cols-4 p-4 md:24 border-2 mx-4'>
            <div>

            </div>
            <div>

            </div>
            <div>

            </div>

            <div>
              <Button className='w-full bg-gray-500 border-none hover:bg-gray-600 btnBacground03' onClick={() => redirectModif()} label='Modifier le document' />
            </div>

          </div>





          <div>
            <div className=' flex gap-10'>
              {
                DataDetailDOc && DataDetailDOc[0]?.titre && (
                  <div className=''>
                  <div>
                    <div className='pt-4'>
                      <span className='font-bold text-2xl text-teal-500'>{ DataDetailDOc[0].titre}</span>
                    </div>
                    <div className='flex gap-4 py-4'>
                      <div className='bg-orange- p-2 text-orange-600'>
                        <span> Ajouter le :  <span className='font-bold'>{ DataDetailDOc[0].date_ajoute}</span></span>
                      </div>
                      {/* <div className='bg-teal- p-2 text-teal-600'>
                        <span> Nombre de lecture  :  <span className='font-bold'>Na</span> </span>
                      </div>
                      <div className='bg-gray-100 p-2 text-gray-600'>
                        <span> Modifier le   : <span className='font-bold'>Na</span> </span>
                      </div> */}
                    </div>
                  </div>
                 
                
                    
                    
                 
                 
                  <div>
                  </div>
                </div>
                )
              } 
             

            </div>

          </div>
          <div className='w-full px-6'>
            {
              LienPDF && (
                <PdfCOmpo pdfFile = {LienPDF} filePath={DataDetailDOc[0]?.documents} className='w-full' />
              )
            }
           
          </div>
        </div>

      </div>
    </div>
  )
}

export default VoirDocument

