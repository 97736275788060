import React from 'react';
import { Image } from 'react-bootstrap';

const ExampleCarouselImage = ({ src, alt, text }) => {
  return (
    <div style={{ width: "100%", height: 509, position: 'relative' }} className='rounded-md '>
      <Image src={src} alt={alt} fluid className='rounded-md '  style={{ width: '100%', height: '100%', objectFit: 'cover' }}  />
      <div className="carousel-text px-4">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default ExampleCarouselImage;
