import React from 'react'
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';
import logocommission from '../../images/ImagesCommission/logoCC.jpg'
import emblemecotedivoire from '../../images/ImagesCommission/embleme.png'


Font.register({
    family: 'Roboto',
    fonts: [
        { src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', fontWeight: 'bold' },
    ],
});



const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 20,
        color: '#333'
    },
    section: {
        marginBottom: 10,
    },
    titleDoc: {
        display: 'flex',
        textAlign: 'center',
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 15,
        fontWeight: 'extrabold',
        color: '#fff',
        backgroundColor: '#36805B'


    }, titlePartie: {

        fontSize: 16,
        fontWeight: 600,
        textAlign: 'center'

    },

    titlePartie02: {

        fontSize: 16,
        fontFamily: 'Roboto'


    }

    , row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingTop: 10,
        paddingLeft: 20,
        paddingRight: 20,
        gap: 25

    },

    columnItems: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },

    columnItems: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10
    },
    texte_styleItems: {
        fontSize: 12,
        textAlign: 'justify'
    },
    objectPlainte: {
        fontSize: 12,
        backgroundColor: '#36805B',
        padding: 10,
        textAlign: 'justify',
        color: '#fff'

    },

    texte_styleItemsResponse: {
        fontSize: 12,

        fontFamily: 'Roboto',
        textAlign: 'justify'
    },


    itemsContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        paddingVertical: 5,
    },

    itemsContentend: {
        marginTop : 15,
        display: 'flex',
        flexDirection: 'row',
        justifyContent : 'flex-end',
        gap: 5,
        paddingVertical: 5,
    },

    coloumnSectionPartie01: {
        marginTop: 15,
        // padding : 10,
        paddingBottom: 15,
        backgroundColor: '#fdf3ec',
        borderRadius: 15,
    },
    coloumnSectionPartie02: {
        marginTop: 35,
    }, coloumnSection03: {
        marginTop: 35,
    },

    titlePartie01: {
        padding: 10,
        fontFamily: 'Roboto',
        backgroundColor: '#fc8f42',
        color: '#fff',
        borderTopLeftRadius: 15,
        borderTopRightRadius: 15,
    },
    titlePartie03: {
        padding: 10,
        fontFamily: 'Roboto',
        backgroundColor: '#fc8f42',
        color: '#fff',

    },


    image: {
        width: 200,
        height: 100,
        marginBottom: 10,
    },reference_plainteStyle : {
        fontFamily : 'Roboto',
        fontSize : 16
    },

    pourquoiInput: {
        fontSize: 12,

        fontFamily: 'Roboto',
        textAlign: 'justify',
        backgroundColor: '#e7e7e7',
        padding: 10,
    }, 
    ImageLogo : {
        width : 150
    },
    Imageembleme : {
        width : 90
    },
    ImageSections : {
        display : 'flex',
        flexDirection : 'row',
        justifyContent : 'space-between',
        alignItems : 'center',
        marginBottom : 15
    }
});


const ViewPdfPLainte = ({ datainfo }) => {
    let DateBon = formatDateBon(datainfo?.date_envoi)
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                

              <View style={styles.ImageSections}>
                <View style={styles.section}>
                        <Image src={logocommission} style={styles.ImageLogo}  />
                    </View>
                    <View style={styles.section}>
                        <Image src={emblemecotedivoire} style={styles.Imageembleme}  />
                    </View>
              </View>
                <View style={styles.titleDoc}>
                    <Text > INFORMATIONS DE LA PLAINTE </Text>
                    <Text style={styles.reference_plainteStyle}> Numéro de la plainte : {datainfo?.reference_plainte} </Text>
                </View>


                <View style={styles.coloumnSectionPartie01}>
                    <View style={styles.titlePartie}>
                        <Text style={styles.titlePartie01} >I/ Informations d'identification </Text>
                    </View>

                    <View style={styles.row}>
                        <View style={styles.columnItems}>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Entreprise, organisation : :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.entreprise ? datainfo?.entreprise : 'Non renseigné'} </Text>
                            </View>



                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Ville :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.ville ? datainfo?.ville : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Adresse electronique :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.adresse_electronique ? datainfo?.adresse_electronique : 'Non renseigné'} </Text>
                            </View >



                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Nom et prénom du signataire :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.nom_prenom ? datainfo?.nom_prenom : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Tel fixe  :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.tel_fix ? datainfo?.tel_fix : 'Non renseigné'} </Text>
                            </View>

                        </View>
                        <View style={styles.columnItems}>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Adresse postale :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>
                                    {datainfo?.adresse_postale ? datainfo?.adresse_postale : 'Non renseigné'}
                                </Text>
                            </View>


                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Code postal :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.code_postal ? datainfo?.code_postal : 'NOn renseigné'} </Text>
                            </View >

                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Site Web :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.ste_web ? datainfo?.ste_web : 'Non renseigné'} </Text>
                            </View>

                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Qualité du signataire  :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.qualites_signataire ? datainfo?.qualites_signataire : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Tél mobile  :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.tel_mobile ? datainfo?.tel_mobile : 'Non renseigné'} </Text>
                            </View>


                        </View>


                    </View>




                </View>





                <View style={styles.coloumnSectionPartie02}>
                    <View style={styles.titlePartie03}>
                        <Text>II/ Deuxieme partie des informations </Text>
                    </View>

                    <View style={styles.row}>
                        <View style={styles.columnItems}>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Je porte plainte pour le compte :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.pour_compte_entreprise ? datainfo?.pour_compte_entreprise : 'Non renseigné'} </Text>
                            </View>



                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Personne/entreprise/organisation contre laquelle je porte plainte  :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.contre_entreprise ? datainfo?.contre_entreprise : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Adresse postale de la Personne/entreprise/organisation contre laquelle je porte plainte : (Si elle est connue) :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.contre_adresse_postal_entreprise ? datainfo?.contre_adresse_postal_entreprise : 'Non renseigné'} </Text>
                            </View >



                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Tel de la Personne/entreprise/organisation contre laquelle je porte plainte : (Si elle est connue) :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.contre_telephone ? datainfo?.contre_telephone : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Objet de la plainte  :  </Text>
                                <Text style={styles.objectPlainte}>  {datainfo?.object_plainte ? datainfo?.object_plainte : 'Non renseigné'} </Text>
                            </View>

                        </View>



                    </View>




                </View>


                <View style={styles.coloumnSection03}>
                    <View style={styles.titlePartie03}>
                        <Text>III/ Troisième partie des informations </Text>
                    </View>

                    <View style={styles.row}>
                        <View style={styles.columnItems}>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Je souhaite que mon anonymat soit préservé et ne soit divulgué à aucun moment de la procédure :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.is_have_anonymat ? datainfo?.is_have_anonymat : 'Non renseigné'} </Text>
                            </View>

                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}>SI Oui, Pourquoi ?  :  </Text>
                                <Text style={styles.pourquoiInput}>  {datainfo?.pourquoi_is_have_anonymat ? datainfo?.pourquoi_is_have_anonymat : 'Non renseigné'} </Text>
                            </View>


                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Je souhaite que des mesures conservatoires soient appliquées  :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.is_have_mesures_con ? datainfo?.is_have_mesures_con : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> SI Oui, Pourquoi ?  :  </Text>
                                <Text style={styles.pourquoiInput}>  {datainfo?.pourquoi_is_have_mesures_con ? datainfo?.pourquoi_is_have_mesures_con : 'Non renseigné'} </Text>
                            </View >



                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Si oui quelles mesures ?   </Text>
                                <Text style={styles.pourquoiInput}>  {datainfo?.quelles_mesures ? datainfo?.quelles_mesures : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Preuve d’existence légale ? :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.preuves_legale_existe ? datainfo?.preuves_legale_existe : 'Non renseigné'} </Text>
                            </View>
                            <View style={styles.itemsContent}>
                                <Text style={styles.texte_styleItems}> Preuve d’habilitation pour le signataire ?   :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {datainfo?.habilitation_preuves_signataire ? datainfo?.habilitation_preuves_signataire : 'Non renseigné'} </Text>
                            </View>

                        </View>



                    </View>




                </View>

                <View style={styles.itemsContentend}>
                                <Text style={styles.texte_styleItems}> Envoyé le    :  </Text>
                                <Text style={styles.texte_styleItemsResponse}>  {DateBon} </Text>
                            </View>





                {/* <View style={styles.section}>
        <Text>ID: {datainfo.id}</Text>
        <Text>Email: {datainfo.adresse_electronique}</Text>
        <Text>Adresse postale: {datainfo.adresse_postale}</Text>
      
      </View> */}
            </Page>
        </Document>
    )
}

export default ViewPdfPLainte
