
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { get_liste_contactsActions } from '../../reduxComponents/Actions/ParametrePagesActions';
import { formatDateBon } from '../../app_Visiteurs/sections/activites/ListeActivites';

const DataListContacts = () => {

    const dispatch = useDispatch()
    const DataListStore = useSelector(state=>state.get_List_Contact_store)
    const [DataList, setDataList]=useState()
    
    useEffect(()=>{

        if (DataListStore?.DataListContact) {
            setDataList(DataListStore?.DataListContact)
        }
    },[DataListStore?.DataListContact])

    useEffect(()=>{
        dispatch(get_liste_contactsActions())
    },[])
    const navigate = useNavigate()
    const id = 12
    const generateAgentsData = (count) => {
        // Génération de données factices pour les agents
        const newData = [];
        for (let i = 0; i < count; i++) {
            newData.push({
                image_cover: `https://randomuser.me/api/portraits/${i % 2 === 0 ? 'men' : 'men'}/${i}.jpg`,
                titre_pub: `Utilisateur ${i}`,
                contenu: `agent${i}@example.com`,
                roles: `roles ${i % 5 + 1}`,
                vues: `${i % 5 + 1}`,
                Date_publications: `12/01/2024 à 13H4${i % 10}`,
            });
        }

        return newData;
    };

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le document" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );

    const [DataEnregistrementListe, setDataEnregistrementListe] = useState(generateAgentsData(25));

    useEffect(() => {

        if (DataEnregistrementListe) {
            setLoading(false)
        }
    }, [DataEnregistrementListe])

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/parametre/administrateur/create')
    }

    const renderHeader = () => {
        return (
            <div className='flex justify-between'>
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher un contact" />
                </span>
            </div>
            <div className="flex justify-content-end">

            </div>
            </div>
        );
    };








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={rowData.image_cover} width="32" />
               
            </div>
        )
    }
    const RedirectTo = (rowDataId)=>{
        const idBtoa = btoa(rowDataId)
        navigate(`/admin/contacts/voir-contact-admin/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = ()=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/update-publication/${idBtoa}`, {replace : false})
    }

    const DialogueArchive = ()=>{
        setVisibleDialogue(true)
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3 text-center">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData?.id)} ><i class="fi fi-sr-eye"></i></button>
               
            </div>
        )
    }



    const bodyContact = (rowData)=>{
        return(
            <div>
                {formatDateBon(rowData?.date_envoye)}
            </div>
        )
    }

    const body_view_admin = (rowData)=>{
        return(
            <div>
                {
                    rowData?.is_vue_by_admin === true ? (
                        <div className='bg-teal-500 font-bold text-white p-3 text-center'>
                            
                        </div>

                    ) : (
                        <div className='bg-red-500 font-bold text-white p-3 text-center'>
                           
                        </div>
                    )
                }
            </div>
        )
    }

    return (
        <div className="card">
            <DataTable value={DataList} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['nom_prenoms', 'email', 'numero_tel', 'status']} header={header} emptyMessage="Aucun contact enregistré.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataEnregistrementListe, props) => props?.rowIndex + 1} />
                <Column header="Nom et Prenom" field='nom_prenoms' style={{ minWidth: '16rem' }} />
                <Column header="Email" field='email' style={{ minWidth: '9rem' }} />
                <Column header="Contact" field='numero_tel' style={{ minWidth: '9rem' }} />
                <Column header="Envoyé le" field='date_envoye' body={bodyContact} style={{ minWidth: '9rem' }} />
                <Column header="Statut" field='is_vue_by_admin' body={body_view_admin} style={{ minWidth: '9rem' }} />
                <Column header="Action" body={ActionsBody} style={{ minWidth: '9rem' }} />


            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog>
        </div>
        </div>
    )
}

export default DataListContacts





