
import React, { useState, useEffect } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { redirect, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { useDispatch, useSelector } from 'react-redux';
import { BackendEntete } from '../../../VariablesConfig';
import { get_GalerieVideo_liste } from '../../../reduxComponents/Actions/Gestion_VideosActions';

const DataListeGalerieVideo = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const DataListeVideosStore = useSelector(state=>state.get_Liste_GalerieVideo)

    const [DataListe, setDataListe] = useState(null)
    const id = 12
  

    useEffect(()=>{
            if (DataListeVideosStore?.DataListeVideo) {
                
                setDataListe(DataListeVideosStore?.DataListeVideo)
                setLoading(false)
            }

       
    }, [DataListeVideosStore?.DataListeVideo])

    const [VisibleDialogue, setVisibleDialogue] = useState(false);

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            <span className="font-bold white-space-nowrap text-red-500">Attention !</span>
        </div>
    );

    const footerContent = (
        <div className='  gap-4'>
            <Button label="Retour" className='bg-orange-500 border-none btnBacground03' icon="pi pi-cross" onClick={() => setVisibleDialogue(false)} autoFocus />
            <Button className='mx-3 bg-teal-500 rounded-lg btnBacground01' label="Archiver le communiqué" icon="pi pi-check" onClick={() => setVisibleDialogue(true)} autoFocus />
        </div>
    );


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        'country.name': { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        representative: { value: null, matchMode: FilterMatchMode.IN },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        verified: { value: null, matchMode: FilterMatchMode.EQUALS }
    });

    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');





    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const redirectNewPersonnel = ()=>{
        navigate('/admin/actualites/galerie-videos/create')
    }

    const renderHeader = () => {
        return (
            <>
             <div className='py-3'>
                <h3 > <span className='font-bold text-teal-600'>Galerie Vidéo</span> </h3>
             </div>
            <div className='flex justify-between'>
               
                <div className="flex justify-content-end">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Chercher une vidéo" />
                </span>
                </div>
            <div className="flex justify-content-end">
            <Button label="Ajouter une nouvelle video" className='border-none btnBacground01' onClick={()=>redirectNewPersonnel()} />

            </div>
            </div>
            </>
            
        );
    };

    useEffect(()=>{
        dispatch(get_GalerieVideo_liste())
    }, [])








    const header = renderHeader();

    const photoBody = (rowData) => {
        return (
            <div className="flex align-items-center gap-2">
                <img alt={rowData.titre_pub} src={BackendEntete + rowData.image_miniature} width="136"/>
               
            </div>
        )
    }
    const RedirectTo = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/galerie-videos/${idBtoa}`, {replace : false})
    }

    const RedirectToUpdate = (id)=>{
        const idBtoa = btoa(id)
        navigate(`/admin/actualites/galerie-videos/modifier/${idBtoa}`, {replace : false})
    }

    const DialogueArchive = ()=>{
        setVisibleDialogue(true)
    }


    const ActionsBody =(rowData)=>{
        return (
            <div className="flex  gap-3">
                <button label="Primary pointer" text onClick={()=>RedirectTo(rowData.id)} ><i class="fi fi-sr-eye"></i></button>
                <button label="Primary pointer" text onClick={()=>RedirectToUpdate(rowData.id)} className='text-green-500' ><i class="fi fi-sr-rotate-square"></i></button>
                <button label="Primary pointer bt" onClick={()=>DialogueArchive()} text className='text-red-500'><i class="fi fi-sr-archive"></i></button>
            </div>
        )
    }



    return (
        <div className="card">
            <DataTable value={DataListe} paginator  showGridlines rows={10} rowsPerPageOptions={[5, 10, 25, 50]} dataKey="id" filters={filters} loading={loading}
                globalFilterFields={['titre', 'date_ajoute', 'is_active']} header={header} emptyMessage="Aucune video enregistrée.">
                <Column field="avatar" header="N°" style={{ minWidth: '3rem' }} body={(DataListe, props) => props?.rowIndex + 1} />

                <Column field="image_miniature" header="Miniature" body={photoBody} style={{ minWidth: '9rem' }} />
                <Column header="Titre de la galerie" field='titre' style={{ minWidth: '9rem' }} />
                <Column header="Date de creation" field='date_ajoute' style={{ minWidth: '9rem' }} />
                <Column header="Actions" body={ActionsBody} style={{ minWidth: '9rem' }} />

             
            </DataTable>

            <div className="card flex justify-content-center">
           
            <Dialog visible={VisibleDialogue} modal header={headerElement} footer={footerContent} style={{ width: '50rem' }} onHide={() => setVisibleDialogue(false)}>
                <p className="m-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </p>
            </Dialog>
        </div>
        </div>
    )
}

export default DataListeGalerieVideo
