export const Gestion_desPublications_Types = {
    CREATE_PUBLICATION_START : "CREATE_PUBLICATION_START",
    CREATE_PUBLICATION_SUCCESS : "CREATE_PUBLICATION_SUCCESS",
    CREATE_PUBLICATION_FAILED : "CREATE_PUBLICATION_FAILED",

   PUT_UPDATE_PUBLICATIONS_START : " PUT_UPDATE_PUBLICATIONS_START",
   PUT_UPDATE_PUBLICATIONS_SUCCESS : "PUT_UPDATE_PUBLICATIONS_SUCCESS",
   PUT_UPDATE_PUBLICATIONS_FAILED : "PUT_UPDATE_PUBLICATIONS_FAILED",


   GET_PUBLICATIONS_DETAILS_START : " GET_PUBLICATIONS_DETAILS_START",
   GET_PUBLICATIONS_DETAILS_SUCCESS : "GET_PUBLICATIONS_DETAILS_SUCCESS",
   GET_PUBLICATIONS_DETAILS_FAILED : "GET_PUBLICATIONS_DETAILS_FAILED",


   GET_Last_Publication_START : " GET_Last_Publication_START",
   GET_Last_Publication_SUCCESS : "GET_Last_Publication_SUCCESS",
   GET_Last_Publication_FAILED : "GET_Last_Publication_FAILED",


   GET_Last_CAROUSELS_START : " GET_Last_CAROUSELS_START",
   GET_Last_CAROUSELS_SUCCESS : "GET_Last_CAROUSELS_SUCCESS",
   GET_Last_CAROUSELS_FAILED : "GET_Last_CAROUSELS_FAILED",


   GET_DERNIER_PUB_START : " GET_DERNIER_PUB_START",
   GET_DERNIER_PUB_SUCCESS : "GET_DERNIER_PUB_SUCCESS",
   GET_DERNIER_PUB_FAILED : "GET_DERNIER_PUB_FAILED",



   PUT_ARCHIVE_PUB_START : " PUT_ARCHIVE_PUB_START",
   PUT_ARCHIVE_PUB_SUCCESS : "PUT_ARCHIVE_PUB_SUCCESS",
   PUT_ARCHIVE_PUB_FAILED : "PUT_ARCHIVE_PUB_FAILED",

    
}