export const Gestion_Des_Types_Activites = {

    POST_CREATE_ACTIVITES_START : "POST_CREATE_ACTIVITES_START",
    POST_CREATE_ACTIVITES_SUCCESS : "POST_CREATE_ACTIVITES_SUCCESS",
    POST_CREATE_ACTIVITES_FAILED : "POST_CREATE_ACTIVITES_FAILED",


    GET_LISTE_ACTIVITES_START : "GET_LISTE_ACTIVITES_START",
    GET_LISTE_ACTIVITES_SUCCESS : "GET_LISTE_ACTIVITES_SUCCESS",
    GET_LISTE_ACTIVITES_FAILED : "GET_LISTE_ACTIVITES_FAILED",


    GET_DETAIL_ACTIVITES_START : "GET_DETAIL_ACTIVITES_START",
    GET_DETAIL_ACTIVITES_SUCCESS : "GET_DETAIL_ACTIVITES_SUCCESS",
    GET_DETAIL_ACTIVITES_FAILED : "GET_DETAIL_ACTIVITES_FAILED",


    PUT_UPDATE_ACTIVITES_START : "PUT_UPDATE_ACTIVITES_START",
    PUT_UPDATE_ACTIVITES_SUCCESS : "PUT_UPDATE_ACTIVITES_SUCCESS",
    PUT_UPDATE_ACTIVITES_FAILED : "PUT_UPDATE_ACTIVITES_FAILED",


    GET_LISTE_BY_CAT_ACTIVITES_START : "GET_LISTE_BY_CAT_ACTIVITES_START",
    GET_LISTE_BY_CAT_ACTIVITES_SUCCESS : "GET_LISTE_BY_CAT_ACTIVITES_SUCCESS",
    GET_LISTE_BY_CAT_ACTIVITES_FAILED : "GET_LISTE_BY_CAT_ACTIVITES_FAILED",


    DELETE_ACTIVITES_START : "DELETE_ACTIVITES_START",
    DELETE_ACTIVITES_SUCCESS : "DELETE_ACTIVITES_SUCCESS",
    DELETE_ACTIVITES_FAILED : "DELETE_ACTIVITES_FAILED",

    PUT_ARCHIVE_ACTIVITES_START : "PUT_ARCHIVE_ACTIVITES_START",
    PUT_ARCHIVE_ACTIVITES_SUCCESS : "PUT_ARCHIVE_ACTIVITES_SUCCESS",
    PUT_ARCHIVE_ACTIVITES_FAILED : "PUT_ARCHIVE_ACTIVITES_FAILED",







    CREATE_FLAH_INFO_START : "CREATE_FLAH_INFO_START",
    CREATE_FLAH_INFO_SUCCESS : "CREATE_FLAH_INFO_SUCCESS",
    CREATE_FLAH_INFO_FAILED : "CREATE_FLAH_INFO_FAILED",


    LIST_ADMIN_FLAH_INFO_START : "LIST_ADMIN_FLAH_INFO_START",
    LIST_ADMIN_FLAH_INFO_SUCCESS : "LIST_ADMIN_FLAH_INFO_SUCCESS",
    LIST_ADMIN_FLAH_INFO_FAILED : "LIST_ADMIN_FLAH_INFO_FAILED",

    LIST_VISITEURS_FLAH_INFO_START : "LIST_VISITEURS_FLAH_INFO_START",
    LIST_VISITEURS_FLAH_INFO_SUCCESS : "LIST_VISITEURS_FLAH_INFO_SUCCESS",
    LIST_VISITEURS_FLAH_INFO_FAILED : "LIST_VISITEURS_FLAH_INFO_FAILED",


    UPDATE_FLAH_INFO_START : "UPDATE_FLAH_INFO_START",
    UPDATE_FLAH_INFO_SUCCESS : "UPDATE_FLAH_INFO_SUCCESS",
    UPDATE_FLAH_INFO_FAILED : "UPDATE_FLAH_INFO_FAILED",

    ARCHIVER_FLAH_INFO_START : "ARCHIVER_FLAH_INFO_START",
    ARCHIVER_FLAH_INFO_SUCCESS : "ARCHIVER_FLAH_INFO_SUCCESS",
    ARCHIVER_FLAH_INFO_FAILED : "ARCHIVER_FLAH_INFO_FAILED",


    
}


