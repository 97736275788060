import React from 'react'
import SaisineBtnActions from './SaisineBtnActions'
import Articles from './DerniersPub'

const SousSections = () => {
  return (
    <> 
        <SaisineBtnActions />
        <div className='pt-4'>
          <h5 className='text-xl font-bold text-slate-500	'>Derniers article d’actualité</h5>
        </div>

        <Articles   />
    </>
  )
}

export default SousSections
