import React, { useEffect, useState } from 'react'
import ImagePresident from '../../../../images/ImagesCommission/Mot du PDT.jpg'
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { get_DetailMot_Du_PresidentAction } from '../../../../reduxComponents/Actions/ParametrePagesActions';
import { useNavigate } from 'react-router-dom'
import { BackendEntete } from '../../../../VariablesConfig';

const Mot_president = () => {

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const DataDetailStore = useSelector(state => state.get_Detail_MotDuPresident)
  const [DataDetail, setDataDetail] = useState()


  useEffect(() => {
    dispatch(get_DetailMot_Du_PresidentAction(1))
  }, [])






  useEffect(() => {
    if (DataDetailStore?.DataDetailMotPresident?.id) {

      setDataDetail(DataDetailStore?.DataDetailMotPresident)

    }
  }, [DataDetailStore?.DataDetailMotPresident?.id])

  const truncateText = (text, maxWords) => {

    const words = text.split(/\s+/);
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join(' ') + '...';
    } else {
      return text;
    }
  }




  const ComponentTronca = ({ longText, nbreTexte }) => {

    var contenuTexte = longText.replace(/<[^>]*>/g, '');
    const truncatedText = truncateText(contenuTexte, nbreTexte);

    return (
      <div>
        {truncatedText}
      </div>
    );
  }

  return (
    <div className='grid grid-cols-1 md:grid-cols-6 gap-4 md:items-center sectionAnime'>
      <div className='col-span-1 md:col-span-2 flex-col content-end  parentImage rounded-lg'>
        <div>
          <img className='ImgaePresident border-5 border-white' src={BackendEntete + DataDetail?.image_cover} alt="Mot du president de la commission de concurrence" />
        </div>
        <div className='text-white px-4 pb-4'>
          <spam className='text-2xl font-bold'>{DataDetail?.nom + ' ' + DataDetail?.prenom}</spam>
          <div>
            <spam>{DataDetail?.poste}</spam>
          </div>
        </div>
      </div>
      <div className='col-span-1 md:col-span-4 content-center md:pl-16'>
        <div>
          {
            DataDetail?.mot_president && (
              <p className='text-justify'><ComponentTronca longText={DataDetail?.mot_president} nbreTexte={150} /></p>

            ) 
          }
        </div>
        <div >
          <Button onClick={()=>navigate('/presentation/mot_du_president/')} className='VoirPlusPresident border-none rounded-lg' label='Voir plus' />
        </div>
      </div>
    </div>
  )
}

export default Mot_president
